import { nothing } from 'lit';
import { getTagNameFromElement } from '../../utils/get-tag-name-from-element.js';

/**
 * The `@required` decorator can be used to indicate that
 * a property is required. When used, if that property
 * is not defined, then the component will not render
 * and will produce an error message in the console.
 * Additionally, the name of the property will be added
 * to `pdsRequiredProperties` on the element.
 *
 * The `@required` decorator should be used in conjunction
 * with the `@property()` decorator.
 *
 * Implementation note: if you are expecting to extend the
 * class that is using this decorator, keep the following
 * in mind:
 *
 * Decorators are applied at design time, meaning they run
 * when the TypeScript code is transpiled to JavaScript,
 * not at runtime.
 *
 * When you extend a class, the decorator on the parent class
 * is applied to the parent class itself, not to the child class.
 *
 * The child class inherits the properties and methods of the
 * parent class, but it does not inherit the decorators.
 *
 * If you want the decorator to apply to both the parent and child
 * classes, you need to override the parent's property in the
 * child class and apply it to directly to the child property.
 *
 * Also, in order for the decorator to notify correctly in the
 * React components, you may need to add { reflect: true } to the
 * property in the web component.
 */
export function required(target: any, propertyKey: string) {
  const originalRender = target.render;

  // eslint-disable-next-line no-param-reassign
  target.pdsRequiredProperties ??= new Set();
  target.pdsRequiredProperties.add(propertyKey);

  // eslint-disable-next-line no-param-reassign
  target.render = function render() {
    if (
      (typeof this[propertyKey] === 'undefined' ||
        this[propertyKey] === null) &&
      // eslint-disable-next-line @typescript-eslint/dot-notation
      !this['_$changedProperties']?.has(propertyKey)
    ) {
      console.error(
        '"%s" is a required property of <%s /> but is undefined. Please check that you are passing all required properties.',
        propertyKey,
        getTagNameFromElement(this),
        this,
      );

      // If a required prop is not passed, the element should not render
      return nothing;
    }

    return originalRender.call(this);
  };
}
