import { html, isServer } from 'lit';
import { property, state } from 'lit/decorators.js';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './tabs.scss?inline';
import { PdsTabButton } from '../tab-button/tab-button';
import { PdsTabContent } from '../tab-content/tab-content';
import { PdsAccordion } from '../accordion/accordion';
import { requiredSlot } from '../../decorators/requiredSlot';

const variants = [
  'minimal',
  'contained',
  'contained-subtle',
  'filled',
  'filled-subtle',
  'subtle',
  'default',
] as const;
type TabsVariant = (typeof variants)[number];

/**
 * @summary This component sorts group of related information into categories of equal hierarchy
 *
 * @slot default Required: Holds the tab elements, pds-tab-button-list and pds-tab-content
 */
@customElement('pds-tabs', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsTabs extends PdsElement {
  /**
   * Style variant
   * - **default** renders the tab with the default colors.
   * - **subtle** renders the tab with the subtle colors.
   * - **contained** renders the tab with the default colors and the slot container with surrounding border.
   * - **contained-subtle** renders the tab with the subtle colors and the slot container with surrounding border.
   * - **filled** renders the tab with the default colors and the slot container with surrounding border and matching background.
   * - **filled-subtle** renders the tab with the subtle colors and the slot container with surrounding border and matching background.
   * - **minimal** renders minimal style to the tabs.
   */
  @property()
  variant: TabsVariant = 'default';

  /**
   * Adds inverted variation to the tabs
   */
  @property({ type: Boolean })
  inverted: boolean = false;

  /**
   * The ID of the tab button that should be opened on
   * initial render.  If undefined, it will default to the first tab.
   */
  @property({ type: String })
  initialTab?: string;

  /**
   * Indicates if the parent container is narrower than tab width
   * @internal
   */
  @state()
  showMobileMarkup: boolean = false;

  /**
   * Contains the total width of the tab buttons
   * @internal
   */
  @state()
  width: number = 0;

  /**
   * @internal
   */
  @state()
  tabButtons: NodeList | undefined;

  /** @internal */
  @state()
  ResizeObserver =
    !isServer && window && window.ResizeObserver
      ? window.ResizeObserver
      : ResizeObserverPolyfill;

  /** @internal */
  @state()
  tabButtonsWidth: number = 0;

  /**
   * Listen for resize events on parent and check if the parent is too narrow for the content
   *
   * @internal
   */
  // We can't actually call the observer, because Jest has no concept of element width
  /* istanbul ignore next */
  resizeObserver = new this.ResizeObserver((entries: any[]) => {
    entries.forEach(async () => this.getContainerSize());
  });

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
    };
  }

  protected override async firstUpdated() {
    super.firstUpdated();
    await this.openInitialTab();

    const tabButtons = this.querySelectorAll('pds-tab-button') as NodeList;

    this.tabButtons = tabButtons;

    this.tabButtons.forEach((element: Node) => {
      const elementAsTab = element as PdsTabButton;
      // Get the width of the button contents, and add 30px for padding (15px left, 15px right)
      const buttonContentWidth =
        elementAsTab.shadowRoot
          ?.querySelector('button > span')
          ?.getBoundingClientRect().width || 0;
      this.tabButtonsWidth += buttonContentWidth + 30;
    });
  }

  updated(changedProperties: Map<string, unknown>) {
    if (changedProperties.has('variant')) {
      this.variantChange();
    }
    this.resizeObserver.observe(this);
  }

  handleTabSelection(event: CustomEvent) {
    // Get the button ID from the event details
    const buttonId = event.detail.summary;
    const tabToOpen = this.querySelector(
      `pds-tab-button[buttonId=${buttonId}]`,
    ) as PdsTabButton;

    this.selectTab(tabToOpen);
  }

  handleKeyboardNavigation(event: CustomEvent) {
    if (event.detail.key === 'ArrowRight' && this.tabButtons) {
      this.tabButtons.forEach((element, index) => {
        const elementAsTab = element as PdsTabButton;
        if (
          this.tabButtons &&
          elementAsTab.buttonId === event.detail.tab &&
          this.tabButtons[index + 1]
        ) {
          this.selectTab(this.tabButtons[index + 1] as PdsTabButton, true);
        } else if (
          this.tabButtons &&
          elementAsTab.buttonId === event.detail.tab
        ) {
          this.selectTab(this.tabButtons[0] as PdsTabButton, true);
        }
      });
    } else if (event.detail.key === 'ArrowLeft' && this.tabButtons) {
      this.tabButtons.forEach((element, index) => {
        const elementAsTab = element as PdsTabButton;
        if (
          this.tabButtons &&
          elementAsTab.buttonId === event.detail.tab &&
          this.tabButtons[index - 1]
        ) {
          this.selectTab(this.tabButtons[index - 1] as PdsTabButton, true);
        } else if (
          this.tabButtons &&
          elementAsTab.buttonId === event.detail.tab
        ) {
          this.selectTab(
            this.tabButtons[this.tabButtons.length - 1] as PdsTabButton,
            true,
          );
        }
      });
    } else if (event.detail.key === 'Home' && this.tabButtons) {
      this.selectTab(this.tabButtons[0] as PdsTabButton, true);
    } else if (event.detail.key === 'End' && this.tabButtons) {
      this.selectTab(
        this.tabButtons[this.tabButtons.length - 1] as PdsTabButton,
        true,
      );
    }
  }

  handleAccordionOpen(event: CustomEvent) {
    // Query for all the accordion elements
    const accordionElements = this.shadowRoot?.querySelectorAll(
      'pds-accordion',
    ) as NodeListOf<PdsAccordion>;
    // Get the clicked accordion, so we can make sure not to close it
    const clickedAccordion = event.target as PdsAccordion;

    accordionElements.forEach((accordionElement) => {
      // Close everything except the clicked element
      if (accordionElement !== clickedAccordion) {
        accordionElement.close();
      }
    });

    // Make sure the tab properties get updated as well,
    // in case the user goes back to a larger screen size
    const tabButtons = this.querySelectorAll(
      'pds-tab-button',
    ) as NodeListOf<PdsTabButton>;
    tabButtons.forEach((tabButton: PdsTabButton) => {
      // eslint-disable-next-line no-param-reassign
      tabButton.selected =
        // @ts-expect-error - we know this type is valid because we set data-id ourselves
        tabButton.buttonId === clickedAccordion.attributes['data-id'].value;
    });
  }

  selectTab(tab: PdsTabButton, keyboardNavigation = false) {
    // Upon tab selection, find the corresponding tab button and open it
    const tabButtons = this.querySelectorAll(
      'pds-tab-button',
    ) as NodeListOf<PdsTabButton>;
    tabButtons.forEach((tabButton: PdsTabButton) => {
      // First we need to de-select all other tab buttons
      tabButton.deSelect();
      if (tabButton.buttonId === tab.buttonId) {
        // If this is the right one, we need to select it
        tabButton.select(keyboardNavigation);
      }
    });
    // Upon tab selection, find the corresponding tab content and open it
    const tabContents = this.querySelectorAll(
      'pds-tab-content',
    ) as NodeListOf<PdsTabContent>;
    tabContents.forEach((tabContent) => {
      // First we need to close all other tab contents
      // eslint-disable-next-line no-param-reassign
      tabContent.open = false;

      if (tabContent.ariaLabelledby === tab.buttonId) {
        // If this is the right one, we need to open it
        // eslint-disable-next-line no-param-reassign
        tabContent.open = true;
      }
    });
  }

  async openInitialTab() {
    // We need to let subcomponents render before we try to open the tab
    await this.updateComplete;
    let tabToOpen;

    if (this.initialTab) {
      tabToOpen = this.querySelector(
        `pds-tab-button[buttonId=${this.initialTab}]`,
      ) as PdsTabButton;
    } else {
      // If no initial tab is specified, default to the first tab
      tabToOpen = this.querySelector('pds-tab-button') as PdsTabButton;
    }

    this.selectTab(tabToOpen);
  }

  async variantChange() {
    const tabElement = this.querySelectorAll(
      'pds-tab-button',
    ) as NodeListOf<PdsTabButton>;
    const contentElement = this.querySelectorAll(
      'pds-tab-content',
    ) as NodeListOf<PdsTabContent>;
    await this.updateComplete;

    tabElement.forEach((element: PdsTabButton) => {
      if (element.shadowRoot) {
        const tabButton = element.shadowRoot.querySelector(
          'button',
        ) as HTMLElement;
        const buttonTabVariantClasses = variants.map((variant) =>
          element.classMod(variant),
        );
        if (tabButton.classList) {
          tabButton.classList.forEach((elementClass) => {
            if (buttonTabVariantClasses.includes(elementClass)) {
              tabButton.classList.remove(elementClass);
            }
          });

          tabButton.classList.add(element.classMod(this.variant));

          if (this.inverted) {
            tabButton.classList.add(element.classMod('inverted'));
          }
        }
      }
    });

    contentElement.forEach((element: PdsTabContent) => {
      if (element.shadowRoot) {
        const tabContent = element.shadowRoot.querySelector(
          '.pds-c-tab-content',
        ) as HTMLElement;
        const tabContentVariantClasses = variants.map((variant) =>
          element.classMod(variant),
        );

        tabContent.classList.forEach((elementClass) => {
          if (tabContentVariantClasses.includes(elementClass)) {
            tabContent.classList.remove(elementClass);
          }
        });

        tabContent.classList.add(element.classMod(this.variant));

        if (this.inverted) {
          tabContent.classList.add(element.classMod('inverted'));
        }
      }
    });
  }

  getMobileMarkup() {
    const tabButtonElements = this.querySelectorAll(
      'pds-tab-button',
    ) as NodeListOf<PdsTabButton>;
    const tabContentElements = this.querySelectorAll(
      'pds-tab-content',
    ) as NodeListOf<PdsTabContent>;
    const tabButtonArray: PdsTabButton[] = [];
    const tabContentArray: PdsTabContent[] = [];
    tabButtonElements.forEach((element: PdsTabButton) => {
      tabButtonArray.push(element);
    });
    tabContentElements.forEach((element: PdsTabContent) => {
      tabContentArray.push(element);
    });
    const accordionContent = tabButtonArray.map(
      (tabButton, index) =>
        html`<pds-accordion
          class="${this.classEl('accordion')}"
          variant=${this.inverted ? 'inverted' : 'default'}
          ?open=${tabButton.selected}
          @pds-accordion-open=${this.handleAccordionOpen}
          data-id=${tabButton.buttonId}
          ><span slot="summary-title">${unsafeHTML(tabButton.innerHTML)}</span
          ><span slot="accordion-content"
            >${unsafeHTML(tabContentArray[index].innerHTML)}</span
          >
        </pds-accordion>`,
    );
    return accordionContent;
  }

  getContainerSize() {
    const controlWidth = this.clientWidth;
    this.showMobileMarkup = this.tabButtonsWidth > controlWidth;
  }

  @requiredSlot(['default'])
  render() {
    if (this.showMobileMarkup) {
      return html`${this.getMobileMarkup()}`;
    }

    return html`<div
      class=${this.getClass()}
      @pds-tab-button-click=${this.handleTabSelection}
      @pds-tab-button-keypress=${this.handleKeyboardNavigation}
    >
      <slot></slot>
    </div>`;
  }
}
