import { html } from 'lit';
import { pdsCustomIconElement as customElement } from '../../../decorators/pds-icon';
import { PdsCustomIcon } from '../PdsCustomIcon';

@customElement('pds-icon-star-half')
export class PdsIconStarHalf extends PdsCustomIcon {
  render() {
    return html`<svg
      width=${this.getSize(this.size)}
      height=${this.getSize(this.size)}
      viewBox="0 0 40 40"
      fill="none"
      strokeWidth="2"
      aria-hidden="true"
      display="block"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6816 2.02451C20.9516 1.87451 21.7116 2.36451 22.3416 3.43451C23.8116 5.93451 24.8316 9.04451 26.3216 11.5145C26.7016 12.1445 27.1016 12.3745 27.8216 12.5245C30.5516 13.0745 33.5116 13.2445 36.2716 13.7445C38.5916 14.1645 39.5516 16.1945 38.1316 18.1445L31.5316 24.5745C31.0916 25.1145 31.0316 25.5345 31.0816 26.2145C31.3116 29.0645 32.3716 32.2045 32.6016 35.0445C32.7616 37.0845 30.9616 38.5145 29.0216 37.8245C26.3816 36.5545 23.8616 35.0045 21.2316 33.7245C20.7016 33.4645 20.2216 33.1845 19.6116 33.4745C17.0416 34.7245 14.5816 36.2245 12.0116 37.4745C9.72164 38.5845 7.41164 37.8645 7.40164 35.0845C7.69164 32.1645 8.70164 29.0345 8.93164 26.1445C8.97164 25.6745 8.93164 25.3145 8.71164 24.8945C6.53164 22.5045 4.06164 20.3545 1.80164 18.0445C0.471643 16.0345 1.46164 14.1545 3.74164 13.7245C6.61164 13.1945 9.60164 12.9645 12.4816 12.4445C13.0516 12.2945 13.4516 11.9245 13.7316 11.4145C15.1916 8.77451 16.3216 5.83451 17.7916 3.18451C18.2016 2.54451 18.9216 2.08451 19.6816 2.00451V2.02451ZM20.3816 7.29451V29.8845C20.9916 29.9545 21.6016 30.0945 22.1616 30.3445L28.9116 33.8645L27.6016 26.2645C27.4916 24.6945 28.0616 23.2345 29.1116 22.0845L34.3716 16.9945C34.3716 16.9145 33.9716 16.8745 33.8816 16.8645C31.6316 16.4845 29.1916 16.3245 26.9716 15.8645C25.4516 15.5445 24.1716 14.6545 23.3816 13.3145L20.3716 7.30451L20.3816 7.29451Z"
        fill="${this.color}"
      />
    </svg>`;
  }
}
