import { QueryResult } from "@apollo/client/react/types/types";
import { FIND_FEATURES_QUERY } from "../atoms/Queries";
import { Feature, FeatureType } from "../generated/graphql";
import { useQuery } from "@apollo/client";

export function useFeature(featureType: FeatureType): QueryResult {
    const queryResult = useQuery(FIND_FEATURES_QUERY)
    const findFeatures = queryResult.data?.findFeatures as Feature[];
    if(findFeatures) {
        queryResult.data = findFeatures.some(feature => feature.type === featureType)
    }
    return queryResult;
}
