import '@principal/design-system-icons-web/alert-circle';
import { html, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { required } from '../../decorators/required.js';
import { PdsElement } from '../PdsElement.js';
import { getTagNameFromElement } from '../../../utils/get-tag-name-from-element.js';

/**
 * The base class for form fieldset elements.
 *
 * @slot legend Optional: The legend for the fieldset element
 */
export abstract class PdsFormFieldsetElement extends PdsElement {
  /**
   * @internal
   * The underlying HTML form fieldset element.
   * This should be implemented with `@query`
   * in the implementing class.
   */
  abstract fieldset: HTMLFieldSetElement;

  protected override firstUpdated() {
    super.firstUpdated();

    this.labelForRequired = `${this.translateText('required-group')}`;
  }

  /**
   * Makes the legend screen-reader-only and visually hidden.
   * Hiding the legend is **strongly discouraged** and should only
   * be used when descriptive text exists elsewhere on the page.
   * This will hide help text too, if applicable.
   */
  @property({ type: Boolean })
  hideLegend: boolean = false;

  /**
   * The name of the form fieldset.
   * For accessibility and to follow best form practices, the name on
   * the fieldset should match the name of all of your inputs within.
   * This is a **required** property.
   */
  @required
  @property()
  name: string;

  /**
   * Takes the value of the id attribute of a form element you want the
   * fieldset to be a part of. Input elements inside the fieldset should
   * have a form attribute with the same value.
   */
  @property()
  form: string;

  /**
   * The legend for the fieldset element. Must be plain text.
   * If the legend requires additional markup (e.g., superscript),
   * then use the legend slot instead.
   */
  @property({ reflect: true })
  legend?: string;

  /**
   * The error message to display when the form
   * field is in an invalid state.
   */
  @property()
  errorMessage: string;

  /**
   * Indicates that the form field is required.
   *
   */
  @property({ type: Boolean })
  required: boolean = false;

  /**
   * Text that will read out with a screenreader to convey
   * that a selection in the checkbox group is required.
   * Can be customized, but defaults to "At least one option
   * within the group must be checked."
   *
   */
  @property({ type: String })
  labelForRequired: string =
    'At least one option within the group must be checked.';

  /**
   * Indicates that the form field is disabled.
   *
   */
  @property({ type: Boolean })
  disabled: boolean = false;

  /**
   * The additional context used to assist the
   * user in filling out the form field.
   */
  @property()
  helpText?: string;

  /**
   * Determines whether the element includes `helpText`
   */
  hasHelpText() {
    return !!this.helpText || !!this.slotNotEmpty('helpText');
  }

  /**
   * Gets the appropriate `aria-describedby` value
   * based on the existence of `helpText` and `errorMessage`.
   *
   * @returns space-seperated strings of ids
   */
  getAriaDescribedBy() {
    return [
      this.hasHelpText() ? `${this.name}-help-text` : '',
      this.errorMessage ? `${this.name}-error-message` : '',
    ]
      .filter(Boolean)
      .join(' ');
  }

  /**
   * Determines whether the element has a label.
   * Console logs an error, if not.
   */
  verifyLegend() {
    const hasLegend = !!this.legend || !!this.slotNotEmpty('legend');

    if (!hasLegend) {
      console.error(
        '"legend" is required as a property or as a slot in <%s /> but is undefined',
        getTagNameFromElement(this),
      );
    }

    return hasLegend;
  }

  /**
   * Creates an HTML template for the legend
   * that is tied to the form field via the `for`
   * attribute and adds the required indicator,
   * if applicable.
   *
   * @returns an HTML template for the legend
   */
  protected legendTemplate() {
    return html`<legend class="${this.classEl('legend')}">
        <slot name="legend">${this.legend}</slot>
        ${this.required
          ? html`<span
                class="${this.classEl('required-indicator')}"
                aria-hidden="true"
              >
                *
              </span>
              <span class="pds-u-sr-only"> ${this.labelForRequired}</span>`
          : nothing}
      </legend>
      ${this.helpTextTemplate()} ${this.errorMessageTemplate()}`;
  }

  /**
   * Creates an HTML template for help text,
   * if the element has help text.
   * Adds an `id` that is included in the form field's
   * aria-describedby attribute.
   *
   * @returns an HTML template for help text
   */
  protected helpTextTemplate() {
    return this.hasHelpText()
      ? html`<div
          class="${this.classEl('help-text')}"
          id="${this.name}-help-text"
        >
          <slot name="help-text">${this.helpText}</slot>
        </div>`
      : nothing;
  }

  /**
   * Creates an HTML template for error message,
   * if the `errorMessage` prop is defined.
   * Adds an `id` that is included in the form field's
   * aria-describedby attribute.
   *
   * @returns an HTML template for help text
   */
  protected errorMessageTemplate() {
    return this.errorMessage
      ? html`<div
          class="${this.classEl('error-message')}"
          id="${this.name}-error-message"
        >
          <pds-icon-alert-circle></pds-icon-alert-circle>
          ${this.errorMessage}
        </div>`
      : nothing;
  }
}
