// disable requiredSlot lint until requiredSlot error can be investigated for this component
/* eslint-disable @nx/workspace-enforce-required-slot-decorator */

/* eslint-disable import/no-duplicates */
import { html, nothing } from 'lit';
import {
  property,
  query,
  queryAssignedElements,
  state,
} from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsFormFieldsetElement } from '../pds-form-fieldset-element/PdsFormFieldsetElement';
import { PdsCheckbox } from '../checkbox/checkbox';
import '../checkbox/checkbox';
import styles from './checkbox-group.scss?inline';

/**
 * @summary This component, used with <pds-checkbox>, allow users
 *   to select one or more options from a list of choices.
 *
 * @slot default Required: Use this slot to pass the actual checkbox. They should be <pds-checkbox> elements.
 * @slot help-text Optional: Use this slot instead of the helpText property, if the help text requires additonal markup.
 */
@customElement('pds-checkbox-group', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsCheckboxGroup extends PdsFormFieldsetElement {
  field: HTMLElement;

  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
  }

  /** @internal */
  @query('fieldset')
  fieldset: HTMLFieldSetElement;

  /**
   * The amount of space between the checkboxes.
   *
   * - **default**
   * - **sm** condense the spacing between checkboxes
   */
  @property()
  spacing: 'sm' | 'default' = 'default';

  /**
   * Style variant
   * - **default** renders the standard checkbox-group color variant
   * - **inverted** renders the inverted checkbox-group color variant
   */
  @property()
  variant: 'default' | 'inverted' = 'default';

  /**
   * @internal
   *
   * This boolean is used to determine if the radio-group has a legend
   * It will be validated in the ValidationTimeout
   */
  @state()
  hasLegend: boolean = true;

  /** @internal */
  @queryAssignedElements({ selector: 'pds-checkbox' })
  checkboxes!: Array<PdsCheckbox>;

  /**
   * @internal
   * State to determine the appropriate checkbox variants are used in conjunction with checkbox group
   */
  @state()
  isValidVariant = true;

  protected override async firstUpdated() {
    super.firstUpdated();
    await this.updateComplete;
    this.setCheckboxes();

    this.checkboxes.forEach((checkbox) => {
      // handle the checkbox changes and dispatch a change at the group level
      checkbox.addEventListener('pds-checkbox-change', (e: Event) => {
        // consumers should listen to 'pds-checkbox-group-change'
        // instead of the 'pds-checkbox-change' event
        e.stopPropagation();
        this.dispatchEvent(
          new Event('pds-checkbox-group-change', {
            bubbles: true,
            composed: true,
          }),
        );
      });
    });

    // TODOv4: [ValidationTimeout] - Check to see if this setTimeout is still needed
    // This validation logic needs to happen late because it messes
    // with the render content and leads to hydration issues (and issues with child elements)
    // This may be something we can remove if we can find a better way to handle this
    // The validation is only for development - so end users should never see the render content change
    setTimeout(() => {
      if (!this.verifyLegend()) {
        this.hasLegend = false;
      }
      this.checkCheckboxVariant();
    }, 1000);
  }

  /** set the name, disabled property, and error state of the children checkboxes */
  protected setCheckboxes() {
    this.checkboxes.forEach((checkbox) => {
      if (!checkbox.name && this.name) {
        checkbox.setAttribute('name', this.name);
      }

      // If disabled attribute is set on checkbox-group, turn all checkboxes to disabled
      if (this.disabled && !checkbox.disabled) {
        checkbox.setAttribute('disabled', 'true');
      }

      if (this.errorMessage) {
        checkbox.setAttribute('error', 'true');
      } else {
        checkbox.removeAttribute('error');
      }
    });
  }

  /** validate that the proper the variant property of the children checkboxes is used */
  protected checkCheckboxVariant() {
    if (this.variant === 'inverted') {
      this.checkboxes.forEach((checkbox) => {
        if (checkbox.variant !== 'inverted') {
          console.error(
            'Please ensure checkboxes have the same variant property as checkbox group.',
          );
          this.isValidVariant = false;
        }
      });
    } else if (this.variant === 'default') {
      this.checkboxes.forEach((checkbox) => {
        if (checkbox.variant !== 'default') {
          console.error(
            'Please ensure checkboxes have the same variant property as checkbox group.',
          );
          this.isValidVariant = false;
        }
      });
    }
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
      'spacing-sm': this.spacing === 'sm',
      'hidden-legend': this.hideLegend,
      'is-disabled': !!this.disabled,
      'is-required': !!this.required,
      'is-error': !!this.errorMessage,
    };
  }

  render() {
    if (!this.isValidVariant || !this.hasLegend) {
      return nothing;
    }

    // have to use role group to allow required property for now since HTML doesn't support it
    // WCAG issue: https://github.com/w3c/aria/issues/1322
    // HTML issue: https://github.com/whatwg/html/issues/6868
    return html`<fieldset
      class="${this.getClass()}"
      name="${this.name}"
      form="${this.form}"
      role="group"
      aria-invalid=${this.errorMessage ? 'true' : 'false'}
      aria-required=${this.required ? 'true' : nothing}
      aria-describedby=${this.getAriaDescribedBy() || nothing}
      ?disabled=${this.disabled}
    >
      <div class="${this.classEl('text-group')}">${this.legendTemplate()}</div>
      <slot></slot>
    </fieldset>`;
  }
}
