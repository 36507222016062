export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigDecimal: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  FieldSet: { input: any; output: any; }
  /**
   * Scalar that returns a String with all but the last 4 characters masked.  For example 123456789, is masked with *****6789.  This can ONLY be
   * used on Types and not Inputs
   */
  MaskedString: { input: any; output: any; }
  Upload: { input: any; output: any; }
  link__Import: { input: any; output: any; }
  link__Purpose: { input: any; output: any; }
};

export type AcceleratedSurvivorBenefit = {
  __typename?: 'AcceleratedSurvivorBenefit';
  payment?: Maybe<Payment>;
  survivorBenefitId: Scalars['String']['output'];
};

export type AccidentBenefitPayable = {
  __typename?: 'AccidentBenefitPayable';
  childAmount: Scalars['Float']['output'];
  employeeAmount: Scalars['Float']['output'];
  spouseAmount: Scalars['Float']['output'];
  type: AccidentProvisionType;
};

export type AccidentBenefitSchedule = {
  __typename?: 'AccidentBenefitSchedule';
  groups: Array<Maybe<AccidentBenefitScheduleGroup>>;
};

export type AccidentBenefitScheduleGroup = {
  __typename?: 'AccidentBenefitScheduleGroup';
  benefitsPayable: Array<AccidentBenefitPayable>;
  dollarLimit?: Maybe<Scalars['Float']['output']>;
  limitMultiplier: Scalars['Float']['output'];
  maxDaysUntilSurgicalRepair?: Maybe<Scalars['Int']['output']>;
  maxDaysUntilTreatment?: Maybe<Scalars['Int']['output']>;
  maxOccurrencesPerAccident?: Maybe<Scalars['Int']['output']>;
  maxPaymentType: MaxPaymentType;
  requiresAccidentalDeathAndDismembermentCoverage: Scalars['Boolean']['output'];
  type: AccidentBenefitScheduleGroupType;
};

export enum AccidentBenefitScheduleGroupType {
  AccidentalDeathAndDismemberment = 'ACCIDENTAL_DEATH_AND_DISMEMBERMENT',
  AccidentalIngestionOfControlledDrugs = 'ACCIDENTAL_INGESTION_OF_CONTROLLED_DRUGS',
  Ambulance = 'AMBULANCE',
  Burn = 'BURN',
  Coma = 'COMA',
  Concussion = 'CONCUSSION',
  DentalInjury = 'DENTAL_INJURY',
  Dislocation = 'DISLOCATION',
  EyeInjury = 'EYE_INJURY',
  Fracture = 'FRACTURE',
  InjuryNotSpecificallyListed = 'INJURY_NOT_SPECIFICALLY_LISTED',
  InternalInjury = 'INTERNAL_INJURY',
  KneeCartilage = 'KNEE_CARTILAGE',
  LossOfSenses = 'LOSS_OF_SENSES',
  LossOfUse = 'LOSS_OF_USE',
  MinorSurgeryKneeCartilage = 'MINOR_SURGERY_KNEE_CARTILAGE',
  MinorSurgeryRupturedDisc = 'MINOR_SURGERY_RUPTURED_DISC',
  MinorSurgeryTendonLigamentRotatorCuff = 'MINOR_SURGERY_TENDON_LIGAMENT_ROTATOR_CUFF',
  RupturedDisc = 'RUPTURED_DISC',
  TendonLigamentRotatorCuff = 'TENDON_LIGAMENT_ROTATOR_CUFF'
}

export type AccidentConfiguration = PolicyCoverageConfiguration & {
  __typename?: 'AccidentConfiguration';
  benefitSchedule: AccidentBenefitSchedule;
  contributory?: Maybe<Scalars['Boolean']['output']>;
  coverageChangesOn?: Maybe<AccidentCoverageChangesOn>;
  coverageType?: Maybe<AccidentCoverageType>;
  domesticPartner?: Maybe<DomesticPartnerEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  employerContributoryPercentage?: Maybe<Scalars['Int']['output']>;
  enrollmentType?: Maybe<EnrollmentTypeEnum>;
  includesAccidentalDeathAndDismemberment: Scalars['Boolean']['output'];
  lapseReinstatementUnderwriting?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  minimumWeeklyHours?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<ProductName>;
  openEnrollment: OpenEnrollmentType;
  periodOfLimitedActivity?: Maybe<Scalars['Boolean']['output']>;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  portability?: Maybe<PortabilityEnum>;
  priorCoverage?: Maybe<Scalars['Boolean']['output']>;
  productShortName: Scalars['String']['output'];
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  ratingLives?: Maybe<Scalars['Int']['output']>;
  sabbatical?: Maybe<Scalars['Boolean']['output']>;
  searchDate: Scalars['String']['output'];
  terminationDate?: Maybe<Scalars['String']['output']>;
  voluntary: Scalars['Boolean']['output'];
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
  waiveActivelyAtWork?: Maybe<Scalars['Boolean']['output']>;
  wellnessBenefitAmount?: Maybe<WellnessBenefitAmountEnum>;
};

export type AccidentConfigurationsInput = {
  actualParticipationPercentage?: InputMaybe<Scalars['Int']['input']>;
  benefitPlanYear?: InputMaybe<Scalars['String']['input']>;
  bookId?: InputMaybe<Scalars['String']['input']>;
  coverageChangesOn?: InputMaybe<AccidentCoverageChangesOn>;
  coverageType?: InputMaybe<AccidentCoverageType>;
  domesticPartner?: InputMaybe<DomesticPartnerEnum>;
  eligibleLives?: InputMaybe<Scalars['Int']['input']>;
  enrollmentType?: InputMaybe<EnrollmentTypeEnum>;
  includesAccidentalDeathAndDismemberment?: InputMaybe<Scalars['Boolean']['input']>;
  lapseReinstatementUnderwriting?: InputMaybe<Scalars['Int']['input']>;
  memberGroupName: Scalars['String']['input'];
  minimumWeeklyHours?: InputMaybe<Scalars['Int']['input']>;
  periodOfLimitedActivity?: InputMaybe<Scalars['Boolean']['input']>;
  planId?: InputMaybe<Scalars['String']['input']>;
  planSequenceNumber?: InputMaybe<Scalars['String']['input']>;
  portability?: InputMaybe<PortabilityEnum>;
  priorCoverage?: InputMaybe<Scalars['Boolean']['input']>;
  rateGuaranteeEndDate?: InputMaybe<Scalars['String']['input']>;
  rateGuaranteePeriod?: InputMaybe<RateGuaranteePeriodEnum>;
  rateGuaranteeReason?: InputMaybe<RateGuaranteeReasonEnum>;
  ratingLives?: InputMaybe<Scalars['Int']['input']>;
  ratingPlanYear?: InputMaybe<Scalars['String']['input']>;
  waiveActivelyAtWork?: InputMaybe<Scalars['Boolean']['input']>;
  wellnessBenefitAmount?: InputMaybe<WellnessBenefitAmountEnum>;
};

export enum AccidentCoverageChangesOn {
  FirstOfTheMonth = 'FIRST_OF_THE_MONTH',
  Other = 'OTHER',
  PolicyAnniversary = 'POLICY_ANNIVERSARY',
  Standard = 'STANDARD'
}

export enum AccidentCoverageType {
  None = 'NONE',
  OffTheJob = 'OFF_THE_JOB',
  TwentyFourHours = 'TWENTY_FOUR_HOURS'
}

export type AccidentDependentPolicyCoverage = PolicyCoverage & {
  __typename?: 'AccidentDependentPolicyCoverage';
  bookId?: Maybe<Scalars['String']['output']>;
  contributoryType?: Maybe<ContributoryTypeEnum>;
  coverageHistory: Array<PolicyCoverageHistory>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  maxAge?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  name: ProductName;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
  terminationDateRule?: Maybe<EffectiveDateRuleEnum>;
};

export type AccidentInput = {
  configuration: Array<AccidentConfigurationsInput>;
  effectiveDate: Scalars['String']['input'];
  policyId: Scalars['String']['input'];
};

export type AccidentPolicyCoverage = PolicyCoverage & {
  __typename?: 'AccidentPolicyCoverage';
  actualParticipationPercentage?: Maybe<Scalars['Int']['output']>;
  amendmentInProgress: Scalars['Boolean']['output'];
  benefitPlanYear?: Maybe<Scalars['String']['output']>;
  bookId?: Maybe<Scalars['String']['output']>;
  configuration: AccidentConfiguration;
  coverageHistory: Array<PolicyCoverageHistory>;
  dependent?: Maybe<AccidentDependentPolicyCoverage>;
  /** @deprecated use configuration.domesticPartner */
  domesticPartner?: Maybe<DomesticPartnerEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  eligibleLives?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  /** @deprecated use configuration.minimumWeeklyHours */
  minimumWeeklyHours?: Maybe<Scalars['Int']['output']>;
  name: ProductName;
  planDescription?: Maybe<Scalars['String']['output']>;
  planId?: Maybe<Scalars['String']['output']>;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  ratingPlanYear?: Maybe<Scalars['String']['output']>;
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
};

export type AccidentProvisionInstance = ProvisionInstanceInterface & {
  __typename?: 'AccidentProvisionInstance';
  payment?: Maybe<Payment>;
  provision: Provision;
  provisionInstanceId: Scalars['String']['output'];
  surgeryDate?: Maybe<Scalars['Date']['output']>;
  treatmentDate?: Maybe<Scalars['Date']['output']>;
};

export type AccidentProvisionInstanceInput = {
  provisionInstanceId?: InputMaybe<Scalars['String']['input']>;
  provisionType?: InputMaybe<ProvisionType>;
  surgeryDate?: InputMaybe<Scalars['Date']['input']>;
  treatmentDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum AccidentProvisionType {
  AccidentalIngestionOfControlledDrugsHospitalConfinement = 'ACCIDENTAL_INGESTION_OF_CONTROLLED_DRUGS_HOSPITAL_CONFINEMENT',
  AccidentalIngestionOfControlledDrugsOutpatientExpenses = 'ACCIDENTAL_INGESTION_OF_CONTROLLED_DRUGS_OUTPATIENT_EXPENSES',
  Ambulance = 'AMBULANCE',
  Coma = 'COMA',
  Concussion = 'CONCUSSION',
  DentalInjury = 'DENTAL_INJURY',
  DislocationClosedReductionAllOtherJointsNotListed = 'DISLOCATION_CLOSED_REDUCTION_ALL_OTHER_JOINTS_NOT_LISTED',
  DislocationClosedReductionAllOtherJointsNotListedWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_ALL_OTHER_JOINTS_NOT_LISTED_WITH_ANESTHESIA',
  DislocationClosedReductionAnkle = 'DISLOCATION_CLOSED_REDUCTION_ANKLE',
  DislocationClosedReductionAnkleWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_ANKLE_WITH_ANESTHESIA',
  DislocationClosedReductionCollarbone = 'DISLOCATION_CLOSED_REDUCTION_COLLARBONE',
  DislocationClosedReductionCollarboneWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_COLLARBONE_WITH_ANESTHESIA',
  DislocationClosedReductionElbow = 'DISLOCATION_CLOSED_REDUCTION_ELBOW',
  DislocationClosedReductionElbowWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_ELBOW_WITH_ANESTHESIA',
  DislocationClosedReductionFoot = 'DISLOCATION_CLOSED_REDUCTION_FOOT',
  DislocationClosedReductionFootWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_FOOT_WITH_ANESTHESIA',
  DislocationClosedReductionHand = 'DISLOCATION_CLOSED_REDUCTION_HAND',
  DislocationClosedReductionHandWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_HAND_WITH_ANESTHESIA',
  DislocationClosedReductionHip = 'DISLOCATION_CLOSED_REDUCTION_HIP',
  DislocationClosedReductionHipWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_HIP_WITH_ANESTHESIA',
  DislocationClosedReductionKnee = 'DISLOCATION_CLOSED_REDUCTION_KNEE',
  DislocationClosedReductionKneeWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_KNEE_WITH_ANESTHESIA',
  DislocationClosedReductionLowerJaw = 'DISLOCATION_CLOSED_REDUCTION_LOWER_JAW',
  DislocationClosedReductionLowerJawWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_LOWER_JAW_WITH_ANESTHESIA',
  DislocationClosedReductionPartialAllOtherJointsNotListed = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_ALL_OTHER_JOINTS_NOT_LISTED',
  DislocationClosedReductionPartialAnkle = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_ANKLE',
  DislocationClosedReductionPartialCollarbone = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_COLLARBONE',
  DislocationClosedReductionPartialElbow = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_ELBOW',
  DislocationClosedReductionPartialFoot = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_FOOT',
  DislocationClosedReductionPartialHand = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_HAND',
  DislocationClosedReductionPartialHip = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_HIP',
  DislocationClosedReductionPartialKnee = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_KNEE',
  DislocationClosedReductionPartialLowerJaw = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_LOWER_JAW',
  DislocationClosedReductionPartialShoulder = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_SHOULDER',
  DislocationClosedReductionPartialWrist = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_WRIST',
  DislocationClosedReductionShoulder = 'DISLOCATION_CLOSED_REDUCTION_SHOULDER',
  DislocationClosedReductionShoulderWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_SHOULDER_WITH_ANESTHESIA',
  DislocationClosedReductionWrist = 'DISLOCATION_CLOSED_REDUCTION_WRIST',
  DislocationClosedReductionWristWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_WRIST_WITH_ANESTHESIA',
  DislocationOpenReductionAllOtherJointsNotListed = 'DISLOCATION_OPEN_REDUCTION_ALL_OTHER_JOINTS_NOT_LISTED',
  DislocationOpenReductionAnkle = 'DISLOCATION_OPEN_REDUCTION_ANKLE',
  DislocationOpenReductionCollarbone = 'DISLOCATION_OPEN_REDUCTION_COLLARBONE',
  DislocationOpenReductionElbow = 'DISLOCATION_OPEN_REDUCTION_ELBOW',
  DislocationOpenReductionFoot = 'DISLOCATION_OPEN_REDUCTION_FOOT',
  DislocationOpenReductionHand = 'DISLOCATION_OPEN_REDUCTION_HAND',
  DislocationOpenReductionHip = 'DISLOCATION_OPEN_REDUCTION_HIP',
  DislocationOpenReductionKnee = 'DISLOCATION_OPEN_REDUCTION_KNEE',
  DislocationOpenReductionLowerJaw = 'DISLOCATION_OPEN_REDUCTION_LOWER_JAW',
  DislocationOpenReductionPartialAllOtherJointsNotListed = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_ALL_OTHER_JOINTS_NOT_LISTED',
  DislocationOpenReductionPartialAnkle = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_ANKLE',
  DislocationOpenReductionPartialCollarbone = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_COLLARBONE',
  DislocationOpenReductionPartialElbow = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_ELBOW',
  DislocationOpenReductionPartialFoot = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_FOOT',
  DislocationOpenReductionPartialHand = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_HAND',
  DislocationOpenReductionPartialHip = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_HIP',
  DislocationOpenReductionPartialKnee = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_KNEE',
  DislocationOpenReductionPartialLowerJaw = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_LOWER_JAW',
  DislocationOpenReductionPartialShoulder = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_SHOULDER',
  DislocationOpenReductionPartialWrist = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_WRIST',
  DislocationOpenReductionShoulder = 'DISLOCATION_OPEN_REDUCTION_SHOULDER',
  DislocationOpenReductionWrist = 'DISLOCATION_OPEN_REDUCTION_WRIST',
  DismembermentBothFeetSeveredAboveAnkle = 'DISMEMBERMENT_BOTH_FEET_SEVERED_ABOVE_ANKLE',
  DismembermentBothHandsSeveredAboveWrist = 'DISMEMBERMENT_BOTH_HANDS_SEVERED_ABOVE_WRIST',
  DismembermentOneFootSeveredAboveAnkle = 'DISMEMBERMENT_ONE_FOOT_SEVERED_ABOVE_ANKLE',
  DismembermentOneHandAndOneFoot = 'DISMEMBERMENT_ONE_HAND_AND_ONE_FOOT',
  DismembermentOneHandSeveredAboveWrist = 'DISMEMBERMENT_ONE_HAND_SEVERED_ABOVE_WRIST',
  DismembermentOneOrMoreFingers = 'DISMEMBERMENT_ONE_OR_MORE_FINGERS',
  DismembermentOneOrMoreToes = 'DISMEMBERMENT_ONE_OR_MORE_TOES',
  DismembermentThumbAndIndexFinger = 'DISMEMBERMENT_THUMB_AND_INDEX_FINGER',
  EyeInjury = 'EYE_INJURY',
  FractureClosedReductionAllOtherBonesNotListed = 'FRACTURE_CLOSED_REDUCTION_ALL_OTHER_BONES_NOT_LISTED',
  FractureClosedReductionAnkle = 'FRACTURE_CLOSED_REDUCTION_ANKLE',
  FractureClosedReductionArm = 'FRACTURE_CLOSED_REDUCTION_ARM',
  FractureClosedReductionChipAllOtherBonesNotListed = 'FRACTURE_CLOSED_REDUCTION_CHIP_ALL_OTHER_BONES_NOT_LISTED',
  FractureClosedReductionChipAnkle = 'FRACTURE_CLOSED_REDUCTION_CHIP_ANKLE',
  FractureClosedReductionChipArm = 'FRACTURE_CLOSED_REDUCTION_CHIP_ARM',
  FractureClosedReductionChipCollarbone = 'FRACTURE_CLOSED_REDUCTION_CHIP_COLLARBONE',
  FractureClosedReductionChipElbow = 'FRACTURE_CLOSED_REDUCTION_CHIP_ELBOW',
  FractureClosedReductionChipFacialBones = 'FRACTURE_CLOSED_REDUCTION_CHIP_FACIAL_BONES',
  FractureClosedReductionChipFoot = 'FRACTURE_CLOSED_REDUCTION_CHIP_FOOT',
  FractureClosedReductionChipHand = 'FRACTURE_CLOSED_REDUCTION_CHIP_HAND',
  FractureClosedReductionChipHip = 'FRACTURE_CLOSED_REDUCTION_CHIP_HIP',
  FractureClosedReductionChipJaw = 'FRACTURE_CLOSED_REDUCTION_CHIP_JAW',
  FractureClosedReductionChipKneeCap = 'FRACTURE_CLOSED_REDUCTION_CHIP_KNEE_CAP',
  FractureClosedReductionChipLowerLeg = 'FRACTURE_CLOSED_REDUCTION_CHIP_LOWER_LEG',
  FractureClosedReductionChipPelvis = 'FRACTURE_CLOSED_REDUCTION_CHIP_PELVIS',
  FractureClosedReductionChipRib = 'FRACTURE_CLOSED_REDUCTION_CHIP_RIB',
  FractureClosedReductionChipShoulderBlade = 'FRACTURE_CLOSED_REDUCTION_CHIP_SHOULDER_BLADE',
  FractureClosedReductionChipSkullDepressed = 'FRACTURE_CLOSED_REDUCTION_CHIP_SKULL_DEPRESSED',
  FractureClosedReductionChipSkullNonDepressed = 'FRACTURE_CLOSED_REDUCTION_CHIP_SKULL_NON_DEPRESSED',
  FractureClosedReductionChipSternum = 'FRACTURE_CLOSED_REDUCTION_CHIP_STERNUM',
  FractureClosedReductionChipTailbone = 'FRACTURE_CLOSED_REDUCTION_CHIP_TAILBONE',
  FractureClosedReductionChipThigh = 'FRACTURE_CLOSED_REDUCTION_CHIP_THIGH',
  FractureClosedReductionChipVertebrae = 'FRACTURE_CLOSED_REDUCTION_CHIP_VERTEBRAE',
  FractureClosedReductionChipVertebralProcesses = 'FRACTURE_CLOSED_REDUCTION_CHIP_VERTEBRAL_PROCESSES',
  FractureClosedReductionChipWrist = 'FRACTURE_CLOSED_REDUCTION_CHIP_WRIST',
  FractureClosedReductionCollarbone = 'FRACTURE_CLOSED_REDUCTION_COLLARBONE',
  FractureClosedReductionElbow = 'FRACTURE_CLOSED_REDUCTION_ELBOW',
  FractureClosedReductionFacialBones = 'FRACTURE_CLOSED_REDUCTION_FACIAL_BONES',
  FractureClosedReductionFoot = 'FRACTURE_CLOSED_REDUCTION_FOOT',
  FractureClosedReductionHand = 'FRACTURE_CLOSED_REDUCTION_HAND',
  FractureClosedReductionHip = 'FRACTURE_CLOSED_REDUCTION_HIP',
  FractureClosedReductionJaw = 'FRACTURE_CLOSED_REDUCTION_JAW',
  FractureClosedReductionKneeCap = 'FRACTURE_CLOSED_REDUCTION_KNEE_CAP',
  FractureClosedReductionLowerLeg = 'FRACTURE_CLOSED_REDUCTION_LOWER_LEG',
  FractureClosedReductionPelvis = 'FRACTURE_CLOSED_REDUCTION_PELVIS',
  FractureClosedReductionRib = 'FRACTURE_CLOSED_REDUCTION_RIB',
  FractureClosedReductionShoulderBlade = 'FRACTURE_CLOSED_REDUCTION_SHOULDER_BLADE',
  FractureClosedReductionSkullDepressed = 'FRACTURE_CLOSED_REDUCTION_SKULL_DEPRESSED',
  FractureClosedReductionSkullNonDepressed = 'FRACTURE_CLOSED_REDUCTION_SKULL_NON_DEPRESSED',
  FractureClosedReductionSternum = 'FRACTURE_CLOSED_REDUCTION_STERNUM',
  FractureClosedReductionTailbone = 'FRACTURE_CLOSED_REDUCTION_TAILBONE',
  FractureClosedReductionThigh = 'FRACTURE_CLOSED_REDUCTION_THIGH',
  FractureClosedReductionVertebrae = 'FRACTURE_CLOSED_REDUCTION_VERTEBRAE',
  FractureClosedReductionVertebralProcesses = 'FRACTURE_CLOSED_REDUCTION_VERTEBRAL_PROCESSES',
  FractureClosedReductionWrist = 'FRACTURE_CLOSED_REDUCTION_WRIST',
  FractureOpenReductionAllOtherBonesNotListed = 'FRACTURE_OPEN_REDUCTION_ALL_OTHER_BONES_NOT_LISTED',
  FractureOpenReductionAnkle = 'FRACTURE_OPEN_REDUCTION_ANKLE',
  FractureOpenReductionArm = 'FRACTURE_OPEN_REDUCTION_ARM',
  FractureOpenReductionChipAllOtherBonesNotListed = 'FRACTURE_OPEN_REDUCTION_CHIP_ALL_OTHER_BONES_NOT_LISTED',
  FractureOpenReductionChipAnkle = 'FRACTURE_OPEN_REDUCTION_CHIP_ANKLE',
  FractureOpenReductionChipArm = 'FRACTURE_OPEN_REDUCTION_CHIP_ARM',
  FractureOpenReductionChipCollarbone = 'FRACTURE_OPEN_REDUCTION_CHIP_COLLARBONE',
  FractureOpenReductionChipElbow = 'FRACTURE_OPEN_REDUCTION_CHIP_ELBOW',
  FractureOpenReductionChipFacialBones = 'FRACTURE_OPEN_REDUCTION_CHIP_FACIAL_BONES',
  FractureOpenReductionChipFoot = 'FRACTURE_OPEN_REDUCTION_CHIP_FOOT',
  FractureOpenReductionChipHand = 'FRACTURE_OPEN_REDUCTION_CHIP_HAND',
  FractureOpenReductionChipHip = 'FRACTURE_OPEN_REDUCTION_CHIP_HIP',
  FractureOpenReductionChipJaw = 'FRACTURE_OPEN_REDUCTION_CHIP_JAW',
  FractureOpenReductionChipKneeCap = 'FRACTURE_OPEN_REDUCTION_CHIP_KNEE_CAP',
  FractureOpenReductionChipLowerLeg = 'FRACTURE_OPEN_REDUCTION_CHIP_LOWER_LEG',
  FractureOpenReductionChipPelvis = 'FRACTURE_OPEN_REDUCTION_CHIP_PELVIS',
  FractureOpenReductionChipRib = 'FRACTURE_OPEN_REDUCTION_CHIP_RIB',
  FractureOpenReductionChipShoulderBlade = 'FRACTURE_OPEN_REDUCTION_CHIP_SHOULDER_BLADE',
  FractureOpenReductionChipSkullDepressed = 'FRACTURE_OPEN_REDUCTION_CHIP_SKULL_DEPRESSED',
  FractureOpenReductionChipSkullNonDepressed = 'FRACTURE_OPEN_REDUCTION_CHIP_SKULL_NON_DEPRESSED',
  FractureOpenReductionChipSternum = 'FRACTURE_OPEN_REDUCTION_CHIP_STERNUM',
  FractureOpenReductionChipTailbone = 'FRACTURE_OPEN_REDUCTION_CHIP_TAILBONE',
  FractureOpenReductionChipThigh = 'FRACTURE_OPEN_REDUCTION_CHIP_THIGH',
  FractureOpenReductionChipVertebrae = 'FRACTURE_OPEN_REDUCTION_CHIP_VERTEBRAE',
  FractureOpenReductionChipVertebralProcesses = 'FRACTURE_OPEN_REDUCTION_CHIP_VERTEBRAL_PROCESSES',
  FractureOpenReductionChipWrist = 'FRACTURE_OPEN_REDUCTION_CHIP_WRIST',
  FractureOpenReductionCollarbone = 'FRACTURE_OPEN_REDUCTION_COLLARBONE',
  FractureOpenReductionElbow = 'FRACTURE_OPEN_REDUCTION_ELBOW',
  FractureOpenReductionFacialBones = 'FRACTURE_OPEN_REDUCTION_FACIAL_BONES',
  FractureOpenReductionFoot = 'FRACTURE_OPEN_REDUCTION_FOOT',
  FractureOpenReductionHand = 'FRACTURE_OPEN_REDUCTION_HAND',
  FractureOpenReductionHip = 'FRACTURE_OPEN_REDUCTION_HIP',
  FractureOpenReductionJaw = 'FRACTURE_OPEN_REDUCTION_JAW',
  FractureOpenReductionKneeCap = 'FRACTURE_OPEN_REDUCTION_KNEE_CAP',
  FractureOpenReductionLowerLeg = 'FRACTURE_OPEN_REDUCTION_LOWER_LEG',
  FractureOpenReductionPelvis = 'FRACTURE_OPEN_REDUCTION_PELVIS',
  FractureOpenReductionRib = 'FRACTURE_OPEN_REDUCTION_RIB',
  FractureOpenReductionShoulderBlade = 'FRACTURE_OPEN_REDUCTION_SHOULDER_BLADE',
  FractureOpenReductionSkullDepressed = 'FRACTURE_OPEN_REDUCTION_SKULL_DEPRESSED',
  FractureOpenReductionSkullNonDepressed = 'FRACTURE_OPEN_REDUCTION_SKULL_NON_DEPRESSED',
  FractureOpenReductionSternum = 'FRACTURE_OPEN_REDUCTION_STERNUM',
  FractureOpenReductionTailbone = 'FRACTURE_OPEN_REDUCTION_TAILBONE',
  FractureOpenReductionThigh = 'FRACTURE_OPEN_REDUCTION_THIGH',
  FractureOpenReductionVertebrae = 'FRACTURE_OPEN_REDUCTION_VERTEBRAE',
  FractureOpenReductionVertebralProcesses = 'FRACTURE_OPEN_REDUCTION_VERTEBRAL_PROCESSES',
  FractureOpenReductionWrist = 'FRACTURE_OPEN_REDUCTION_WRIST',
  InjuryNotSpecificallyListedNotSurgicallyRepaired = 'INJURY_NOT_SPECIFICALLY_LISTED_NOT_SURGICALLY_REPAIRED',
  InjuryNotSpecificallyListedSurgicallyRepaired = 'INJURY_NOT_SPECIFICALLY_LISTED_SURGICALLY_REPAIRED',
  InternalInjuryNotSurgicallyRepaired = 'INTERNAL_INJURY_NOT_SURGICALLY_REPAIRED',
  InternalInjurySurgicallyRepaired = 'INTERNAL_INJURY_SURGICALLY_REPAIRED',
  KneeCartilage = 'KNEE_CARTILAGE',
  Ligament = 'LIGAMENT',
  LossOfHearing = 'LOSS_OF_HEARING',
  LossOfHearingOneEar = 'LOSS_OF_HEARING_ONE_EAR',
  LossOfLife = 'LOSS_OF_LIFE',
  LossOfSightBothEyes = 'LOSS_OF_SIGHT_BOTH_EYES',
  LossOfSightOneEye = 'LOSS_OF_SIGHT_ONE_EYE',
  LossOfSpeech = 'LOSS_OF_SPEECH',
  LossOfSpeechAndHearingInBothEars = 'LOSS_OF_SPEECH_AND_HEARING_IN_BOTH_EARS',
  ParalysisBothFeet = 'PARALYSIS_BOTH_FEET',
  ParalysisBothHands = 'PARALYSIS_BOTH_HANDS',
  ParalysisHemiplegia = 'PARALYSIS_HEMIPLEGIA',
  ParalysisOneArm = 'PARALYSIS_ONE_ARM',
  ParalysisOneFoot = 'PARALYSIS_ONE_FOOT',
  ParalysisOneHand = 'PARALYSIS_ONE_HAND',
  ParalysisOneHandAndOneFoot = 'PARALYSIS_ONE_HAND_AND_ONE_FOOT',
  ParalysisOneLeg = 'PARALYSIS_ONE_LEG',
  ParalysisParaplegia = 'PARALYSIS_PARAPLEGIA',
  ParalysisQuadriplegia = 'PARALYSIS_QUADRIPLEGIA',
  RotatorCuff = 'ROTATOR_CUFF',
  RupturedDisc = 'RUPTURED_DISC',
  SecondDegreeBurnOverTwentyFivePercentOfBody = 'SECOND_DEGREE_BURN_OVER_TWENTY_FIVE_PERCENT_OF_BODY',
  SecondDegreeBurnOverTwentyFivePercentOfBodyWithSkinGraft = 'SECOND_DEGREE_BURN_OVER_TWENTY_FIVE_PERCENT_OF_BODY_WITH_SKIN_GRAFT',
  SecondDegreeBurnUpToTwentyFivePercentOfBody = 'SECOND_DEGREE_BURN_UP_TO_TWENTY_FIVE_PERCENT_OF_BODY',
  SecondDegreeBurnUpToTwentyFivePercentOfBodyWithSkinGraft = 'SECOND_DEGREE_BURN_UP_TO_TWENTY_FIVE_PERCENT_OF_BODY_WITH_SKIN_GRAFT',
  Tendon = 'TENDON',
  ThirdDegreeBurnOverTwentyFivePercentOfBody = 'THIRD_DEGREE_BURN_OVER_TWENTY_FIVE_PERCENT_OF_BODY',
  ThirdDegreeBurnOverTwentyFivePercentOfBodyWithSkinGraft = 'THIRD_DEGREE_BURN_OVER_TWENTY_FIVE_PERCENT_OF_BODY_WITH_SKIN_GRAFT',
  ThirdDegreeBurnUpToTwentyFivePercentOfBody = 'THIRD_DEGREE_BURN_UP_TO_TWENTY_FIVE_PERCENT_OF_BODY',
  ThirdDegreeBurnUpToTwentyFivePercentOfBodyWithSkinGraft = 'THIRD_DEGREE_BURN_UP_TO_TWENTY_FIVE_PERCENT_OF_BODY_WITH_SKIN_GRAFT'
}

export type AccidentalDeathAndDismembermentPolicyCoverage = PolicyCoverage & {
  __typename?: 'AccidentalDeathAndDismembermentPolicyCoverage';
  amountType?: Maybe<AmountTypeEnum>;
  bookId?: Maybe<Scalars['String']['output']>;
  contributoryType?: Maybe<ContributoryTypeEnum>;
  coverageHistory: Array<PolicyCoverageHistory>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  flatAmount?: Maybe<Scalars['Int']['output']>;
  isFamilyIncluded?: Maybe<Scalars['Boolean']['output']>;
  isOccupational?: Maybe<Scalars['Boolean']['output']>;
  isStandardIncluded?: Maybe<Scalars['Boolean']['output']>;
  isTransportationIncluded?: Maybe<Scalars['Boolean']['output']>;
  maximumBenefit?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  minimumBenefit?: Maybe<Scalars['Int']['output']>;
  multipleOfBasicLife?: Maybe<Scalars['String']['output']>;
  name: ProductName;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  searchDate: Scalars['String']['output'];
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
};

export type AchPaymentPreference = {
  __typename?: 'AchPaymentPreference';
  active: Scalars['Boolean']['output'];
  bankAccountNumber: Scalars['String']['output'];
  bankAccountType: BankAccountType;
  bankRoutingNumber: Scalars['String']['output'];
  paymentPreferenceId: Scalars['String']['output'];
  valid: Scalars['Boolean']['output'];
};

export type AchPaymentPreferenceInput = {
  bankAccountNumber: Scalars['String']['input'];
  bankAccountType: BankAccountType;
  bankRoutingNumber: Scalars['String']['input'];
};

export enum ActiveCancelledStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export enum ActiveOrTerminatedStatus {
  Active = 'ACTIVE',
  Terminated = 'TERMINATED'
}

export enum ActivitiesOfDailyLiving {
  EightyPercentIncomeReplacement = 'EIGHTY_PERCENT_INCOME_REPLACEMENT',
  NinetyPercentIncomeReplacement = 'NINETY_PERCENT_INCOME_REPLACEMENT',
  None = 'NONE',
  OneHundredPercentIncomeReplacement = 'ONE_HUNDRED_PERCENT_INCOME_REPLACEMENT'
}

export enum ActivitiesOfDailyLivingEnum {
  EightyPercentIncomeReplacement = 'EIGHTY_PERCENT_INCOME_REPLACEMENT',
  NinetyPercentIncomeReplacement = 'NINETY_PERCENT_INCOME_REPLACEMENT',
  None = 'NONE',
  OneHundredPercentIncomeReplacement = 'ONE_HUNDRED_PERCENT_INCOME_REPLACEMENT'
}

export type Activity = {
  __typename?: 'Activity';
  /** The id of the submitted request. */
  id: Scalars['String']['output'];
  /** The status of the submitted request. */
  status: ActivityStatus;
  /** The type of the submitted request. */
  type: ActivityType;
};

export enum ActivityStatus {
  /** The request was completed. */
  Completed = 'COMPLETED',
  /** The request was received. */
  Created = 'CREATED',
  /** Failed to process the request. */
  Failed = 'FAILED',
  /** The request did not result in any changes. */
  NoChangesRequired = 'NO_CHANGES_REQUIRED',
  /** The request is pending review. */
  Pending = 'PENDING',
  /** The request is processing. */
  Processing = 'PROCESSING'
}

export enum ActivityType {
  AccidentBenefitsUpdate = 'ACCIDENT_BENEFITS_UPDATE',
  AccidentPolicyCoverageUpdate = 'ACCIDENT_POLICY_COVERAGE_UPDATE',
  CriticalIllnessPolicyCoverageUpdate = 'CRITICAL_ILLNESS_POLICY_COVERAGE_UPDATE',
  HospitalIndemnityPolicyCoverageUpdate = 'HOSPITAL_INDEMNITY_POLICY_COVERAGE_UPDATE',
  PolicyUpdate = 'POLICY_UPDATE',
  RatesAccidentUpdate = 'RATES_ACCIDENT_UPDATE'
}

export type AddBenefitWithholdingInput = {
  amount: Scalars['BigDecimal']['input'];
  type: BenefitWithholdingType;
};

export type AddDocumentStatusInput = {
  documentId: Scalars['String']['input'];
  status: DocumentStatusType;
};

export type Address = {
  __typename?: 'Address';
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressLine3?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  effectiveDate: Scalars['String']['output'];
  lineOne: Scalars['String']['output'];
  lineThree?: Maybe<Scalars['String']['output']>;
  lineTwo?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  stateAbbreviation: StateAbbreviation;
  type: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type AddressInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type AdminDependent = {
  __typename?: 'AdminDependent';
  coveredPersonType?: Maybe<CoveredPersonType>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  dependentType?: Maybe<DetailedDependentType>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  nameId: Scalars['Int']['output'];
};

export type AdminMember = {
  __typename?: 'AdminMember';
  address?: Maybe<Address>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  dependents?: Maybe<Array<Maybe<AdminDependent>>>;
  email?: Maybe<Scalars['String']['output']>;
  existingClaims?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fullName: Scalars['String']['output'];
  partyId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  policies: Array<Maybe<MemberPolicy>>;
  privacyId?: Maybe<Scalars['String']['output']>;
};

export enum AdminSourceSystem {
  Cgs = 'CGS',
  Compass = 'COMPASS'
}

export enum AdministrationType {
  SelfAccounting = 'SELF_ACCOUNTING',
  StandardAccounting = 'STANDARD_ACCOUNTING',
  StandardAndSelfAccounting = 'STANDARD_AND_SELF_ACCOUNTING'
}

export type AgeReductionType = {
  __typename?: 'AgeReductionType';
  age?: Maybe<Scalars['Int']['output']>;
  reductionPercentage?: Maybe<Scalars['Int']['output']>;
};

export type Agency = {
  __typename?: 'Agency';
  id: Scalars['String']['output'];
  organization: Organization;
};

export type Agent = {
  __typename?: 'Agent';
  id: Scalars['String']['output'];
  person: Person;
};

export enum AmountTypeEnum {
  Flat = 'FLAT',
  Increment = 'INCREMENT',
  MultipleOfSalaryPercentage = 'MULTIPLE_OF_SALARY_PERCENTAGE',
  PercentageOfSalary = 'PERCENTAGE_OF_SALARY'
}

export type AnesthesiaProvision = {
  __typename?: 'AnesthesiaProvision';
  amount?: Maybe<Scalars['String']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
};

export type Announcement = {
  __typename?: 'Announcement';
  message: Scalars['String']['output'];
};

export type Announcements = {
  __typename?: 'Announcements';
  announcements: Array<Maybe<Announcement>>;
  title: Scalars['String']['output'];
};

export type AnyAndAllDateDetail = {
  __typename?: 'AnyAndAllDateDetail';
  date?: Maybe<Scalars['Date']['output']>;
  helperText: Scalars['String']['output'];
};

export enum AnyOccupationIncomeLossEnum {
  BetweenOneAndBenefitPercent = 'BETWEEN_ONE_AND_BENEFIT_PERCENT',
  EightyPercent = 'EIGHTY_PERCENT',
  None = 'NONE'
}

export type AppInfo = {
  __typename?: 'AppInfo';
  featureFlags?: Maybe<Array<Maybe<FeatureFlag>>>;
  versionId: Scalars['String']['output'];
};

export type Associate = AssociatedOrganization | AssociatedPerson;

export type AssociateClaimant = {
  __typename?: 'AssociateClaimant';
  enterpriseId: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  partyId: Scalars['String']['output'];
};

export type AssociateClaimantAssociation = {
  __typename?: 'AssociateClaimantAssociation';
  claimant: AssociateClaimant;
  partyAssociateType: PartyAssociateType;
};

export type AssociateSearchFilter = {
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  name: Scalars['String']['input'];
  type: AssociateType;
};

export type AssociateSearchResult = {
  __typename?: 'AssociateSearchResult';
  associateId: Scalars['String']['output'];
  associateType: AssociateType;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  name: Scalars['String']['output'];
};

export enum AssociateType {
  Organization = 'ORGANIZATION',
  Person = 'PERSON'
}

export type AssociatedAgent = {
  __typename?: 'AssociatedAgent';
  /**  an organization associated with the policy or accompanying agent */
  agency?: Maybe<Agency>;
  agencyId?: Maybe<Scalars['String']['output']>;
  /** an individual associated with the policy */
  agent?: Maybe<Agent>;
  agentId?: Maybe<Scalars['String']['output']>;
  /**
   * when the broker is listed as the payee on the associated policy,
   * this field returns information on commissions the broker is owed for listed coverages
   */
  commissions?: Maybe<Array<Maybe<BrokerCommission>>>;
  /** flag indicating if the listed broker is acting as the primary for the associated policy */
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  /** flag indicating if this agent is part of a PFG sales office */
  isSalesOffice: Scalars['Boolean']['output'];
  policyId: Scalars['String']['output'];
  searchDate: Scalars['String']['output'];
  /** list of services the broker provides for the associated policy */
  services: Array<Maybe<BrokerService>>;
  /**
   * when the broker is listed as servicing on the associated policy,
   * this field returns information on servicing the broker is owed for all coverages
   */
  servicing?: Maybe<BrokerServicing>;
};

export type AssociatedOrganization = Auditable & {
  __typename?: 'AssociatedOrganization';
  address?: Maybe<Address>;
  associateId: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  taxIdNumber?: Maybe<Scalars['MaskedString']['output']>;
};

export type AssociatedOrganizationInput = {
  address?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  taxIdNumber?: InputMaybe<Scalars['String']['input']>;
};

export type AssociatedPerson = Auditable & {
  __typename?: 'AssociatedPerson';
  address?: Maybe<Address>;
  associateId: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  socialSecurityNumber?: Maybe<Scalars['MaskedString']['output']>;
  taxIdNumber?: Maybe<Scalars['MaskedString']['output']>;
};

export type AssociatedPersonInput = {
  address?: InputMaybe<AddressInput>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fullName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  socialSecurityNumber?: InputMaybe<Scalars['String']['input']>;
  taxIdNumber?: InputMaybe<Scalars['String']['input']>;
};

export type Auditable = {
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
};

export type AuthorityLevel = Auditable & {
  __typename?: 'AuthorityLevel';
  approvalAmount: Scalars['BigDecimal']['output'];
  authorityLevelId: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  denialAmount: Scalars['BigDecimal']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  reviewableBenefitType: BenefitType;
  skipRandomReview: Scalars['Boolean']['output'];
};

export type AuthorityLevelInput = {
  approvalAmount: Scalars['BigDecimal']['input'];
  denialAmount: Scalars['BigDecimal']['input'];
  name: Scalars['String']['input'];
  reviewableBenefitType: BenefitType;
  skipRandomReview: Scalars['Boolean']['input'];
};

export type AuthorityLevelUser = {
  __typename?: 'AuthorityLevelUser';
  authorityLevel: AuthorityLevel;
};

export enum BankAccountType {
  Checking = 'CHECKING',
  Savings = 'SAVINGS'
}

export type BeneficiariesInput = {
  beneficiaries: Array<InputMaybe<BeneficiaryInput>>;
};

export type BeneficiaryInput = {
  beneficiaryId?: InputMaybe<Scalars['String']['input']>;
  beneficiaryType: BeneficiaryType;
  effectiveEnd?: InputMaybe<Scalars['Date']['input']>;
  effectiveStart: Scalars['Date']['input'];
  partyAssociateId: Scalars['String']['input'];
  percentage: Scalars['BigDecimal']['input'];
  productType: LifeProductType;
};

export enum BeneficiaryType {
  Contingent = 'CONTINGENT',
  Primary = 'PRIMARY'
}

export type Benefit = Auditable & {
  __typename?: 'Benefit';
  autopayedWellness: Scalars['Boolean']['output'];
  benefitAllocation?: Maybe<BenefitAllocation>;
  benefitId: Scalars['String']['output'];
  benefitStatus?: Maybe<Scalars['String']['output']>;
  claimId: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  decision?: Maybe<PrimaryBenefitDecision>;
  employerContributionPercentage?: Maybe<Scalars['Int']['output']>;
  eobRemarkOverride?: Maybe<Scalars['String']['output']>;
  hasFinalizedApproval: Scalars['Boolean']['output'];
  medicareTaxExempt: Scalars['Boolean']['output'];
  memberStatusIndicator?: Maybe<DecisionType>;
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  number: Scalars['Int']['output'];
  overpayments?: Maybe<Array<Maybe<Overpayment>>>;
  payments: Array<Maybe<Payment>>;
  policy?: Maybe<Policy>;
  preExistingConditionContext?: Maybe<PreExistingConditionContext>;
  preExistingConditionReview?: Maybe<PreExistingConditionReview>;
  premiumPaymentType: PremiumPaymentType;
  proofOfLossDate?: Maybe<Scalars['Date']['output']>;
  provisionInstances?: Maybe<Array<Maybe<ProvisionInstance>>>;
  secondaryDecisions?: Maybe<Array<Maybe<SecondaryBenefitDecision>>>;
  socialSecurityTaxExempt: Scalars['Boolean']['output'];
  statuses?: Maybe<Array<Maybe<BenefitStatus>>>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  terminationDate?: Maybe<Scalars['Date']['output']>;
  type: BenefitType;
  validations: Array<Maybe<Validation>>;
};

export type BenefitAllocation = Auditable & {
  __typename?: 'BenefitAllocation';
  benefitAllocationId: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  details: Array<Maybe<BenefitAllocationDetail>>;
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  type: PaymentType;
};

export type BenefitAllocationDetail = Auditable & {
  __typename?: 'BenefitAllocationDetail';
  benefitAllocationDetailId: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  flatAmount?: Maybe<Scalars['BigDecimal']['output']>;
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  partyAssociateId: Scalars['String']['output'];
};

export type BenefitAllocationInput = {
  benefitAllocationDetailId?: InputMaybe<Scalars['String']['input']>;
  flatAmount?: InputMaybe<Scalars['BigDecimal']['input']>;
  partyAssociateId: Scalars['String']['input'];
};

export type BenefitAllocationOverview = {
  __typename?: 'BenefitAllocationOverview';
  benefitAllocation?: Maybe<BenefitAllocation>;
  claimId: Scalars['String']['output'];
  enterpriseId: Scalars['String']['output'];
  partyAssociates: Array<Maybe<PartyAssociate>>;
  partyId: Scalars['String']['output'];
};

export type BenefitDecision = PrimaryBenefitDecision | SecondaryBenefitDecision;

export type BenefitDecisionInput = {
  checkPaymentDeliveryMethod?: InputMaybe<CheckPaymentDeliveryMethod>;
  completed: Scalars['Boolean']['input'];
  decisionType: DecisionType;
  denialReason?: InputMaybe<DenialReasonType>;
  note: Scalars['String']['input'];
  paymentPreferenceId?: InputMaybe<Scalars['String']['input']>;
};

export type BenefitDetail = LifeWaiverBenefitDetail | LongTermDisabilityBenefitDetail | ShortTermDisabilityBenefitDetail;

export enum BenefitDocumentType {
  Booklet = 'BOOKLET',
  ScheduledBenefitSummary = 'SCHEDULED_BENEFIT_SUMMARY'
}

export type BenefitDomain = {
  __typename?: 'BenefitDomain';
  type: BenefitType;
};

export enum BenefitDurationBasis {
  Days = 'DAYS',
  Months = 'MONTHS',
  Weeks = 'WEEKS'
}

export enum BenefitDurationEnum {
  FiveYear = 'FIVE_YEAR',
  /** AKA RBD */
  ReducingBenefitDuration = 'REDUCING_BENEFIT_DURATION',
  /** AKA 65/5/70 */
  SixtyFiveSlashFiveSlashSeventy = 'SIXTY_FIVE_SLASH_FIVE_SLASH_SEVENTY',
  /** AKA: SSNRA */
  SocialSecurityNormalRetirementAge = 'SOCIAL_SECURITY_NORMAL_RETIREMENT_AGE',
  TwoYear = 'TWO_YEAR'
}

export type BenefitInput = {
  employerContributionPercentage?: InputMaybe<Scalars['Int']['input']>;
  medicareTaxExempt?: InputMaybe<Scalars['Boolean']['input']>;
  premiumPaymentType: PremiumPaymentType;
  proofOfLossDate?: InputMaybe<Scalars['Date']['input']>;
  socialSecurityTaxExempt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BenefitPeriod = {
  __typename?: 'BenefitPeriod';
  benefitPeriodId: Scalars['String']['output'];
  endDate: Scalars['Date']['output'];
  paymentItems?: Maybe<Array<Maybe<PaymentItem>>>;
  startDate: Scalars['Date']['output'];
};

export enum BenefitPlanYear {
  FiftyOne = 'FIFTY_ONE',
  FortyOne = 'FORTY_ONE',
  OneHundredThirtyOne = 'ONE_HUNDRED_THIRTY_ONE',
  SixtyOne = 'SIXTY_ONE',
  SixtyTwo = 'SIXTY_TWO'
}

export type BenefitReview = Auditable & {
  __typename?: 'BenefitReview';
  benefitDecisionId: Scalars['String']['output'];
  benefitReviewId: Scalars['String']['output'];
  completed: Scalars['Boolean']['output'];
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  decision: BenefitDecision;
  decisionAgreed?: Maybe<Scalars['Boolean']['output']>;
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  note: Note;
  reviewCompletedByUserId?: Maybe<Scalars['String']['output']>;
  reviewCompletedByUserName?: Maybe<Scalars['String']['output']>;
  reviewType: BenefitReviewType;
};

export type BenefitReviewInput = {
  completed: Scalars['Boolean']['input'];
  decisionAgreed?: InputMaybe<Scalars['Boolean']['input']>;
  decisionNote?: InputMaybe<Scalars['String']['input']>;
  denialReason?: InputMaybe<DenialReasonType>;
  reviewNote: Scalars['String']['input'];
};

export enum BenefitReviewType {
  Authority = 'AUTHORITY',
  Mandatory = 'MANDATORY',
  Random = 'RANDOM'
}

export type BenefitScheduleDto = {
  __typename?: 'BenefitScheduleDto';
  amount: Scalars['BigDecimal']['output'];
  provisionType: ProvisionType;
};

export type BenefitStatus = Auditable & {
  __typename?: 'BenefitStatus';
  additionalInformation?: Maybe<Scalars['String']['output']>;
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  effectiveDate: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  type: BenefitStatusType;
};

export enum BenefitStatusType {
  Approved = 'APPROVED',
  Assigned = 'ASSIGNED',
  BuiltInError = 'BUILT_IN_ERROR',
  Closed = 'CLOSED',
  Created = 'CREATED',
  Denied = 'DENIED',
  InReview = 'IN_REVIEW',
  Open = 'OPEN',
  PartiallyDenied = 'PARTIALLY_DENIED',
  Pending = 'PENDING',
  ReviewCompleted = 'REVIEW_COMPLETED'
}

export enum BenefitType {
  Accident = 'ACCIDENT',
  AccidentWellness = 'ACCIDENT_WELLNESS',
  CoreBuyUp = 'CORE_BUY_UP',
  CriticalIllness = 'CRITICAL_ILLNESS',
  CriticalIllnessWellness = 'CRITICAL_ILLNESS_WELLNESS',
  DualChoice = 'DUAL_CHOICE',
  HospitalIndemnity = 'HOSPITAL_INDEMNITY',
  HospitalIndemnityWellness = 'HOSPITAL_INDEMNITY_WELLNESS',
  Life = 'LIFE',
  LifeWaiver = 'LIFE_WAIVER',
  LongTermDisability = 'LONG_TERM_DISABILITY',
  NonChoice = 'NON_CHOICE',
  PaidFamilyLeave = 'PAID_FAMILY_LEAVE',
  PaidMedicalLeave = 'PAID_MEDICAL_LEAVE',
  ShortTermDisability = 'SHORT_TERM_DISABILITY',
  TripleChoice = 'TRIPLE_CHOICE'
}

export type BenefitValidation = {
  __typename?: 'BenefitValidation';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  info?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isPassed?: Maybe<Scalars['Boolean']['output']>;
};

export type BenefitValidationResult = {
  __typename?: 'BenefitValidationResult';
  benefitCalculationsChecks?: Maybe<BenefitValidation>;
  claimChecks?: Maybe<BenefitValidation>;
  eligibilityChecks?: Maybe<BenefitValidation>;
};

export type BenefitWithholding = {
  __typename?: 'BenefitWithholding';
  amount: Scalars['BigDecimal']['output'];
  benefitWithholdingId: Scalars['String']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  startDate: Scalars['DateTime']['output'];
  type: BenefitWithholdingType;
};

export enum BenefitWithholdingType {
  VoluntaryFederalIncomeTax = 'VOLUNTARY_FEDERAL_INCOME_TAX',
  VoluntaryStateIncomeTax = 'VOLUNTARY_STATE_INCOME_TAX'
}

export type BillGroup = {
  __typename?: 'BillGroup';
  billingDivisions: Array<BillingDivision>;
  contacts: Array<Contact>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Use id instead */
  number: Scalars['Int']['output'];
  organization?: Maybe<Organization>;
  policyId: Scalars['String']['output'];
  searchDate: Scalars['String']['output'];
  status: BillGroupStatus;
  type: BillGroupType;
};

export enum BillGroupStatus {
  ClosedToNewMembers = 'CLOSED_TO_NEW_MEMBERS',
  OpenToNewMembers = 'OPEN_TO_NEW_MEMBERS'
}

export enum BillGroupType {
  GroupBilling = 'GROUP_BILLING',
  MemberDirectBilling = 'MEMBER_DIRECT_BILLING'
}

export type BillingDivision = {
  __typename?: 'BillingDivision';
  name: Scalars['String']['output'];
  status: ActiveCancelledStatus;
};

export type BrokerCommission = {
  __typename?: 'BrokerCommission';
  /** a list of coverages the commission applies to */
  coverages?: Maybe<Array<Maybe<ProductName>>>;
  /** effective date of commission */
  effectiveDate?: Maybe<Scalars['String']['output']>;
  /** a description of the commission the broker receives */
  name?: Maybe<Scalars['String']['output']>;
  /** percent of commission the broker receives for the listed coverages */
  percent?: Maybe<Scalars['Float']['output']>;
  scale?: Maybe<Scalars['String']['output']>;
  /** termination date of commission */
  terminationDate?: Maybe<Scalars['String']['output']>;
};

export enum BrokerService {
  Payee = 'PAYEE',
  Selling = 'SELLING',
  Servicing = 'SERVICING'
}

export type BrokerServicing = {
  __typename?: 'BrokerServicing';
  effectiveDate: Scalars['String']['output'];
  /** a history of dates the agent acted in a SERVICING role on this policy */
  history: Array<ServicingHistory>;
  percent?: Maybe<Scalars['Int']['output']>;
  terminationDate?: Maybe<Scalars['String']['output']>;
};

export enum CadenceEnum {
  Days = 'DAYS',
  Months = 'MONTHS'
}

export type CalculationOverride = {
  __typename?: 'CalculationOverride';
  claimType?: Maybe<ClaimType>;
  fieldName?: Maybe<Scalars['String']['output']>;
};

export type CalculationOverrideInstanceInput = {
  overrideAmount?: InputMaybe<Scalars['BigDecimal']['input']>;
  overrideReason?: InputMaybe<Scalars['String']['input']>;
};

export type CancelPaymentInput = {
  paymentId: Scalars['String']['input'];
  reason: PaymentCancellationReason;
};

export enum CheckPaymentDeliveryMethod {
  DirectDelivery = 'DIRECT_DELIVERY',
  FirstClassMail = 'FIRST_CLASS_MAIL'
}

export type CheckPaymentPreference = {
  __typename?: 'CheckPaymentPreference';
  active: Scalars['Boolean']['output'];
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressLine3?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  paymentPreferenceId: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  valid: Scalars['Boolean']['output'];
};

export type ChildPolicyCoverage = PolicyCoverage & {
  __typename?: 'ChildPolicyCoverage';
  availableAmounts: Array<Scalars['Int']['output']>;
  bookId?: Maybe<Scalars['String']['output']>;
  cannotExceedPercentOfEmployee?: Maybe<Scalars['Int']['output']>;
  contributoryType?: Maybe<ContributoryTypeEnum>;
  /** Current and past information of the coverage including dates and product names. */
  coverageHistory: Array<PolicyCoverageHistory>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  memberGroupId: Scalars['String']['output'];
  name: ProductName;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
};

export type Choice = {
  choiceType: ChoiceTypeEnum;
  coverages: Array<ChoiceCoverageUnion>;
};

export type ChoiceCoverageUnion = HospitalIndemnityPolicyCoveragePlan;

export enum ChoiceTypeEnum {
  CoreBuyUp = 'CORE_BUY_UP',
  DualChoice = 'DUAL_CHOICE',
  NonChoice = 'NON_CHOICE',
  TripleChoice = 'TRIPLE_CHOICE'
}

export type Claim = Auditable & {
  __typename?: 'Claim';
  assignedUserActive?: Maybe<Scalars['Boolean']['output']>;
  assignedUserDisplayName?: Maybe<Scalars['String']['output']>;
  assignedUserExtension?: Maybe<Scalars['String']['output']>;
  assignedUserId?: Maybe<Scalars['String']['output']>;
  benefits: Array<Maybe<Benefit>>;
  claimId: Scalars['String']['output'];
  claimStatus?: Maybe<Scalars['String']['output']>;
  claimType: ClaimType;
  claimantId?: Maybe<Scalars['String']['output']>;
  coveredPerson?: Maybe<CoveredPerson>;
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  documents?: Maybe<Array<Maybe<Document>>>;
  electronicConsent?: Maybe<ElectronicConsent>;
  hipaa?: Maybe<Hipaa>;
  incidentId: Scalars['Int']['output'];
  incurredDate?: Maybe<Scalars['Date']['output']>;
  medical: Medical;
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  notes?: Maybe<Array<Maybe<Note>>>;
  notificationDate: Scalars['Date']['output'];
  occupation: Occupation;
  partyId: Scalars['String']['output'];
  tasks?: Maybe<Array<Maybe<Task>>>;
};

export type ClaimConnection = {
  __typename?: 'ClaimConnection';
  edges?: Maybe<Array<Maybe<ClaimEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalElements: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type ClaimEdge = {
  __typename?: 'ClaimEdge';
  cursor: Scalars['String']['output'];
  node: Claim;
};

export enum ClaimStatusType {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

export type ClaimSummary = {
  __typename?: 'ClaimSummary';
  assignedToUserActive?: Maybe<Scalars['Boolean']['output']>;
  assignedToUserDisplayName?: Maybe<Scalars['String']['output']>;
  assignedToUserId?: Maybe<Scalars['String']['output']>;
  claimId?: Maybe<Scalars['String']['output']>;
  claimStatus?: Maybe<ClaimStatusType>;
  claimType?: Maybe<ClaimType>;
  id?: Maybe<Scalars['Int']['output']>;
  incurredDate?: Maybe<Scalars['Date']['output']>;
  notificationDate?: Maybe<Scalars['Date']['output']>;
};

export type ClaimSummaryConnection = {
  __typename?: 'ClaimSummaryConnection';
  edges?: Maybe<Array<Maybe<ClaimSummaryEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalElements: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type ClaimSummaryEdge = {
  __typename?: 'ClaimSummaryEdge';
  cursor: Scalars['String']['output'];
  node: ClaimSummary;
};

export enum ClaimType {
  Accident = 'ACCIDENT',
  CriticalIllness = 'CRITICAL_ILLNESS',
  Disability = 'DISABILITY',
  HospitalIndemnity = 'HOSPITAL_INDEMNITY',
  Wellness = 'WELLNESS'
}

export type Claimant = {
  __typename?: 'Claimant';
  allPolicies?: Maybe<ListMemberPoliciesResponse>;
  claims: Array<Maybe<Claim>>;
  dateOfDeath?: Maybe<Scalars['Date']['output']>;
  demographics?: Maybe<ClaimantDemographics>;
  enterpriseId: Scalars['String']['output'];
  isPfgEmployee?: Maybe<Scalars['Boolean']['output']>;
  partyAssociates?: Maybe<Array<Maybe<PartyAssociate>>>;
  partyId: Scalars['String']['output'];
  paymentPreferences?: Maybe<Array<Maybe<PaymentPreference>>>;
  policies: Array<Maybe<Policy>>;
};

export type ClaimantDemographics = {
  __typename?: 'ClaimantDemographics';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  privacyId?: Maybe<Scalars['String']['output']>;
  socialSecurityNumber?: Maybe<Scalars['MaskedString']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

/** Only one of filters can be applied at a time. */
export type ClaimantFilter = {
  enterpriseIdFilter?: InputMaybe<Scalars['String']['input']>;
  governmentAssignedIdFilter?: InputMaybe<Scalars['String']['input']>;
  privacyIdFilter?: InputMaybe<Scalars['String']['input']>;
};

export type ClaimsFilter = {
  benefitStatusFilter?: InputMaybe<Array<InputMaybe<BenefitStatusType>>>;
  benefitTypeFilter?: InputMaybe<Array<InputMaybe<BenefitType>>>;
  claimantFilter?: InputMaybe<ClaimantFilter>;
  excludeBenefitIdsFilter?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  incurredDateFilter?: InputMaybe<DateRangeFilter>;
};

export enum CobraBillRecipient {
  Group = 'GROUP',
  Individual = 'INDIVIDUAL',
  NotApplicable = 'NOT_APPLICABLE'
}

export type CompanionLodgingProvision = {
  __typename?: 'CompanionLodgingProvision';
  amount?: Maybe<Scalars['Int']['output']>;
  daysPerYear?: Maybe<Scalars['Int']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
  milesFromHome?: Maybe<Scalars['Int']['output']>;
};

export enum CompassEvidenceOfInsurabilityStatus {
  Approved = 'APPROVED',
  ClosedOut = 'CLOSED_OUT',
  Declined = 'DECLINED',
  EoiRequested = 'EOI_REQUESTED',
  InfoRequestedEmployee = 'INFO_REQUESTED_EMPLOYEE',
  InfoRequestedPhysician = 'INFO_REQUESTED_PHYSICIAN',
  Pending = 'PENDING',
  PendingPrincipal = 'PENDING_PRINCIPAL',
  Unknown = 'UNKNOWN'
}

export enum ConditionType {
  Injury = 'INJURY',
  Pregnancy = 'PREGNANCY',
  Sickness = 'SICKNESS'
}

export type Contact = {
  __typename?: 'Contact';
  emailAddresses: Array<EmailAddressType>;
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  phoneNumbers: Array<PhoneNumberType>;
  primary: Scalars['Boolean']['output'];
  searchDate: Scalars['String']['output'];
  type?: Maybe<ContactType>;
};

export enum ContactType {
  Billing = 'BILLING',
  BillingAdditionalContact = 'BILLING_ADDITIONAL_CONTACT',
  EmployerAdditionalContact = 'EMPLOYER_ADDITIONAL_CONTACT',
  EmployerPrimary = 'EMPLOYER_PRIMARY',
  ThirdPartyAdministrator = 'THIRD_PARTY_ADMINISTRATOR'
}

export enum ContributoryTypeEnum {
  Contributory = 'CONTRIBUTORY',
  NonContributory = 'NON_CONTRIBUTORY'
}

export enum CoordinationOfBenefitsEnum {
  Direct = 'DIRECT',
  SeventyPercentAllSources = 'SEVENTY_PERCENT_ALL_SOURCES'
}

export type Copay = {
  __typename?: 'Copay';
  amount?: Maybe<Scalars['Int']['output']>;
  isApplicable: Scalars['Boolean']['output'];
};

export enum CostOfLivingDurationEnum {
  EndOfBenefitDuration = 'END_OF_BENEFIT_DURATION',
  FiveYear = 'FIVE_YEAR',
  NotApplicable = 'NOT_APPLICABLE',
  TenYear = 'TEN_YEAR'
}

export enum CostOfLivingDurationType {
  EndOfBenefitDuraiton = 'END_OF_BENEFIT_DURAITON',
  FiveYear = 'FIVE_YEAR',
  NotApplicable = 'NOT_APPLICABLE',
  TenYear = 'TEN_YEAR'
}

export enum CostOfLivingEnum {
  ConsumerPriceIndex = 'CONSUMER_PRICE_INDEX',
  EightPercent = 'EIGHT_PERCENT',
  FiftyPercentOfConsumerPriceIndex = 'FIFTY_PERCENT_OF_CONSUMER_PRICE_INDEX',
  FivePercent = 'FIVE_PERCENT',
  FourPercent = 'FOUR_PERCENT',
  NinePercent = 'NINE_PERCENT',
  None = 'NONE',
  OnePercent = 'ONE_PERCENT',
  SevenPercent = 'SEVEN_PERCENT',
  SixPercent = 'SIX_PERCENT',
  TenPercent = 'TEN_PERCENT',
  ThreePercent = 'THREE_PERCENT',
  TwoPercent = 'TWO_PERCENT'
}

export enum CostOfLivingType {
  Cpi = 'CPI',
  EightPercent = 'EIGHT_PERCENT',
  FiftyPercentOfCpi = 'FIFTY_PERCENT_OF_CPI',
  FivePercent = 'FIVE_PERCENT',
  FourPercent = 'FOUR_PERCENT',
  NinePercent = 'NINE_PERCENT',
  None = 'NONE',
  OnePercent = 'ONE_PERCENT',
  SevenPercent = 'SEVEN_PERCENT',
  SixPercent = 'SIX_PERCENT',
  TenPercent = 'TEN_PERCENT',
  ThreePercent = 'THREE_PERCENT',
  TwoPercent = 'TWO_PERCENT',
  UniversityCpi = 'UNIVERSITY_CPI'
}

export enum Coverage {
  Accident = 'ACCIDENT',
  CriticalIllness = 'CRITICAL_ILLNESS',
  HospitalIndemnity = 'HOSPITAL_INDEMNITY',
  Life = 'LIFE',
  LongTermDisability = 'LONG_TERM_DISABILITY',
  PaidFamilyLeave = 'PAID_FAMILY_LEAVE',
  PaidMedicalLeave = 'PAID_MEDICAL_LEAVE',
  ShortTermDisability = 'SHORT_TERM_DISABILITY',
  VoluntaryTermLife = 'VOLUNTARY_TERM_LIFE'
}

export enum CoverageTypeEnum {
  NoSpouseChild = 'NO_SPOUSE_CHILD',
  SpouseWithEmployeeEnrolled = 'SPOUSE_WITH_EMPLOYEE_ENROLLED'
}

export type CoveredPerson = {
  __typename?: 'CoveredPerson';
  coveredPersonId: Scalars['String']['output'];
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  dependentType?: Maybe<DependentType>;
  detailedDependentType?: Maybe<DetailedDependentType>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  nameId?: Maybe<Scalars['Int']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
};

export enum CoveredPersonType {
  Child = 'CHILD',
  DomesticPartner = 'DOMESTIC_PARTNER',
  Self = 'SELF',
  Spouse = 'SPOUSE',
  Unknown = 'UNKNOWN'
}

export type CreateAccidentClaimInput = {
  claimantId?: InputMaybe<Scalars['String']['input']>;
  coveredPerson?: InputMaybe<CreateClaimCoveredPerson>;
  dateLastWorked?: InputMaybe<Scalars['Date']['input']>;
  employerContributionPercent?: InputMaybe<Scalars['Int']['input']>;
  hasCeasedWorking?: InputMaybe<Scalars['Boolean']['input']>;
  hoursWorkedPerWeek?: InputMaybe<Scalars['BigDecimal']['input']>;
  incurredDate: Scalars['Date']['input'];
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  notificationDate: Scalars['Date']['input'];
  paymentPreference?: InputMaybe<PaymentPreferenceInput>;
  policy?: InputMaybe<CreateClaimPolicy>;
  premiumPaymentType?: InputMaybe<CreateClaimPremiumPaymentType>;
  unknownClaimantInput?: InputMaybe<UnknownClaimantInput>;
  workRelated?: InputMaybe<Scalars['Boolean']['input']>;
  workedInState?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCheckPaymentPreferenceInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type CreateClaimCoveredPerson = {
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
};

export type CreateClaimDiagnosisInput = {
  code: Scalars['String']['input'];
};

export type CreateClaimPolicy = {
  memberId?: InputMaybe<Scalars['String']['input']>;
  planNumber?: InputMaybe<Scalars['String']['input']>;
  policyNumber: Scalars['String']['input'];
  unitNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum CreateClaimPremiumPaymentType {
  NotApplicable = 'NOT_APPLICABLE',
  PostTax = 'POST_TAX',
  PreTax = 'PRE_TAX',
  Unknown = 'UNKNOWN'
}

export type CreateCriticalIllnessClaimInput = {
  claimantId?: InputMaybe<Scalars['String']['input']>;
  coveredPerson?: InputMaybe<CreateClaimCoveredPerson>;
  dateLastWorked?: InputMaybe<Scalars['Date']['input']>;
  diagnoses?: InputMaybe<Array<InputMaybe<CreateClaimDiagnosisInput>>>;
  employerContributionPercent?: InputMaybe<Scalars['Int']['input']>;
  hasCeasedWorking?: InputMaybe<Scalars['Boolean']['input']>;
  hoursWorkedPerWeek?: InputMaybe<Scalars['BigDecimal']['input']>;
  illnesses?: InputMaybe<Array<InputMaybe<CriticalIllnessProvisionType>>>;
  incurredDate?: InputMaybe<Scalars['Date']['input']>;
  isCompetent?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  notificationDate: Scalars['Date']['input'];
  paymentPreference?: InputMaybe<PaymentPreferenceInput>;
  policy?: InputMaybe<CreateClaimPolicy>;
  premiumPaymentType?: InputMaybe<CreateClaimPremiumPaymentType>;
  unknownClaimantInput?: InputMaybe<UnknownClaimantInput>;
  workedInState?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDisabilityClaimInput = {
  benefits: Array<InputMaybe<DisabilityBenefitType>>;
  claimantId?: InputMaybe<Scalars['String']['input']>;
  conditionType?: InputMaybe<ConditionType>;
  coveredPerson?: InputMaybe<CreateClaimCoveredPerson>;
  dateLastWorked?: InputMaybe<Scalars['Date']['input']>;
  diagnoses?: InputMaybe<Array<InputMaybe<CreateClaimDiagnosisInput>>>;
  employerContributionPercent?: InputMaybe<Scalars['Int']['input']>;
  hasCeasedWorking?: InputMaybe<Scalars['Boolean']['input']>;
  hoursWorkedPerWeek?: InputMaybe<Scalars['BigDecimal']['input']>;
  incurredDate: Scalars['Date']['input'];
  isCompetent?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  notificationDate: Scalars['Date']['input'];
  paymentPreference?: InputMaybe<PaymentPreferenceInput>;
  policy?: InputMaybe<CreateClaimPolicy>;
  pregnancyDeliveryDate?: InputMaybe<Scalars['Date']['input']>;
  premiumPaymentType?: InputMaybe<CreateClaimPremiumPaymentType>;
  unknownClaimantInput?: InputMaybe<UnknownClaimantInput>;
  workedInState?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEftPaymentPreferenceInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  bankAccountNumber: Scalars['String']['input'];
  bankAccountType: BankAccountType;
  bankRoutingNumber: Scalars['String']['input'];
};

export type CreateHospitalIndemnityClaimInput = {
  claimantId?: InputMaybe<Scalars['String']['input']>;
  conditionType?: InputMaybe<ConditionType>;
  coveredPerson?: InputMaybe<CreateClaimCoveredPerson>;
  dateLastWorked?: InputMaybe<Scalars['Date']['input']>;
  employerContributionPercent?: InputMaybe<Scalars['Int']['input']>;
  hasCeasedWorking?: InputMaybe<Scalars['Boolean']['input']>;
  hoursWorkedPerWeek?: InputMaybe<Scalars['BigDecimal']['input']>;
  incurredDate: Scalars['Date']['input'];
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  notificationDate: Scalars['Date']['input'];
  paymentPreference?: InputMaybe<PaymentPreferenceInput>;
  policy?: InputMaybe<CreateClaimPolicy>;
  premiumPaymentType?: InputMaybe<PremiumPaymentType>;
  unknownClaimantInput?: InputMaybe<UnknownClaimantInput>;
  workRelated?: InputMaybe<Scalars['Boolean']['input']>;
  workedInState?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNoteInput = {
  claimId: Scalars['String']['input'];
  completed: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  direction?: InputMaybe<DirectionType>;
  noteType: NoteType;
  value: Scalars['String']['input'];
};

export type CreateOverpaymentInput = {
  amount: Scalars['BigDecimal']['input'];
  paymentId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

export type CreateOverpaymentRecoveryInput = {
  amount: Scalars['BigDecimal']['input'];
  completedReason?: InputMaybe<OverpaymentCompletedReasonType>;
  overpaymentId: Scalars['String']['input'];
  recoveryDate: Scalars['Date']['input'];
  transactionNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWellnessClaimInput = {
  benefits: Array<InputMaybe<WellnessBenefitType>>;
  claimantId?: InputMaybe<Scalars['String']['input']>;
  coveredPerson?: InputMaybe<CreateClaimCoveredPerson>;
  dateLastWorked?: InputMaybe<Scalars['Date']['input']>;
  employerContributionPercent?: InputMaybe<Scalars['Int']['input']>;
  hasCeasedWorking?: InputMaybe<Scalars['Boolean']['input']>;
  hoursWorkedPerWeek?: InputMaybe<Scalars['BigDecimal']['input']>;
  incurredDate: Scalars['Date']['input'];
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  notificationDate: Scalars['Date']['input'];
  paymentPreference?: InputMaybe<PaymentPreferenceInput>;
  policy?: InputMaybe<CreateClaimPolicy>;
  premiumPaymentType?: InputMaybe<CreateClaimPremiumPaymentType>;
  screeningType: ScreeningType;
  unknownClaimantInput?: InputMaybe<UnknownClaimantInput>;
  workedInState?: InputMaybe<Scalars['String']['input']>;
};

export type CriticalIllnessConfiguration = PolicyCoverageConfiguration & {
  __typename?: 'CriticalIllnessConfiguration';
  amountType?: Maybe<AmountTypeEnum>;
  benefitSchedule: CriticalIllnessPolicyCoverageBenefitSchedule;
  cancerOneTwo?: Maybe<Scalars['Boolean']['output']>;
  contributory?: Maybe<Scalars['Boolean']['output']>;
  coverageType?: Maybe<CoverageTypeEnum>;
  domesticPartner?: Maybe<DomesticPartnerEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  grandfathered?: Maybe<Scalars['Boolean']['output']>;
  guaranteedIssueAmount?: Maybe<Scalars['Int']['output']>;
  incrementAmount?: Maybe<Scalars['Int']['output']>;
  /** Signifies if the coverage is health savings account compatible */
  isHsaCompatible?: Maybe<Scalars['Boolean']['output']>;
  maximumBenefit?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  minimumBenefit?: Maybe<Scalars['Int']['output']>;
  minimumWeeklyHours?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<ProductName>;
  openEnrollment: OpenEnrollmentType;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  portability?: Maybe<PortabilityEnum>;
  preExistingConditions?: Maybe<PreExistingConditionType>;
  priorCoverage?: Maybe<Scalars['Boolean']['output']>;
  productShortName: Scalars['String']['output'];
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  sabbatical?: Maybe<Scalars['Boolean']['output']>;
  searchDate: Scalars['String']['output'];
  spouseRatedOn?: Maybe<SpouseRatedOn>;
  terminationDate?: Maybe<Scalars['String']['output']>;
  voluntary: Scalars['Boolean']['output'];
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
  wellnessBenefitAmount?: Maybe<WellnessBenefitAmountEnum>;
};

export type CriticalIllnessConfigurationsInput = {
  actualParticipationPercentage?: InputMaybe<Scalars['Int']['input']>;
  amountType?: InputMaybe<AmountTypeEnum>;
  benefitPlanYear?: InputMaybe<Scalars['String']['input']>;
  bookId?: InputMaybe<Scalars['String']['input']>;
  cancerOneTwo?: InputMaybe<Scalars['Boolean']['input']>;
  contributory?: InputMaybe<Scalars['Boolean']['input']>;
  coverageType?: InputMaybe<CoverageTypeEnum>;
  domesticPartner?: InputMaybe<DomesticPartnerEnum>;
  eligibleLives?: InputMaybe<Scalars['Int']['input']>;
  guaranteedIssueAmount?: InputMaybe<Scalars['Int']['input']>;
  incrementAmount?: InputMaybe<Scalars['Int']['input']>;
  isHsaCompatible?: InputMaybe<Scalars['Boolean']['input']>;
  maximumBenefit?: InputMaybe<Scalars['Int']['input']>;
  memberGroupName: Scalars['String']['input'];
  minimumBenefit?: InputMaybe<Scalars['Int']['input']>;
  minimumWeeklyHours?: InputMaybe<Scalars['Int']['input']>;
  planId?: InputMaybe<Scalars['String']['input']>;
  portability?: InputMaybe<PortabilityEnum>;
  preExistingConditions?: InputMaybe<PreExistingConditionsInput>;
  rateGuaranteeEndDate?: InputMaybe<Scalars['String']['input']>;
  rateGuaranteeReason?: InputMaybe<RateGuaranteeReasonEnum>;
  ratingPlanYear?: InputMaybe<Scalars['String']['input']>;
  sabbatical?: InputMaybe<Scalars['Boolean']['input']>;
  signatureGracePeriod?: InputMaybe<Scalars['Int']['input']>;
  spouse?: InputMaybe<CriticalIllnessSpouseConfigurationInput>;
  wellnessBenefitAmount?: InputMaybe<WellnessBenefitAmountEnum>;
};

export type CriticalIllnessInput = {
  configuration: Array<CriticalIllnessConfigurationsInput>;
  effectiveDate: Scalars['String']['input'];
  policyId: Scalars['String']['input'];
};

export type CriticalIllnessPolicyBenefitPayable = {
  __typename?: 'CriticalIllnessPolicyBenefitPayable';
  flatAmount?: Maybe<CriticalIllnessPolicyCoverageBenefitAmounts>;
  isChildhoodOnlyCondition: Scalars['Boolean']['output'];
  percentOfScheduleBenefit?: Maybe<CriticalIllnessPolicyCoverageBenefitAmounts>;
  requiresCancerOneTwo: Scalars['Boolean']['output'];
  type: CriticalIllnessProvisionType;
};

export type CriticalIllnessPolicyCoverage = PolicyCoverage & {
  __typename?: 'CriticalIllnessPolicyCoverage';
  actualParticipationPercentage?: Maybe<Scalars['Int']['output']>;
  /**
   * Amount Type describes the benefit amount.  Previously planType.
   * @deprecated use configuration.amountType
   */
  amountType?: Maybe<AmountTypeEnum>;
  benefitPlanYear?: Maybe<Scalars['String']['output']>;
  bookId?: Maybe<Scalars['String']['output']>;
  child?: Maybe<ChildPolicyCoverage>;
  /** Configuration of benefit. These are generally defined in compass as benefit option. */
  configuration: CriticalIllnessConfiguration;
  /** @deprecated use configuration.contributory */
  contributoryType?: Maybe<ContributoryTypeEnum>;
  coverageHistory: Array<PolicyCoverageHistory>;
  /** @deprecated use configuration.domesticPartner */
  domesticPartner?: Maybe<DomesticPartnerEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  eligibleLives?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use configuration.employeeTerminationDateRul */
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  /** @deprecated use configuration.guaranteedIssueAmount */
  guaranteedIssueAmount?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use configuration.incrementAmount */
  incrementAmount?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use configuration.maximumBenefit */
  maximumBenefit?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  /** @deprecated use configuration.minimumBenefit */
  minimumBenefit?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use configuration.minimumWeeklyHours */
  minimumWeeklyHours?: Maybe<Scalars['Int']['output']>;
  name: ProductName;
  planDescription?: Maybe<Scalars['String']['output']>;
  planId?: Maybe<Scalars['String']['output']>;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  /** @deprecated use configuration.portability */
  portability?: Maybe<PortabilityEnum>;
  priorCarrier?: Maybe<PriorCarrier>;
  productShortName: Scalars['String']['output'];
  /** @deprecated use configuration.rateGuaranteeEndDate */
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.rateGuaranteeReason */
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  /** @deprecated use configuration.rateGuaranteePeriod */
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  ratingPlanYear?: Maybe<Scalars['String']['output']>;
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  spouse?: Maybe<CriticalIllnessSpousePolicyCoverage>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.waitingPeriod */
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
  /** @deprecated use configuration.wellnessBenefitAmount */
  wellnessBenefitAmount?: Maybe<WellnessBenefitAmountEnum>;
};

export type CriticalIllnessPolicyCoverageBenefitAmounts = {
  __typename?: 'CriticalIllnessPolicyCoverageBenefitAmounts';
  additionalOccurrence: Scalars['Float']['output'];
  firstOccurrence: Scalars['Float']['output'];
};

export type CriticalIllnessPolicyCoverageBenefitSchedule = {
  __typename?: 'CriticalIllnessPolicyCoverageBenefitSchedule';
  benefitsPayable: Array<Maybe<CriticalIllnessPolicyBenefitPayable>>;
  childPercentOfMemberScheduleBenefit: Scalars['Float']['output'];
};

export type CriticalIllnessProvisionInstance = ProvisionInstanceInterface & {
  __typename?: 'CriticalIllnessProvisionInstance';
  firstOccurrence?: Maybe<Scalars['Boolean']['output']>;
  payment?: Maybe<Payment>;
  primary?: Maybe<Scalars['Boolean']['output']>;
  provision: Provision;
  provisionInstanceId: Scalars['String']['output'];
};

export type CriticalIllnessProvisionInstanceInput = {
  firstOccurrence: Scalars['Boolean']['input'];
  primary: Scalars['Boolean']['input'];
  provisionInstanceId?: InputMaybe<Scalars['String']['input']>;
  provisionType?: InputMaybe<ProvisionType>;
};

export enum CriticalIllnessProvisionType {
  AlzheimersDisease = 'ALZHEIMERS_DISEASE',
  AmyotrophicLateralSclerosis = 'AMYOTROPHIC_LATERAL_SCLEROSIS',
  BenignBrainTumor = 'BENIGN_BRAIN_TUMOR',
  BipolarOneDisorder = 'BIPOLAR_ONE_DISORDER',
  CarcinomaInSitu = 'CARCINOMA_IN_SITU',
  CerebralPalsy = 'CEREBRAL_PALSY',
  CleftLipPalate = 'CLEFT_LIP_PALATE',
  CoronaryArteryDisease = 'CORONARY_ARTERY_DISEASE',
  Coronavirus = 'CORONAVIRUS',
  Covid_19 = 'COVID_19',
  CriticalIllnessComa = 'CRITICAL_ILLNESS_COMA',
  CriticalIllnessLossOfHearing = 'CRITICAL_ILLNESS_LOSS_OF_HEARING',
  CriticalIllnessLossOfSight = 'CRITICAL_ILLNESS_LOSS_OF_SIGHT',
  CriticalIllnessLossOfSpeech = 'CRITICAL_ILLNESS_LOSS_OF_SPEECH',
  CysticFibrosis = 'CYSTIC_FIBROSIS',
  Diphtheria = 'DIPHTHERIA',
  DownSyndrome = 'DOWN_SYNDROME',
  Encephalitis = 'ENCEPHALITIS',
  HeartAttack = 'HEART_ATTACK',
  InvasiveCancer = 'INVASIVE_CANCER',
  LegionnairesDisease = 'LEGIONNAIRES_DISEASE',
  LymeDisease = 'LYME_DISEASE',
  MajorOrganFailure = 'MAJOR_ORGAN_FAILURE',
  Malaria = 'MALARIA',
  Meningitis = 'MENINGITIS',
  MethicillinResistantStaphylococcusAureus = 'METHICILLIN_RESISTANT_STAPHYLOCOCCUS_AUREUS',
  MultipleSclerosis = 'MULTIPLE_SCLEROSIS',
  MuscularDystrophy = 'MUSCULAR_DYSTROPHY',
  NecrotizingFasciitis = 'NECROTIZING_FASCIITIS',
  OccupationalInfectiousDisease = 'OCCUPATIONAL_INFECTIOUS_DISEASE',
  Osteomyelitis = 'OSTEOMYELITIS',
  Paralysis = 'PARALYSIS',
  ParkinsonsDisease = 'PARKINSONS_DISEASE',
  Poliomyelitis = 'POLIOMYELITIS',
  PostTraumaticStressDisorder = 'POST_TRAUMATIC_STRESS_DISORDER',
  Rabies = 'RABIES',
  Schizophrenia = 'SCHIZOPHRENIA',
  Sepsis = 'SEPSIS',
  SkinCancer = 'SKIN_CANCER',
  SpinaBifida = 'SPINA_BIFIDA',
  Stroke = 'STROKE',
  Tetanus = 'TETANUS',
  Tuberculosis = 'TUBERCULOSIS'
}

export type CriticalIllnessSpouseConfigurationInput = {
  cannotExceedPercentOfEmployee?: InputMaybe<Scalars['Int']['input']>;
  guaranteedIssueAmount?: InputMaybe<Scalars['Int']['input']>;
  incrementAmount?: InputMaybe<Scalars['Int']['input']>;
  maximumBenefit?: InputMaybe<Scalars['Int']['input']>;
  minimumBenefit?: InputMaybe<Scalars['Int']['input']>;
  planSequenceNumber?: InputMaybe<Scalars['String']['input']>;
  wellnessBenefitAmount?: InputMaybe<WellnessBenefitAmountEnum>;
};

export type CriticalIllnessSpousePolicyCoverage = PolicyCoverage & {
  __typename?: 'CriticalIllnessSpousePolicyCoverage';
  bookId?: Maybe<Scalars['String']['output']>;
  cannotExceedPercentOfEmployee?: Maybe<Scalars['Int']['output']>;
  contributoryType?: Maybe<ContributoryTypeEnum>;
  coverageHistory: Array<PolicyCoverageHistory>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  grandfathered?: Maybe<Scalars['Boolean']['output']>;
  guaranteedIssueAmount?: Maybe<Scalars['Int']['output']>;
  incrementAmount?: Maybe<Scalars['Int']['output']>;
  maximumBenefit?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  minimumBenefit?: Maybe<Scalars['Int']['output']>;
  name: ProductName;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
};

export enum Dhmo {
  CaliforniaDentalNetwork = 'CALIFORNIA_DENTAL_NETWORK',
  Dhs = 'DHS',
  Eds = 'EDS',
  NotAvailable = 'NOT_AVAILABLE',
  Other = 'OTHER',
  Safeguard = 'SAFEGUARD',
  Solstice = 'SOLSTICE',
  WilliametteRich = 'WILLIAMETTE_RICH'
}

export type DateComparisonFilter = {
  gte?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
};

/** Filter for properties that within the given date range.  Both Begin and End are inclusive. */
export type DateRangeFilter = {
  begin: Scalars['Date']['input'];
  end: Scalars['Date']['input'];
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export enum DecisionType {
  Approved = 'APPROVED',
  ClaimBuiltInError = 'CLAIM_BUILT_IN_ERROR',
  Denied = 'DENIED',
  PartiallyDenied = 'PARTIALLY_DENIED'
}

export enum DefinitionOfDisabilityEnum {
  Partial = 'PARTIAL',
  Residual = 'RESIDUAL',
  Total = 'TOTAL',
  TotalWithSocialSecurityApproval = 'TOTAL_WITH_SOCIAL_SECURITY_APPROVAL'
}

export enum DefinitionOfEarningsEnum {
  BaseWage = 'BASE_WAGE',
  BaseWageWithBonus = 'BASE_WAGE_WITH_BONUS',
  BaseWageWithBonusAndCommission = 'BASE_WAGE_WITH_BONUS_AND_COMMISSION',
  BaseWageWithCommission = 'BASE_WAGE_WITH_COMMISSION',
  ContractSalaryTwelveMonths = 'CONTRACT_SALARY_TWELVE_MONTHS',
  SpecialEarnings = 'SPECIAL_EARNINGS',
  W2OneYearAverage = 'W2_ONE_YEAR_AVERAGE',
  W2ThreeYearAverage = 'W2_THREE_YEAR_AVERAGE',
  W2TwoYearAverage = 'W2_TWO_YEAR_AVERAGE'
}

export enum DefinitionOfEarningsType {
  BaseWage = 'BASE_WAGE',
  BaseWageWithBonus = 'BASE_WAGE_WITH_BONUS',
  BaseWageWithBonusAndCommission = 'BASE_WAGE_WITH_BONUS_AND_COMMISSION',
  BaseWageWithCommission = 'BASE_WAGE_WITH_COMMISSION',
  ContractSalary = 'CONTRACT_SALARY',
  SpecialEarnings = 'SPECIAL_EARNINGS',
  W2_1YearAverage = 'W2_1_YEAR_AVERAGE',
  W2_2YearAverage = 'W2_2_YEAR_AVERAGE',
  W2_3YearAverage = 'W2_3_YEAR_AVERAGE'
}

export enum DeletionReason {
  IncorrectMedicalRecordContained = 'INCORRECT_MEDICAL_RECORD_CONTAINED',
  NotSentOrNeeded = 'NOT_SENT_OR_NEEDED',
  Other = 'OTHER',
  PhiOrPii = 'PHI_OR_PII',
  WrongClaimFile = 'WRONG_CLAIM_FILE'
}

export enum DenialReasonType {
  Eligibility = 'ELIGIBILITY',
  MaximumBenefitReached = 'MAXIMUM_BENEFIT_REACHED',
  PolicyLimitation = 'POLICY_LIMITATION',
  PolicyRequirementsNotSatisfied = 'POLICY_REQUIREMENTS_NOT_SATISFIED',
  PregnancyLimitation = 'PREGNANCY_LIMITATION',
  PreExistingCondition = 'PRE_EXISTING_CONDITION',
  ProofOfDocumentationNotSatisfied = 'PROOF_OF_DOCUMENTATION_NOT_SATISFIED'
}

export type DentalConfiguration = PolicyCoverageConfiguration & {
  __typename?: 'DentalConfiguration';
  annualEnrollment?: Maybe<Scalars['Boolean']['output']>;
  choiceGroup: Scalars['Boolean']['output'];
  /** Returns true if employee contributes any percentage, and returns false if the employer covers 100% of the cost */
  contributory?: Maybe<Scalars['Boolean']['output']>;
  deductible: DentalDeductible;
  /** Dental Health Maintenance Organization indicator */
  dhmo?: Maybe<Dhmo>;
  domesticPartner?: Maybe<DomesticPartnerEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  /** Essential Health Benefits indicator */
  ehb?: Maybe<Scalars['Boolean']['output']>;
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  maximumAccumulationElected: Scalars['Boolean']['output'];
  memberGroupId: Scalars['String']['output'];
  minimumWeeklyHours?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<ProductName>;
  network: DentalNetwork;
  openEnrollment: OpenEnrollmentType;
  plan: DentalPlan;
  planSequenceNumber: Scalars['String']['output'];
  policyId: Scalars['String']['output'];
  prevailingCharges: Scalars['Int']['output'];
  preventivePassport: Scalars['Boolean']['output'];
  productShortName: Scalars['String']['output'];
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  retireesCovered?: Maybe<RetireesCoveredEnum>;
  searchDate: Scalars['String']['output'];
  terminationDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use ..voluntary instead */
  voluntary: Scalars['Boolean']['output'];
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
};

export type DentalCoverageBenefitsType = {
  __typename?: 'DentalCoverageBenefitsType';
  basic?: Maybe<DentalNetworkBenefitType>;
  major?: Maybe<DentalNetworkBenefitType>;
  maximumAmount?: Maybe<Scalars['Int']['output']>;
  preventive?: Maybe<DentalNetworkBenefitType>;
};

export type DentalDeductible = {
  __typename?: 'DentalDeductible';
  familyDeductible?: Maybe<Scalars['Int']['output']>;
  maximumDefinition?: Maybe<DentalDeductibleMaximumDefinition>;
  option?: Maybe<DentalDeductibleOption>;
};

export enum DentalDeductibleMaximumDefinition {
  CalendarYear = 'CALENDAR_YEAR',
  PolicyYear = 'POLICY_YEAR'
}

export enum DentalDeductibleOption {
  Annual = 'ANNUAL',
  Lifetime = 'LIFETIME'
}

export type DentalDependentPolicyCoverage = PolicyCoverage & {
  __typename?: 'DentalDependentPolicyCoverage';
  bookId?: Maybe<Scalars['String']['output']>;
  contributoryType?: Maybe<ContributoryTypeEnum>;
  coverageHistory: Array<PolicyCoverageHistory>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  maxAge?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  name: ProductName;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
  terminationDateRule?: Maybe<EffectiveDateRuleEnum>;
};

export type DentalEpo = {
  __typename?: 'DentalEPO';
  inNetwork: DentalNetworkProvisions;
  provisions: DentalProvisionsType;
};

export type DentalIndemnity = {
  __typename?: 'DentalIndemnity';
  benefits: DentalCoverageBenefitsType;
  provisions: DentalProvisionsType;
  riders: DentalRidersType;
};

export type DentalNetwork = {
  __typename?: 'DentalNetwork';
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  select?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<NetworkType>;
};

export type DentalNetworkBenefitType = {
  __typename?: 'DentalNetworkBenefitType';
  coinsurance: Scalars['Int']['output'];
  combinedDeductible: Scalars['Boolean']['output'];
  deductible: Scalars['Int']['output'];
};

export type DentalNetworkProvisions = {
  __typename?: 'DentalNetworkProvisions';
  benefits?: Maybe<DentalCoverageBenefitsType>;
  riders?: Maybe<DentalRidersType>;
};

export type DentalOrthoRiderType = {
  __typename?: 'DentalOrthoRiderType';
  coinsurance?: Maybe<Scalars['Int']['output']>;
  lifetimeDeductible?: Maybe<Scalars['Int']['output']>;
  lifetimeMaximum?: Maybe<Scalars['Int']['output']>;
  orthoType: OrthoTypeEnum;
};

export type DentalPos = {
  __typename?: 'DentalPOS';
  epo: DentalEpo;
  ppo: DentalPpo;
};

export type DentalPpo = {
  __typename?: 'DentalPPO';
  inNetwork: DentalNetworkProvisions;
  outOfNetwork: DentalNetworkProvisions;
  provisions: DentalProvisionsType;
};

export type DentalPlan = {
  __typename?: 'DentalPlan';
  epo?: Maybe<DentalEpo>;
  indemnity?: Maybe<DentalIndemnity>;
  planType?: Maybe<DentalPlanTypeEnum>;
  pos?: Maybe<DentalPos>;
  ppo?: Maybe<DentalPpo>;
};

export enum DentalPlanTypeEnum {
  Epo = 'EPO',
  Indemnity = 'INDEMNITY',
  Pos = 'POS',
  Ppo = 'PPO',
  VoluntaryEpo = 'VOLUNTARY_EPO',
  VoluntaryIndemnity = 'VOLUNTARY_INDEMNITY',
  VoluntaryPos = 'VOLUNTARY_POS',
  VoluntaryPpo = 'VOLUNTARY_PPO'
}

export type DentalPolicyCoverage = PolicyCoverage & {
  __typename?: 'DentalPolicyCoverage';
  benefitPlanYear?: Maybe<Scalars['String']['output']>;
  bookId?: Maybe<Scalars['String']['output']>;
  configuration: DentalConfiguration;
  /** @deprecated use configuration.contributory instead */
  contributoryType?: Maybe<ContributoryTypeEnum>;
  coverageHistory: Array<PolicyCoverageHistory>;
  dependent?: Maybe<DentalDependentPolicyCoverage>;
  /** @deprecated use configuration.domesticPartner instead */
  domesticPartner?: Maybe<DomesticPartnerEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.employeeTerminationDateRule instead */
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  isSelfFunded?: Maybe<Scalars['Boolean']['output']>;
  memberGroupId: Scalars['String']['output'];
  /** @deprecated use configuration.minimumWeeklyHours instead */
  minimumWeeklyHours?: Maybe<Scalars['Int']['output']>;
  name: ProductName;
  planNumber?: Maybe<Scalars['String']['output']>;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  priorCarrier?: Maybe<PriorCarrier>;
  productShortName: Scalars['String']['output'];
  /** @deprecated use configuration.rateGuaranteeEndDate instead */
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.rateGuaranteePeriod instead */
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  /** @deprecated use configuration.rateGuaranteeReason instead */
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  ratingPlanYear?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.retireesCovered instead */
  retireesCovered?: Maybe<RetireesCoveredEnum>;
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
  voluntary: Scalars['Boolean']['output'];
  /** @deprecated use configuration.waitingPeriod instead */
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
};

export enum DentalProvisionEnum {
  Basic = 'BASIC',
  Covered = 'COVERED',
  CoveredInUnitOne = 'COVERED_IN_UNIT_ONE',
  CoveredInUnitTwo = 'COVERED_IN_UNIT_TWO',
  Major = 'MAJOR',
  NotCovered = 'NOT_COVERED',
  Preventive = 'PREVENTIVE',
  Rider = 'RIDER',
  Split = 'SPLIT',
  UnitThree = 'UNIT_THREE'
}

export enum DentalProvisionFrequencyEnum {
  EightyFourMonthReplacement = 'EIGHTY_FOUR_MONTH_REPLACEMENT',
  FourEveryTwelveMonths = 'FOUR_EVERY_TWELVE_MONTHS',
  FourPerCalendarYear = 'FOUR_PER_CALENDAR_YEAR',
  Na = 'NA',
  No = 'NO',
  None = 'NONE',
  OneHundredTwentyMonthReplacement = 'ONE_HUNDRED_TWENTY_MONTH_REPLACEMENT',
  OnePerCalendarYear = 'ONE_PER_CALENDAR_YEAR',
  OnePerSixtyMonths = 'ONE_PER_SIXTY_MONTHS',
  OnePerSixMonths = 'ONE_PER_SIX_MONTHS',
  OnePerThirtySixMonths = 'ONE_PER_THIRTY_SIX_MONTHS',
  OnePerTwentyFourMonths = 'ONE_PER_TWENTY_FOUR_MONTHS',
  SixtyMonthReplacement = 'SIXTY_MONTH_REPLACEMENT',
  TwoEveryTwelveMonths = 'TWO_EVERY_TWELVE_MONTHS',
  TwoPerCalendarYear = 'TWO_PER_CALENDAR_YEAR',
  Yes = 'YES'
}

export type DentalProvisionsType = {
  __typename?: 'DentalProvisionsType';
  ageLimit: Scalars['Int']['output'];
  compositesOnMolars: DentalProvisionEnum;
  crown: DentalProvisionEnum;
  crownFrequency?: Maybe<DentalProvisionFrequencyEnum>;
  emergencyExam: DentalProvisionEnum;
  emergencyExamFrequency?: Maybe<DentalProvisionFrequencyEnum>;
  endodonticComplex: DentalProvisionEnum;
  endodonticSimple: DentalProvisionEnum;
  fluoride: DentalProvisionEnum;
  fluorideFrequency?: Maybe<DentalProvisionFrequencyEnum>;
  generalAnesthesia: DentalProvisionEnum;
  harmfulHabitAppliance: DentalProvisionEnum;
  implants: DentalProvisionEnum;
  occlusalGuard: DentalProvisionEnum;
  oralSurgeryComplex: DentalProvisionEnum;
  oralSurgerySimple: DentalProvisionEnum;
  periodonticNonSurgical: DentalProvisionEnum;
  periodonticSurgical: DentalProvisionEnum;
  porcelainFacingOnMolars: DentalProvisionEnum;
  prophylaxisPeriodontal: DentalProvisionEnum;
  prophylaxisPeriodontalFrequency?: Maybe<DentalProvisionFrequencyEnum>;
  prophylaxisRoutine: DentalProvisionEnum;
  prophylaxisRoutineFrequency?: Maybe<DentalProvisionFrequencyEnum>;
  repairs: DentalProvisionEnum;
  sealants: DentalProvisionEnum;
  sealantsFrequency?: Maybe<DentalProvisionFrequencyEnum>;
  spaceMaintainers: DentalProvisionEnum;
  xrays: DentalProvisionEnum;
  xraysBitewingFrequency?: Maybe<DentalProvisionFrequencyEnum>;
  xraysFullMouthFrequency?: Maybe<DentalProvisionFrequencyEnum>;
};

export type DentalRiderType = {
  __typename?: 'DentalRiderType';
  coinsurance: Scalars['Int']['output'];
  lifetimeDeductible: Scalars['Int']['output'];
  lifetimeMaximum: Scalars['Int']['output'];
};

export type DentalRidersType = {
  __typename?: 'DentalRidersType';
  accident?: Maybe<DentalRiderType>;
  cosmetic?: Maybe<DentalRiderType>;
  implant?: Maybe<DentalRiderType>;
  orthodontia?: Maybe<DentalOrthoRiderType>;
  tmj?: Maybe<DentalRiderType>;
};

export type Dependent = {
  __typename?: 'Dependent';
  benefit?: Maybe<MemberOrDependentBenefit>;
  birthDate?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<Relationship>;
};

export enum DependentEducationEnum {
  None = 'NONE',
  OneHundredDollars = 'ONE_HUNDRED_DOLLARS',
  OneHundredFiftyDollars = 'ONE_HUNDRED_FIFTY_DOLLARS',
  TwoHundredDollars = 'TWO_HUNDRED_DOLLARS',
  TwoHundredFiftyDollars = 'TWO_HUNDRED_FIFTY_DOLLARS'
}

export enum DependentType {
  Child = 'CHILD',
  Spouse = 'SPOUSE'
}

export enum DetailedDependentType {
  DependentChild = 'DEPENDENT_CHILD',
  DomesticPartner = 'DOMESTIC_PARTNER',
  FosterChild = 'FOSTER_CHILD',
  HandicappedChild = 'HANDICAPPED_CHILD',
  NonTraditionalChild = 'NON_TRADITIONAL_CHILD',
  Spouse = 'SPOUSE',
  Unknown = 'UNKNOWN'
}

export type Diagnosis = {
  __typename?: 'Diagnosis';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export enum DiagnosisType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY'
}

export enum DirectionType {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING'
}

export enum DisabilityBenefitType {
  LifeWaiver = 'LIFE_WAIVER',
  LongTermDisability = 'LONG_TERM_DISABILITY',
  ShortTermDisability = 'SHORT_TERM_DISABILITY'
}

export type DisabilityEarnings = {
  __typename?: 'DisabilityEarnings';
  lifeWaiverEarnings?: Maybe<LifeWaiverEarnings>;
  ltdEarnings?: Maybe<LtdEarnings>;
  stdEarnings?: Maybe<StdEarnings>;
};

export enum DisabilityEarningsCalculationType {
  BaseWage = 'BASE_WAGE',
  BaseWageWithBonus = 'BASE_WAGE_WITH_BONUS',
  BaseWageWithBonusAndCommission = 'BASE_WAGE_WITH_BONUS_AND_COMMISSION',
  BaseWageWithCommission = 'BASE_WAGE_WITH_COMMISSION',
  GrossEarnings = 'GROSS_EARNINGS',
  Manual = 'MANUAL',
  W2_1YearAverage = 'W2_1_YEAR_AVERAGE',
  W2_2YearAverage = 'W2_2_YEAR_AVERAGE',
  W2_3YearAverage = 'W2_3_YEAR_AVERAGE'
}

export type DisabilityEarningsDetail = {
  __typename?: 'DisabilityEarningsDetail';
  benefitId: Scalars['String']['output'];
  bonusAmount?: Maybe<Scalars['BigDecimal']['output']>;
  bonusAndCommissionEndDate?: Maybe<Scalars['Date']['output']>;
  bonusAndCommissionStartDate?: Maybe<Scalars['Date']['output']>;
  commissionAmount?: Maybe<Scalars['BigDecimal']['output']>;
  dateOfHire?: Maybe<Scalars['Date']['output']>;
  definitionOfEarnings?: Maybe<DefinitionOfEarningsType>;
  disabilityEarningsCalculationType?: Maybe<DisabilityEarningsCalculationType>;
  grossEarningsAmount?: Maybe<Scalars['BigDecimal']['output']>;
  grossEarningsEndDate?: Maybe<Scalars['Date']['output']>;
  grossEarningsStartDate?: Maybe<Scalars['Date']['output']>;
  hasBonusOrCommission?: Maybe<Scalars['Boolean']['output']>;
  hoursWorkedPerWeek?: Maybe<Scalars['BigDecimal']['output']>;
  leaveInstances?: Maybe<Array<Maybe<LeaveInstance>>>;
  minimumDateOfHireToUseW2Earnings?: Maybe<Scalars['Date']['output']>;
  payEffectiveDate?: Maybe<Scalars['Date']['output']>;
  payFrequency?: Maybe<PayFrequencyType>;
  payRate?: Maybe<Scalars['BigDecimal']['output']>;
  preDisabilityEarnings?: Maybe<DisabilityEarnings>;
  preDisabilityEarningsOverride?: Maybe<Scalars['BigDecimal']['output']>;
  salaryChangeEffectiveDefinition?: Maybe<Scalars['String']['output']>;
  w2Earnings?: Maybe<Array<Maybe<W2Earnings>>>;
  w2EarningsCalculationType?: Maybe<W2EarningsCalculationType>;
  w2YearsRequired?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type DisabilityEarningsInput = {
  bonusAmount?: InputMaybe<Scalars['BigDecimal']['input']>;
  bonusAndCommissionEndDate?: InputMaybe<Scalars['Date']['input']>;
  bonusAndCommissionStartDate?: InputMaybe<Scalars['Date']['input']>;
  commissionAmount?: InputMaybe<Scalars['BigDecimal']['input']>;
  dateOfHire?: InputMaybe<Scalars['Date']['input']>;
  grossEarningsAmount?: InputMaybe<Scalars['BigDecimal']['input']>;
  grossEarningsEndDate?: InputMaybe<Scalars['Date']['input']>;
  grossEarningsStartDate?: InputMaybe<Scalars['Date']['input']>;
  hasBonusOrCommission?: InputMaybe<Scalars['Boolean']['input']>;
  hoursWorkedPerWeek?: InputMaybe<Scalars['BigDecimal']['input']>;
  leaveInstances?: InputMaybe<Array<InputMaybe<LeaveInstanceInput>>>;
  payEffectiveDate?: InputMaybe<Scalars['Date']['input']>;
  payFrequency?: InputMaybe<PayFrequencyType>;
  payRate?: InputMaybe<Scalars['BigDecimal']['input']>;
  preDisabilityEarningsOverride?: InputMaybe<Scalars['BigDecimal']['input']>;
  w2Earnings?: InputMaybe<Array<InputMaybe<W2EarningsInput>>>;
  w2EarningsCalculationType?: InputMaybe<W2EarningsCalculationType>;
};

export enum DisabilityEarningsPeriod {
  PerMonth = 'PER_MONTH',
  PerWeek = 'PER_WEEK',
  PerYear = 'PER_YEAR'
}

export enum DisabilitySurvivorBenefitType {
  None = 'NONE',
  SixMonthsBenefitPayable = 'SIX_MONTHS_BENEFIT_PAYABLE',
  SixMonthsPrimaryBenefit = 'SIX_MONTHS_PRIMARY_BENEFIT',
  ThreeMonthsBenefitPayable = 'THREE_MONTHS_BENEFIT_PAYABLE',
  ThreeMonthsPrimaryBenefit = 'THREE_MONTHS_PRIMARY_BENEFIT',
  ThreeWeeksPrimaryBenefit = 'THREE_WEEKS_PRIMARY_BENEFIT'
}

export enum DistributionType {
  LumpSum = 'LUMP_SUM',
  Recurring = 'RECURRING'
}

export type Document = Auditable & {
  __typename?: 'Document';
  content?: Maybe<Scalars['String']['output']>;
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  deletionRequest?: Maybe<DocumentDeletionRequest>;
  documentAttachments: Array<Maybe<DocumentAttachment>>;
  documentId: Scalars['String']['output'];
  link: Scalars['String']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  sharepointItemId: Scalars['String']['output'];
  statuses?: Maybe<Array<Maybe<DocumentStatus>>>;
  type: DocumentType;
};

export type DocumentAttachment = Auditable & {
  __typename?: 'DocumentAttachment';
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  documentAttachmentId: Scalars['String']['output'];
  endTime?: Maybe<Scalars['DateTime']['output']>;
  formId: Scalars['String']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  startTime: Scalars['DateTime']['output'];
};

export type DocumentAttachmentGroup = Auditable & {
  __typename?: 'DocumentAttachmentGroup';
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  documentAttachments: Array<Maybe<DocumentAttachment>>;
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
};

export type DocumentDeletionRequest = Auditable & {
  __typename?: 'DocumentDeletionRequest';
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  deletionReason: DeletionReason;
  documentId: Scalars['String']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
};

export type DocumentFilter = {
  claimId: Scalars['String']['input'];
  types?: InputMaybe<Array<InputMaybe<DocumentType>>>;
};

export type DocumentStatus = {
  __typename?: 'DocumentStatus';
  effectiveDate: Scalars['DateTime']['output'];
  type: DocumentStatusType;
};

export type DocumentStatusInput = {
  effectiveDate?: InputMaybe<Scalars['DateTime']['input']>;
  type: DocumentStatusType;
};

export enum DocumentStatusType {
  Created = 'CREATED',
  PrintToMailComplete = 'PRINT_TO_MAIL_COMPLETE',
  PrintToMailPending = 'PRINT_TO_MAIL_PENDING',
  StoredInContentManager = 'STORED_IN_CONTENT_MANAGER'
}

export enum DocumentType {
  Accounting = 'ACCOUNTING',
  Claims = 'CLAIMS',
  Financial = 'FINANCIAL',
  Legal = 'LEGAL',
  Letter = 'LETTER',
  Medical = 'MEDICAL',
  Policy = 'POLICY',
  SocialSecurity = 'SOCIAL_SECURITY',
  Vocational = 'VOCATIONAL'
}

export enum DomesticPartnerEnum {
  Included = 'INCLUDED',
  None = 'NONE',
  OppositeSex = 'OPPOSITE_SEX',
  SameSex = 'SAME_SEX',
  SameSexAndOppositeSex = 'SAME_SEX_AND_OPPOSITE_SEX',
  SameSexOnly = 'SAME_SEX_ONLY',
  SameSexOrOppositeSex = 'SAME_SEX_OR_OPPOSITE_SEX'
}

export type DurableMedicalEquipmentProvision = {
  __typename?: 'DurableMedicalEquipmentProvision';
  daysPerYear?: Maybe<Scalars['Int']['output']>;
  hospitalizationRequired?: Maybe<Scalars['Boolean']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
  tierOneAmount?: Maybe<Scalars['Int']['output']>;
  tierOneIncluded?: Maybe<Scalars['Boolean']['output']>;
  tierThreeAmount?: Maybe<Scalars['Int']['output']>;
  tierThreeIncluded?: Maybe<Scalars['Boolean']['output']>;
  tierTwoAmount?: Maybe<Scalars['Int']['output']>;
  tierTwoIncluded?: Maybe<Scalars['Boolean']['output']>;
};

export enum EffectiveDateRuleEnum {
  AprilFifteenth = 'APRIL_FIFTEENTH',
  AprilFirst = 'APRIL_FIRST',
  AugustFifteenth = 'AUGUST_FIFTEENTH',
  AugustFirst = 'AUGUST_FIRST',
  DecemberFifteenth = 'DECEMBER_FIFTEENTH',
  DecemberFirst = 'DECEMBER_FIRST',
  DecemberThirtyFirst = 'DECEMBER_THIRTY_FIRST',
  FebruaryFifteenth = 'FEBRUARY_FIFTEENTH',
  FebruaryFirst = 'FEBRUARY_FIRST',
  FebruaryTwentyEighth = 'FEBRUARY_TWENTY_EIGHTH',
  FifteenthOfCoincidingOrFollowingMonth = 'FIFTEENTH_OF_COINCIDING_OR_FOLLOWING_MONTH',
  FifteenthOfFollowingMonth = 'FIFTEENTH_OF_FOLLOWING_MONTH',
  FifteenthOfTheMonth = 'FIFTEENTH_OF_THE_MONTH',
  FirstOfCoincidingFollowingMonth = 'FIRST_OF_COINCIDING_FOLLOWING_MONTH',
  FirstOfFollowingMonth = 'FIRST_OF_FOLLOWING_MONTH',
  FirstOfTheMonth = 'FIRST_OF_THE_MONTH',
  JanuaryFifteenth = 'JANUARY_FIFTEENTH',
  JanuaryFirst = 'JANUARY_FIRST',
  JanuarySecond = 'JANUARY_SECOND',
  JanuaryThirtieth = 'JANUARY_THIRTIETH',
  JanuaryThirtyFirst = 'JANUARY_THIRTY_FIRST',
  JanuaryTwentieth = 'JANUARY_TWENTIETH',
  JulyFifteenth = 'JULY_FIFTEENTH',
  JulyFirst = 'JULY_FIRST',
  JuneFifteenth = 'JUNE_FIFTEENTH',
  JuneFirst = 'JUNE_FIRST',
  MarchFifteenth = 'MARCH_FIFTEENTH',
  MarchFirst = 'MARCH_FIRST',
  MayFifteenth = 'MAY_FIFTEENTH',
  MayFirst = 'MAY_FIRST',
  MemberTerm_60DaysRules = 'MEMBER_TERM_60_DAYS_RULES',
  NovemberFifteenth = 'NOVEMBER_FIFTEENTH',
  NovemberFirst = 'NOVEMBER_FIRST',
  OctoberFifteenth = 'OCTOBER_FIFTEENTH',
  OctoberFirst = 'OCTOBER_FIRST',
  SchemeAnniversaryCurrentOrFollowing = 'SCHEME_ANNIVERSARY_CURRENT_OR_FOLLOWING',
  SchemeAnniversaryFollowing = 'SCHEME_ANNIVERSARY_FOLLOWING',
  SeptemberFifteenth = 'SEPTEMBER_FIFTEENTH',
  SeptemberFirst = 'SEPTEMBER_FIRST',
  SeptemberThirtieth = 'SEPTEMBER_THIRTIETH',
  Standard = 'STANDARD'
}

export type EftPaymentPreference = {
  __typename?: 'EftPaymentPreference';
  active: Scalars['Boolean']['output'];
  bankAccountNumber: Scalars['String']['output'];
  bankAccountType: BankAccountType;
  bankRoutingNumber: Scalars['String']['output'];
  paymentPreferenceId: Scalars['String']['output'];
  valid: Scalars['Boolean']['output'];
};

export type ElectronicConsent = Auditable & {
  __typename?: 'ElectronicConsent';
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  electronicConsentId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  signatureDate: Scalars['String']['output'];
  supportingDocumentId: Scalars['String']['output'];
  supportingDocumentationLink: Scalars['String']['output'];
};

export type ElectronicConsentInput = {
  email: Scalars['String']['input'];
  signatureDate: Scalars['Date']['input'];
  supportingDocumentId: Scalars['String']['input'];
};

export type ElectronicConsentInputFileUpload = {
  email: Scalars['String']['input'];
  signatureDate: Scalars['Date']['input'];
};

export enum EliminationPeriodMode {
  Days = 'DAYS',
  Months = 'MONTHS'
}

export type EmailAddressType = {
  __typename?: 'EmailAddressType';
  effectiveDate: Scalars['String']['output'];
  emailAddress: Scalars['String']['output'];
};

export type Employee = {
  __typename?: 'Employee';
  claims: Array<Maybe<EmployerClaim>>;
  dateOfBirth: Scalars['Date']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  privacyId: Scalars['Int']['output'];
};

export enum EmployeeAssistanceProgramProvision {
  Core = 'CORE',
  NotIncluded = 'NOT_INCLUDED',
  Premier = 'PREMIER'
}

export type Employer = {
  __typename?: 'Employer';
  addresses: Array<Address>;
  contacts: Array<Contact>;
  id: Scalars['String']['output'];
  isPrimary: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  policyId: Scalars['String']['output'];
  searchDate: Scalars['String']['output'];
  status: ActiveCancelledStatus;
};

export type EmployerClaim = {
  __typename?: 'EmployerClaim';
  benefitStatus: BenefitStatusType;
  claimId: Scalars['String']['output'];
  conditionType?: Maybe<ConditionType>;
  coverage: Scalars['String']['output'];
  coveredPersonFirstName: Scalars['String']['output'];
  coveredPersonType: CoveredPersonType;
  incurredDate?: Maybe<Scalars['Date']['output']>;
  notificationDate?: Maybe<Scalars['Date']['output']>;
  payments: Array<Maybe<EmployerPayment>>;
};

export type EmployerClaimSummary = {
  __typename?: 'EmployerClaimSummary';
  employees: Array<Maybe<Employee>>;
};

export type EmployerFilter = {
  accountId: Scalars['String']['input'];
  billGroupId: Scalars['String']['input'];
};

export type EmployerPayment = {
  __typename?: 'EmployerPayment';
  effectiveDate: Scalars['Date']['output'];
  netAmount: Scalars['BigDecimal']['output'];
  status: PaymentStatusType;
};

export type EmployerUpdateInput = {
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Employment = {
  __typename?: 'Employment';
  hoursWorkedPerWeek?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<EmploymentStatus>;
  wage?: Maybe<Scalars['Float']['output']>;
  wageFrequency?: Maybe<WageFrequency>;
  workState?: Maybe<Scalars['String']['output']>;
};

export enum EmploymentStatus {
  ActivelyAtWork = 'ACTIVELY_AT_WORK',
  CobraContinuation = 'COBRA_CONTINUATION',
  Retired = 'RETIRED',
  StateContinuation = 'STATE_CONTINUATION',
  Unknown = 'UNKNOWN'
}

export type EndBenefitWithholdingInput = {
  benefitWithholdingId: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum EnrollmentPlatformType {
  ApiBeacon = 'API_BEACON',
  ApiBenefitfocus = 'API_BENEFITFOCUS',
  ApiBoonChapman = 'API_BOON_CHAPMAN',
  ApiCoPower = 'API_CO_POWER',
  ApiEasecentral = 'API_EASECENTRAL',
  ApiEmployeeNavigator = 'API_EMPLOYEE_NAVIGATOR',
  ApiEverythingBenefits = 'API_EVERYTHING_BENEFITS',
  ApiGusto = 'API_GUSTO',
  ApiNetchex = 'API_NETCHEX',
  ApiNexben = 'API_NEXBEN',
  ApiNoyo = 'API_NOYO',
  ApiOnedigital = 'API_ONEDIGITAL',
  ApiTrionfo = 'API_TRIONFO',
  ApiVericred = 'API_VERICRED',
  ApiZenefits = 'API_ZENEFITS',
  EasyElect = 'EASY_ELECT',
  Efile = 'EFILE',
  EnrollmentExclusive = 'ENROLLMENT_EXCLUSIVE',
  SimplifiedManagement = 'SIMPLIFIED_MANAGEMENT',
  TotalManagement = 'TOTAL_MANAGEMENT'
}

export enum EnrollmentTypeEnum {
  Startup = 'STARTUP',
  TakeoverWithReenrollment = 'TAKEOVER_WITH_REENROLLMENT',
  TakeoverWithSimplifiedEnrollment = 'TAKEOVER_WITH_SIMPLIFIED_ENROLLMENT'
}

export type EobRemark = {
  __typename?: 'EobRemark';
  defaultEobRemark?: Maybe<Scalars['String']['output']>;
  overrideEobRemark?: Maybe<Scalars['String']['output']>;
};

export type ExplanationOfBenefits = {
  __typename?: 'ExplanationOfBenefits';
  content?: Maybe<Scalars['String']['output']>;
};

export type Feature = {
  __typename?: 'Feature';
  type: FeatureType;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  enabled: Scalars['Boolean']['output'];
  name: FeatureName;
};

export enum FeatureName {
  AlternatePayments = 'ALTERNATE_PAYMENTS',
  ClaimEventPublishing = 'CLAIM_EVENT_PUBLISHING',
  Disability = 'DISABILITY'
}

export enum FeatureType {
  UpdatePaymentPreference = 'UPDATE_PAYMENT_PREFERENCE'
}

export type FederalTaxWithholding = Auditable & {
  __typename?: 'FederalTaxWithholding';
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  effectiveEnd?: Maybe<Scalars['Date']['output']>;
  effectiveStart: Scalars['Date']['output'];
  maximumWage: Scalars['BigDecimal']['output'];
  minimumWage: Scalars['BigDecimal']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  surTaxRate: Scalars['BigDecimal']['output'];
  taxRate: Scalars['BigDecimal']['output'];
  taxType: TaxType;
  taxWithholdingId: Scalars['String']['output'];
};

export type FederalTaxWithholdingInput = {
  effectiveEnd?: InputMaybe<Scalars['Date']['input']>;
  effectiveStart: Scalars['Date']['input'];
  maximumWage: Scalars['BigDecimal']['input'];
  minimumWage: Scalars['BigDecimal']['input'];
  taxRate: Scalars['BigDecimal']['input'];
  taxType: TaxType;
  taxWithholdingId?: InputMaybe<Scalars['String']['input']>;
};

export type FederalTaxWithholdingsInput = {
  federalTaxWithholdings: Array<InputMaybe<FederalTaxWithholdingInput>>;
};

export type FicaFinalPaymentDetail = {
  __typename?: 'FicaFinalPaymentDetail';
  calculatedFicaFinalPayment?: Maybe<Scalars['Date']['output']>;
  ficaFinalPayment?: Maybe<Scalars['Date']['output']>;
  ficaFinalPaymentHelperText?: Maybe<Scalars['String']['output']>;
  ficaFinalPaymentOverride?: Maybe<Scalars['Date']['output']>;
  ficaFinalPaymentOverrideReason?: Maybe<Scalars['String']['output']>;
};

export enum FirstDaySeparationPeriod {
  NotIncluded = 'NOT_INCLUDED',
  ThirtyDays = 'THIRTY_DAYS'
}

export type FuturePayment = {
  __typename?: 'FuturePayment';
  eobRemark?: Maybe<Scalars['String']['output']>;
  federalIncomeTaxAmount: Scalars['BigDecimal']['output'];
  grossAmount: Scalars['BigDecimal']['output'];
  medicareTaxAmount: Scalars['BigDecimal']['output'];
  netAmount: Scalars['BigDecimal']['output'];
  paymentDate: Scalars['Date']['output'];
  socialSecurityTaxAmount: Scalars['BigDecimal']['output'];
  stateIncomeTaxAmount: Scalars['BigDecimal']['output'];
  stateTransitTaxAmount: Scalars['BigDecimal']['output'];
  stateUnemploymentTaxAmount: Scalars['BigDecimal']['output'];
};

export type GroupTermLifeConfiguration = PolicyCoverageConfiguration & {
  __typename?: 'GroupTermLifeConfiguration';
  acceleratedBenefits?: Maybe<Scalars['Boolean']['output']>;
  ageReductions?: Maybe<Array<Maybe<AgeReductionType>>>;
  amountType?: Maybe<AmountTypeEnum>;
  /**
   * Displays the annual month & day benefits will be updated when benefits are based on a percentage of the
   * member's salary/compensation package. Format: MM/DD
   */
  compensationChangesOn?: Maybe<Scalars['String']['output']>;
  contributory?: Maybe<Scalars['Boolean']['output']>;
  definitionOfEarnings?: Maybe<DefinitionOfEarningsEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  flatAmount?: Maybe<Scalars['Int']['output']>;
  grandfathered?: Maybe<Scalars['Boolean']['output']>;
  guaranteedIssueAge70AndOver?: Maybe<Scalars['Int']['output']>;
  guaranteedIssueUnder70?: Maybe<Scalars['Int']['output']>;
  hasSuicideExclusion?: Maybe<Scalars['Boolean']['output']>;
  maximumBenefit?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  minimumBenefit?: Maybe<Scalars['Int']['output']>;
  minimumWeeklyHours?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use multipleOfSalaryPercentage instead */
  multipleOfSalary?: Maybe<Scalars['Int']['output']>;
  multipleOfSalaryMinusAmount?: Maybe<Scalars['Int']['output']>;
  multipleOfSalaryPercentage?: Maybe<Scalars['Int']['output']>;
  multipleOfSalaryPlusAmount?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<ProductName>;
  openEnrollment: OpenEnrollmentType;
  periodOfLimitedActivity?: Maybe<Scalars['Boolean']['output']>;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  sabbatical?: Maybe<Scalars['Boolean']['output']>;
  salaryChangeEffectiveDate?: Maybe<SalaryChangeEffectiveDateEnum>;
  searchDate: Scalars['String']['output'];
  terminationDate?: Maybe<Scalars['String']['output']>;
  voluntary: Scalars['Boolean']['output'];
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
  waiveActivelyAtWork?: Maybe<Scalars['Boolean']['output']>;
  waiverDefinitionOfDisability?: Maybe<WaiverDefinitionOfDisabilityEnum>;
  waiverDuration?: Maybe<WaiverDurationEnum>;
  waiverEliminationPeriod?: Maybe<WaiverEliminationPeriodEnum>;
};

export type GroupTermLifeDependentPolicyCoverage = PolicyCoverage & {
  __typename?: 'GroupTermLifeDependentPolicyCoverage';
  benefitPlanYear?: Maybe<Scalars['String']['output']>;
  bookId?: Maybe<Scalars['String']['output']>;
  childSixMonthsAndOverAmount?: Maybe<Scalars['Int']['output']>;
  childUnderSixMonthsAmount?: Maybe<Scalars['Int']['output']>;
  contributoryType?: Maybe<ContributoryTypeEnum>;
  coverageHistory: Array<PolicyCoverageHistory>;
  domesticPartner?: Maybe<DomesticPartnerEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  memberGroupId: Scalars['String']['output'];
  name: ProductName;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  ratingPlanYear?: Maybe<Scalars['String']['output']>;
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  spouseAmount?: Maybe<Scalars['Int']['output']>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
};

export type GroupTermLifePolicyCoverage = PolicyCoverage & {
  __typename?: 'GroupTermLifePolicyCoverage';
  accidentalDeathAndDismemberment?: Maybe<AccidentalDeathAndDismembermentPolicyCoverage>;
  /**
   * Amount Type describes the benefit amount.  Previously planType.
   * @deprecated use configuration.amountType
   */
  amountType?: Maybe<AmountTypeEnum>;
  benefitPlanYear?: Maybe<Scalars['String']['output']>;
  bookId?: Maybe<Scalars['String']['output']>;
  configuration?: Maybe<GroupTermLifeConfiguration>;
  /** @deprecated use configuration.contributory */
  contributoryType?: Maybe<ContributoryTypeEnum>;
  coverageHistory: Array<PolicyCoverageHistory>;
  /** @deprecated use configuration.definitionOfEarnings */
  definitionOfEarnings?: Maybe<DefinitionOfEarningsEnum>;
  dependent?: Maybe<GroupTermLifeDependentPolicyCoverage>;
  /** @deprecated use dependent.domesticPartner */
  dependentDomesticPartner?: Maybe<DomesticPartnerEnum>;
  /** @deprecated use dependent.domesticPartner */
  domesticPartner?: Maybe<DomesticPartnerEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.employeeTerminationDateRule */
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  /** @deprecated use configuration.flatAmount */
  flatAmount?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use configuration.maximumBenefit */
  maximumBenefit?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  /** @deprecated use configuration.minimumBenefit */
  minimumBenefit?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use configuration.minimumWeeklyHours */
  minimumWeeklyHours?: Maybe<Scalars['Int']['output']>;
  name: ProductName;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  priorCarrier?: Maybe<PriorCarrier>;
  productShortName: Scalars['String']['output'];
  /** @deprecated use configuration.rateGuaranteeEndDate */
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.rateGuaranteePeriod */
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  /** @deprecated use configuration.rateGuaranteeReason */
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  ratingPlanYear?: Maybe<Scalars['String']['output']>;
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.waitingPeriod */
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
};

export type HealthScreeningProvision = {
  __typename?: 'HealthScreeningProvision';
  amount?: Maybe<Scalars['Int']['output']>;
  daysPerPersonPerYear?: Maybe<Scalars['Int']['output']>;
  daysPerYear?: Maybe<Scalars['Int']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
};

export type Hipaa = Auditable & {
  __typename?: 'Hipaa';
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  hipaaId: Scalars['String']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  partyAssociates: Array<Maybe<PartyAssociateHipaa>>;
  signatureDate: Scalars['String']['output'];
  supportingDocumentId: Scalars['String']['output'];
  supportingDocumentationLink: Scalars['String']['output'];
};

export type HipaaInput = {
  partyAssociateIds: Array<InputMaybe<Scalars['String']['input']>>;
  signatureDate: Scalars['Date']['input'];
  supportingDocumentId: Scalars['String']['input'];
};

export type HipaaInputFileUpload = {
  partyAssociateIds: Array<InputMaybe<Scalars['String']['input']>>;
  signatureDate: Scalars['Date']['input'];
};

export type HospiceProvision = {
  __typename?: 'HospiceProvision';
  amount?: Maybe<Scalars['Int']['output']>;
  hospitalizationRequired?: Maybe<Scalars['Boolean']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
  maximumDaysPerLifetime?: Maybe<Scalars['Int']['output']>;
};

export enum HospitalIndemnityBenefitPercentEnum {
  FiftyPercentOfEmployee = 'FIFTY_PERCENT_OF_EMPLOYEE',
  OneHundredPercentOfEmployee = 'ONE_HUNDRED_PERCENT_OF_EMPLOYEE',
  TwentyFivePercentOfEmployee = 'TWENTY_FIVE_PERCENT_OF_EMPLOYEE'
}

export type HospitalIndemnityChoice = Choice & {
  __typename?: 'HospitalIndemnityChoice';
  choiceType: ChoiceTypeEnum;
  coverages: Array<HospitalIndemnityPolicyCoveragePlan>;
};

export type HospitalIndemnityConfiguration = PolicyCoverageConfiguration & {
  __typename?: 'HospitalIndemnityConfiguration';
  childBenefitPercentageOfEmployee?: Maybe<Scalars['Int']['output']>;
  contributory?: Maybe<Scalars['Boolean']['output']>;
  coverageType?: Maybe<HospitalIndemnityCoverageType>;
  domesticPartner?: Maybe<DomesticPartnerEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  employerContributoryPercentage?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  minimumWeeklyHours?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<ProductName>;
  openEnrollment: OpenEnrollmentType;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  planType?: Maybe<HospitalIndemnityPlanType>;
  policyId: Scalars['String']['output'];
  portability?: Maybe<PortabilityEnum>;
  preExistingConditions?: Maybe<PreExistingConditionType>;
  productShortName: Scalars['String']['output'];
  provisions: HospitalIndemnityProvisionsType;
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  searchDate: Scalars['String']['output'];
  spouseBenefitPercentageOfEmployee?: Maybe<Scalars['Int']['output']>;
  terminationDate?: Maybe<Scalars['String']['output']>;
  voluntary: Scalars['Boolean']['output'];
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
  waiverOfPremium?: Maybe<WaiverOfPremium>;
};

export type HospitalIndemnityConfigurationInput = {
  actualParticipationPercentage?: InputMaybe<Scalars['Int']['input']>;
  benefitPlanYear?: InputMaybe<Scalars['String']['input']>;
  benefitType?: InputMaybe<BenefitType>;
  bookId?: InputMaybe<Scalars['String']['input']>;
  childBenefit?: InputMaybe<HospitalIndemnityBenefitPercentEnum>;
  coverageType?: InputMaybe<HospitalIndemnityCoverageType>;
  domesticPartner?: InputMaybe<DomesticPartnerEnum>;
  eligibleLives?: InputMaybe<Scalars['Int']['input']>;
  hoursOfEligibility?: InputMaybe<Scalars['Int']['input']>;
  memberGroupName: Scalars['String']['input'];
  planDescription?: InputMaybe<Scalars['String']['input']>;
  planId?: InputMaybe<Scalars['String']['input']>;
  planType?: InputMaybe<HospitalIndemnityPlanType>;
  portability?: InputMaybe<PortabilityEnum>;
  preExistingConditions?: InputMaybe<PreExistingConditionsInput>;
  rateGuaranteeEndDate?: InputMaybe<Scalars['String']['input']>;
  rateGuaranteePeriod?: InputMaybe<RateGuaranteePeriodEnum>;
  rateGuaranteeReason?: InputMaybe<RateGuaranteeReasonEnum>;
  ratingPlanYear?: InputMaybe<Scalars['String']['input']>;
  spouseBenefit?: InputMaybe<HospitalIndemnityBenefitPercentEnum>;
  waiverOfPremium?: InputMaybe<WaiverOfPremium>;
};

export enum HospitalIndemnityCoverageType {
  NotApplicable = 'NOT_APPLICABLE',
  OffTheJob = 'OFF_THE_JOB',
  TwentyFourHour = 'TWENTY_FOUR_HOUR'
}

export type HospitalIndemnityDependentPolicyCoverage = PolicyCoverage & {
  __typename?: 'HospitalIndemnityDependentPolicyCoverage';
  bookId?: Maybe<Scalars['String']['output']>;
  coverageHistory: Array<PolicyCoverageHistory>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  maxAge?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  name: ProductName;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  searchDate: Scalars['String']['output'];
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
  terminationDateRule?: Maybe<EffectiveDateRuleEnum>;
};

export type HospitalIndemnityInput = {
  configuration: Array<HospitalIndemnityConfigurationInput>;
  effectiveDate: Scalars['String']['input'];
  policyId: Scalars['String']['input'];
};

export enum HospitalIndemnityMaternity {
  ComplicationsOnly = 'COMPLICATIONS_ONLY',
  FullAfterTenMonths = 'FULL_AFTER_TEN_MONTHS',
  FullMaternity = 'FULL_MATERNITY',
  NotApplicable = 'NOT_APPLICABLE'
}

export enum HospitalIndemnityPlanType {
  InjuryOnly = 'INJURY_ONLY',
  SicknessAndInjury = 'SICKNESS_AND_INJURY',
  SicknessOnly = 'SICKNESS_ONLY'
}

export type HospitalIndemnityPolicyCoverage = PolicyCoverage & {
  __typename?: 'HospitalIndemnityPolicyCoverage';
  bookId?: Maybe<Scalars['String']['output']>;
  coverageHistory: Array<PolicyCoverageHistory>;
  domesticPartner?: Maybe<DomesticPartnerEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  eligibleLives?: Maybe<Scalars['Int']['output']>;
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  employerContributoryPercentage?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  minimumWeeklyHours?: Maybe<Scalars['Int']['output']>;
  name: ProductName;
  plans: Array<HospitalIndemnityPolicyCoveragePlan>;
  policyId: Scalars['String']['output'];
  portability?: Maybe<PortabilityEnum>;
  productShortName: Scalars['String']['output'];
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
};

export type HospitalIndemnityPolicyCoveragePlan = PolicyCoverage & {
  __typename?: 'HospitalIndemnityPolicyCoveragePlan';
  actualParticipationPercentage?: Maybe<Scalars['Int']['output']>;
  benefitPlanYear?: Maybe<Scalars['String']['output']>;
  bookId?: Maybe<Scalars['String']['output']>;
  configuration: HospitalIndemnityConfiguration;
  /** @deprecated use configuration.contributory */
  contributoryType?: Maybe<ContributoryTypeEnum>;
  coverageHistory: Array<PolicyCoverageHistory>;
  dependent?: Maybe<HospitalIndemnityDependentPolicyCoverage>;
  /** @deprecated use configuration.domesticPartner */
  domesticPartner?: Maybe<DomesticPartnerEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  eligibleLives?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use configuration.employeeTerminationDateRule */
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  /** @deprecated use configuration.employerContributoryPercent */
  employerContributoryPercentage?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  /** @deprecated use configuration.minimumWeeklyHours */
  minimumWeeklyHours?: Maybe<Scalars['Int']['output']>;
  name: ProductName;
  /** Description for Voluntary Hospital Indemnity choice plans.  */
  planDescription?: Maybe<Scalars['String']['output']>;
  planId: Scalars['String']['output'];
  policyId: Scalars['String']['output'];
  /** @deprecated use configuration.portability */
  portability?: Maybe<PortabilityEnum>;
  productShortName: Scalars['String']['output'];
  /** @deprecated use configuration.rateGuaranteeEndDate */
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.rateGuaranteePeriod */
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  /** @deprecated use configuration.rateGuaranteeReason */
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  ratingPlanYear?: Maybe<Scalars['String']['output']>;
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.voluntary */
  voluntary: Scalars['Boolean']['output'];
  /** @deprecated use configuration.waitingPeriod */
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
};

export enum HospitalIndemnityPortabilityEnum {
  None = 'NONE',
  ToAge_70 = 'TO_AGE_70'
}

export type HospitalIndemnityProvisionsType = {
  __typename?: 'HospitalIndemnityProvisionsType';
  airAmbulance?: Maybe<Provision>;
  bloodProducts?: Maybe<Provision>;
  chiropracticCare?: Maybe<HospitalizationProvision>;
  companionLodging?: Maybe<CompanionLodgingProvision>;
  dailyHospitalInjury?: Maybe<TieredProvisionType>;
  dailyHospitalSickness?: Maybe<TieredProvisionType>;
  dailyIntensiveCareUnitInjury?: Maybe<TieredProvisionType>;
  dailyIntensiveCareUnitSickness?: Maybe<TieredProvisionType>;
  dailyTieredBenefit?: Maybe<Scalars['Boolean']['output']>;
  doulaCare?: Maybe<PregnancyProvision>;
  durableMedicalEquipment?: Maybe<DurableMedicalEquipmentProvision>;
  emergencyRoomInjury?: Maybe<Provision>;
  emergencyRoomSickness?: Maybe<Provision>;
  familyCare?: Maybe<Provision>;
  firstDayHospitalInjury?: Maybe<Provision>;
  firstDayHospitalSickness?: Maybe<Provision>;
  firstDayIntensiveCareUnitInjury?: Maybe<Provision>;
  firstDayIntensiveCareUnitSickness?: Maybe<Provision>;
  firstDaySeparationPeriod?: Maybe<FirstDaySeparationPeriod>;
  generalAnesthesia?: Maybe<AnesthesiaProvision>;
  groundOrWaterAmbulance?: Maybe<Provision>;
  healthSavingsAccountCompatible?: Maybe<Scalars['Boolean']['output']>;
  healthScreening?: Maybe<HealthScreeningProvision>;
  homeHealthServices?: Maybe<HospiceProvision>;
  hospiceCare?: Maybe<HospiceProvision>;
  inpatientSurgery?: Maybe<Provision>;
  invasiveDiagnosticProcedure?: Maybe<Provision>;
  labTestOrXray?: Maybe<Provision>;
  majorDiagnosticProcedure?: Maybe<Provision>;
  maternity?: Maybe<HospitalIndemnityMaternity>;
  medicalTravel?: Maybe<MedicalTravelProvision>;
  mentalDisorderOutpatientTherapy?: Maybe<TherapyAndFacilityProvision>;
  mentalDisorderTreatmentFacility?: Maybe<TherapyAndFacilityProvision>;
  newbornNursery?: Maybe<Provision>;
  observationUnit?: Maybe<Provision>;
  outpatientSurgeryAmbulatory?: Maybe<Provision>;
  outpatientSurgeryPhysiciansOffice?: Maybe<Provision>;
  petCare?: Maybe<Provision>;
  physicianVisit?: Maybe<PhysicianVisitProvision>;
  prescriptionDrug?: Maybe<PrescriptionDrugProvision>;
  rehabilitationFacility?: Maybe<HospitalizationProvision>;
  secondOpinion?: Maybe<HospitalizationProvision>;
  skilledNursingFacility?: Maybe<HospitalizationProvision>;
  substanceAbuseOutpatientTherapy?: Maybe<TherapyAndFacilityProvision>;
  substanceAbuseTreatmentFacility?: Maybe<TherapyAndFacilityProvision>;
  therapyServices?: Maybe<HospitalizationProvision>;
  urgentCareFacility?: Maybe<Provision>;
};

export type HospitalProvisionInstance = ProvisionInstanceInterface & {
  __typename?: 'HospitalProvisionInstance';
  endDate?: Maybe<Scalars['Date']['output']>;
  payment?: Maybe<Payment>;
  provision: Provision;
  provisionInstanceId: Scalars['String']['output'];
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type HospitalProvisionInstanceInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  provisionInstanceId?: InputMaybe<Scalars['String']['input']>;
  provisionType?: InputMaybe<ProvisionType>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type HospitalizationProvision = {
  __typename?: 'HospitalizationProvision';
  amount?: Maybe<Scalars['Int']['output']>;
  daysPerYear?: Maybe<Scalars['Int']['output']>;
  hospitalizationRequired?: Maybe<Scalars['Boolean']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
};

export enum IdiOffsetEnum {
  All = 'ALL',
  EmployeePaid = 'EMPLOYEE_PAID',
  None = 'NONE'
}

export enum IdCardMailingLocation {
  EmployerAddress = 'EMPLOYER_ADDRESS',
  MemberAddress = 'MEMBER_ADDRESS',
  PolicyholderAddress = 'POLICYHOLDER_ADDRESS'
}

export type Incident = Auditable & {
  __typename?: 'Incident';
  claimantId: Scalars['String']['output'];
  claims: Array<Maybe<Claim>>;
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  incidentId: Scalars['Int']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
};

export enum JobOccupationalStrengthType {
  Heavy = 'HEAVY',
  Light = 'LIGHT',
  Medium = 'MEDIUM',
  Sedentary = 'SEDENTARY',
  VeryHeavy = 'VERY_HEAVY'
}

export type LeaveInstance = {
  __typename?: 'LeaveInstance';
  endDate: Scalars['Date']['output'];
  grossEarnings: Scalars['BigDecimal']['output'];
  leaveInstanceId: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
};

export type LeaveInstanceInput = {
  endDate: Scalars['Date']['input'];
  grossEarnings: Scalars['BigDecimal']['input'];
  leaveInstanceId?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['Date']['input'];
};

export enum LifeProductType {
  AccidentLife = 'ACCIDENT_LIFE',
  BasicLife = 'BASIC_LIFE',
  VoluntaryLife = 'VOLUNTARY_LIFE'
}

export type LifeReductionAmounts = {
  __typename?: 'LifeReductionAmounts';
  age?: Maybe<Scalars['Int']['output']>;
  amount?: Maybe<Scalars['BigDecimal']['output']>;
  effectiveDate?: Maybe<Scalars['Date']['output']>;
  helperText?: Maybe<Scalars['String']['output']>;
  reductionPercentage?: Maybe<Scalars['Int']['output']>;
};

export type LifeWaiverBenefitBeneficiary = Auditable & {
  __typename?: 'LifeWaiverBenefitBeneficiary';
  beneficiaryId: Scalars['String']['output'];
  beneficiaryType: BeneficiaryType;
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  effectiveEnd?: Maybe<Scalars['Date']['output']>;
  effectiveStart: Scalars['Date']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  partyAssociate: PartyAssociate;
  percentage: Scalars['BigDecimal']['output'];
  productType: LifeProductType;
};

export type LifeWaiverBenefitCalculation = {
  __typename?: 'LifeWaiverBenefitCalculation';
  ageReductions?: Maybe<Array<Maybe<LifeReductionAmounts>>>;
  amountToBePaid?: Maybe<Scalars['BigDecimal']['output']>;
  calculationType?: Maybe<LifeWaiverPlanType>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  helperText?: Maybe<Scalars['String']['output']>;
  maximumBenefit?: Maybe<Scalars['Int']['output']>;
  minimumBenefit?: Maybe<Scalars['Int']['output']>;
  multipleOfPay?: Maybe<Scalars['Int']['output']>;
  multipleOfPayMinusAmount?: Maybe<Scalars['Int']['output']>;
  multipleOfPayPlusAmount?: Maybe<Scalars['Int']['output']>;
};

export type LifeWaiverBenefitDetail = {
  __typename?: 'LifeWaiverBenefitDetail';
  acceleratedLife?: Maybe<Scalars['Boolean']['output']>;
  adlDisabledDate?: Maybe<Scalars['Date']['output']>;
  benefitEndDate?: Maybe<Scalars['Date']['output']>;
  benefitEndDateHelperText: Scalars['String']['output'];
  benefitId: Scalars['String']['output'];
  benefitStartDate?: Maybe<Scalars['Date']['output']>;
  benefitStartDateHelperText: Scalars['String']['output'];
  claimId: Scalars['String']['output'];
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  dateOfDeath?: Maybe<Scalars['Date']['output']>;
  earnings?: Maybe<DisabilityEarningsDetail>;
  employerContributionPercentage?: Maybe<Scalars['Int']['output']>;
  enterpriseId?: Maybe<Scalars['String']['output']>;
  hasCoverage: Scalars['Boolean']['output'];
  hasFinalizedApproval: Scalars['Boolean']['output'];
  incurredDate?: Maybe<Scalars['Date']['output']>;
  lifeWaiverBenefitCalculation?: Maybe<LifeWaiverBenefitCalculation>;
  lifeWaiverTerminationReason?: Maybe<LifeWaiverBenefitTerminationType>;
  premiumPaymentType: PremiumPaymentType;
  proofOfLossDate?: Maybe<Scalars['Date']['output']>;
  terminallyIllPayoutDate?: Maybe<Scalars['Date']['output']>;
  terminationDate?: Maybe<Scalars['Date']['output']>;
  totallyDisabledDate?: Maybe<Scalars['Date']['output']>;
  waiverBenefitId: Scalars['String']['output'];
  waiverDefinitionOfDisability?: Maybe<Scalars['String']['output']>;
  waiverDuration?: Maybe<Scalars['String']['output']>;
  waiverEliminationPeriod?: Maybe<Scalars['String']['output']>;
};

export type LifeWaiverBenefitInput = {
  adlDisabledDate?: InputMaybe<Scalars['Date']['input']>;
  dateOfDeath?: InputMaybe<Scalars['Date']['input']>;
  terminallyIllPayoutDate?: InputMaybe<Scalars['Date']['input']>;
  totallyDisabledDate?: InputMaybe<Scalars['Date']['input']>;
};

export type LifeWaiverBenefitTerminationInput = {
  terminationDate: Scalars['Date']['input'];
  terminationReason: LifeWaiverBenefitTerminationType;
};

export enum LifeWaiverBenefitTerminationType {
  Compromise = 'COMPROMISE',
  Death = 'DEATH',
  ExhaustionOfBenefits = 'EXHAUSTION_OF_BENEFITS',
  ReturnedToWorkForGroupPolicyholder = 'RETURNED_TO_WORK_FOR_GROUP_POLICYHOLDER',
  Settlement = 'SETTLEMENT'
}

export enum LifeWaiverDenialBeyondBenefitTerminationType {
  NoResponseToSupportOngoingProofOfDisability = 'NO_RESPONSE_TO_SUPPORT_ONGOING_PROOF_OF_DISABILITY',
  PolicyRequirementsNotSatisfied = 'POLICY_REQUIREMENTS_NOT_SATISFIED',
  ReturnToHealthNoReturnToWorkConfirmed = 'RETURN_TO_HEALTH_NO_RETURN_TO_WORK_CONFIRMED'
}

export type LifeWaiverEarnings = {
  __typename?: 'LifeWaiverEarnings';
  amount?: Maybe<Scalars['BigDecimal']['output']>;
  timePeriod?: Maybe<DisabilityEarningsPeriod>;
};

export enum LifeWaiverPlanType {
  FlatBenefit = 'FLAT_BENEFIT',
  MultipleOfPay = 'MULTIPLE_OF_PAY'
}

export type ListMemberPoliciesResponse = {
  __typename?: 'ListMemberPoliciesResponse';
  errorMessages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  invalidPolicies?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  policies: Array<Maybe<MemberPolicy>>;
};

export type LongTermDisabilityBenefitDetail = {
  __typename?: 'LongTermDisabilityBenefitDetail';
  activitiesOfDailyLiving?: Maybe<ActivitiesOfDailyLiving>;
  anyAndAllDate?: Maybe<AnyAndAllDateDetail>;
  benefitDuration?: Maybe<LtdBenefitDurationType>;
  benefitEndDate?: Maybe<Scalars['Date']['output']>;
  benefitEndDateHelperText: Scalars['String']['output'];
  benefitEndDateOverride?: Maybe<Scalars['Date']['output']>;
  benefitEndDateOverrideReason?: Maybe<Scalars['String']['output']>;
  benefitEndDateOverrideReasonType?: Maybe<Scalars['String']['output']>;
  benefitId: Scalars['String']['output'];
  benefitPeriods?: Maybe<Array<Maybe<BenefitPeriod>>>;
  benefitStartDate?: Maybe<Scalars['Date']['output']>;
  benefitStartDateHelperText: Scalars['String']['output'];
  benefitWithholdings?: Maybe<Array<Maybe<BenefitWithholding>>>;
  calculatedBenefitEndDate?: Maybe<Scalars['Date']['output']>;
  claimId: Scalars['String']['output'];
  conditionType?: Maybe<ConditionType>;
  coordinationOfBenefitsType?: Maybe<Scalars['String']['output']>;
  costOfLiving?: Maybe<CostOfLivingType>;
  costOfLivingDuration?: Maybe<CostOfLivingDurationType>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  earnings?: Maybe<DisabilityEarningsDetail>;
  eliminationPeriod?: Maybe<Scalars['Int']['output']>;
  eliminationPeriodMode?: Maybe<EliminationPeriodMode>;
  employerContributionPercentage?: Maybe<Scalars['Int']['output']>;
  enterpriseId?: Maybe<Scalars['String']['output']>;
  fica?: Maybe<FicaFinalPaymentDetail>;
  futurePayments: Array<Maybe<FuturePayment>>;
  hasBonusUp?: Maybe<Scalars['Boolean']['output']>;
  hasCoverage: Scalars['Boolean']['output'];
  hasFinalizedApproval: Scalars['Boolean']['output'];
  incurredDate?: Maybe<Scalars['Date']['output']>;
  limits?: Maybe<LongTermDisabilityLimitDetail>;
  ltdTerminationReason?: Maybe<LtdBenefitTerminationType>;
  lumpSumPayoutAmount?: Maybe<Scalars['BigDecimal']['output']>;
  medicareTaxExempt?: Maybe<Scalars['Boolean']['output']>;
  otherIncome?: Maybe<Array<Maybe<OtherIncome>>>;
  paymentPeriods?: Maybe<Array<Maybe<PaymentPeriod>>>;
  payments?: Maybe<Array<Maybe<Payment>>>;
  planType?: Maybe<Scalars['String']['output']>;
  premiumPaymentType: PremiumPaymentType;
  primaryBenefit?: Maybe<PrimaryBenefitDetail>;
  proofOfLossDate?: Maybe<Scalars['Date']['output']>;
  recurringDisability?: Maybe<Scalars['String']['output']>;
  recurringPaymentDetails?: Maybe<RecurringPaymentDetails>;
  requiresGainfulOccupation?: Maybe<Scalars['Boolean']['output']>;
  shouldPayDisabilityBenefitAsLumpSum?: Maybe<Scalars['Boolean']['output']>;
  socialSecurity: SocialSecurity;
  socialSecurityTaxExempt?: Maybe<Scalars['Boolean']['output']>;
  stdLtdIntegration?: Maybe<Scalars['Boolean']['output']>;
  survivorBenefit?: Maybe<SurvivorBenefitDetail>;
  terminationDate?: Maybe<Scalars['Date']['output']>;
  workIncentive?: Maybe<LongTermDisabilityWorkIncentiveType>;
};

export type LongTermDisabilityConfiguration = PolicyCoverageConfiguration & {
  __typename?: 'LongTermDisabilityConfiguration';
  accelerated?: Maybe<Scalars['Boolean']['output']>;
  accidentalDisability: Scalars['Boolean']['output'];
  activitiesOfDailyLiving?: Maybe<ActivitiesOfDailyLivingEnum>;
  amountType?: Maybe<AmountTypeEnum>;
  anyOccupationIncomeLoss?: Maybe<AnyOccupationIncomeLossEnum>;
  benefitDuration?: Maybe<BenefitDurationEnum>;
  benefitPercent?: Maybe<Scalars['Float']['output']>;
  contributory?: Maybe<Scalars['Boolean']['output']>;
  conversion: Scalars['Boolean']['output'];
  coordinationOfBenefits: CoordinationOfBenefitsEnum;
  costOfLiving: CostOfLivingEnum;
  costOfLivingDuration: CostOfLivingDurationEnum;
  definitionOfDisability: DefinitionOfDisabilityEnum;
  definitionOfEarnings?: Maybe<DefinitionOfEarningsEnum>;
  dependentEducation: Scalars['String']['output'];
  drugAlcohol: MentalNervousDrugAlcoholEnum;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  eliminationPeriod: Scalars['Int']['output'];
  eliminationPeriodMode: CadenceEnum;
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  employerContributoryPercentage?: Maybe<Scalars['Int']['output']>;
  fortyHourWorkWeek: Scalars['Boolean']['output'];
  fullCapacity: Scalars['Boolean']['output'];
  gainfulOccupation: Scalars['Boolean']['output'];
  incrementAmount?: Maybe<Scalars['Int']['output']>;
  indexing: Scalars['Boolean']['output'];
  individualDisabilityIncomeBenefit?: Maybe<Scalars['Int']['output']>;
  individualDisabilityIncomeOffset?: Maybe<IdiOffsetEnum>;
  individualDisabilityIncomeReverseCombo?: Maybe<Scalars['Boolean']['output']>;
  layoffLeaveOfAbsence: Scalars['Boolean']['output'];
  mandatoryRehabilitation: Scalars['Boolean']['output'];
  maximumBenefit?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  mentalNervous: MentalNervousDrugAlcoholEnum;
  minimumBenefit?: Maybe<Scalars['Int']['output']>;
  minimumBenefitPercent?: Maybe<Scalars['Int']['output']>;
  minimumWeeklyHours?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<ProductName>;
  nationalEconomy: Scalars['Boolean']['output'];
  newEntrantsOnly: Scalars['Boolean']['output'];
  nonMedicalMaximum?: Maybe<Scalars['Int']['output']>;
  nonOccupationalCoverage: Scalars['Boolean']['output'];
  openEnrollment: OpenEnrollmentType;
  ownOccupationPeriod?: Maybe<OccupationPeriodEnum>;
  partialCalculationMethod: PartialCalculationMethodEnum;
  paymentMethod: PaymentMethodEnum;
  percentOfExpensesReimbursed?: Maybe<Scalars['Int']['output']>;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  preExistingConditions?: Maybe<PreExistingConditionType>;
  productShortName: Scalars['String']['output'];
  prudentPerson: Scalars['Boolean']['output'];
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  reasonableAccommodation: ReasonableAccommodationEnum;
  rehabilitationIncentive: Scalars['Boolean']['output'];
  retirementPlanSupplement: RetirementPlanSupplementEnum;
  returnToWorkChildcare?: Maybe<ReturnToWorkChildcareEnum>;
  sabbatical: Scalars['Boolean']['output'];
  salaryContinuanceOffset: Scalars['Boolean']['output'];
  searchDate: Scalars['String']['output'];
  socialSecurityIntegration: SocialSecurityIntegrationEnum;
  specialConditions: SpecialConditionsEnum;
  spouseRehabilitation: SpouseRehabilitationEnum;
  statePlanIntegration: Scalars['Boolean']['output'];
  status: PolicyCoverageStatus;
  stdLtdIntegration: Scalars['Boolean']['output'];
  survivorBenefit: SurvivorBenefitEnum;
  takeOverCoverage: Scalars['Boolean']['output'];
  taxBenefit: TaxBenefitEnum;
  terminationDate?: Maybe<Scalars['String']['output']>;
  trsPrsCode?: Maybe<Scalars['String']['output']>;
  voluntary: Scalars['Boolean']['output'];
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
  workIncentive: WorkIncentiveEnum;
  workersCompensationParticipation: Scalars['Boolean']['output'];
};

export type LongTermDisabilityLimit = {
  __typename?: 'LongTermDisabilityLimit';
  benefitShouldContinue?: Maybe<Scalars['Boolean']['output']>;
  endDate: Scalars['Date']['output'];
  endDateChangeReason?: Maybe<LongTermDisabilityLimitEndDateChangeReasonType>;
  longTermDisabilityLimitId: Scalars['String']['output'];
  specialConditionType?: Maybe<LongTermDisabilitySpecialConditionType>;
  startDate: Scalars['Date']['output'];
  type: LongTermDisabilityLimitType;
};

export type LongTermDisabilityLimitDetail = {
  __typename?: 'LongTermDisabilityLimitDetail';
  drugsAlcohol?: Maybe<LongTermDisabilityLimitDurationType>;
  instances?: Maybe<Array<Maybe<LongTermDisabilityLimit>>>;
  mentalNervous?: Maybe<LongTermDisabilityLimitDurationType>;
  remainingNumberOfDays?: Maybe<Scalars['Int']['output']>;
  specialConditions?: Maybe<LongTermDisabilityLimitDurationType>;
};

export enum LongTermDisabilityLimitDurationType {
  ThirtySixMonths = 'THIRTY_SIX_MONTHS',
  TwelveMonths = 'TWELVE_MONTHS',
  TwentyFourMonths = 'TWENTY_FOUR_MONTHS'
}

export enum LongTermDisabilityLimitEndDateChangeReasonType {
  ConditionNoLongerDisabling = 'CONDITION_NO_LONGER_DISABLING',
  OtherDisablingConditionsExist = 'OTHER_DISABLING_CONDITIONS_EXIST'
}

export type LongTermDisabilityLimitInput = {
  benefitShouldContinue?: InputMaybe<Scalars['Boolean']['input']>;
  endDate: Scalars['Date']['input'];
  endDateChangeReason?: InputMaybe<Scalars['String']['input']>;
  longTermDisabilityLimitId?: InputMaybe<Scalars['String']['input']>;
  specialConditionType?: InputMaybe<LongTermDisabilitySpecialConditionType>;
  startDate: Scalars['Date']['input'];
  type: LongTermDisabilityLimitType;
};

export type LongTermDisabilityLimitRevision = {
  __typename?: 'LongTermDisabilityLimitRevision';
  description: Scalars['String']['output'];
  longTermDisabilityLimit?: Maybe<LongTermDisabilityLimit>;
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
};

export enum LongTermDisabilityLimitType {
  DrugsAlcohol = 'DRUGS_ALCOHOL',
  MentalNervous = 'MENTAL_NERVOUS',
  SpecialConditions = 'SPECIAL_CONDITIONS'
}

export type LongTermDisabilityPolicyCoverage = PolicyCoverage & {
  __typename?: 'LongTermDisabilityPolicyCoverage';
  actualParticipationPercentage?: Maybe<Scalars['Int']['output']>;
  /**
   * Amount Type describes the benefit amount.  Previously planType..
   * @deprecated use configuration.amountType
   */
  amountType?: Maybe<AmountTypeEnum>;
  benefitPlanYear?: Maybe<Scalars['String']['output']>;
  bookId?: Maybe<Scalars['String']['output']>;
  configuration: LongTermDisabilityConfiguration;
  /** @deprecated use configuration.contributory */
  contributoryType?: Maybe<ContributoryTypeEnum>;
  coverageHistory: Array<PolicyCoverageHistory>;
  /** @deprecated use configuration.definitionOfEarnings */
  definitionOfEarnings?: Maybe<DefinitionOfEarningsEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.employeeTerminationDateRule */
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  /** @deprecated use configuration.EmployerContributoryPercentage */
  employerContributoryPercentage?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use configuration.incrementAmount */
  incrementAmount?: Maybe<Scalars['Int']['output']>;
  isBonusUp?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated use configuration.maximumBenefit */
  maximumBenefit?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  /** @deprecated use configuration.minimumBenefit */
  minimumBenefit?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use configuration.minimumWeeklyHours */
  minimumWeeklyHours?: Maybe<Scalars['Int']['output']>;
  name: ProductName;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  priorCarrier?: Maybe<PriorCarrier>;
  productShortName: Scalars['String']['output'];
  /** @deprecated use configuration.rateGuaranteeEndDate */
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.rateGuaranteePeriod */
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  /** @deprecated use configuration.rateGuaranteeReason */
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  ratingPlanYear?: Maybe<Scalars['String']['output']>;
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.waitingPeriod */
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
};

export enum LongTermDisabilitySpecialConditionType {
  ChronicFatigueSyndrome = 'CHRONIC_FATIGUE_SYNDROME',
  EnvironmentalAllergies = 'ENVIRONMENTAL_ALLERGIES',
  Fibromyalgia = 'FIBROMYALGIA',
  Headaches = 'HEADACHES',
  MusculoskeletalAndConnectiveTissueDisorders = 'MUSCULOSKELETAL_AND_CONNECTIVE_TISSUE_DISORDERS',
  RepetitiveStrainInjury = 'REPETITIVE_STRAIN_INJURY',
  TemporomandibularJoint = 'TEMPOROMANDIBULAR_JOINT',
  ThoracicOutletSyndrome = 'THORACIC_OUTLET_SYNDROME'
}

export enum LongTermDisabilityWorkIncentiveType {
  None = 'NONE',
  SixMonths = 'SIX_MONTHS',
  ThreeMonths = 'THREE_MONTHS',
  TwelveMonths = 'TWELVE_MONTHS',
  TwentyFourMonths = 'TWENTY_FOUR_MONTHS'
}

export enum LtdBenefitDurationType {
  FiveYear = 'FIVE_YEAR',
  ReducingBenefitDuration = 'REDUCING_BENEFIT_DURATION',
  SixtyFiveFiveSeventy = 'SIXTY_FIVE_FIVE_SEVENTY',
  SocialSecurityNormalRetirementAge = 'SOCIAL_SECURITY_NORMAL_RETIREMENT_AGE',
  TwoYear = 'TWO_YEAR'
}

export enum LtdBenefitEndDateOverrideReasonType {
  CustomContract = 'CUSTOM_CONTRACT',
  PreX = 'PRE_X'
}

export type LtdBenefitTerminationInput = {
  terminationDate: Scalars['Date']['input'];
  terminationReason: LtdBenefitTerminationType;
};

export enum LtdBenefitTerminationType {
  Compromise = 'COMPROMISE',
  Death = 'DEATH',
  EndOfApprovedActivityPeriodNoReturnToWork = 'END_OF_APPROVED_ACTIVITY_PERIOD_NO_RETURN_TO_WORK',
  EndOfBenefitsPostpartum = 'END_OF_BENEFITS_POSTPARTUM',
  ExhaustionOfBenefits = 'EXHAUSTION_OF_BENEFITS',
  ExhaustionOfBenefitsMentalNervousDrugAlcoholSpecialConditionsLimit = 'EXHAUSTION_OF_BENEFITS_MENTAL_NERVOUS_DRUG_ALCOHOL_SPECIAL_CONDITIONS_LIMIT',
  LumpSum = 'LUMP_SUM',
  ReturnedToWorkForGroupPolicyholder = 'RETURNED_TO_WORK_FOR_GROUP_POLICYHOLDER',
  Settlement = 'SETTLEMENT'
}

export enum LtdDenialBeyondBenefitTerminationType {
  AnyAndAll = 'ANY_AND_ALL',
  NoResponseToSupportOngoingProofOfDisability = 'NO_RESPONSE_TO_SUPPORT_ONGOING_PROOF_OF_DISABILITY',
  PolicyRequirementsNotSatisfied = 'POLICY_REQUIREMENTS_NOT_SATISFIED',
  ReturnToWorkForNewEmployerWithNoLossOfIncome = 'RETURN_TO_WORK_FOR_NEW_EMPLOYER_WITH_NO_LOSS_OF_INCOME'
}

export type LtdEarnings = {
  __typename?: 'LtdEarnings';
  amount?: Maybe<Scalars['BigDecimal']['output']>;
  timePeriod?: Maybe<DisabilityEarningsPeriod>;
};

export enum MaternityEnum {
  ComplicationsOnly = 'COMPLICATIONS_ONLY',
  FullMaternity = 'FULL_MATERNITY',
  FullMaternityAfterTwelveMonths = 'FULL_MATERNITY_AFTER_TWELVE_MONTHS',
  TwelveWeekMaternity = 'TWELVE_WEEK_MATERNITY'
}

export enum MaxPaymentType {
  PayNGreatest = 'PAY_N_GREATEST',
  PayPercentOfMaximum = 'PAY_PERCENT_OF_MAXIMUM',
  PayUpToLimit = 'PAY_UP_TO_LIMIT'
}

export type Medical = {
  __typename?: 'Medical';
  competent?: Maybe<Scalars['Boolean']['output']>;
  conditionType?: Maybe<ConditionType>;
  expectedMedicalRecoveryDate?: Maybe<Scalars['Date']['output']>;
  firstHospitalizationStartDate?: Maybe<Scalars['Date']['output']>;
  medicalDiagnoses?: Maybe<Array<Maybe<MedicalDiagnosis>>>;
  medicalId: Scalars['String']['output'];
  medicalMedications?: Maybe<Array<Maybe<MedicalMedications>>>;
  medicalRecords?: Maybe<Array<Maybe<MedicalRecord>>>;
  medicalRecordsRequests?: Maybe<Array<Maybe<MedicalRecordsRequest>>>;
  pregnancyDeliveryDate?: Maybe<Scalars['Date']['output']>;
  screeningType?: Maybe<ScreeningType>;
  workRelated?: Maybe<Scalars['Boolean']['output']>;
};

export type MedicalDiagnosis = {
  __typename?: 'MedicalDiagnosis';
  diagnosis: Diagnosis;
  diagnosisType: DiagnosisType;
  medicalDiagnosisId: Scalars['String']['output'];
  preExistingCondition?: Maybe<Scalars['Boolean']['output']>;
};

export type MedicalMedications = {
  __typename?: 'MedicalMedications';
  medicalMedicationId: Scalars['String']['output'];
  medication: Medication;
  prescribedBy: PartyAssociate;
};

export type MedicalRecord = {
  __typename?: 'MedicalRecord';
  document: Document;
  healthcareProvider: PartyAssociate;
  medicalRecordId: Scalars['String']['output'];
  receivedDate: Scalars['Date']['output'];
  relevantToPreExistingCondition: Scalars['Boolean']['output'];
  summarization: Scalars['String']['output'];
};

export type MedicalRecordInput = {
  documentId: Scalars['String']['input'];
  partyAssociateId: Scalars['String']['input'];
  receivedDate: Scalars['Date']['input'];
  relevantToDisablingCondition: Scalars['Boolean']['input'];
  summarization: Scalars['String']['input'];
};

export type MedicalRecordsRequest = {
  __typename?: 'MedicalRecordsRequest';
  currentStatus: MedicalRecordsRequestStatus;
  endDate: Scalars['Date']['output'];
  medicalRecordsRequestId: Scalars['String']['output'];
  partyAssociate: PartyAssociate;
  startDate: Scalars['Date']['output'];
  statuses: Array<Maybe<MedicalRecordsRequestStatus>>;
};

export type MedicalRecordsRequestInput = {
  endDate: Scalars['Date']['input'];
  partyAssociateIds: Array<InputMaybe<Scalars['String']['input']>>;
  startDate: Scalars['Date']['input'];
};

export type MedicalRecordsRequestStatus = {
  __typename?: 'MedicalRecordsRequestStatus';
  effectiveDate: Scalars['DateTime']['output'];
  medicalRecordsRequestStatusId: Scalars['String']['output'];
  status: MedicalRecordsRequestStatusType;
};

export enum MedicalRecordsRequestStatusType {
  Completed = 'COMPLETED',
  Requested = 'REQUESTED'
}

export type MedicalTravelProvision = {
  __typename?: 'MedicalTravelProvision';
  amount?: Maybe<Scalars['Int']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
  milesFromHome?: Maybe<Scalars['Int']['output']>;
  roundTripsPerYear?: Maybe<Scalars['Int']['output']>;
};

export type Medication = {
  __typename?: 'Medication';
  medicationId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rxcui: Scalars['String']['output'];
};

export type Member = {
  __typename?: 'Member';
  benefit?: Maybe<MemberOrDependentBenefit>;
  birthDate: Scalars['DateTime']['output'];
  dependents?: Maybe<Array<Maybe<Dependent>>>;
  employment: Employment;
  firstName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  policyNumber?: Maybe<Scalars['String']['output']>;
  privacyId?: Maybe<Scalars['String']['output']>;
  sourceSystem?: Maybe<AdminSourceSystem>;
};

export type MemberCoverage = {
  __typename?: 'MemberCoverage';
  coverageName: Scalars['String']['output'];
  effectiveDate: Scalars['Date']['output'];
  status: Scalars['String']['output'];
  terminationDate?: Maybe<Scalars['Date']['output']>;
};

export type MemberGroupCoverages = {
  __typename?: 'MemberGroupCoverages';
  accident?: Maybe<AccidentPolicyCoverage>;
  criticalIllness?: Maybe<CriticalIllnessPolicyCoverage>;
  dental?: Maybe<DentalPolicyCoverage>;
  groupTermLife?: Maybe<GroupTermLifePolicyCoverage>;
  /** @deprecated use hospitalIndemnityChoice */
  hospitalIndemnity?: Maybe<HospitalIndemnityPolicyCoverage>;
  hospitalIndemnityChoice?: Maybe<HospitalIndemnityChoice>;
  longTermDisability?: Maybe<LongTermDisabilityPolicyCoverage>;
  paidFamilyLeave?: Maybe<PaidFamilyLeavePolicyCoverage>;
  paidMedicalLeave?: Maybe<PaidMedicalLeavePolicyCoverage>;
  shortTermDisability?: Maybe<ShortTermDisabilityPolicyCoverage>;
  vision?: Maybe<VisionPolicyCoverage>;
  voluntaryTermLife?: Maybe<VoluntaryTermLifePolicyCoverage>;
};

export enum MemberGroupStatus {
  ClosedToNewMembers = 'CLOSED_TO_NEW_MEMBERS',
  OpenToNewMembers = 'OPEN_TO_NEW_MEMBERS'
}

export type MemberGroupType = {
  __typename?: 'MemberGroupType';
  coverages?: Maybe<MemberGroupCoverages>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  policyId: Scalars['String']['output'];
  searchDate: Scalars['String']['output'];
  status: MemberGroupStatus;
};


export type MemberGroupTypeCoveragesArgs = {
  filter?: InputMaybe<PolicyCoveragesFilterInput>;
};

export type MemberOrDependentBenefit = {
  __typename?: 'MemberOrDependentBenefit';
  accidentalDeathAndDismembermentApprovedAmount?: Maybe<Scalars['Float']['output']>;
  accidentalDeathAndDismembermentPendingAmount?: Maybe<Scalars['Float']['output']>;
  approvedAmount?: Maybe<Scalars['Float']['output']>;
  contractState?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['DateTime']['output']>;
  employeeAssistanceProgramProvision?: Maybe<EmployeeAssistanceProgramProvision>;
  evidenceOfInsurabilityStatus?: Maybe<CompassEvidenceOfInsurabilityStatus>;
  evidenceOfInsurabilityUrl?: Maybe<Scalars['String']['output']>;
  pendingAmount?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<MemberOrDependentBenefitStatus>;
  terminationDate?: Maybe<Scalars['DateTime']['output']>;
};

export enum MemberOrDependentBenefitStatus {
  Active = 'ACTIVE',
  ActiveBenefitLocked = 'ACTIVE_BENEFIT_LOCKED',
  Terminated = 'TERMINATED',
  Unknown = 'UNKNOWN'
}

export type MemberPolicy = {
  __typename?: 'MemberPolicy';
  coverages?: Maybe<Array<Maybe<MemberCoverage>>>;
  employerName?: Maybe<Scalars['String']['output']>;
  memberStatus?: Maybe<Scalars['String']['output']>;
  policyNumber: Scalars['String']['output'];
};

export enum MentalNervousDrugAlcoholEnum {
  SameAsAnyOther = 'SAME_AS_ANY_OTHER',
  ThirtySixMonths = 'THIRTY_SIX_MONTHS',
  TwelveMonths = 'TWELVE_MONTHS',
  TwentyFourMonths = 'TWENTY_FOUR_MONTHS'
}

export type MonthlyRecurringPayment = {
  __typename?: 'MonthlyRecurringPayment';
  dayOfMonth: Scalars['Int']['output'];
  nextPaymentDate?: Maybe<Scalars['Date']['output']>;
  recurringPaymentId: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createCheckPaymentPreference?: Maybe<PaymentPreference>;
  createEftPaymentPreference?: Maybe<PaymentPreference>;
  updatePolicy: PolicyUpdateResponse;
  updatePolicyCoverageAccident: PolicyCoverageUpdateResponse;
  updatePolicyCoverageCriticalIllness: PolicyCoverageUpdateResponse;
  updatePolicyCoverageHospitalIndemnity: PolicyCoverageUpdateResponse;
  uploadClaimDocument?: Maybe<Document>;
};


export type MutationCreateCheckPaymentPreferenceArgs = {
  createCheckPaymentPreference: CreateCheckPaymentPreferenceInput;
};


export type MutationCreateEftPaymentPreferenceArgs = {
  createEftPaymentPreference: CreateEftPaymentPreferenceInput;
};


export type MutationUpdatePolicyArgs = {
  policyUpdateInput: PolicyUpdateInput;
};


export type MutationUpdatePolicyCoverageAccidentArgs = {
  accidentInput: AccidentInput;
};


export type MutationUpdatePolicyCoverageCriticalIllnessArgs = {
  criticalIllnessInput: CriticalIllnessInput;
};


export type MutationUpdatePolicyCoverageHospitalIndemnityArgs = {
  hospitalIndemnityInput: HospitalIndemnityInput;
};


export type MutationUploadClaimDocumentArgs = {
  file: Scalars['Upload']['input'];
  uploadDocumentInput: UploadDocumentInput;
};

export enum NetworkType {
  Scheduled = 'SCHEDULED',
  Unscheduled = 'UNSCHEDULED'
}

export type Note = Auditable & {
  __typename?: 'Note';
  claimId: Scalars['String']['output'];
  completed: Scalars['Boolean']['output'];
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  direction?: Maybe<DirectionType>;
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  noteId: Scalars['String']['output'];
  noteType: NoteType;
  value: Scalars['String']['output'];
};

export enum NoteType {
  Claim = 'CLAIM',
  Decision = 'DECISION',
  PhoneCall = 'PHONE_CALL',
  Review = 'REVIEW',
  Voicemail = 'VOICEMAIL'
}

export type Occupation = {
  __typename?: 'Occupation';
  dateLastWorked?: Maybe<Scalars['Date']['output']>;
  hasCeasedWorking?: Maybe<Scalars['Boolean']['output']>;
  hoursWorkedPerWeek?: Maybe<Scalars['Float']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  ltdOccupationalStrength?: Maybe<JobOccupationalStrengthType>;
  occupationId: Scalars['String']['output'];
  stdJobStrength?: Maybe<JobOccupationalStrengthType>;
  workedInState?: Maybe<Scalars['String']['output']>;
};

export type OccupationInput = {
  dateLastWorked?: InputMaybe<Scalars['Date']['input']>;
  hasCeasedWorking?: InputMaybe<Scalars['Boolean']['input']>;
  hoursWorkedPerWeek?: InputMaybe<Scalars['BigDecimal']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  ltdOccupationalStrength?: InputMaybe<JobOccupationalStrengthType>;
  occupationId: Scalars['String']['input'];
  stdJobStrength?: InputMaybe<JobOccupationalStrengthType>;
  workedInState?: InputMaybe<Scalars['String']['input']>;
};

export enum OccupationPeriodEnum {
  EndOfBenefitDuration = 'END_OF_BENEFIT_DURATION',
  FiveYear = 'FIVE_YEAR',
  None = 'NONE',
  OneYear = 'ONE_YEAR',
  TenYear = 'TEN_YEAR',
  ThreeYear = 'THREE_YEAR',
  TwoYear = 'TWO_YEAR'
}

export enum OpenEnrollmentEnum {
  Annual = 'ANNUAL',
  None = 'NONE',
  PeriodicBenefitIncrease = 'PERIODIC_BENEFIT_INCREASE'
}

export type OpenEnrollmentType = {
  __typename?: 'OpenEnrollmentType';
  effectiveDate?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  type: OpenEnrollmentEnum;
};

export type Organization = {
  __typename?: 'Organization';
  addresses: Array<Address>;
  /** @deprecated This data is not populated correctly in compass. Do not use. */
  dba?: Maybe<Scalars['String']['output']>;
  emailAddresses: Array<EmailAddressType>;
  id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  searchDate: Scalars['String']['output'];
  taxId?: Maybe<Scalars['String']['output']>;
};

export type OrganizationUpdateInput = {
  taxId?: InputMaybe<Scalars['String']['input']>;
};

export enum OrthoTypeEnum {
  Adult = 'ADULT',
  AdultAndChild = 'ADULT_AND_CHILD',
  Child = 'CHILD',
  None = 'NONE'
}

export type OtherIncome = {
  __typename?: 'OtherIncome';
  amount: Scalars['BigDecimal']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  frequency?: Maybe<OtherIncomeFrequencyType>;
  offsetType: OtherIncomeOffsetType;
  otherIncomeId: Scalars['String']['output'];
  paymentType: OtherIncomePaymentType;
  startDate: Scalars['Date']['output'];
  type: OtherIncomeType;
};

export enum OtherIncomeFrequencyType {
  Annually = 'ANNUALLY',
  BiWeekly = 'BI_WEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  SemiMonthly = 'SEMI_MONTHLY',
  Weekly = 'WEEKLY'
}

export type OtherIncomeInput = {
  amount: Scalars['BigDecimal']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  frequency?: InputMaybe<OtherIncomeFrequencyType>;
  offsetType: OtherIncomeOffsetType;
  otherIncomeId?: InputMaybe<Scalars['String']['input']>;
  paymentType: OtherIncomePaymentType;
  startDate: Scalars['Date']['input'];
  type: OtherIncomeType;
};

export enum OtherIncomeOffsetType {
  Direct = 'DIRECT',
  Indirect = 'INDIRECT'
}

export enum OtherIncomePaymentType {
  LumpSum = 'LUMP_SUM',
  Recurring = 'RECURRING'
}

export enum OtherIncomeType {
  EmployeePaidIndividualDisability = 'EMPLOYEE_PAID_INDIVIDUAL_DISABILITY',
  IndividualDisability = 'INDIVIDUAL_DISABILITY',
  LossOfTimeFromWork = 'LOSS_OF_TIME_FROM_WORK',
  NoFaultBenefits = 'NO_FAULT_BENEFITS',
  Other = 'OTHER',
  OtherGovernmentProgramDisability = 'OTHER_GOVERNMENT_PROGRAM_DISABILITY',
  OtherGovernmentProgramRetirement = 'OTHER_GOVERNMENT_PROGRAM_RETIREMENT',
  OtherGroupDisability = 'OTHER_GROUP_DISABILITY',
  PaidFamilyMedicalLeave = 'PAID_FAMILY_MEDICAL_LEAVE',
  PensionRetirement = 'PENSION_RETIREMENT',
  PersonalTimeOff = 'PERSONAL_TIME_OFF',
  RailroadRetirement = 'RAILROAD_RETIREMENT',
  Rehabilitation = 'REHABILITATION',
  RenewalCommissions = 'RENEWAL_COMMISSIONS',
  SalaryContinuance = 'SALARY_CONTINUANCE',
  SettlementClaimJudicial = 'SETTLEMENT_CLAIM_JUDICIAL',
  SeverancePay = 'SEVERANCE_PAY',
  SickPay = 'SICK_PAY',
  SocialSecurityDisabilityDependent = 'SOCIAL_SECURITY_DISABILITY_DEPENDENT',
  SocialSecurityDisabilityEstimateDependent = 'SOCIAL_SECURITY_DISABILITY_ESTIMATE_DEPENDENT',
  SocialSecurityDisabilityEstimatePrimary = 'SOCIAL_SECURITY_DISABILITY_ESTIMATE_PRIMARY',
  SocialSecurityDisabilityPrimary = 'SOCIAL_SECURITY_DISABILITY_PRIMARY',
  SocialSecurityEstimateRetirement = 'SOCIAL_SECURITY_ESTIMATE_RETIREMENT',
  SocialSecurityRetirement = 'SOCIAL_SECURITY_RETIREMENT',
  StateDisabilityBenefitsCa = 'STATE_DISABILITY_BENEFITS_CA',
  StateDisabilityBenefitsHi = 'STATE_DISABILITY_BENEFITS_HI',
  StateDisabilityBenefitsNj = 'STATE_DISABILITY_BENEFITS_NJ',
  StateDisabilityBenefitsNy = 'STATE_DISABILITY_BENEFITS_NY',
  StateDisabilityBenefitsPr = 'STATE_DISABILITY_BENEFITS_PR',
  StateDisabilityBenefitsRi = 'STATE_DISABILITY_BENEFITS_RI',
  VacationPay = 'VACATION_PAY',
  WorkersCompensationJonesAct = 'WORKERS_COMPENSATION_JONES_ACT'
}

export type Overpayment = Auditable & {
  __typename?: 'Overpayment';
  amount: Scalars['BigDecimal']['output'];
  benefitId?: Maybe<Scalars['String']['output']>;
  checkNumber?: Maybe<Scalars['String']['output']>;
  completedReason?: Maybe<OverpaymentCompletedReasonType>;
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  currentBalance?: Maybe<Scalars['BigDecimal']['output']>;
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  overpaymentId: Scalars['String']['output'];
  payments: Array<Maybe<Payment>>;
  reason?: Maybe<OverpaymentReasonType>;
  recoveries?: Maybe<Array<Maybe<OverpaymentRecovery>>>;
  status?: Maybe<OverpaymentStatusType>;
  taxYears?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export enum OverpaymentCompletedReasonType {
  NotYetCompleted = 'NOT_YET_COMPLETED',
  PartiallyRecoveredWriteOffRemaining = 'PARTIALLY_RECOVERED_WRITE_OFF_REMAINING',
  RecoveryReceivedInFull = 'RECOVERY_RECEIVED_IN_FULL',
  WriteOffEntireOverpayment = 'WRITE_OFF_ENTIRE_OVERPAYMENT'
}

export enum OverpaymentReasonType {
  BenefitEligibility = 'BENEFIT_ELIGIBILITY',
  BenefitOffsetError = 'BENEFIT_OFFSET_ERROR',
  ClaimantNotCovered = 'CLAIMANT_NOT_COVERED',
  DuplicatePaymentSystemError = 'DUPLICATE_PAYMENT_SYSTEM_ERROR',
  EarningsBenefitCalculationError = 'EARNINGS_BENEFIT_CALCULATION_ERROR',
  Other = 'OTHER',
  PaidBeyondReturnToWork = 'PAID_BEYOND_RETURN_TO_WORK',
  SocialSecurityDisabilityInsurance = 'SOCIAL_SECURITY_DISABILITY_INSURANCE',
  TaxCalculationError = 'TAX_CALCULATION_ERROR',
  WorkersCompensation = 'WORKERS_COMPENSATION'
}

export type OverpaymentRecovery = {
  __typename?: 'OverpaymentRecovery';
  amount: Scalars['Float']['output'];
  overpaymentId: Scalars['String']['output'];
  recoveryDate: Scalars['Date']['output'];
  recoveryId: Scalars['String']['output'];
  transactionNumber?: Maybe<Scalars['String']['output']>;
};

export enum OverpaymentStatusType {
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export type OverrideBenefitDecisionInput = {
  checkPaymentDeliveryMethod?: InputMaybe<CheckPaymentDeliveryMethod>;
  decisionType: DecisionType;
  denialReason?: InputMaybe<DenialReasonType>;
  note: Scalars['String']['input'];
  overrideType?: InputMaybe<OverrideType>;
  paymentPreferenceId?: InputMaybe<Scalars['String']['input']>;
};

export type OverrideFicaFinalPaymentInput = {
  ficaFinalPayment: Scalars['Date']['input'];
  reason: Scalars['String']['input'];
};

export type OverrideLtdBenefitEndDateInput = {
  endDate: Scalars['Date']['input'];
  reason: Scalars['String']['input'];
  reasonType: LtdBenefitEndDateOverrideReasonType;
};

export type OverrideSecondaryBenefitDecisionInput = {
  decisionType: DecisionType;
  denialReason?: InputMaybe<DenialReasonType>;
  effectiveDate: Scalars['Date']['input'];
  note: Scalars['String']['input'];
  overrideType?: InputMaybe<OverrideType>;
  secondaryDecisionType: SecondaryBenefitDecisionType;
};

export type OverrideStdBenefitEndDateInput = {
  endDate: Scalars['Date']['input'];
  reason: Scalars['String']['input'];
  reasonType: StdBenefitEndDateOverrideReasonType;
};

export enum OverrideType {
  AdditionalInformationReceived = 'ADDITIONAL_INFORMATION_RECEIVED',
  Compromise = 'COMPROMISE',
  Litigation = 'LITIGATION',
  MandatoryAppeal = 'MANDATORY_APPEAL'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaidFamilyLeaveBenefitSchedule = {
  __typename?: 'PaidFamilyLeaveBenefitSchedule';
  groups: Array<Maybe<PaidFamilyLeaveBenefitScheduleGroup>>;
};

export type PaidFamilyLeaveBenefitScheduleGroup = {
  __typename?: 'PaidFamilyLeaveBenefitScheduleGroup';
  benefitsPayableDescription: Scalars['String']['output'];
  sharedTimeGroups: Array<Maybe<PaidFamilyLeaveBenefitScheduleGroup>>;
  timeBasis: BenefitDurationBasis;
  timeEntitlement: Scalars['Float']['output'];
  timeWithinPeriod: Scalars['Float']['output'];
  timeWithinPeriodBasis: BenefitDurationBasis;
  type: Scalars['String']['output'];
};

export type PaidFamilyLeaveConfiguration = PolicyCoverageConfiguration & {
  __typename?: 'PaidFamilyLeaveConfiguration';
  benefitSchedule: PaidFamilyLeaveBenefitSchedule;
  bookId: Scalars['String']['output'];
  effectiveDate?: Maybe<Scalars['String']['output']>;
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  memberGroupId: Scalars['String']['output'];
  minimumWeeklyHours?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<ProductName>;
  openEnrollment: OpenEnrollmentType;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  rateScaleStates: Array<Maybe<StateAbbreviation>>;
  searchDate: Scalars['String']['output'];
  terminationDate?: Maybe<Scalars['String']['output']>;
  voluntary: Scalars['Boolean']['output'];
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
};

export type PaidFamilyLeavePolicyCoverage = PolicyCoverage & {
  __typename?: 'PaidFamilyLeavePolicyCoverage';
  benefitPlanYear?: Maybe<Scalars['String']['output']>;
  bookId?: Maybe<Scalars['String']['output']>;
  configuration: PaidFamilyLeaveConfiguration;
  coverageHistory: Array<PolicyCoverageHistory>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.employeeTerminationDateRule */
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  memberGroupId: Scalars['String']['output'];
  /** @deprecated use configuration.minimumWeeklyHours */
  minimumWeeklyHours?: Maybe<Scalars['Int']['output']>;
  name: ProductName;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  /** @deprecated use configuration.rateGuaranteeEndDate */
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.rateGuaranteePeriod */
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  /** @deprecated use configuration.rateGuaranteeReason */
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  searchDate: Scalars['String']['output'];
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.waitingPeriod */
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
};

export type PaidMedicalLeaveBenefitSchedule = {
  __typename?: 'PaidMedicalLeaveBenefitSchedule';
  groups: Array<Maybe<PaidMedicalLeaveBenefitScheduleGroup>>;
};

export type PaidMedicalLeaveBenefitScheduleGroup = {
  __typename?: 'PaidMedicalLeaveBenefitScheduleGroup';
  benefitsPayableDescription: Scalars['String']['output'];
  sharedTimeGroups: Array<Maybe<PaidMedicalLeaveBenefitScheduleGroup>>;
  timeBasis: BenefitDurationBasis;
  timeEntitlement: Scalars['Float']['output'];
  timeWithinPeriod: Scalars['Float']['output'];
  timeWithinPeriodBasis: BenefitDurationBasis;
  type: Scalars['String']['output'];
};

export type PaidMedicalLeaveConfiguration = PolicyCoverageConfiguration & {
  __typename?: 'PaidMedicalLeaveConfiguration';
  benefitSchedule: PaidMedicalLeaveBenefitSchedule;
  bookId: Scalars['String']['output'];
  effectiveDate?: Maybe<Scalars['String']['output']>;
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  memberGroupId: Scalars['String']['output'];
  minimumWeeklyHours?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<ProductName>;
  openEnrollment: OpenEnrollmentType;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  rateScaleStates: Array<Maybe<StateAbbreviation>>;
  searchDate: Scalars['String']['output'];
  terminationDate?: Maybe<Scalars['String']['output']>;
  voluntary: Scalars['Boolean']['output'];
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
};

export type PaidMedicalLeavePolicyCoverage = PolicyCoverage & {
  __typename?: 'PaidMedicalLeavePolicyCoverage';
  benefitPlanYear?: Maybe<Scalars['String']['output']>;
  bookId?: Maybe<Scalars['String']['output']>;
  configuration: PaidMedicalLeaveConfiguration;
  coverageHistory: Array<PolicyCoverageHistory>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.employeeTerminationDateRule */
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  memberGroupId: Scalars['String']['output'];
  /** @deprecated use configuration.minimumWeeklyHours */
  minimumWeeklyHours?: Maybe<Scalars['Int']['output']>;
  name: ProductName;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  /** @deprecated use configuration.rateGuaranteeEndDate */
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.rateGuaranteePeriod */
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  /** @deprecated use configuration.rateGuaranteeReason */
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  searchDate: Scalars['String']['output'];
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.waitingPeriod */
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
};

export enum PartialCalculationMethodEnum {
  IncomeLoss = 'INCOME_LOSS',
  None = 'NONE',
  PercentOfEarnings = 'PERCENT_OF_EARNINGS'
}

export type Party = Claimant | UnknownParty;

export type PartyAssociate = {
  __typename?: 'PartyAssociate';
  associate: Associate;
  partyAssociateId: Scalars['String']['output'];
  type: PartyAssociateType;
};

export type PartyAssociateHipaa = {
  __typename?: 'PartyAssociateHipaa';
  partyAssociate: PartyAssociate;
};

export enum PartyAssociateType {
  Attorney = 'ATTORNEY',
  Child = 'CHILD',
  HealthcareProvider = 'HEALTHCARE_PROVIDER',
  Other = 'OTHER',
  Parent = 'PARENT',
  Payee = 'PAYEE',
  Spouse = 'SPOUSE',
  Trust = 'TRUST'
}

export enum PayFrequencyType {
  Annually = 'ANNUALLY',
  BiWeekly = 'BI_WEEKLY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  SemiMonthly = 'SEMI_MONTHLY',
  Weekly = 'WEEKLY'
}

export type Payment = {
  __typename?: 'Payment';
  benefitId?: Maybe<Scalars['String']['output']>;
  benefitType: Scalars['String']['output'];
  cancellation?: Maybe<PaymentCancellation>;
  checkNumber?: Maybe<Scalars['String']['output']>;
  checkPaymentDeliveryMethod?: Maybe<CheckPaymentDeliveryMethod>;
  createdBy?: Maybe<Scalars['String']['output']>;
  currentStatus: PaymentStatus;
  distributionType?: Maybe<DistributionType>;
  edfEmployeeEobDocumentInstanceId?: Maybe<Scalars['String']['output']>;
  federalIncomeTaxAmount: Scalars['BigDecimal']['output'];
  itemizations?: Maybe<Array<Maybe<PaymentItem>>>;
  medicareSurtaxAmount: Scalars['BigDecimal']['output'];
  medicareTaxAmount: Scalars['BigDecimal']['output'];
  method: PaymentMethod;
  netAmount: Scalars['BigDecimal']['output'];
  originalPayment?: Maybe<Payment>;
  originalPaymentId?: Maybe<Scalars['String']['output']>;
  overpayment?: Maybe<Overpayment>;
  paidToPartyAssociateId?: Maybe<Scalars['String']['output']>;
  paymentId: Scalars['String']['output'];
  percentTaxable?: Maybe<Scalars['Int']['output']>;
  provisionInstances?: Maybe<Array<Maybe<ProvisionInstance>>>;
  reissue?: Maybe<Payment>;
  socialSecurityTaxAmount: Scalars['BigDecimal']['output'];
  stateIncomeTaxAmount: Scalars['BigDecimal']['output'];
  stateTransitTaxAmount: Scalars['BigDecimal']['output'];
  stateUnemploymentTaxAmount: Scalars['BigDecimal']['output'];
  statuses: Array<Maybe<PaymentStatus>>;
  taxState: Scalars['String']['output'];
  transactionNumber?: Maybe<Scalars['String']['output']>;
  type: PaymentType;
};

export type PaymentCancellation = {
  __typename?: 'PaymentCancellation';
  cancelId: Scalars['String']['output'];
  reason: PaymentCancellationReason;
};

export enum PaymentCancellationReason {
  Death = 'DEATH',
  IncorrectBankInformation = 'INCORRECT_BANK_INFORMATION',
  IncorrectInformation = 'INCORRECT_INFORMATION',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentIssuedInError = 'PAYMENT_ISSUED_IN_ERROR',
  PaymentNotReceived = 'PAYMENT_NOT_RECEIVED'
}

export type PaymentItem = Auditable & {
  __typename?: 'PaymentItem';
  benefitPeriod?: Maybe<BenefitPeriod>;
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  name?: Maybe<Scalars['String']['output']>;
  paymentAmount?: Maybe<Scalars['BigDecimal']['output']>;
};

export enum PaymentMethod {
  Ach = 'ACH',
  Check = 'CHECK',
  Eft = 'EFT',
  Wire = 'WIRE'
}

export enum PaymentMethodEnum {
  FlexiblePremiumDeferredAnnuity = 'FLEXIBLE_PREMIUM_DEFERRED_ANNUITY',
  /** 401(k) retirement account */
  FourZeroOneK = 'FOUR_ZERO_ONE_K',
  /** TIAA / 403(b) */
  FourZeroThreeB = 'FOUR_ZERO_THREE_B',
  None = 'NONE'
}

export type PaymentPeriod = {
  __typename?: 'PaymentPeriod';
  endDate: Scalars['Date']['output'];
  paymentPeriodId: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
  wasWorking: Scalars['Boolean']['output'];
};

export enum PaymentPeriodEnum {
  CalendarDays = 'CALENDAR_DAYS',
  WorkingDays = 'WORKING_DAYS'
}

export type PaymentPeriodInput = {
  endDate: Scalars['Date']['input'];
  paymentPeriodId?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['Date']['input'];
  wasWorking: Scalars['Boolean']['input'];
};

export enum PaymentPeriodType {
  CalendarDays = 'CALENDAR_DAYS',
  WorkingDays = 'WORKING_DAYS'
}

export type PaymentPreference = AchPaymentPreference | CheckPaymentPreference | EftPaymentPreference | WirePaymentPreference;

export type PaymentPreferenceInput = {
  achPaymentPreference?: InputMaybe<AchPaymentPreferenceInput>;
  checkPaymentPreference?: InputMaybe<CreateCheckPaymentPreferenceInput>;
  eftPaymentPreference?: InputMaybe<CreateEftPaymentPreferenceInput>;
  method: PaymentMethod;
  wirePaymentPreference?: InputMaybe<WirePaymentPreferenceInput>;
};

export type PaymentPreferenceVerificationResponse = {
  __typename?: 'PaymentPreferenceVerificationResponse';
  description: Scalars['String']['output'];
  valid: Scalars['Boolean']['output'];
};

export type PaymentStatus = {
  __typename?: 'PaymentStatus';
  additionalInformation?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  effectiveDate: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedDate?: Maybe<Scalars['DateTime']['output']>;
  type: PaymentStatusType;
};

export enum PaymentStatusType {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Ledger = 'LEDGER',
  Pending = 'PENDING',
  Requested = 'REQUESTED'
}

export enum PaymentType {
  ChildSupport = 'CHILD_SUPPORT',
  Garnishment = 'GARNISHMENT',
  Guardianship = 'GUARDIANSHIP',
  MailedToAttorneyPaidToAttorney = 'MAILED_TO_ATTORNEY_PAID_TO_ATTORNEY',
  MailedToAttorneyPaidToBoth = 'MAILED_TO_ATTORNEY_PAID_TO_BOTH',
  MailedToAttorneyPaidToClaimant = 'MAILED_TO_ATTORNEY_PAID_TO_CLAIMANT',
  Regular = 'REGULAR',
  RemainingBenefit = 'REMAINING_BENEFIT',
  SurvivorBenefit = 'SURVIVOR_BENEFIT'
}

export type PendingUser = {
  __typename?: 'PendingUser';
  displayName: Scalars['String']['output'];
  emailAddress: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type Person = {
  __typename?: 'Person';
  emailAddresses: Array<EmailAddressType>;
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  phoneNumbers: Array<PhoneNumberType>;
  searchDate: Scalars['String']['output'];
};

export type PersonFilter = {
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type PhoneNumberType = {
  __typename?: 'PhoneNumberType';
  effectiveDate: Scalars['String']['output'];
  extension?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  type: PhoneType;
};

export enum PhoneType {
  BusinessPhone = 'BUSINESS_PHONE',
  DirectExtension = 'DIRECT_EXTENSION',
  Fax = 'FAX',
  Home = 'HOME',
  TeamExtension = 'TEAM_EXTENSION'
}

export type PhysicianVisitProvision = {
  __typename?: 'PhysicianVisitProvision';
  /**
   * Derived from physicianVisit.daysPerPersonPerYear
   * Possible values: 6, 9, 12, 15, 18, 21, 24, 27, 30
   */
  daysPerFamilyPerYear?: Maybe<Scalars['Int']['output']>;
  daysPerPersonPerYear?: Maybe<Scalars['Int']['output']>;
  hospitalizationRequired?: Maybe<Scalars['Boolean']['output']>;
  inOfficeAmount?: Maybe<Scalars['Int']['output']>;
  inOfficeIncluded?: Maybe<Scalars['Boolean']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
  telemedicineAmount?: Maybe<Scalars['Int']['output']>;
  telemedicineIncluded?: Maybe<Scalars['Boolean']['output']>;
};

export type Policy = {
  __typename?: 'Policy';
  administrationType: AdministrationType;
  /** CompassUi: File.Open.Scheme.Entities.Associate Agents and Payees */
  agents: Array<AssociatedAgent>;
  amendmentInProgress: Scalars['Boolean']['output'];
  anniversaryDate?: Maybe<Scalars['String']['output']>;
  billGroups: Array<BillGroup>;
  cobraBillRecipient?: Maybe<CobraBillRecipient>;
  contractState?: Maybe<Scalars['String']['output']>;
  dependents?: Maybe<Array<Maybe<PolicyDependents>>>;
  effectiveDate: Scalars['String']['output'];
  employerName?: Maybe<Scalars['String']['output']>;
  employers: Array<Employer>;
  enrollmentPlatformType?: Maybe<EnrollmentPlatformType>;
  /**
   * Unique identifier for a policy.
   * AKA: policyNumber | schemeId | accountNumber | contractNumber
   */
  id: Scalars['String']['output'];
  idCardMailingLocation?: Maybe<IdCardMailingLocation>;
  inputSearchDate: Scalars['String']['output'];
  memberCount?: Maybe<Scalars['Int']['output']>;
  memberGroups: Array<Maybe<MemberGroupType>>;
  name: Scalars['String']['output'];
  nextAnniversaryDate?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  policyHolder: PolicyHolder;
  pricingCategory?: Maybe<PricingCategory>;
  productLine?: Maybe<ProductLine>;
  renewalFinalized?: Maybe<Scalars['Boolean']['output']>;
  searchDate: Scalars['String']['output'];
  serviceLocation?: Maybe<ServiceLocation>;
  servicers: Array<Servicer>;
  /** This Standard Industrial Classification (SIC) codes associated with the policy. https://www.osha.gov/data/sic-manual */
  sicCodes: Array<Maybe<SicCode>>;
  /** @deprecated use statusV2.type instead */
  status: PolicyStatus;
  /** This field will eventually be renamed back to 'status', v2 was added to enable deprecating an old field. Planned in year 2023. */
  statusV2?: Maybe<PolicyStatusWithEffectiveDate>;
  summary?: Maybe<PolicySummary>;
  taxState?: Maybe<Scalars['String']['output']>;
  /** Set if the policy is TERMINATED or LAPSED. This will be the day before the TERMINATED status becomes effective. */
  terminationDate?: Maybe<Scalars['String']['output']>;
};

export enum PolicyAdministrationType {
  SelfAccounting = 'SELF_ACCOUNTING',
  StandardAccounting = 'STANDARD_ACCOUNTING'
}

export type PolicyCoverage = {
  /** Identifier that relates to a specific set of benefits for an individual PolicyCoverage */
  bookId?: Maybe<Scalars['String']['output']>;
  coverageHistory: Array<PolicyCoverageHistory>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  memberGroupId: Scalars['String']['output'];
  name: ProductName;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  searchDate: Scalars['String']['output'];
  /**
   * Status as of the **search date**.
   * This field is meant to be a shortcut helping ot avoid comparing effective and termination dates to find out the status of a PolicyCoverage.
   */
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
};

export type PolicyCoverageConfiguration = {
  effectiveDate?: Maybe<Scalars['String']['output']>;
  memberGroupId: Scalars['String']['output'];
  name?: Maybe<ProductName>;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  searchDate: Scalars['String']['output'];
  terminationDate?: Maybe<Scalars['String']['output']>;
  voluntary: Scalars['Boolean']['output'];
};

export type PolicyCoverageHistory = {
  __typename?: 'PolicyCoverageHistory';
  effectiveDate?: Maybe<Scalars['String']['output']>;
  name: ProductName;
  terminationDate?: Maybe<Scalars['String']['output']>;
};

export enum PolicyCoverageStatus {
  Active = 'ACTIVE',
  Terminated = 'TERMINATED'
}

export type PolicyCoverageUpdateResponse = {
  __typename?: 'PolicyCoverageUpdateResponse';
  /** Activity created in WB Federation Activity Ledger. No activity will be created if request has validation errors and null will be returned instead. */
  activity?: Maybe<Activity>;
  /** Validation errors. Will be empty array if no errors. */
  errors: Array<PolicyCoverageValidationError>;
  /**
   * The id of the submitted request.
   * @deprecated use Activity
   */
  id: Scalars['String']['output'];
  /**
   * The status of the submitted request.
   * @deprecated use Activity
   */
  status: ActivityStatus;
  /**
   * The type of the submitted request.
   * @deprecated use Activity
   */
  type: ActivityType;
};

export type PolicyCoverageValidationError = {
  __typename?: 'PolicyCoverageValidationError';
  /** Message of the error. */
  errorMessage: Scalars['String']['output'];
};

export type PolicyCoveragesFilterInput = {
  /** If never effective coverages should be returned. Default is false. */
  showNeverEffective?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PolicyDependents = {
  __typename?: 'PolicyDependents';
  dateOfBirth: Scalars['Date']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  nameId: Scalars['Int']['output'];
};

export type PolicyHolder = {
  __typename?: 'PolicyHolder';
  /** @deprecated use policyHolder.organization.name instead */
  name: Scalars['String']['output'];
  organization?: Maybe<Organization>;
};

export type PolicyHolderUpdateInput = {
  organization?: InputMaybe<OrganizationUpdateInput>;
};

export enum PolicyPricingCategory {
  Insured = 'INSURED',
  SelfFunded = 'SELF_FUNDED'
}

export enum PolicyStatus {
  Active = 'ACTIVE',
  ActiveOnHold = 'ACTIVE_ON_HOLD',
  Lapsed = 'LAPSED',
  Pending = 'PENDING',
  PendingDiscontinuance = 'PENDING_DISCONTINUANCE',
  PreLapsed = 'PRE_LAPSED',
  Terminated = 'TERMINATED',
  TerminatedOnHold = 'TERMINATED_ON_HOLD'
}

export type PolicyStatusWithEffectiveDate = {
  __typename?: 'PolicyStatusWithEffectiveDate';
  effectiveDate?: Maybe<Scalars['String']['output']>;
  type?: Maybe<PolicyStatus>;
};

export type PolicySummary = {
  __typename?: 'PolicySummary';
  administrationType?: Maybe<PolicyAdministrationType>;
  contractState?: Maybe<Scalars['String']['output']>;
  pricingCategory?: Maybe<PolicyPricingCategory>;
};

export type PolicyUpdateInput = {
  employers?: InputMaybe<Array<InputMaybe<EmployerUpdateInput>>>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  policyHolder?: InputMaybe<PolicyHolderUpdateInput>;
};

export type PolicyUpdateResponse = {
  __typename?: 'PolicyUpdateResponse';
  /** Activity created in WB Federation Activity Ledger. No activity will be created if request has validation errors and null will be returned instead. */
  activity?: Maybe<Activity>;
  /** Validation errors. Will be empty array if no errors. */
  validationErrors: Array<PolicyValidationError>;
};

export type PolicyValidationError = {
  __typename?: 'PolicyValidationError';
  /** Message of the error. */
  errorMessage: Scalars['String']['output'];
  /** Path of the field that is associated with the error. */
  path: Scalars['String']['output'];
};

export enum PortabilityEnum {
  FiveYear = 'FIVE_YEAR',
  FullPortabilityToAge_70 = 'FULL_PORTABILITY_TO_AGE_70',
  FullPortabilityToAge_75 = 'FULL_PORTABILITY_TO_AGE_75',
  None = 'NONE',
  ThreeYear = 'THREE_YEAR',
  ToAge_70 = 'TO_AGE_70',
  Unlimited = 'UNLIMITED'
}

export type PreExistingConditionContext = {
  __typename?: 'PreExistingConditionContext';
  errorMessage?: Maybe<Scalars['String']['output']>;
  lookBackEndDate?: Maybe<Scalars['Date']['output']>;
  lookBackStartDate?: Maybe<Scalars['Date']['output']>;
  preExistingConditionDefinition?: Maybe<Scalars['String']['output']>;
  reviewRequired: Scalars['Boolean']['output'];
  reviewRequiredHelperText: Scalars['String']['output'];
};

export type PreExistingConditionReview = {
  __typename?: 'PreExistingConditionReview';
  completed: Scalars['Boolean']['output'];
  conditionIsPreExisting: Scalars['Boolean']['output'];
  preExistingConditionReviewId: Scalars['String']['output'];
};

export type PreExistingConditionReviewInput = {
  completed: Scalars['Boolean']['input'];
  conditionIsPreExisting: Scalars['Boolean']['input'];
};

export type PreExistingConditionType = {
  __typename?: 'PreExistingConditionType';
  afterEffectiveDateWithoutTreatment?: Maybe<Scalars['String']['output']>;
  afterEffectiveDateWithoutTreatmentCadence?: Maybe<CadenceEnum>;
  afterEffectiveDateWithoutTreatmentPeriod?: Maybe<Scalars['Int']['output']>;
  beforeEffectiveDate?: Maybe<Scalars['String']['output']>;
  beforeEffectiveDateCadence?: Maybe<CadenceEnum>;
  beforeEffectiveDatePeriod?: Maybe<Scalars['Int']['output']>;
  disabilityBeginsWithin?: Maybe<Scalars['String']['output']>;
  disabilityBeginsWithinCadence?: Maybe<CadenceEnum>;
  disabilityBeginsWithinPeriod?: Maybe<Scalars['Int']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
};

export type PreExistingConditionsInput = {
  afterEffectiveDateWithoutTreatment?: InputMaybe<Scalars['String']['input']>;
  afterEffectiveDateWithoutTreatmentCadence?: InputMaybe<CadenceEnum>;
  afterEffectiveDateWithoutTreatmentPeriod?: InputMaybe<Scalars['Int']['input']>;
  beforeEffectiveDate?: InputMaybe<Scalars['String']['input']>;
  beforeEffectiveDateCadence?: InputMaybe<CadenceEnum>;
  beforeEffectiveDatePeriod?: InputMaybe<Scalars['Int']['input']>;
  disabilityBeginsWithin?: InputMaybe<Scalars['String']['input']>;
  disabilityBeginsWithinCadence?: InputMaybe<CadenceEnum>;
  disabilityBeginsWithinPeriod?: InputMaybe<Scalars['Int']['input']>;
  included: Scalars['Boolean']['input'];
};

export type PregnancyProvision = {
  __typename?: 'PregnancyProvision';
  amount?: Maybe<Scalars['Int']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
  visitsPerPregnancy?: Maybe<Scalars['Int']['output']>;
};

export enum PremiumPaymentType {
  BonusUp = 'BONUS_UP',
  NotApplicable = 'NOT_APPLICABLE',
  PostTax = 'POST_TAX',
  PreTax = 'PRE_TAX',
  TaxChoicePostTax = 'TAX_CHOICE_POST_TAX',
  TaxChoicePreTax = 'TAX_CHOICE_PRE_TAX',
  Unknown = 'UNKNOWN'
}

export type PrescriptionDrugProvision = {
  __typename?: 'PrescriptionDrugProvision';
  brandNameAmount?: Maybe<Scalars['Int']['output']>;
  brandNameIncluded?: Maybe<Scalars['Boolean']['output']>;
  daysPerYear?: Maybe<Scalars['Int']['output']>;
  genericAmount?: Maybe<Scalars['Int']['output']>;
  genericIncluded?: Maybe<Scalars['Boolean']['output']>;
  hospitalizationRequired?: Maybe<Scalars['Boolean']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
};

export enum PricingCategory {
  OnaDepositors = 'ONA_DEPOSITORS',
  PooledGroup = 'POOLED_GROUP',
  RbgDepositors = 'RBG_DEPOSITORS',
  RegularGroup = 'REGULAR_GROUP',
  SelfFunded = 'SELF_FUNDED'
}

export type PrimaryBenefitDecision = Auditable & {
  __typename?: 'PrimaryBenefitDecision';
  benefitDecisionId: Scalars['String']['output'];
  benefitId: Scalars['String']['output'];
  checkPaymentDeliveryMethod?: Maybe<CheckPaymentDeliveryMethod>;
  completed: Scalars['Boolean']['output'];
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  decisionMadeByUserId?: Maybe<Scalars['String']['output']>;
  decisionMadeByUserName?: Maybe<Scalars['String']['output']>;
  decisionType: DecisionType;
  denialReason?: Maybe<DenialReasonType>;
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  note: Note;
  overrideType?: Maybe<OverrideType>;
  paymentPreference?: Maybe<PaymentPreference>;
  review?: Maybe<BenefitReview>;
};

export type PrimaryBenefitDetail = {
  __typename?: 'PrimaryBenefitDetail';
  maximumBenefit?: Maybe<Scalars['BigDecimal']['output']>;
  maximumBenefitHelperText?: Maybe<Scalars['String']['output']>;
  minimumBenefit?: Maybe<Scalars['BigDecimal']['output']>;
  minimumBenefitHelperText?: Maybe<Scalars['String']['output']>;
  primaryBenefit?: Maybe<Scalars['BigDecimal']['output']>;
  primaryBenefitHelperText?: Maybe<Scalars['String']['output']>;
};

export type PriorCarrier = {
  __typename?: 'PriorCarrier';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  billReceived?: Maybe<YesNo>;
  city?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  memberGroupId: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  precedingCarrier?: Maybe<Scalars['String']['output']>;
  productShortName: Scalars['String']['output'];
  searchDate: Scalars['String']['output'];
  serviceLength?: Maybe<ServiceLength>;
  state?: Maybe<StateAbbreviation>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type Privilege = {
  __typename?: 'Privilege';
  description: Scalars['String']['output'];
  type: PrivilegeType;
};

export enum PrivilegeType {
  BenefitCalculate = 'BENEFIT_CALCULATE',
  BenefitMakeDecision = 'BENEFIT_MAKE_DECISION',
  BenefitOverrideCalculation = 'BENEFIT_OVERRIDE_CALCULATION',
  BenefitOverrideDecision = 'BENEFIT_OVERRIDE_DECISION',
  BenefitReview = 'BENEFIT_REVIEW',
  BulkReassignClaim = 'BULK_REASSIGN_CLAIM',
  BulkReassignTask = 'BULK_REASSIGN_TASK',
  ClaimCloseInError = 'CLAIM_CLOSE_IN_ERROR',
  ClaimUpdate = 'CLAIM_UPDATE',
  CreateOverpayment = 'CREATE_OVERPAYMENT',
  DeleteDocument = 'DELETE_DOCUMENT',
  DocumentModify = 'DOCUMENT_MODIFY',
  DocumentRequestDelete = 'DOCUMENT_REQUEST_DELETE',
  EligibilityModify = 'ELIGIBILITY_MODIFY',
  NoteModify = 'NOTE_MODIFY',
  PaymentCancel = 'PAYMENT_CANCEL',
  StdOverrideEndDate = 'STD_OVERRIDE_END_DATE',
  TaskModify = 'TASK_MODIFY'
}

export enum ProductLine {
  OldNorthwestAgentsDespositors = 'OLD_NORTHWEST_AGENTS_DESPOSITORS',
  PooledGroup = 'POOLED_GROUP',
  RegularGroup = 'REGULAR_GROUP',
  RogersBenefitGroupDespositors = 'ROGERS_BENEFIT_GROUP_DESPOSITORS'
}

export enum ProductName {
  Accident = 'ACCIDENT',
  AccidentDependent = 'ACCIDENT_DEPENDENT',
  CriticalIllness = 'CRITICAL_ILLNESS',
  CriticalIllnessChild = 'CRITICAL_ILLNESS_CHILD',
  CriticalIllnessSpouse = 'CRITICAL_ILLNESS_SPOUSE',
  Dental = 'DENTAL',
  DentalDependent = 'DENTAL_DEPENDENT',
  GroupTermLife = 'GROUP_TERM_LIFE',
  GroupTermLifeAccidentalDeathAndDismemberment = 'GROUP_TERM_LIFE_ACCIDENTAL_DEATH_AND_DISMEMBERMENT',
  GroupTermLifeDependent = 'GROUP_TERM_LIFE_DEPENDENT',
  HospitalIndemnity = 'HOSPITAL_INDEMNITY',
  HospitalIndemnityDependent = 'HOSPITAL_INDEMNITY_DEPENDENT',
  LongTermDisability = 'LONG_TERM_DISABILITY',
  PaidFamilyLeave = 'PAID_FAMILY_LEAVE',
  PaidMedicalLeave = 'PAID_MEDICAL_LEAVE',
  SelfFundedDental = 'SELF_FUNDED_DENTAL',
  SelfFundedShortTermDisability = 'SELF_FUNDED_SHORT_TERM_DISABILITY',
  SelfFundedVision = 'SELF_FUNDED_VISION',
  ShortTermDisability = 'SHORT_TERM_DISABILITY',
  Vision = 'VISION',
  VisionDependent = 'VISION_DEPENDENT',
  VoluntaryDental = 'VOLUNTARY_DENTAL',
  VoluntaryDentalDependent = 'VOLUNTARY_DENTAL_DEPENDENT',
  VoluntaryHospitalIndemnity = 'VOLUNTARY_HOSPITAL_INDEMNITY',
  VoluntaryHospitalIndemnityDependent = 'VOLUNTARY_HOSPITAL_INDEMNITY_DEPENDENT',
  VoluntaryHospitalIndemnityDependentThree = 'VOLUNTARY_HOSPITAL_INDEMNITY_DEPENDENT_THREE',
  VoluntaryHospitalIndemnityDependentTwo = 'VOLUNTARY_HOSPITAL_INDEMNITY_DEPENDENT_TWO',
  VoluntaryHospitalIndemnityThree = 'VOLUNTARY_HOSPITAL_INDEMNITY_THREE',
  VoluntaryHospitalIndemnityTwo = 'VOLUNTARY_HOSPITAL_INDEMNITY_TWO',
  VoluntaryLongTermDisability = 'VOLUNTARY_LONG_TERM_DISABILITY',
  VoluntaryShortTermDisability = 'VOLUNTARY_SHORT_TERM_DISABILITY',
  VoluntaryTermLife = 'VOLUNTARY_TERM_LIFE',
  VoluntaryTermLifeAccidentalDeathAndDismemberment = 'VOLUNTARY_TERM_LIFE_ACCIDENTAL_DEATH_AND_DISMEMBERMENT',
  VoluntaryTermLifeChild = 'VOLUNTARY_TERM_LIFE_CHILD',
  VoluntaryTermLifeSpouse = 'VOLUNTARY_TERM_LIFE_SPOUSE',
  VoluntaryTermLifeSpouseAccidentalDeathAndDismemberment = 'VOLUNTARY_TERM_LIFE_SPOUSE_ACCIDENTAL_DEATH_AND_DISMEMBERMENT',
  VoluntaryVision = 'VOLUNTARY_VISION',
  VoluntaryVisionDependent = 'VOLUNTARY_VISION_DEPENDENT'
}

export type Provision = {
  __typename?: 'Provision';
  amount?: Maybe<Scalars['Int']['output']>;
  benefitDomain: BenefitDomain;
  daysPerYear?: Maybe<Scalars['Int']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
  name: ProvisionType;
};

export type ProvisionInstance = AccidentProvisionInstance | CriticalIllnessProvisionInstance | HospitalProvisionInstance | WellnessProvisionInstance;

export type ProvisionInstanceInterface = {
  payment?: Maybe<Payment>;
  provision: Provision;
  provisionInstanceId: Scalars['String']['output'];
};

export type ProvisionTierType = {
  __typename?: 'ProvisionTierType';
  amount?: Maybe<Scalars['Int']['output']>;
  dayRangeEnd?: Maybe<Scalars['Int']['output']>;
  dayRangeStart?: Maybe<Scalars['Int']['output']>;
  tier?: Maybe<Scalars['Int']['output']>;
};

export enum ProvisionType {
  AddCommonCarrier = 'ADD_COMMON_CARRIER',
  AddRepatriation = 'ADD_REPATRIATION',
  AddSeatBeltOrAirBag = 'ADD_SEAT_BELT_OR_AIR_BAG',
  /** Hospital Indemnity provision types */
  AirAmbulance = 'AIR_AMBULANCE',
  /** Critical Illness provision types */
  AlzheimersDisease = 'ALZHEIMERS_DISEASE',
  /** Accident provision types */
  Ambulance = 'AMBULANCE',
  AmyotrophicLateralSclerosis = 'AMYOTROPHIC_LATERAL_SCLEROSIS',
  BenignBrainTumor = 'BENIGN_BRAIN_TUMOR',
  BipolarOneDisorder = 'BIPOLAR_ONE_DISORDER',
  BloodProducts = 'BLOOD_PRODUCTS',
  CarcinomaInSitu = 'CARCINOMA_IN_SITU',
  CerebralPalsy = 'CEREBRAL_PALSY',
  ChiropracticCare = 'CHIROPRACTIC_CARE',
  CleftLipPalate = 'CLEFT_LIP_PALATE',
  Coma = 'COMA',
  CompanionLodging = 'COMPANION_LODGING',
  Concussion = 'CONCUSSION',
  CoronaryArteryDisease = 'CORONARY_ARTERY_DISEASE',
  Coronavirus = 'CORONAVIRUS',
  Covid_19 = 'COVID_19',
  CriticalIllnessComa = 'CRITICAL_ILLNESS_COMA',
  CriticalIllnessLossOfHearing = 'CRITICAL_ILLNESS_LOSS_OF_HEARING',
  CriticalIllnessLossOfSight = 'CRITICAL_ILLNESS_LOSS_OF_SIGHT',
  CriticalIllnessLossOfSpeech = 'CRITICAL_ILLNESS_LOSS_OF_SPEECH',
  CysticFibrosis = 'CYSTIC_FIBROSIS',
  DentalInjury = 'DENTAL_INJURY',
  Diphtheria = 'DIPHTHERIA',
  DislocationClosedReductionAnkle = 'DISLOCATION_CLOSED_REDUCTION_ANKLE',
  DislocationClosedReductionAnkleWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_ANKLE_WITH_ANESTHESIA',
  DislocationClosedReductionCollarbone = 'DISLOCATION_CLOSED_REDUCTION_COLLARBONE',
  DislocationClosedReductionCollarboneWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_COLLARBONE_WITH_ANESTHESIA',
  DislocationClosedReductionElbow = 'DISLOCATION_CLOSED_REDUCTION_ELBOW',
  DislocationClosedReductionElbowWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_ELBOW_WITH_ANESTHESIA',
  DislocationClosedReductionFoot = 'DISLOCATION_CLOSED_REDUCTION_FOOT',
  DislocationClosedReductionFootWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_FOOT_WITH_ANESTHESIA',
  DislocationClosedReductionHand = 'DISLOCATION_CLOSED_REDUCTION_HAND',
  DislocationClosedReductionHandWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_HAND_WITH_ANESTHESIA',
  DislocationClosedReductionHip = 'DISLOCATION_CLOSED_REDUCTION_HIP',
  DislocationClosedReductionHipWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_HIP_WITH_ANESTHESIA',
  DislocationClosedReductionKnee = 'DISLOCATION_CLOSED_REDUCTION_KNEE',
  DislocationClosedReductionKneeWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_KNEE_WITH_ANESTHESIA',
  DislocationClosedReductionLowerJaw = 'DISLOCATION_CLOSED_REDUCTION_LOWER_JAW',
  DislocationClosedReductionLowerJawWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_LOWER_JAW_WITH_ANESTHESIA',
  DislocationClosedReductionPartialAnkle = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_ANKLE',
  DislocationClosedReductionPartialCollarbone = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_COLLARBONE',
  DislocationClosedReductionPartialElbow = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_ELBOW',
  DislocationClosedReductionPartialFoot = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_FOOT',
  DislocationClosedReductionPartialHand = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_HAND',
  DislocationClosedReductionPartialHip = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_HIP',
  DislocationClosedReductionPartialKnee = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_KNEE',
  DislocationClosedReductionPartialLowerJaw = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_LOWER_JAW',
  DislocationClosedReductionPartialShoulder = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_SHOULDER',
  DislocationClosedReductionPartialWrist = 'DISLOCATION_CLOSED_REDUCTION_PARTIAL_WRIST',
  DislocationClosedReductionShoulder = 'DISLOCATION_CLOSED_REDUCTION_SHOULDER',
  DislocationClosedReductionShoulderWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_SHOULDER_WITH_ANESTHESIA',
  DislocationClosedReductionWrist = 'DISLOCATION_CLOSED_REDUCTION_WRIST',
  DislocationClosedReductionWristWithAnesthesia = 'DISLOCATION_CLOSED_REDUCTION_WRIST_WITH_ANESTHESIA',
  DislocationOpenReductionAnkle = 'DISLOCATION_OPEN_REDUCTION_ANKLE',
  DislocationOpenReductionCollarbone = 'DISLOCATION_OPEN_REDUCTION_COLLARBONE',
  DislocationOpenReductionElbow = 'DISLOCATION_OPEN_REDUCTION_ELBOW',
  DislocationOpenReductionFoot = 'DISLOCATION_OPEN_REDUCTION_FOOT',
  DislocationOpenReductionHand = 'DISLOCATION_OPEN_REDUCTION_HAND',
  DislocationOpenReductionHip = 'DISLOCATION_OPEN_REDUCTION_HIP',
  DislocationOpenReductionKnee = 'DISLOCATION_OPEN_REDUCTION_KNEE',
  DislocationOpenReductionLowerJaw = 'DISLOCATION_OPEN_REDUCTION_LOWER_JAW',
  DislocationOpenReductionPartialAnkle = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_ANKLE',
  DislocationOpenReductionPartialCollarbone = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_COLLARBONE',
  DislocationOpenReductionPartialElbow = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_ELBOW',
  DislocationOpenReductionPartialFoot = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_FOOT',
  DislocationOpenReductionPartialHand = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_HAND',
  DislocationOpenReductionPartialHip = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_HIP',
  DislocationOpenReductionPartialKnee = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_KNEE',
  DislocationOpenReductionPartialLowerJaw = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_LOWER_JAW',
  DislocationOpenReductionPartialShoulder = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_SHOULDER',
  DislocationOpenReductionPartialWrist = 'DISLOCATION_OPEN_REDUCTION_PARTIAL_WRIST',
  DislocationOpenReductionShoulder = 'DISLOCATION_OPEN_REDUCTION_SHOULDER',
  DislocationOpenReductionWrist = 'DISLOCATION_OPEN_REDUCTION_WRIST',
  DismembermentBothFeetSeveredAboveAnkle = 'DISMEMBERMENT_BOTH_FEET_SEVERED_ABOVE_ANKLE',
  DismembermentBothHandsSeveredAboveWrist = 'DISMEMBERMENT_BOTH_HANDS_SEVERED_ABOVE_WRIST',
  DismembermentOneFootSeveredAboveAnkle = 'DISMEMBERMENT_ONE_FOOT_SEVERED_ABOVE_ANKLE',
  DismembermentOneHandAndOneFoot = 'DISMEMBERMENT_ONE_HAND_AND_ONE_FOOT',
  DismembermentOneHandSeveredAboveWrist = 'DISMEMBERMENT_ONE_HAND_SEVERED_ABOVE_WRIST',
  DismembermentOneOrMoreFingers = 'DISMEMBERMENT_ONE_OR_MORE_FINGERS',
  DismembermentOneOrMoreToes = 'DISMEMBERMENT_ONE_OR_MORE_TOES',
  DismembermentThumbAndIndexFinger = 'DISMEMBERMENT_THUMB_AND_INDEX_FINGER',
  DoulaCare = 'DOULA_CARE',
  DownSyndrome = 'DOWN_SYNDROME',
  DurableMedicalEquipment = 'DURABLE_MEDICAL_EQUIPMENT',
  EmergencyRoom = 'EMERGENCY_ROOM',
  Encephalitis = 'ENCEPHALITIS',
  EyeInjury = 'EYE_INJURY',
  FamilyCare = 'FAMILY_CARE',
  FirstDayHospital = 'FIRST_DAY_HOSPITAL',
  FirstDayIcu = 'FIRST_DAY_ICU',
  FractureClosedReductionAnkle = 'FRACTURE_CLOSED_REDUCTION_ANKLE',
  FractureClosedReductionArm = 'FRACTURE_CLOSED_REDUCTION_ARM',
  FractureClosedReductionChipAnkle = 'FRACTURE_CLOSED_REDUCTION_CHIP_ANKLE',
  FractureClosedReductionChipArm = 'FRACTURE_CLOSED_REDUCTION_CHIP_ARM',
  FractureClosedReductionChipCollarbone = 'FRACTURE_CLOSED_REDUCTION_CHIP_COLLARBONE',
  FractureClosedReductionChipElbow = 'FRACTURE_CLOSED_REDUCTION_CHIP_ELBOW',
  FractureClosedReductionChipFacialBones = 'FRACTURE_CLOSED_REDUCTION_CHIP_FACIAL_BONES',
  FractureClosedReductionChipFoot = 'FRACTURE_CLOSED_REDUCTION_CHIP_FOOT',
  FractureClosedReductionChipHand = 'FRACTURE_CLOSED_REDUCTION_CHIP_HAND',
  FractureClosedReductionChipHip = 'FRACTURE_CLOSED_REDUCTION_CHIP_HIP',
  FractureClosedReductionChipJaw = 'FRACTURE_CLOSED_REDUCTION_CHIP_JAW',
  FractureClosedReductionChipKneeCap = 'FRACTURE_CLOSED_REDUCTION_CHIP_KNEE_CAP',
  FractureClosedReductionChipLowerLeg = 'FRACTURE_CLOSED_REDUCTION_CHIP_LOWER_LEG',
  FractureClosedReductionChipPelvis = 'FRACTURE_CLOSED_REDUCTION_CHIP_PELVIS',
  FractureClosedReductionChipRib = 'FRACTURE_CLOSED_REDUCTION_CHIP_RIB',
  FractureClosedReductionChipShoulderBlade = 'FRACTURE_CLOSED_REDUCTION_CHIP_SHOULDER_BLADE',
  FractureClosedReductionChipSkullDepressed = 'FRACTURE_CLOSED_REDUCTION_CHIP_SKULL_DEPRESSED',
  FractureClosedReductionChipSkullNonDepressed = 'FRACTURE_CLOSED_REDUCTION_CHIP_SKULL_NON_DEPRESSED',
  FractureClosedReductionChipSternum = 'FRACTURE_CLOSED_REDUCTION_CHIP_STERNUM',
  FractureClosedReductionChipTailbone = 'FRACTURE_CLOSED_REDUCTION_CHIP_TAILBONE',
  FractureClosedReductionChipThigh = 'FRACTURE_CLOSED_REDUCTION_CHIP_THIGH',
  FractureClosedReductionChipVertebrae = 'FRACTURE_CLOSED_REDUCTION_CHIP_VERTEBRAE',
  FractureClosedReductionChipVertebralProcesses = 'FRACTURE_CLOSED_REDUCTION_CHIP_VERTEBRAL_PROCESSES',
  FractureClosedReductionChipWrist = 'FRACTURE_CLOSED_REDUCTION_CHIP_WRIST',
  FractureClosedReductionCollarbone = 'FRACTURE_CLOSED_REDUCTION_COLLARBONE',
  FractureClosedReductionElbow = 'FRACTURE_CLOSED_REDUCTION_ELBOW',
  FractureClosedReductionFacialBones = 'FRACTURE_CLOSED_REDUCTION_FACIAL_BONES',
  FractureClosedReductionFoot = 'FRACTURE_CLOSED_REDUCTION_FOOT',
  FractureClosedReductionHand = 'FRACTURE_CLOSED_REDUCTION_HAND',
  FractureClosedReductionHip = 'FRACTURE_CLOSED_REDUCTION_HIP',
  FractureClosedReductionJaw = 'FRACTURE_CLOSED_REDUCTION_JAW',
  FractureClosedReductionKneeCap = 'FRACTURE_CLOSED_REDUCTION_KNEE_CAP',
  FractureClosedReductionLowerLeg = 'FRACTURE_CLOSED_REDUCTION_LOWER_LEG',
  FractureClosedReductionPelvis = 'FRACTURE_CLOSED_REDUCTION_PELVIS',
  FractureClosedReductionRib = 'FRACTURE_CLOSED_REDUCTION_RIB',
  FractureClosedReductionShoulderBlade = 'FRACTURE_CLOSED_REDUCTION_SHOULDER_BLADE',
  FractureClosedReductionSkullDepressed = 'FRACTURE_CLOSED_REDUCTION_SKULL_DEPRESSED',
  FractureClosedReductionSkullNonDepressed = 'FRACTURE_CLOSED_REDUCTION_SKULL_NON_DEPRESSED',
  FractureClosedReductionSternum = 'FRACTURE_CLOSED_REDUCTION_STERNUM',
  FractureClosedReductionTailbone = 'FRACTURE_CLOSED_REDUCTION_TAILBONE',
  FractureClosedReductionThigh = 'FRACTURE_CLOSED_REDUCTION_THIGH',
  FractureClosedReductionVertebrae = 'FRACTURE_CLOSED_REDUCTION_VERTEBRAE',
  FractureClosedReductionVertebralProcesses = 'FRACTURE_CLOSED_REDUCTION_VERTEBRAL_PROCESSES',
  FractureClosedReductionWrist = 'FRACTURE_CLOSED_REDUCTION_WRIST',
  FractureOpenReductionAnkle = 'FRACTURE_OPEN_REDUCTION_ANKLE',
  FractureOpenReductionArm = 'FRACTURE_OPEN_REDUCTION_ARM',
  FractureOpenReductionChipAnkle = 'FRACTURE_OPEN_REDUCTION_CHIP_ANKLE',
  FractureOpenReductionChipArm = 'FRACTURE_OPEN_REDUCTION_CHIP_ARM',
  FractureOpenReductionChipCollarbone = 'FRACTURE_OPEN_REDUCTION_CHIP_COLLARBONE',
  FractureOpenReductionChipElbow = 'FRACTURE_OPEN_REDUCTION_CHIP_ELBOW',
  FractureOpenReductionChipFacialBones = 'FRACTURE_OPEN_REDUCTION_CHIP_FACIAL_BONES',
  FractureOpenReductionChipFoot = 'FRACTURE_OPEN_REDUCTION_CHIP_FOOT',
  FractureOpenReductionChipHand = 'FRACTURE_OPEN_REDUCTION_CHIP_HAND',
  FractureOpenReductionChipHip = 'FRACTURE_OPEN_REDUCTION_CHIP_HIP',
  FractureOpenReductionChipJaw = 'FRACTURE_OPEN_REDUCTION_CHIP_JAW',
  FractureOpenReductionChipKneeCap = 'FRACTURE_OPEN_REDUCTION_CHIP_KNEE_CAP',
  FractureOpenReductionChipLowerLeg = 'FRACTURE_OPEN_REDUCTION_CHIP_LOWER_LEG',
  FractureOpenReductionChipPelvis = 'FRACTURE_OPEN_REDUCTION_CHIP_PELVIS',
  FractureOpenReductionChipRib = 'FRACTURE_OPEN_REDUCTION_CHIP_RIB',
  FractureOpenReductionChipShoulderBlade = 'FRACTURE_OPEN_REDUCTION_CHIP_SHOULDER_BLADE',
  FractureOpenReductionChipSkullDepressed = 'FRACTURE_OPEN_REDUCTION_CHIP_SKULL_DEPRESSED',
  FractureOpenReductionChipSkullNonDepressed = 'FRACTURE_OPEN_REDUCTION_CHIP_SKULL_NON_DEPRESSED',
  FractureOpenReductionChipSternum = 'FRACTURE_OPEN_REDUCTION_CHIP_STERNUM',
  FractureOpenReductionChipTailbone = 'FRACTURE_OPEN_REDUCTION_CHIP_TAILBONE',
  FractureOpenReductionChipThigh = 'FRACTURE_OPEN_REDUCTION_CHIP_THIGH',
  FractureOpenReductionChipVertebrae = 'FRACTURE_OPEN_REDUCTION_CHIP_VERTEBRAE',
  FractureOpenReductionChipVertebralProcesses = 'FRACTURE_OPEN_REDUCTION_CHIP_VERTEBRAL_PROCESSES',
  FractureOpenReductionChipWrist = 'FRACTURE_OPEN_REDUCTION_CHIP_WRIST',
  FractureOpenReductionCollarbone = 'FRACTURE_OPEN_REDUCTION_COLLARBONE',
  FractureOpenReductionElbow = 'FRACTURE_OPEN_REDUCTION_ELBOW',
  FractureOpenReductionFacialBones = 'FRACTURE_OPEN_REDUCTION_FACIAL_BONES',
  FractureOpenReductionFoot = 'FRACTURE_OPEN_REDUCTION_FOOT',
  FractureOpenReductionHand = 'FRACTURE_OPEN_REDUCTION_HAND',
  FractureOpenReductionHip = 'FRACTURE_OPEN_REDUCTION_HIP',
  FractureOpenReductionJaw = 'FRACTURE_OPEN_REDUCTION_JAW',
  FractureOpenReductionKneeCap = 'FRACTURE_OPEN_REDUCTION_KNEE_CAP',
  FractureOpenReductionLowerLeg = 'FRACTURE_OPEN_REDUCTION_LOWER_LEG',
  FractureOpenReductionPelvis = 'FRACTURE_OPEN_REDUCTION_PELVIS',
  FractureOpenReductionRib = 'FRACTURE_OPEN_REDUCTION_RIB',
  FractureOpenReductionShoulderBlade = 'FRACTURE_OPEN_REDUCTION_SHOULDER_BLADE',
  FractureOpenReductionSkullDepressed = 'FRACTURE_OPEN_REDUCTION_SKULL_DEPRESSED',
  FractureOpenReductionSkullNonDepressed = 'FRACTURE_OPEN_REDUCTION_SKULL_NON_DEPRESSED',
  FractureOpenReductionSternum = 'FRACTURE_OPEN_REDUCTION_STERNUM',
  FractureOpenReductionTailbone = 'FRACTURE_OPEN_REDUCTION_TAILBONE',
  FractureOpenReductionThigh = 'FRACTURE_OPEN_REDUCTION_THIGH',
  FractureOpenReductionVertebrae = 'FRACTURE_OPEN_REDUCTION_VERTEBRAE',
  FractureOpenReductionVertebralProcesses = 'FRACTURE_OPEN_REDUCTION_VERTEBRAL_PROCESSES',
  FractureOpenReductionWrist = 'FRACTURE_OPEN_REDUCTION_WRIST',
  GeneralAnesthesia = 'GENERAL_ANESTHESIA',
  GroundOrWaterAmbulance = 'GROUND_OR_WATER_AMBULANCE',
  HeartAttack = 'HEART_ATTACK',
  HomeHealthServices = 'HOME_HEALTH_SERVICES',
  HospiceCare = 'HOSPICE_CARE',
  HospitalConfinement = 'HOSPITAL_CONFINEMENT',
  IcuConfinement = 'ICU_CONFINEMENT',
  IngestionOfControlledDrugsHospitalConfinement = 'INGESTION_OF_CONTROLLED_DRUGS_HOSPITAL_CONFINEMENT',
  IngestionOfControlledDrugsOutpatientExpenses = 'INGESTION_OF_CONTROLLED_DRUGS_OUTPATIENT_EXPENSES',
  InjuryNotSpecificallyListedNotSurgicallyRepaired = 'INJURY_NOT_SPECIFICALLY_LISTED_NOT_SURGICALLY_REPAIRED',
  InjuryNotSpecificallyListedSurgicallyRepaired = 'INJURY_NOT_SPECIFICALLY_LISTED_SURGICALLY_REPAIRED',
  InpatientSurgery = 'INPATIENT_SURGERY',
  InternalInjuryNotSurgicallyRepaired = 'INTERNAL_INJURY_NOT_SURGICALLY_REPAIRED',
  InternalInjurySurgicallyRepaired = 'INTERNAL_INJURY_SURGICALLY_REPAIRED',
  InvasiveCancer = 'INVASIVE_CANCER',
  InvasiveDiagnosticProcedure = 'INVASIVE_DIAGNOSTIC_PROCEDURE',
  KneeCartilage = 'KNEE_CARTILAGE',
  LabTestOrXray = 'LAB_TEST_OR_XRAY',
  LegionnairesDisease = 'LEGIONNAIRES_DISEASE',
  Ligament = 'LIGAMENT',
  LossOfHearing = 'LOSS_OF_HEARING',
  LossOfHearingOneEar = 'LOSS_OF_HEARING_ONE_EAR',
  LossOfLife = 'LOSS_OF_LIFE',
  LossOfSightBothEyes = 'LOSS_OF_SIGHT_BOTH_EYES',
  LossOfSightOneEye = 'LOSS_OF_SIGHT_ONE_EYE',
  LossOfSpeech = 'LOSS_OF_SPEECH',
  LossOfSpeechAndHearingInBothEars = 'LOSS_OF_SPEECH_AND_HEARING_IN_BOTH_EARS',
  LymeDisease = 'LYME_DISEASE',
  MajorDiagnosticProcedure = 'MAJOR_DIAGNOSTIC_PROCEDURE',
  MajorOrganFailure = 'MAJOR_ORGAN_FAILURE',
  Malaria = 'MALARIA',
  MedicalTravel = 'MEDICAL_TRAVEL',
  Meningitis = 'MENINGITIS',
  MentalDisorderOutpatientTherapy = 'MENTAL_DISORDER_OUTPATIENT_THERAPY',
  MentalDisorderTreatmentFacility = 'MENTAL_DISORDER_TREATMENT_FACILITY',
  MethicillinResistantStaphylococcusAureus = 'METHICILLIN_RESISTANT_STAPHYLOCOCCUS_AUREUS',
  MultipleSclerosis = 'MULTIPLE_SCLEROSIS',
  MuscularDystrophy = 'MUSCULAR_DYSTROPHY',
  NecrotizingFasciitis = 'NECROTIZING_FASCIITIS',
  NewbornHospitalConfinement = 'NEWBORN_HOSPITAL_CONFINEMENT',
  NewbornIcuConfinement = 'NEWBORN_ICU_CONFINEMENT',
  NewbornNursery = 'NEWBORN_NURSERY',
  ObservationUnit = 'OBSERVATION_UNIT',
  OccupationalInfectiousDisease = 'OCCUPATIONAL_INFECTIOUS_DISEASE',
  Osteomyelitis = 'OSTEOMYELITIS',
  OutpatientSurgeryAmbulatorySurgeryCenter = 'OUTPATIENT_SURGERY_AMBULATORY_SURGERY_CENTER',
  OutpatientSurgeryPhysiciansOffice = 'OUTPATIENT_SURGERY_PHYSICIANS_OFFICE',
  Paralysis = 'PARALYSIS',
  ParalysisBothFeet = 'PARALYSIS_BOTH_FEET',
  ParalysisBothHands = 'PARALYSIS_BOTH_HANDS',
  ParalysisHemiplegia = 'PARALYSIS_HEMIPLEGIA',
  ParalysisOneArm = 'PARALYSIS_ONE_ARM',
  ParalysisOneFoot = 'PARALYSIS_ONE_FOOT',
  ParalysisOneHand = 'PARALYSIS_ONE_HAND',
  ParalysisOneHandAndOneFoot = 'PARALYSIS_ONE_HAND_AND_ONE_FOOT',
  ParalysisOneLeg = 'PARALYSIS_ONE_LEG',
  ParalysisParaplegia = 'PARALYSIS_PARAPLEGIA',
  ParalysisQuadriplegia = 'PARALYSIS_QUADRIPLEGIA',
  ParkinsonsDisease = 'PARKINSONS_DISEASE',
  PetCare = 'PET_CARE',
  PhysicianOfficeVisit = 'PHYSICIAN_OFFICE_VISIT',
  Poliomyelitis = 'POLIOMYELITIS',
  PostTraumaticStressDisorder = 'POST_TRAUMATIC_STRESS_DISORDER',
  PrescriptionDrugBrandName = 'PRESCRIPTION_DRUG_BRAND_NAME',
  PrescriptionDrugGeneric = 'PRESCRIPTION_DRUG_GENERIC',
  Rabies = 'RABIES',
  RehabilitationFacility = 'REHABILITATION_FACILITY',
  RotatorCuff = 'ROTATOR_CUFF',
  RupturedDisc = 'RUPTURED_DISC',
  Schizophrenia = 'SCHIZOPHRENIA',
  SecondDegreeBurnOverTwentyFivePercentOfBody = 'SECOND_DEGREE_BURN_OVER_TWENTY_FIVE_PERCENT_OF_BODY',
  SecondDegreeBurnOverTwentyFivePercentOfBodyWithSkinGraft = 'SECOND_DEGREE_BURN_OVER_TWENTY_FIVE_PERCENT_OF_BODY_WITH_SKIN_GRAFT',
  SecondDegreeBurnUpToTwentyFivePercentOfBody = 'SECOND_DEGREE_BURN_UP_TO_TWENTY_FIVE_PERCENT_OF_BODY',
  SecondDegreeBurnUpToTwentyFivePercentOfBodyWithSkinGraft = 'SECOND_DEGREE_BURN_UP_TO_TWENTY_FIVE_PERCENT_OF_BODY_WITH_SKIN_GRAFT',
  SecondOpinion = 'SECOND_OPINION',
  Sepsis = 'SEPSIS',
  SkilledNursingFacility = 'SKILLED_NURSING_FACILITY',
  SkinCancer = 'SKIN_CANCER',
  SpinaBifida = 'SPINA_BIFIDA',
  Stroke = 'STROKE',
  SubstanceAbuseOutpatientTherapy = 'SUBSTANCE_ABUSE_OUTPATIENT_THERAPY',
  SubstanceAbuseTreatmentFacility = 'SUBSTANCE_ABUSE_TREATMENT_FACILITY',
  Telemedicine = 'TELEMEDICINE',
  Tendon = 'TENDON',
  Tetanus = 'TETANUS',
  TherapyServices = 'THERAPY_SERVICES',
  ThirdDegreeBurnOverTwentyFivePercentOfBody = 'THIRD_DEGREE_BURN_OVER_TWENTY_FIVE_PERCENT_OF_BODY',
  ThirdDegreeBurnOverTwentyFivePercentOfBodyWithSkinGraft = 'THIRD_DEGREE_BURN_OVER_TWENTY_FIVE_PERCENT_OF_BODY_WITH_SKIN_GRAFT',
  ThirdDegreeBurnUpToTwentyFivePercentOfBody = 'THIRD_DEGREE_BURN_UP_TO_TWENTY_FIVE_PERCENT_OF_BODY',
  ThirdDegreeBurnUpToTwentyFivePercentOfBodyWithSkinGraft = 'THIRD_DEGREE_BURN_UP_TO_TWENTY_FIVE_PERCENT_OF_BODY_WITH_SKIN_GRAFT',
  Tuberculosis = 'TUBERCULOSIS',
  UrgentCareFacility = 'URGENT_CARE_FACILITY',
  /** Wellness provision types */
  WellnessScreening = 'WELLNESS_SCREENING'
}

export type PutBenefitAllocationInput = {
  allocations: Array<InputMaybe<BenefitAllocationInput>>;
  type: PaymentType;
};

export type Query = {
  __typename?: 'Query';
  findAnnouncements?: Maybe<Announcements>;
  findBenefitDocument?: Maybe<Document>;
  findClaimAnalyst?: Maybe<User>;
  findClaimDocument?: Maybe<Document>;
  findClaims?: Maybe<Array<Maybe<Claim>>>;
  findExplanationOfBenefits?: Maybe<ExplanationOfBenefits>;
  findFeatures: Array<Feature>;
  findMember?: Maybe<Member>;
  findMemberGroup?: Maybe<MemberGroupCoverages>;
  /** Get an activity by its unique identifier. Does not support member activities, use getMemberActivity instead. */
  getActivity: Activity;
  getPolicy: Policy;
  getPolicyCoveragesByMemberGroup: MemberGroupCoverages;
  validateAddress?: Maybe<ValidatedAddress>;
  validateRoutingNumber: Scalars['Boolean']['output'];
};


export type QueryFindAnnouncementsArgs = {
  coverage: Coverage;
};


export type QueryFindBenefitDocumentArgs = {
  benefitKey: Scalars['String']['input'];
  coverage: Coverage;
  documentType: BenefitDocumentType;
};


export type QueryFindClaimAnalystArgs = {
  claimId: Scalars['String']['input'];
};


export type QueryFindClaimDocumentArgs = {
  claimId: Scalars['String']['input'];
  sharepointItemId: Scalars['String']['input'];
};


export type QueryFindClaimsArgs = {
  coverage: Coverage;
};


export type QueryFindExplanationOfBenefitsArgs = {
  claimId: Scalars['String']['input'];
  coverage: Coverage;
  paymentId: Scalars['String']['input'];
};


export type QueryFindMemberArgs = {
  benefitKey: Scalars['String']['input'];
  coverage: Coverage;
};


export type QueryFindMemberGroupArgs = {
  benefitKey: Scalars['String']['input'];
};


export type QueryGetActivityArgs = {
  activityId: Scalars['String']['input'];
};


export type QueryGetPolicyArgs = {
  id: Scalars['String']['input'];
  searchDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPolicyCoveragesByMemberGroupArgs = {
  filter?: InputMaybe<PolicyCoveragesFilterInput>;
  memberGroupName: Scalars['String']['input'];
  policyId: Scalars['String']['input'];
  searchDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryValidateAddressArgs = {
  validateAddressInput: ValidateAddressInput;
};


export type QueryValidateRoutingNumberArgs = {
  routingNumber: Scalars['String']['input'];
};

export type RandomReviewInput = {
  percentage: Scalars['Int']['input'];
};

export type RandomReviewPercentage = Auditable & {
  __typename?: 'RandomReviewPercentage';
  benefitType: BenefitType;
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  percentage: Scalars['Int']['output'];
  version: Scalars['Int']['output'];
};

export enum RateGuaranteePeriodEnum {
  OneYear = 'ONE_YEAR',
  ThreeYear = 'THREE_YEAR',
  TwoYear = 'TWO_YEAR'
}

export enum RateGuaranteeReasonEnum {
  Amendment = 'AMENDMENT',
  NewSale = 'NEW_SALE',
  Renewal = 'RENEWAL'
}

export enum ReasonableAccommodationEnum {
  FiveHundredDollars = 'FIVE_HUNDRED_DOLLARS',
  FiveThousandDollars = 'FIVE_THOUSAND_DOLLARS',
  None = 'NONE',
  TwoThousandDollars = 'TWO_THOUSAND_DOLLARS'
}

export enum RecurringDisabilityEnum {
  ThirtyDays = 'THIRTY_DAYS'
}

export type RecurringPayment = MonthlyRecurringPayment | WeeklyRecurringPayment;

export type RecurringPaymentDetails = {
  __typename?: 'RecurringPaymentDetails';
  frequency?: Maybe<RecurringPaymentFrequencyType>;
  nextPaymentDate?: Maybe<Scalars['Date']['output']>;
  renewalDay: Scalars['String']['output'];
  renewalType: Scalars['String']['output'];
};

export enum RecurringPaymentFrequencyType {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type RegularSurvivorBenefit = {
  __typename?: 'RegularSurvivorBenefit';
  survivorBenefitId: Scalars['String']['output'];
  survivorBenefitPayees: Array<Maybe<SurvivorBenefitPayee>>;
};

export type RegularSurvivorBenefitInput = {
  partyAssociateId: Scalars['String']['input'];
  survivorBenefitPayeeId?: InputMaybe<Scalars['String']['input']>;
};

export enum Relationship {
  Child = 'CHILD',
  DomesticPartner = 'DOMESTIC_PARTNER',
  Spouse = 'SPOUSE'
}

export enum RelationshipType {
  Child = 'CHILD',
  Myself = 'MYSELF',
  Spouse = 'SPOUSE'
}

export enum RemoveBenefitDecisionReasonType {
  ApprovedInError = 'APPROVED_IN_ERROR',
  DeniedInError = 'DENIED_IN_ERROR',
  Litigation = 'LITIGATION',
  MandatoryAppeal = 'MANDATORY_APPEAL',
  ReversalOfDecision = 'REVERSAL_OF_DECISION',
  VoluntaryAppeal = 'VOLUNTARY_APPEAL'
}

export enum RetireesCoveredEnum {
  No = 'NO',
  YesFuturesOnly = 'YES_FUTURES_ONLY',
  YesInitialAndFuture = 'YES_INITIAL_AND_FUTURE',
  YesInitialOnly = 'YES_INITIAL_ONLY'
}

export enum RetirementPlanSupplementEnum {
  EightPercent = 'EIGHT_PERCENT',
  FivePercent = 'FIVE_PERCENT',
  FourPercent = 'FOUR_PERCENT',
  NinePercent = 'NINE_PERCENT',
  None = 'NONE',
  OnePercent = 'ONE_PERCENT',
  SevenPercent = 'SEVEN_PERCENT',
  SixPercent = 'SIX_PERCENT',
  TenPercent = 'TEN_PERCENT',
  ThreePercent = 'THREE_PERCENT',
  TwoPercent = 'TWO_PERCENT'
}

export enum ReturnToWorkChildcareEnum {
  EightHundredDollars = 'EIGHT_HUNDRED_DOLLARS',
  FiveHundredDollars = 'FIVE_HUNDRED_DOLLARS',
  FiveHundredFiftyDollars = 'FIVE_HUNDRED_FIFTY_DOLLARS',
  FourHundredDollars = 'FOUR_HUNDRED_DOLLARS',
  FourHundredFiftyDollars = 'FOUR_HUNDRED_FIFTY_DOLLARS',
  None = 'NONE',
  OneHundredDollars = 'ONE_HUNDRED_DOLLARS',
  OneHundredFiftyDollars = 'ONE_HUNDRED_FIFTY_DOLLARS',
  SevenHundredDollars = 'SEVEN_HUNDRED_DOLLARS',
  SevenHundredFiftyDollars = 'SEVEN_HUNDRED_FIFTY_DOLLARS',
  SixHundredDollars = 'SIX_HUNDRED_DOLLARS',
  SixHundredFiftyDollars = 'SIX_HUNDRED_FIFTY_DOLLARS',
  ThreeHundredDollars = 'THREE_HUNDRED_DOLLARS',
  ThreeHundredFiftyDollars = 'THREE_HUNDRED_FIFTY_DOLLARS',
  TwoHundredDollars = 'TWO_HUNDRED_DOLLARS',
  TwoHundredFiftyDollars = 'TWO_HUNDRED_FIFTY_DOLLARS'
}

export enum RevisionType {
  Add = 'ADD',
  Del = 'DEL',
  Mod = 'MOD'
}

export type Role = {
  __typename?: 'Role';
  privileges: Array<Maybe<Privilege>>;
  roleId: Scalars['String']['output'];
  type: RoleType;
};

export enum RoleType {
  Accounting = 'ACCOUNTING',
  Analyst = 'ANALYST',
  Assistant = 'ASSISTANT',
  BusinessOperationsSupport = 'BUSINESS_OPERATIONS_SUPPORT',
  CallCenter = 'CALL_CENTER',
  Consultant = 'CONSULTANT',
  Leader = 'LEADER',
  SeniorClaimAccountManager = 'SENIOR_CLAIM_ACCOUNT_MANAGER'
}

export enum SalaryChangeEffectiveDateEnum {
  CalendarMonth = 'CALENDAR_MONTH',
  FifteenthOfCoincidingMonthSlashFollowingMonth = 'FIFTEENTH_OF_COINCIDING_MONTH_SLASH_FOLLOWING_MONTH',
  FirstOfCoincidingMonth = 'FIRST_OF_COINCIDING_MONTH',
  PolicyAnniversary = 'POLICY_ANNIVERSARY',
  Standard = 'STANDARD'
}

export enum ScreeningType {
  AnnualPhysical = 'ANNUAL_PHYSICAL',
  AnyOtherCancerScreening = 'ANY_OTHER_CANCER_SCREENING',
  BoneDensityScreening = 'BONE_DENSITY_SCREENING',
  BoneMarrowCancerScreening = 'BONE_MARROW_CANCER_SCREENING',
  BreastCancerScreening = 'BREAST_CANCER_SCREENING',
  CardiacStressTest = 'CARDIAC_STRESS_TEST',
  CervicalCancerScreening = 'CERVICAL_CANCER_SCREENING',
  ChestXRay = 'CHEST_X_RAY',
  ColorectalCancerScreening = 'COLORECTAL_CANCER_SCREENING',
  CompletionOfASmokingCessationProgram = 'COMPLETION_OF_A_SMOKING_CESSATION_PROGRAM',
  CompletionOfAWeightReductionProgram = 'COMPLETION_OF_A_WEIGHT_REDUCTION_PROGRAM',
  CovidTesting = 'COVID_TESTING',
  DopplerScreening = 'DOPPLER_SCREENING',
  GeneticScreeningTest = 'GENETIC_SCREENING_TEST',
  HumanPapillomavirusScreening = 'HUMAN_PAPILLOMAVIRUS_SCREENING',
  HumanPapillomavirusVaccine = 'HUMAN_PAPILLOMAVIRUS_VACCINE',
  Immunization = 'IMMUNIZATION',
  MentalHealthAssessment = 'MENTAL_HEALTH_ASSESSMENT',
  OvarianCancerScreening = 'OVARIAN_CANCER_SCREENING',
  ProstateCancerScreening = 'PROSTATE_CANCER_SCREENING',
  SkinCancerScreening = 'SKIN_CANCER_SCREENING',
  StandardBloodChemistryProfile = 'STANDARD_BLOOD_CHEMISTRY_PROFILE',
  TestForGeneticSusceptibilityForTheRiskOfCancer = 'TEST_FOR_GENETIC_SUSCEPTIBILITY_FOR_THE_RISK_OF_CANCER',
  UltrasoundScreeningOfAbdominalAorta = 'ULTRASOUND_SCREENING_OF_ABDOMINAL_AORTA',
  VisionTesting = 'VISION_TESTING'
}

export type SearchClaimFilter = {
  claimIdFilter?: InputMaybe<Scalars['String']['input']>;
  enterpriseIdFilter?: InputMaybe<Scalars['String']['input']>;
  governmentAssignedIdFilter?: InputMaybe<Scalars['String']['input']>;
  personFilter?: InputMaybe<PersonFilter>;
  privacyIdFilter?: InputMaybe<Scalars['String']['input']>;
};

export type SearchClaimResult = {
  __typename?: 'SearchClaimResult';
  benefitTypes?: Maybe<Array<Maybe<BenefitType>>>;
  claimDate?: Maybe<Scalars['Date']['output']>;
  claimId?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  enterpriseId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  partyId?: Maybe<Scalars['String']['output']>;
  policyNumber?: Maybe<Scalars['String']['output']>;
};

export type SearchIncidentClaim = {
  __typename?: 'SearchIncidentClaim';
  claimDate?: Maybe<Scalars['Date']['output']>;
  claimId: Scalars['String']['output'];
  claimStatusType: ClaimStatusType;
  coveredPerson: SearchIncidentCoveredPerson;
  policyNumber?: Maybe<Scalars['String']['output']>;
};

export type SearchIncidentCoveredPerson = {
  __typename?: 'SearchIncidentCoveredPerson';
  coveredPersonType: CoveredPersonType;
  dateOfBirth: Scalars['Date']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  nameId?: Maybe<Scalars['Int']['output']>;
};

export type SearchIncidentResult = {
  __typename?: 'SearchIncidentResult';
  claims: Array<Maybe<SearchIncidentClaim>>;
  incidentId: Scalars['Int']['output'];
};

export type SearchTasksSortByInput = {
  assignedTo?: InputMaybe<Sort>;
  claim?: InputMaybe<Sort>;
  created?: InputMaybe<Sort>;
  createdBy?: InputMaybe<Sort>;
  lastModified?: InputMaybe<Sort>;
  status?: InputMaybe<Sort>;
  summary?: InputMaybe<Sort>;
  targetDate?: InputMaybe<Sort>;
};

export type SearchUserClaimsFilter = {
  assignedUserId?: InputMaybe<Scalars['String']['input']>;
  claimId?: InputMaybe<Scalars['String']['input']>;
  claimStatus?: InputMaybe<ClaimStatusType>;
  claimType?: InputMaybe<ClaimType>;
  unassigned?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SearchUserClaimsSortByInput = {
  assignedToUserDisplayName?: InputMaybe<Sort>;
  claimId?: InputMaybe<Sort>;
  claimStatus?: InputMaybe<Sort>;
  notificationDate?: InputMaybe<Sort>;
};

export type SecondaryBenefitDecision = Auditable & {
  __typename?: 'SecondaryBenefitDecision';
  benefitDecisionId: Scalars['String']['output'];
  benefitId: Scalars['String']['output'];
  completed: Scalars['Boolean']['output'];
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  decisionMadeByUserId?: Maybe<Scalars['String']['output']>;
  decisionMadeByUserName?: Maybe<Scalars['String']['output']>;
  decisionType: DecisionType;
  denialReason?: Maybe<DenialReasonType>;
  effectiveDate: Scalars['Date']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  note: Note;
  overrideType?: Maybe<OverrideType>;
  review?: Maybe<BenefitReview>;
  secondaryDecisionType: SecondaryBenefitDecisionType;
};

export type SecondaryBenefitDecisionInput = {
  completed: Scalars['Boolean']['input'];
  decisionType: DecisionType;
  denialReason?: InputMaybe<DenialReasonType>;
  effectiveDate: Scalars['Date']['input'];
  note: Scalars['String']['input'];
  secondaryDecisionType: SecondaryBenefitDecisionType;
};

export enum SecondaryBenefitDecisionType {
  AnyAndAll = 'ANY_AND_ALL',
  PostApproval = 'POST_APPROVAL'
}

export enum ServiceLength {
  LessThanTwelveMonths = 'LESS_THAN_TWELVE_MONTHS',
  OverTwentyFourMonths = 'OVER_TWENTY_FOUR_MONTHS',
  TwelveToTwentyFourMonths = 'TWELVE_TO_TWENTY_FOUR_MONTHS'
}

export enum ServiceLocation {
  DesMoines = 'DES_MOINES',
  GrandIsland = 'GRAND_ISLAND'
}

export type Servicer = {
  __typename?: 'Servicer';
  effectiveDate: Scalars['String']['output'];
  person?: Maybe<Person>;
  status: ActiveOrTerminatedStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ServicerType>;
};

export enum ServicerType {
  AccountExecutive = 'ACCOUNT_EXECUTIVE',
  Administration = 'ADMINISTRATION',
  Billing = 'BILLING',
  Contracts = 'CONTRACTS',
  EbeContact = 'EBE_CONTACT',
  EfileImplementation = 'EFILE_IMPLEMENTATION',
  EfileProduction = 'EFILE_PRODUCTION',
  NewCase = 'NEW_CASE',
  RelationshipManager = 'RELATIONSHIP_MANAGER',
  ScheduleA = 'SCHEDULE_A',
  Security = 'SECURITY',
  SelfAndStdAdministrator = 'SELF_AND_STD_ADMINISTRATOR',
  Underwriting = 'UNDERWRITING'
}

export type ServicingHistory = {
  __typename?: 'ServicingHistory';
  effectiveDate?: Maybe<Scalars['String']['output']>;
  percent?: Maybe<Scalars['Int']['output']>;
  terminationDate?: Maybe<Scalars['String']['output']>;
};

export type ShortTermDisabilityBenefitDetail = {
  __typename?: 'ShortTermDisabilityBenefitDetail';
  benefitEndDate?: Maybe<Scalars['Date']['output']>;
  benefitEndDateHelperText: Scalars['String']['output'];
  benefitEndDateOverride?: Maybe<Scalars['Date']['output']>;
  benefitEndDateOverrideReason?: Maybe<Scalars['String']['output']>;
  benefitEndDateOverrideReasonType?: Maybe<Scalars['String']['output']>;
  benefitId: Scalars['String']['output'];
  benefitPeriods?: Maybe<Array<Maybe<BenefitPeriod>>>;
  benefitStartDate?: Maybe<Scalars['Date']['output']>;
  benefitStartDateHelperText: Scalars['String']['output'];
  benefitWithholdings?: Maybe<Array<Maybe<BenefitWithholding>>>;
  calculatedBenefitEndDate?: Maybe<Scalars['Date']['output']>;
  claimId: Scalars['String']['output'];
  conditionType?: Maybe<ConditionType>;
  daysWorked?: Maybe<Array<Maybe<DayOfWeek>>>;
  earnings?: Maybe<DisabilityEarningsDetail>;
  eliminationPeriod?: Maybe<Scalars['Int']['output']>;
  employerContributionPercentage?: Maybe<Scalars['Int']['output']>;
  enterpriseId?: Maybe<Scalars['String']['output']>;
  eobRemark?: Maybe<EobRemark>;
  fica?: Maybe<FicaFinalPaymentDetail>;
  firstHospitalizationStartDate?: Maybe<Scalars['Date']['output']>;
  futurePayments: Array<Maybe<FuturePayment>>;
  hasBonusUp?: Maybe<Scalars['Boolean']['output']>;
  hasCoverage: Scalars['Boolean']['output'];
  hasFinalizedApproval: Scalars['Boolean']['output'];
  hasFirstDayHospital?: Maybe<Scalars['Boolean']['output']>;
  hasWorkIncentive?: Maybe<Scalars['Boolean']['output']>;
  incurredDate?: Maybe<Scalars['Date']['output']>;
  lumpSumPayoutAmount?: Maybe<Scalars['BigDecimal']['output']>;
  maternityType?: Maybe<StdMaternityType>;
  maximumBenefitPeriod?: Maybe<Scalars['Int']['output']>;
  medicareTaxExempt?: Maybe<Scalars['Boolean']['output']>;
  otherIncome?: Maybe<Array<Maybe<OtherIncome>>>;
  paymentPeriodType?: Maybe<PaymentPeriodType>;
  paymentPeriods?: Maybe<Array<Maybe<PaymentPeriod>>>;
  payments?: Maybe<Array<Maybe<Payment>>>;
  planType?: Maybe<Scalars['String']['output']>;
  premiumPaymentType: PremiumPaymentType;
  primaryBenefit?: Maybe<PrimaryBenefitDetail>;
  proofOfLossDate?: Maybe<Scalars['Date']['output']>;
  recurringDisability?: Maybe<Scalars['String']['output']>;
  recurringPaymentDetails?: Maybe<RecurringPaymentDetails>;
  shouldPayDisabilityBenefitAsLumpSum?: Maybe<Scalars['Boolean']['output']>;
  socialSecurityTaxExempt?: Maybe<Scalars['Boolean']['output']>;
  stdTerminationReason?: Maybe<StdBenefitTerminationType>;
  survivorBenefit?: Maybe<SurvivorBenefitDetail>;
  terminationDate?: Maybe<Scalars['Date']['output']>;
};

export type ShortTermDisabilityCalculationTemplate = {
  __typename?: 'ShortTermDisabilityCalculationTemplate';
  calculationType?: Maybe<StdPlanType>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  flatBenefitAmount?: Maybe<Scalars['BigDecimal']['output']>;
  hasFlatBenefitSixtySixAndTwoThirdsLimit?: Maybe<Scalars['Boolean']['output']>;
  requiresPreDisabilityEarningsCalculation?: Maybe<Scalars['Boolean']['output']>;
};

export type ShortTermDisabilityConfiguration = PolicyCoverageConfiguration & {
  __typename?: 'ShortTermDisabilityConfiguration';
  accidentEliminationPeriod: Scalars['Int']['output'];
  amountType?: Maybe<AmountTypeEnum>;
  benefitPercent?: Maybe<Scalars['Float']['output']>;
  contributory?: Maybe<Scalars['Boolean']['output']>;
  definitionOfDisability: DefinitionOfDisabilityEnum;
  definitionOfEarnings?: Maybe<DefinitionOfEarningsEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  employerContributoryPercentage?: Maybe<Scalars['Int']['output']>;
  firstDayHospital: Scalars['Boolean']['output'];
  flatAmount?: Maybe<Scalars['Int']['output']>;
  fortyHourWorkWeek: Scalars['Boolean']['output'];
  fullCapacity: Scalars['Boolean']['output'];
  incrementAmount?: Maybe<Scalars['Int']['output']>;
  mandatoryRehabilitation: Scalars['Boolean']['output'];
  maternity: MaternityEnum;
  maximumBenefit?: Maybe<Scalars['Int']['output']>;
  maximumBenefitPeriod: Scalars['Int']['output'];
  memberGroupId: Scalars['String']['output'];
  minimumBenefit?: Maybe<Scalars['Int']['output']>;
  minimumWeeklyHours?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<ProductName>;
  nationalEconomy: Scalars['Boolean']['output'];
  openEnrollment: OpenEnrollmentType;
  partialCalculationMethod: PartialCalculationMethodEnum;
  paymentPeriod: PaymentPeriodEnum;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  preExistingConditions?: Maybe<PreExistingConditionType>;
  productShortName: Scalars['String']['output'];
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  reasonableAccommodation: ReasonableAccommodationEnum;
  recurringDisability: RecurringDisabilityEnum;
  rehabilitationIncentive: Scalars['Boolean']['output'];
  salaryContinuanceOffset: Scalars['Boolean']['output'];
  searchDate: Scalars['String']['output'];
  sicknessEliminationPeriod: Scalars['Int']['output'];
  sixtySixPercentLimit?: Maybe<Scalars['Boolean']['output']>;
  statePlanIntegration: Scalars['Boolean']['output'];
  survivorBenefit: StdSurvivorBenefitEnum;
  terminationDate?: Maybe<Scalars['String']['output']>;
  twentyFourHourCoverage: Scalars['Boolean']['output'];
  voluntary: Scalars['Boolean']['output'];
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
  workIncentive: Scalars['Boolean']['output'];
  workersCompensationParticipation: Scalars['Boolean']['output'];
};

export type ShortTermDisabilityPolicyCoverage = PolicyCoverage & {
  __typename?: 'ShortTermDisabilityPolicyCoverage';
  actualParticipationPercentage?: Maybe<Scalars['Int']['output']>;
  /**
   * Amount Type describes the benefit amount.  Previously planType..
   * @deprecated use configuration.amountType
   */
  amountType?: Maybe<AmountTypeEnum>;
  benefitPlanYear?: Maybe<Scalars['String']['output']>;
  bookId?: Maybe<Scalars['String']['output']>;
  configuration: ShortTermDisabilityConfiguration;
  /** @deprecated use configuration.contributory */
  contributoryType?: Maybe<ContributoryTypeEnum>;
  coverageHistory: Array<PolicyCoverageHistory>;
  /** @deprecated use configuration.definitionOfEarnings */
  definitionOfEarnings?: Maybe<DefinitionOfEarningsEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.employeeTerminationDateRule */
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  /** @deprecated use configuration.employerContributoryPercentage */
  employerContributoryPercentage?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use configuration.incrementAmount */
  incrementAmount?: Maybe<Scalars['Int']['output']>;
  isBonusUp?: Maybe<Scalars['Boolean']['output']>;
  isSelfFunded?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated use configuration.maximumBenefit */
  maximumBenefit?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  /** @deprecated use configuration.minimumBenefit */
  minimumBenefit?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use configuration.minimumWeeklyHours */
  minimumWeeklyHours?: Maybe<Scalars['Int']['output']>;
  name: ProductName;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  priorCarrier?: Maybe<PriorCarrier>;
  productShortName: Scalars['String']['output'];
  /** @deprecated use configuration.rateGuaranteeEndDate */
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.rateGuaranteePeriod */
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  /** @deprecated use configuration.rateGuaranteeReason */
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  ratingPlanYear?: Maybe<Scalars['String']['output']>;
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.waitingPeriod */
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
};

export type SicCode = {
  __typename?: 'SicCode';
  code: Scalars['String']['output'];
  effectiveDate: Scalars['String']['output'];
  terminationDate?: Maybe<Scalars['String']['output']>;
};

export type SocialSecurity = {
  __typename?: 'SocialSecurity';
  awardLetterSupportingDocumentId?: Maybe<Scalars['String']['output']>;
  awardLetterSupportingDocumentLink?: Maybe<Scalars['String']['output']>;
  benefitAmount?: Maybe<Scalars['BigDecimal']['output']>;
  consent?: Maybe<SocialSecurityConsent>;
  dateLastInsured?: Maybe<Scalars['Date']['output']>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  decisions: Array<Maybe<SocialSecurityDecision>>;
  disabilityInsuranceEligibilityDate?: Maybe<Scalars['Date']['output']>;
  earlyRetirementEligibilityDate?: Maybe<Scalars['Date']['output']>;
  normalRetirementDate?: Maybe<Scalars['Date']['output']>;
  reimbursementAgreement?: Maybe<SocialSecurityReimbursementAgreement>;
  socialSecurityId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<SocialSecurityStatusType>;
};

export enum SocialSecurityApprovalType {
  AdministrativeHearing = 'ADMINISTRATIVE_HEARING',
  AppealsCouncil = 'APPEALS_COUNCIL',
  FederalCourtReview = 'FEDERAL_COURT_REVIEW',
  InitialDetermination = 'INITIAL_DETERMINATION',
  RequestForReconsideration = 'REQUEST_FOR_RECONSIDERATION'
}

export type SocialSecurityAwardLetterInput = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  supportingDocumentId?: InputMaybe<Scalars['String']['input']>;
};

export type SocialSecurityConsent = {
  __typename?: 'SocialSecurityConsent';
  signatureDate?: Maybe<Scalars['Date']['output']>;
  supportingDocumentId?: Maybe<Scalars['String']['output']>;
  supportingDocumentLink?: Maybe<Scalars['String']['output']>;
};

export type SocialSecurityConsentInput = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  signatureDate: Scalars['Date']['input'];
  supportingDocumentId?: InputMaybe<Scalars['String']['input']>;
};

export type SocialSecurityDecision = {
  __typename?: 'SocialSecurityDecision';
  applicationDate?: Maybe<Scalars['Date']['output']>;
  decisionDate?: Maybe<Scalars['Date']['output']>;
  decisionType?: Maybe<SocialSecurityDecisionType>;
  socialSecurityDecisionId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<SocialSecurityApprovalType>;
};

export type SocialSecurityDecisionInput = {
  applicationDate: Scalars['Date']['input'];
  decisionDate?: InputMaybe<Scalars['Date']['input']>;
  decisionType?: InputMaybe<SocialSecurityDecisionType>;
  type: SocialSecurityApprovalType;
};

export enum SocialSecurityDecisionType {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Pending = 'PENDING'
}

export type SocialSecurityInput = {
  benefitAmount?: InputMaybe<Scalars['BigDecimal']['input']>;
  status?: InputMaybe<SocialSecurityStatusType>;
};

export enum SocialSecurityIntegrationEnum {
  None = 'NONE',
  PrimaryAndFamily = 'PRIMARY_AND_FAMILY',
  PrimaryOnly = 'PRIMARY_ONLY'
}

export type SocialSecurityReimbursementAgreement = {
  __typename?: 'SocialSecurityReimbursementAgreement';
  shouldReducePayments?: Maybe<Scalars['Boolean']['output']>;
  supportingDocumentId?: Maybe<Scalars['String']['output']>;
  supportingDocumentLink?: Maybe<Scalars['String']['output']>;
};

export type SocialSecurityReimbursementAgreementInput = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  shouldReducePayments: Scalars['Boolean']['input'];
  supportingDocumentId?: InputMaybe<Scalars['String']['input']>;
};

export enum SocialSecurityStatusType {
  NotOffsettingReceivingSsrPriorToDisability = 'NOT_OFFSETTING_RECEIVING_SSR_PRIOR_TO_DISABILITY',
  SsdiApprovedAmountConfirmed = 'SSDI_APPROVED_AMOUNT_CONFIRMED',
  SsdiApprovedAmountEstimated = 'SSDI_APPROVED_AMOUNT_ESTIMATED',
  SsdiApprovedWorkingPartTime = 'SSDI_APPROVED_WORKING_PART_TIME',
  SsdiDeclinedNotEligibleDueToDisability = 'SSDI_DECLINED_NOT_ELIGIBLE_DUE_TO_DISABILITY',
  SsdiDeclinedNotEnoughWorkCredits = 'SSDI_DECLINED_NOT_ENOUGH_WORK_CREDITS',
  SsdiEstimating = 'SSDI_ESTIMATING',
  SsdiFiledDecisionPending = 'SSDI_FILED_DECISION_PENDING',
  SsdiNotEligibleWorkingPartTime = 'SSDI_NOT_ELIGIBLE_WORKING_PART_TIME',
  SsdiPendingNotYetApplied = 'SSDI_PENDING_NOT_YET_APPLIED',
  SsrApprovedAmountConfirmed = 'SSR_APPROVED_AMOUNT_CONFIRMED',
  SsrApprovedAmountEstimated = 'SSR_APPROVED_AMOUNT_ESTIMATED'
}

export enum Sort {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export enum SpecialConditionsEnum {
  SameAsAnyOther = 'SAME_AS_ANY_OTHER',
  ThirtySixMonths = 'THIRTY_SIX_MONTHS',
  TwelveMonths = 'TWELVE_MONTHS',
  TwentyFourMonths = 'TWENTY_FOUR_MONTHS'
}

export enum SpouseRatedOn {
  MemberAge = 'MEMBER_AGE',
  SpouseAge = 'SPOUSE_AGE'
}

export enum SpouseRehabilitationEnum {
  FiveHundredDollars = 'FIVE_HUNDRED_DOLLARS',
  None = 'NONE',
  OneThousandDollars = 'ONE_THOUSAND_DOLLARS'
}

export enum StateAbbreviation {
  Aa = 'AA',
  Ae = 'AE',
  Ak = 'AK',
  Al = 'AL',
  Ap = 'AP',
  Ar = 'AR',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY'
}

export enum StdBenefitEndDateOverrideReasonType {
  RecurringDisability = 'RECURRING_DISABILITY'
}

export type StdBenefitTerminationInput = {
  terminationDate: Scalars['Date']['input'];
  terminationReason: StdBenefitTerminationType;
};

export enum StdBenefitTerminationType {
  Compromise = 'COMPROMISE',
  Death = 'DEATH',
  EndOfApprovedActivityPeriodNoReturnToWork = 'END_OF_APPROVED_ACTIVITY_PERIOD_NO_RETURN_TO_WORK',
  EndOfBenefitsPostpartum = 'END_OF_BENEFITS_POSTPARTUM',
  ExhaustionOfBenefits = 'EXHAUSTION_OF_BENEFITS',
  LumpSum = 'LUMP_SUM',
  ReturnedToWorkForGroupPolicyholder = 'RETURNED_TO_WORK_FOR_GROUP_POLICYHOLDER',
  Settlement = 'SETTLEMENT'
}

export enum StdDenialBeyondBenefitTerminationType {
  PolicyRequirementsNotSatisfied = 'POLICY_REQUIREMENTS_NOT_SATISFIED',
  ReturnToWorkForNewEmployerWithNoLossOfIncome = 'RETURN_TO_WORK_FOR_NEW_EMPLOYER_WITH_NO_LOSS_OF_INCOME',
  StdPreExistingBenefitsPaid = 'STD_PRE_EXISTING_BENEFITS_PAID'
}

export type StdEarnings = {
  __typename?: 'StdEarnings';
  amount?: Maybe<Scalars['BigDecimal']['output']>;
  timePeriod?: Maybe<DisabilityEarningsPeriod>;
};

export enum StdMaternityType {
  Complications = 'COMPLICATIONS',
  FullMaternity = 'FULL_MATERNITY',
  FullMaternityAfterTwelveMonths = 'FULL_MATERNITY_AFTER_TWELVE_MONTHS',
  None = 'NONE',
  TwelveWeekMaternity = 'TWELVE_WEEK_MATERNITY'
}

export enum StdPlanType {
  FlatBenefit = 'FLAT_BENEFIT',
  Increment = 'INCREMENT',
  PercentOfSalary = 'PERCENT_OF_SALARY'
}

export enum StdSurvivorBenefitEnum {
  None = 'NONE',
  ThreeWeeksGross = 'THREE_WEEKS_GROSS'
}

export type Subscription = {
  __typename?: 'Subscription';
  appInfo?: Maybe<AppInfo>;
};

export type SurvivorBenefit = AcceleratedSurvivorBenefit | RegularSurvivorBenefit;

export type SurvivorBenefitDetail = {
  __typename?: 'SurvivorBenefitDetail';
  configuration?: Maybe<SurvivorBenefit>;
  policy: SurvivorBenefitPolicy;
};

export enum SurvivorBenefitEnum {
  None = 'NONE',
  SixMonthsGross = 'SIX_MONTHS_GROSS',
  SixMonthsNet = 'SIX_MONTHS_NET',
  ThreeMonthsGross = 'THREE_MONTHS_GROSS',
  ThreeMonthsNet = 'THREE_MONTHS_NET'
}

export type SurvivorBenefitInput = {
  payees?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: SurvivorBenefitType;
};

export type SurvivorBenefitPayee = {
  __typename?: 'SurvivorBenefitPayee';
  partyAssociate: PartyAssociate;
  payment?: Maybe<Payment>;
  survivorBenefitPayeeId: Scalars['String']['output'];
};

export type SurvivorBenefitPolicy = {
  __typename?: 'SurvivorBenefitPolicy';
  isAccelerated?: Maybe<Scalars['Boolean']['output']>;
  survivorBenefitType?: Maybe<DisabilitySurvivorBenefitType>;
};

export enum SurvivorBenefitType {
  Accelerated = 'ACCELERATED',
  Regular = 'REGULAR'
}

export type Task = {
  __typename?: 'Task';
  benefitId?: Maybe<Scalars['String']['output']>;
  claimId: Scalars['String']['output'];
  completed: Scalars['Boolean']['output'];
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  summary: Scalars['String']['output'];
  targetDate: Scalars['Date']['output'];
  taskId: Scalars['String']['output'];
  type: TaskType;
  userActive?: Maybe<Scalars['Boolean']['output']>;
  userDisplayName?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type TaskConnection = {
  __typename?: 'TaskConnection';
  edges?: Maybe<Array<Maybe<TaskEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalElements: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type TaskEdge = {
  __typename?: 'TaskEdge';
  cursor: Scalars['String']['output'];
  node: Task;
};

export type TaskInput = {
  completed: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  summary: Scalars['String']['input'];
  targetDate: Scalars['Date']['input'];
  type: TaskType;
};

export type TaskRevision = {
  __typename?: 'TaskRevision';
  description?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedDate?: Maybe<Scalars['DateTime']['output']>;
  task?: Maybe<Task>;
};

export type TaskSearchFilter = {
  assignedUserId?: InputMaybe<Scalars['String']['input']>;
  claimId?: InputMaybe<Scalars['String']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  targetDate?: InputMaybe<DateComparisonFilter>;
  type?: InputMaybe<TaskType>;
  unassigned?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TaskType {
  Appeal = 'APPEAL',
  DeleteDocument = 'DELETE_DOCUMENT',
  Eft = 'EFT',
  FollowUp = 'FOLLOW_UP',
  Medical = 'MEDICAL',
  Miscellaneous = 'MISCELLANEOUS',
  NewClaim = 'NEW_CLAIM',
  NewInformation = 'NEW_INFORMATION',
  Overpayment = 'OVERPAYMENT',
  Pend = 'PEND',
  Review = 'REVIEW',
  ReEvaluateClaim = 'RE_EVALUATE_CLAIM'
}

export enum TaxBenefitEnum {
  NotApplicable = 'NOT_APPLICABLE',
  TenPercent = 'TEN_PERCENT',
  TwentyPercent = 'TWENTY_PERCENT'
}

export enum TaxType {
  Federal = 'FEDERAL',
  Medicare = 'MEDICARE',
  SocialSecurity = 'SOCIAL_SECURITY',
  StateTransit = 'STATE_TRANSIT',
  StateUnemployment = 'STATE_UNEMPLOYMENT'
}

export type TherapyAndFacilityProvision = {
  __typename?: 'TherapyAndFacilityProvision';
  amount?: Maybe<Scalars['Int']['output']>;
  daysPerLifetime?: Maybe<Scalars['Int']['output']>;
  daysPerYear?: Maybe<Scalars['Int']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
};

export type TieredProvisionType = {
  __typename?: 'TieredProvisionType';
  amount?: Maybe<Scalars['Int']['output']>;
  daysPerLifetime?: Maybe<Scalars['Int']['output']>;
  daysPerYear?: Maybe<Scalars['Int']['output']>;
  included?: Maybe<Scalars['Boolean']['output']>;
  tiers?: Maybe<Array<Maybe<ProvisionTierType>>>;
};

export type UnknownClaimantInput = {
  address?: InputMaybe<AddressInput>;
  claimantDateOfBirth: Scalars['Date']['input'];
  claimantFullName: Scalars['String']['input'];
  coveredPersonDateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  coveredPersonName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UnknownParty = {
  __typename?: 'UnknownParty';
  claims: Array<Maybe<Claim>>;
  detail?: Maybe<UnknownPartyDetail>;
  partyId: Scalars['String']['output'];
};

export type UnknownPartyDetail = {
  __typename?: 'UnknownPartyDetail';
  address?: Maybe<Address>;
  coveredPersonDateOfBirth?: Maybe<Scalars['Date']['output']>;
  coveredPersonName?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  policyNumber?: Maybe<Scalars['String']['output']>;
  unknownPartyDetailId: Scalars['String']['output'];
};

export type UpdateClaimBenefit = {
  employerContributionPercent?: InputMaybe<Scalars['Int']['input']>;
  premiumPaymentType?: InputMaybe<PremiumPaymentType>;
};

export type UpdateClaimInput = {
  benefit?: InputMaybe<UpdateClaimBenefit>;
  claimId: Scalars['String']['input'];
  createTask?: InputMaybe<Scalars['Boolean']['input']>;
  incurredDate?: InputMaybe<Scalars['Date']['input']>;
  medical?: InputMaybe<UpdateClaimMedical>;
  notificationDate?: InputMaybe<Scalars['Date']['input']>;
  occupation?: InputMaybe<UpdateClaimOccupation>;
  updateMethod?: InputMaybe<UpdateMethod>;
};

export type UpdateClaimMedical = {
  competent?: InputMaybe<Scalars['Boolean']['input']>;
  conditionType?: InputMaybe<ConditionType>;
  medicalId: Scalars['String']['input'];
  screeningType?: InputMaybe<ScreeningType>;
  workRelated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateClaimOccupation = {
  dateLastWorked?: InputMaybe<Scalars['Date']['input']>;
  hasCeasedWorking?: InputMaybe<Scalars['Boolean']['input']>;
  hoursWorkedPerWeek?: InputMaybe<Scalars['BigDecimal']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  ltdOccupationalStrength?: InputMaybe<JobOccupationalStrengthType>;
  occupationId: Scalars['String']['input'];
  stdJobStrength?: InputMaybe<JobOccupationalStrengthType>;
  workedInState?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDiagnosisInput = {
  code: Scalars['String']['input'];
  diagnosisType: DiagnosisType;
  medicalDiagnosisId?: InputMaybe<Scalars['String']['input']>;
  preExistingCondition?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateDocumentInput = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
};

export type UpdateMedicalInput = {
  competent?: InputMaybe<Scalars['Boolean']['input']>;
  conditionType?: InputMaybe<ConditionType>;
  diagnoses: Array<InputMaybe<UpdateDiagnosisInput>>;
  expectedMedicalRecoveryDate?: InputMaybe<Scalars['Date']['input']>;
  firstHospitalizationStartDate?: InputMaybe<Scalars['Date']['input']>;
  pregnancyDeliveryDate?: InputMaybe<Scalars['Date']['input']>;
  screeningType?: InputMaybe<Scalars['String']['input']>;
  workRelated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateMedicationInput = {
  medicalMedicationId?: InputMaybe<Scalars['String']['input']>;
  prescribedByPartyAssociateId: Scalars['String']['input'];
  rxcui: Scalars['String']['input'];
};

export enum UpdateMethod {
  Patch = 'PATCH',
  UpdateNonNulls = 'UPDATE_NON_NULLS'
}

export type UpdateNoteInput = {
  completed: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  direction?: InputMaybe<DirectionType>;
  noteId: Scalars['String']['input'];
  noteType: NoteType;
  value: Scalars['String']['input'];
};

export type UpdateOverpaymentInput = {
  completedReason?: InputMaybe<OverpaymentCompletedReasonType>;
  overpaymentId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

export type UploadDocumentInput = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  claimId: Scalars['String']['input'];
  createTask?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  type: DocumentType;
};

export type User = Auditable & {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  authorityLevels?: Maybe<Array<Maybe<AuthorityLevelUser>>>;
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  emailAddress: Scalars['String']['output'];
  extension?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['DateTime']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  privileges?: Maybe<Array<Maybe<PrivilegeType>>>;
  roles?: Maybe<Array<Maybe<RoleType>>>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  userId: Scalars['String']['output'];
};

export type UserInput = {
  active: Scalars['Boolean']['input'];
  authorityLevelIds: Array<InputMaybe<Scalars['String']['input']>>;
  roles: Array<InputMaybe<RoleType>>;
};

export type UserRecentClaim = {
  __typename?: 'UserRecentClaim';
  claimId: Scalars['String']['output'];
  claimant?: Maybe<UserRecentClaimClaimant>;
  error?: Maybe<Scalars['String']['output']>;
  lastViewed: Scalars['DateTime']['output'];
};

export type UserRecentClaimClaimant = {
  __typename?: 'UserRecentClaimClaimant';
  enterpriseId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  partyId: Scalars['String']['output'];
};

export type ValidateAddressInput = {
  city: Scalars['String']['input'];
  state: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type ValidatedAddress = {
  __typename?: 'ValidatedAddress';
  city: Scalars['String']['output'];
  state: Scalars['String']['output'];
  streetAddress: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type Validation = {
  __typename?: 'Validation';
  name: Scalars['String']['output'];
  note: Scalars['String']['output'];
  parameters: Array<Maybe<ValidationParameter>>;
  pass: Scalars['Boolean']['output'];
  suppress: Scalars['Boolean']['output'];
  suppressionReason?: Maybe<Scalars['String']['output']>;
  type: ValidationType;
  validationId: Scalars['String']['output'];
};

export type ValidationParameter = {
  __typename?: 'ValidationParameter';
  highlight: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  validationParameterId: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum ValidationType {
  Benefit = 'BENEFIT',
  Coverage = 'COVERAGE'
}

export type VisionConfiguration = PolicyCoverageConfiguration & {
  __typename?: 'VisionConfiguration';
  /** Returns true if employee contributes any percentage, and returns false if the employer covers 100% of the cost */
  contributory?: Maybe<Scalars['Boolean']['output']>;
  domesticPartner?: Maybe<DomesticPartnerEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  memberGroupId: Scalars['String']['output'];
  minimumWeeklyHours?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<ProductName>;
  openEnrollment: OpenEnrollmentType;
  planSequenceNumber: Scalars['String']['output'];
  policyId: Scalars['String']['output'];
  priorCoverage?: Maybe<Scalars['Boolean']['output']>;
  productShortName: Scalars['String']['output'];
  provisions: VisionProvisionsType;
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  retireesCovered?: Maybe<RetireesCoveredEnum>;
  searchDate: Scalars['String']['output'];
  terminationDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use ..voluntary */
  voluntary: Scalars['Boolean']['output'];
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
  waiversOnNonContrib?: Maybe<Scalars['Boolean']['output']>;
};

export type VisionDependentPolicyCoverage = PolicyCoverage & {
  __typename?: 'VisionDependentPolicyCoverage';
  bookId?: Maybe<Scalars['String']['output']>;
  contributoryType?: Maybe<ContributoryTypeEnum>;
  coverageHistory: Array<PolicyCoverageHistory>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  maxAge?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  name: ProductName;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  removalGracePeriod?: Maybe<Scalars['Int']['output']>;
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
  terminationDateRule?: Maybe<EffectiveDateRuleEnum>;
};

export type VisionPolicyCoverage = PolicyCoverage & {
  __typename?: 'VisionPolicyCoverage';
  benefitPlanYear?: Maybe<Scalars['String']['output']>;
  bookId?: Maybe<Scalars['String']['output']>;
  configuration: VisionConfiguration;
  /** @deprecated use configuration.contributory */
  contributoryType?: Maybe<ContributoryTypeEnum>;
  coverageHistory: Array<PolicyCoverageHistory>;
  dependent?: Maybe<VisionDependentPolicyCoverage>;
  /** @deprecated use configuration.domesticPartner */
  domesticPartner?: Maybe<DomesticPartnerEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.employeeTerminationDateRule */
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  isSelfFunded?: Maybe<Scalars['Boolean']['output']>;
  memberGroupId: Scalars['String']['output'];
  /** @deprecated use configuration.minimumWeeklyHours */
  minimumWeeklyHours?: Maybe<Scalars['Int']['output']>;
  name: ProductName;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  /** @deprecated use configuration.rateGuaranteeEndDate */
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.rateGuaranteePeriod */
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  /** @deprecated use configuration.rateGuaranteeReason */
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  ratingPlanYear?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.retireesCovered */
  retireesCovered?: Maybe<RetireesCoveredEnum>;
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
  voluntary: Scalars['Boolean']['output'];
  vsnPlanNumber?: Maybe<Scalars['Int']['output']>;
  vspPlanNumber?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use configuration.waitingPeriod */
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
};

export enum VisionProvisionFrequency {
  None = 'NONE',
  TwelveMonths = 'TWELVE_MONTHS',
  TwentyFourMonths = 'TWENTY_FOUR_MONTHS'
}

export type VisionProvisionsType = {
  __typename?: 'VisionProvisionsType';
  bifocalCopay?: Maybe<Copay>;
  bifocalFrequency?: Maybe<VisionProvisionFrequency>;
  bifocalMaxAllowance?: Maybe<Scalars['Int']['output']>;
  contactExamCopay?: Maybe<Copay>;
  contactExamFrequency?: Maybe<VisionProvisionFrequency>;
  contactExamMaxAllowance?: Maybe<Scalars['Int']['output']>;
  contactsCopay?: Maybe<Copay>;
  contactsFrequency?: Maybe<VisionProvisionFrequency>;
  contactsMaxAllowance?: Maybe<Scalars['Int']['output']>;
  examsCopay: Copay;
  examsFrequency: VisionProvisionFrequency;
  examsMaxAllowance?: Maybe<Scalars['Int']['output']>;
  framesCopay?: Maybe<Copay>;
  framesFrequency: VisionProvisionFrequency;
  framesMaxAllowance: Scalars['Int']['output'];
  lensCopay?: Maybe<Copay>;
  lensFrequency: VisionProvisionFrequency;
  lensMaxAllowance?: Maybe<Scalars['Int']['output']>;
  lenticularCopay?: Maybe<Copay>;
  lenticularFrequency?: Maybe<VisionProvisionFrequency>;
  lenticularMaxAllowance?: Maybe<Scalars['Int']['output']>;
  materialsCopay: Copay;
  /** @deprecated Spelling mistake, use materialsCopay instead. */
  materielsCopay: Copay;
  trifocalCopay?: Maybe<Copay>;
  trifocalFrequency?: Maybe<VisionProvisionFrequency>;
  trifocalMaxAllowance?: Maybe<Scalars['Int']['output']>;
};

export type VoluntaryTermLifeConfiguration = PolicyCoverageConfiguration & {
  __typename?: 'VoluntaryTermLifeConfiguration';
  acceleratedBenefits?: Maybe<Scalars['Boolean']['output']>;
  ageReductions?: Maybe<Array<Maybe<AgeReductionType>>>;
  amountType?: Maybe<AmountTypeEnum>;
  contributory?: Maybe<Scalars['Boolean']['output']>;
  coverageType?: Maybe<CoverageTypeEnum>;
  definitionOfEarnings?: Maybe<DefinitionOfEarningsEnum>;
  domesticPartner?: Maybe<DomesticPartnerEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  enrollmentType?: Maybe<EnrollmentTypeEnum>;
  guaranteedIssue70AndOver?: Maybe<Scalars['Int']['output']>;
  guaranteedIssueMultiplier?: Maybe<Scalars['Int']['output']>;
  guaranteedIssueUnder70?: Maybe<Scalars['Int']['output']>;
  incrementAmount?: Maybe<Scalars['Int']['output']>;
  maximumBenefit?: Maybe<Scalars['Int']['output']>;
  maximumMultipleOfSalaryPercentage?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  minimumBenefit?: Maybe<Scalars['Int']['output']>;
  minimumMultipleOfSalaryPercentage?: Maybe<Scalars['Int']['output']>;
  minimumWeeklyHours?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<ProductName>;
  openEnrollment: OpenEnrollmentType;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  portability?: Maybe<PortabilityEnum>;
  productShortName: Scalars['String']['output'];
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  searchDate: Scalars['String']['output'];
  terminationDate?: Maybe<Scalars['String']['output']>;
  voluntary: Scalars['Boolean']['output'];
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
  waiverDefinitionOfDisability?: Maybe<WaiverDefinitionOfDisabilityEnum>;
  waiverDuration?: Maybe<WaiverDurationEnum>;
  waiverEliminationPeriod?: Maybe<WaiverEliminationPeriodEnum>;
  wellnessBenefitAmount?: Maybe<WellnessBenefitAmountEnum>;
};

export type VoluntaryTermLifePolicyCoverage = PolicyCoverage & {
  __typename?: 'VoluntaryTermLifePolicyCoverage';
  accidentalDeathAndDismemberment?: Maybe<AccidentalDeathAndDismembermentPolicyCoverage>;
  actualParticipationPercentage?: Maybe<Scalars['Int']['output']>;
  /**
   * Amount Type describes the benefit amount.  Previously planType..
   * @deprecated use configuration.amountType
   */
  amountType?: Maybe<AmountTypeEnum>;
  benefitPlanYear?: Maybe<Scalars['String']['output']>;
  bookId?: Maybe<Scalars['String']['output']>;
  child?: Maybe<ChildPolicyCoverage>;
  configuration?: Maybe<VoluntaryTermLifeConfiguration>;
  /** @deprecated use configuration.contributory */
  contributoryType?: Maybe<ContributoryTypeEnum>;
  coverageHistory: Array<PolicyCoverageHistory>;
  /** @deprecated use configuration.definitionOfEarnings */
  definitionOfEarnings?: Maybe<DefinitionOfEarningsEnum>;
  /** @deprecated use configuration.domesticPartner */
  domesticPartner?: Maybe<DomesticPartnerEnum>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  eligibleLives?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use configuration.employeeTerminationDateRule */
  employeeTerminationDateRule?: Maybe<EffectiveDateRuleEnum>;
  /** @deprecated use configuration.incrementAmount */
  incrementAmount?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use configuration.maximumBenefit */
  maximumBenefit?: Maybe<Scalars['Int']['output']>;
  /**
   * Highest percent of Salary Benefit Allowed. E.G. 300
   * @deprecated use configuration.maximumMultipleOfSalaryPercentage
   */
  maximumMultipleOfSalaryPercentage?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  /** @deprecated use configuration.minimumBenefit */
  minimumBenefit?: Maybe<Scalars['Int']['output']>;
  /**
   * Lowest percent of Salary Benefit Allowed. E.G. 100
   * @deprecated use configuration.minimumMultipleOfSalaryPercentage
   */
  minimumMultipleOfSalaryPercentage?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use configuration.minimumWeeklyHours */
  minimumWeeklyHours?: Maybe<Scalars['Int']['output']>;
  name: ProductName;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  /** @deprecated use configuration.portability */
  portability?: Maybe<PortabilityEnum>;
  priorCarrier?: Maybe<PriorCarrier>;
  productShortName: Scalars['String']['output'];
  /** @deprecated use configuration.rateGuaranteeEndDate */
  rateGuaranteeEndDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.rateGuaranteePeriod */
  rateGuaranteePeriod?: Maybe<RateGuaranteePeriodEnum>;
  /** @deprecated use configuration.rateGuaranteeReason */
  rateGuaranteeReason?: Maybe<RateGuaranteeReasonEnum>;
  ratingPlanYear?: Maybe<Scalars['String']['output']>;
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  spouse?: Maybe<VoluntaryTermLifeSpousePolicyCoverage>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
  /** @deprecated use configuration.waitingPeriod */
  waitingPeriod?: Maybe<WaitingPeriodParentType>;
  /** @deprecated use configuration.wellnessBenefitAmount */
  wellnessBenefitAmount?: Maybe<WellnessBenefitAmountEnum>;
};

export type VoluntaryTermLifeSpousePolicyCoverage = PolicyCoverage & {
  __typename?: 'VoluntaryTermLifeSpousePolicyCoverage';
  accidentalDeathAndDismemberment?: Maybe<AccidentalDeathAndDismembermentPolicyCoverage>;
  ageReductions?: Maybe<Array<Maybe<AgeReductionType>>>;
  bookId?: Maybe<Scalars['String']['output']>;
  cannotExceedPercentOfEmployee?: Maybe<Scalars['Int']['output']>;
  contributoryType?: Maybe<ContributoryTypeEnum>;
  coverageHistory: Array<PolicyCoverageHistory>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  guaranteedIssue70AndOver?: Maybe<Scalars['Int']['output']>;
  guaranteedIssueAmount?: Maybe<Scalars['Int']['output']>;
  guaranteedIssueUnder70?: Maybe<Scalars['Int']['output']>;
  incrementAmount?: Maybe<Scalars['Int']['output']>;
  maximumBenefit?: Maybe<Scalars['Int']['output']>;
  memberGroupId: Scalars['String']['output'];
  minimumBenefit?: Maybe<Scalars['Int']['output']>;
  name: ProductName;
  planSequenceNumber?: Maybe<Scalars['String']['output']>;
  policyId: Scalars['String']['output'];
  productShortName: Scalars['String']['output'];
  searchDate: Scalars['String']['output'];
  signatureGracePeriod?: Maybe<Scalars['Int']['output']>;
  status: PolicyCoverageStatus;
  terminationDate?: Maybe<Scalars['String']['output']>;
};

export type W2Earnings = {
  __typename?: 'W2Earnings';
  box1?: Maybe<Scalars['BigDecimal']['output']>;
  box5?: Maybe<Scalars['BigDecimal']['output']>;
  section125?: Maybe<Scalars['BigDecimal']['output']>;
  taxYear: Scalars['Int']['output'];
  w2EarningsId: Scalars['String']['output'];
};

export enum W2EarningsCalculationType {
  Box_1 = 'BOX_1',
  Box_1AndSection_125 = 'BOX_1_AND_SECTION_125',
  Box_5 = 'BOX_5',
  Box_5AndSection_125 = 'BOX_5_AND_SECTION_125',
  Default = 'DEFAULT'
}

export type W2EarningsInput = {
  box1?: InputMaybe<Scalars['BigDecimal']['input']>;
  box5?: InputMaybe<Scalars['BigDecimal']['input']>;
  section125?: InputMaybe<Scalars['BigDecimal']['input']>;
  taxYear: Scalars['Int']['input'];
};

export enum WageFrequency {
  Biweekly = 'BIWEEKLY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Semimonthly = 'SEMIMONTHLY',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type WaitingPeriod = {
  __typename?: 'WaitingPeriod';
  effectiveDateRule?: Maybe<EffectiveDateRuleEnum>;
  period?: Maybe<Scalars['Int']['output']>;
  periodDescription?: Maybe<Scalars['String']['output']>;
};

export type WaitingPeriodParentType = {
  __typename?: 'WaitingPeriodParentType';
  future?: Maybe<WaitingPeriod>;
  initial?: Maybe<WaitingPeriod>;
};

export enum WaiverDefinitionOfDisabilityEnum {
  AdlAndTotal = 'ADL_AND_TOTAL',
  None = 'NONE',
  Total = 'TOTAL'
}

export enum WaiverDurationEnum {
  FiveYear = 'FIVE_YEAR',
  None = 'NONE',
  ToAgeSeventy = 'TO_AGE_SEVENTY',
  ToAgeSixtyFive = 'TO_AGE_SIXTY_FIVE',
  TwoYear = 'TWO_YEAR'
}

export enum WaiverEliminationPeriodEnum {
  NineMonths = 'NINE_MONTHS',
  None = 'NONE',
  SixMonths = 'SIX_MONTHS',
  ThreeMonths = 'THREE_MONTHS',
  TwelveMonths = 'TWELVE_MONTHS'
}

export enum WaiverOfPremium {
  NotIncluded = 'NOT_INCLUDED'
}

export type WeeklyRecurringPayment = {
  __typename?: 'WeeklyRecurringPayment';
  dayOfWeek: DayOfWeek;
  nextPaymentDate?: Maybe<Scalars['Date']['output']>;
  recurringPaymentId: Scalars['String']['output'];
};

export enum WellnessBenefitAmountEnum {
  FiftyDollars = 'FIFTY_DOLLARS',
  None = 'NONE',
  NotApplicable = 'NOT_APPLICABLE',
  OneHundredDollars = 'ONE_HUNDRED_DOLLARS',
  OneHundredFiftyDollars = 'ONE_HUNDRED_FIFTY_DOLLARS'
}

export enum WellnessBenefitType {
  AccidentWellness = 'ACCIDENT_WELLNESS',
  CriticalIllnessWellness = 'CRITICAL_ILLNESS_WELLNESS',
  HospitalIndemnityWellness = 'HOSPITAL_INDEMNITY_WELLNESS'
}

export type WellnessProvisionInstance = ProvisionInstanceInterface & {
  __typename?: 'WellnessProvisionInstance';
  payment?: Maybe<Payment>;
  provision: Provision;
  provisionInstanceId: Scalars['String']['output'];
  screeningDate?: Maybe<Scalars['Date']['output']>;
};

export type WellnessProvisionInstanceInput = {
  provisionInstanceId?: InputMaybe<Scalars['String']['input']>;
  provisionType?: InputMaybe<ProvisionType>;
  screeningDate?: InputMaybe<Scalars['Date']['input']>;
};

export type WirePaymentPreference = {
  __typename?: 'WirePaymentPreference';
  active: Scalars['Boolean']['output'];
  bankAccountNumber: Scalars['String']['output'];
  bankAccountType: BankAccountType;
  bankRoutingNumber: Scalars['String']['output'];
  paymentPreferenceId: Scalars['String']['output'];
  valid: Scalars['Boolean']['output'];
};

export type WirePaymentPreferenceInput = {
  bankAccountNumber: Scalars['String']['input'];
  bankAccountType: BankAccountType;
  bankRoutingNumber: Scalars['String']['input'];
};

export enum WorkIncentiveEnum {
  None = 'NONE',
  SixMonths = 'SIX_MONTHS',
  ThreeMonths = 'THREE_MONTHS',
  TwelveMonths = 'TWELVE_MONTHS',
  TwentyFourMonths = 'TWENTY_FOUR_MONTHS'
}

export enum YesNo {
  No = 'NO',
  Yes = 'YES'
}
