import { PdsLayoutContainer, PdsLink, PdsHeading, PdsSidebar } from "@principal/design-system-react";
import React, { ReactElement } from "react";
import emotional from '../images/emotional.png';
import lasik from '../images/lasik.png';
import hearing from '../images/hearing.png';
import travel from '../images/travel.png';
import will from '../images/will.png';
import magellan from '../images/magellan.png';
import { useCoverage } from "../hooks/useCoverage";
import { Coverage } from "../enums/Coverage";
import { Navigate } from "react-router-dom";
import { PdsIconExternalLink } from "@principal/design-system-icons-react";
import hasEmployeeAssistanceProgram = Coverage.hasEmployeeAssistanceProgram;
import VirtualSupplyLink from "../atoms/VirtualSupplyLink";
import { useMember } from "../hooks/useMember";
import { EmployeeAssistanceProgramProvision } from "../enums/EmployeeAssistanceProgramProvision";
import LoadingSpinner from "../atoms/LoadingSpinner";
import { State } from "../enums/State";
import hasTravelAssistance = Coverage.hasTravelAssistance;
import hasWillAndLegalDocumentCenter = Coverage.hasWillAndLegalDocumentCenter;
import findPhoneNumberFor = EmployeeAssistanceProgramProvision.findPhoneNumberFor;

const DiscountsAndServices = () => {
    const { loading: memberLoading, data: member, error: memberError } = useMember()
    const coverage = useCoverage();
    const eapProvision = member?.benefit?.employeeAssistanceProgramProvision;
    const newYorkContract = State.NY === member?.benefit?.contractState

    if(!Coverage.hasResources(coverage)) {
        return <Navigate to={`/${coverage}`} replace />
    }

    return (
        <div id="discounts">
            <PdsLayoutContainer>
                <div className="flex-gap">
                    <PdsHeading headingTag="h2" variant="title-sm">Discounts and services</PdsHeading>
                    <p>Everybody loves a discount! Use these to help improve your life - financially, mentally, and physically. Offered by
                        some of the most trusted companies in the U.S., these discounts and services are available through your group
                        benefits from Principal®. These discounts are not insurance.</p>
                    {buildEmotionalHealthSupportLine()}
                    {buildLaserVisionCorrection()}
                    {buildHearingAidProgram()}
                    {buildTravelAssistance()}
                    {buildWillAndLegalDocumentCenter()}
                    {buildEmployeeAssistanceProgram()}
                    <div>
                        <small>The discounts and services listed here are available to members, and/or their dependents or beneficiaries,
                            with group coverage underwritten by or with administrative services provided by Principal Life Insurance
                            Company. The discounts and services are not a part of the policy or contract and may be changed or discontinued
                            at any time. Although Principal has arranged to make these programs available to you, the third party providers
                            are solely responsible for their products and services.</small>
                    </div>
                </div>
            </PdsLayoutContainer>
        </div>
    );

    function buildEmotionalHealthSupportLine() {
        if (memberLoading) {
            return <LoadingSpinner/>;
        } else if (memberError || !member) {
            console.log('member error', memberError);
            return <p>Could not load member information, please try again later.</p>;
        } else if (newYorkContract) {
            return null;
        }
        return buildDiscount(emotional, "emotional",
            "Emotional Health Support Line",
            "Get help when you're feeling overwhelmed or need support.",
            "You, your spouse, and dependent children can call this free, confidential support line 24 hours a day, 7 days a week to reach licensed behavioral health clinicians who can provide emotional support, tips for coping, and referrals to local resources. If your employer offers an Employee Assistance Program (EAP), use those resources instead.",
            undefined,
            <>
                <PdsLink href="tel:+8004244612">800-424-4612</PdsLink>
            </>)
    }

    function buildLaserVisionCorrection() {
        return buildDiscount(lasik, "lasik",
            "Laser Vision Correction",
            "Imagine your life free from glasses and contacts.",
            "You, your spouse, and dependent children save $800 with featured providers LasikPlus, TLC Laser Eye Centers, or the LASIK Vision Institute, or receive 15% off standard pricing or 5% off promotional pricing on LASIK through the National Lasik Network's 600 locations. Administered by LCA Vision.",
            undefined,
            <>
                <PdsLink button="default" href="https://www.principallasik.com" target="_blank">
                    Laser Vision Correction
                </PdsLink>
                <span className="pds-u-padding-left-8">
                    Or call <PdsLink href="tel:+8886473937">888-647-3937</PdsLink>
                </span>
            </>)
    }

    function buildHearingAidProgram() {
        return buildDiscount(hearing, "hearing",
            "Hearing Aid Program",
            "Protect your hearing health to improve your quality of life.",
            "You, your spouse, children, parents, and grandparents can get discounts up to 48% off hearing aids, including rechargeable and Bluetooth options, with a 60-day trial to ensure full satisfaction. You can also receive a free hearing consultation at any of the 3,000+ locations nationwide. Administered by Start Hearing.",
            undefined,
            <>
                <PdsLink button="default" href="https://www.principal.com/hearingbenefits/ahb" target="_blank">
                    Hearing Aid Program
                </PdsLink>
                <span className="pds-u-padding-left-8">
                    Or call <PdsLink href="tel:+8778904694">877-890-4694</PdsLink>
                </span>
            </>)
    }

    function buildTravelAssistance() {
        if(!hasTravelAssistance(coverage)) {
            return null;
        } else if (memberLoading) {
            return <LoadingSpinner/>;
        } else if (memberError || !member) {
            console.log('member error', memberError);
            return <p>Could not load member information, please try again later.</p>;
        } else if (newYorkContract) {
            return null;
        }
        return buildDiscount(travel, "travel",
            "Travel Assistance",
            "Ease some of the worries of traveling - whether in the U.S. or internationally.",
            "You, your spouse, and dependent children have access to a variety of benefits provided through AXA Assistance. These services include travel and medical assistance plus emergency medical evacuation benefits. Assistance is available for travel 100+ miles away from home for up to 120 consecutive days.",
            <><strong>This program is not insurance.</strong> Travel assistance will be provided as permitted under applicable law.</>,
            <>
                <PdsLink button="default" href="https://www.principal.com/travelassistance" target="_blank">
                    Travel Assistance Program
                </PdsLink>
                <span className="pds-u-padding-left-8">
                    Call <PdsLink href="tel:+8886472611">888-647-2611</PdsLink> or call collect when outside the U.S. <PdsLink href="tel:+6307667696">630-766-7696</PdsLink>
                </span>
            </>)
    }

    function buildWillAndLegalDocumentCenter() {
        if(!hasWillAndLegalDocumentCenter(coverage)) {
            return null;
        } else if (memberLoading) {
            return <LoadingSpinner/>;
        } else if (memberError || !member) {
            console.log('member error', memberError);
            return <p>Could not load member information, please try again later.</p>;
        } else if (newYorkContract) {
            return null;
        }
        return buildDiscount(will, "will",
            "Will and Legal Document Center",
            "Consider preparing your simple legal documents online.",
            "These online resources and tools, provided by ARAG®, are easy-to-use. You and your spouse can prepare, print, and store essential legal documents - such as a will, living will, healthcare power of attorney, durable power of attorney, and medical treatment authorization for minors. Plus, you can access estate planning tools and resources, and a personal information organizer.",
            undefined,
            <>
                <PdsLink button="default" href="https://principal.araglegal.com/login" target="_blank">
                    Will and Legal Document Center
                    <span slot="icon-right">
                        <PdsIconExternalLink size="default"/>
                    </span>
                </PdsLink>
                <span className="pds-u-padding-left-8">
                    <VirtualSupplyLink formNumber="GP54930" container="span">Plan and protect your family and finances</VirtualSupplyLink>
                </span>
            </>)
    }

    function buildEmployeeAssistanceProgram() {
        const employeeAssistanceProgramProvision = member?.benefit?.employeeAssistanceProgramProvision;
        if(!hasEmployeeAssistanceProgram(coverage) || EmployeeAssistanceProgramProvision.NOT_INCLUDED === employeeAssistanceProgramProvision) {
            return null;
        } else if (memberLoading) {
            return <LoadingSpinner />;
        } else if(memberError || !member) {
            console.log('member error', memberError);
            return <p>Could not load member information, please try again later.</p>;
        }
        const phoneNumber = findPhoneNumberFor(employeeAssistanceProgramProvision)
        return buildDiscount(magellan, "magellan",
            "Employee Assistance Program (EAP)",
            "Count on help to be there when you need it.",
            `You and your family have access to free, confidential resources to help handle life's everyday - and not so everyday - challenges. This includes in-person or virtual counseling; legal, financial, and identity theft services*; and work-life web services, all provided by Magellan Healthcare. When you create an account, enter ${EmployeeAssistanceProgramProvision.findProgramNameFor(eapProvision)} as the program name.`,
            "* Not available to group policies issued in New York.",
            <>
                <PdsLink button="default" href="https://member.magellanhealthcare.com" target="_blank">
                    Magellan Healthcare
                    <span slot="icon-right">
                        <PdsIconExternalLink size="default"/>
                    </span>
                </PdsLink>
                <span className="pds-u-padding-left-8">
                    <PdsLink href={`tel:+${phoneNumber.replaceAll("-", "")}`}>{phoneNumber}</PdsLink>
                    {EmployeeAssistanceProgramProvision.CORE === eapProvision &&
                        <span> or <VirtualSupplyLink formNumber="GP59489" container="span">Your EAP</VirtualSupplyLink></span>
                    }
                </span>
            </>)
    }

    function buildDiscount(image: string, altImage: string, heading: string, title: string, content: string, footer?: string | ReactElement, footerLinks?: ReactElement) {
        return <PdsSidebar style={{ '--pds-sidebar-left-width': '400px'} as React.CSSProperties}>
            <div slot="left-sidebar">
                <img src={image} alt={altImage}/>
            </div>
            <div>
                <PdsHeading headingTag="h3" variant="label-default">{heading}</PdsHeading>
                <p>
                    <strong>{title}</strong>
                    <br/>
                    <span>{content}</span>
                </p>
                {footer && <p><b><small>{footer}</small></b></p> }
                <div>
                    {footerLinks}
                </div>
            </div>
        </PdsSidebar>
    }
}

export default DiscountsAndServices;
