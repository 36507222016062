// Helper function to recursively get the text content of the slotted elements and their children
export const getSlottedElementsScreenreaderText = (
  node: Node,
  srText: string,
  srParentNodes: Set<Node>,
): string => {
  const { parentNode, nodeType } = node;
  let finalSrText = srText;
  if (nodeType === Node.TEXT_NODE) {
    /**
     * If the parent node is in the set of parent nodes, the element's text content has already been added to the screenreader text
     * Return the screenreader text
     * */
    if (parentNode && srParentNodes.has(parentNode)) {
      return finalSrText;
    }

    // Get the text content of the node with trimmed white space
    if (node.textContent) {
      const trimmedTextContent = node.textContent.replace(/\s+/g, ' ').trim();
      finalSrText += ` ${trimmedTextContent}`;
    }
  } else if (nodeType === Node.ELEMENT_NODE) {
    const element = node as HTMLElement;
    const { tagName } = element;

    // If the parent node is not in the set of parent nodes, add the text content to the screenreader text
    if (parentNode && !srParentNodes.has(parentNode) && element.textContent) {
      const trimmedTextContent = element.textContent
        .replace(/\s+/g, ' ')
        .trim();
      finalSrText += ` ${trimmedTextContent}`;
    }

    // Appends button or link to the screenreader text if it is a button or link element
    if (tagName === 'BUTTON' || tagName === 'PDS-BUTTON') {
      finalSrText += ' button';
    } else if (tagName === 'A' || tagName === 'PDS-LINK') {
      finalSrText += ' link';
    }

    srParentNodes.add(node);

    // Recursively check child nodes
    node.childNodes.forEach((childNode) => {
      finalSrText = getSlottedElementsScreenreaderText(
        childNode,
        finalSrText,
        srParentNodes,
      );
    });
  }

  return finalSrText;
};
