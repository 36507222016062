import { html, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './sidebar.scss?inline';
import { requiredSlot } from '../../decorators/requiredSlot';

/**
 * @summary This components provides a layout for webpages containing sidebars
 *
 * @slot default Required: Main content
 * @slot left-sidebar Optional: Content to be rendered at left sidebar
 * @slot right-sidebar Optional: Content to be rendered at right sidebar
 */
@customElement('pds-sidebar', {
  category: 'layout',
  type: 'component',
  state: 'stable',
  styles,
})
export class PdsSidebar extends PdsElement {
  /**
   * - **default** stacks at (64rem/ 1024px) viewport
   * - **faster** stacks at a smaller viewport (48rem/ 768px)
   * - **slower** stacks at a larger viewport (85.375rem/ 1366px)
   */
  @property()
  break: 'faster' | 'slower' | 'default' = 'default';

  /**
   * - **default** spaced with a default gap (24px)
   * - **none** no gap between content and sidebar
   * - **sm** spaced with a smaller gap (8px)
   * - **lg** spaced with a larger gap (32px)
   */
  @property()
  gap: 'none' | 'sm' | 'lg' | 'default' = 'default';

  protected override firstUpdated() {
    super.firstUpdated();
    this.setWindowResizeHandler();
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [`break-${this.break}`]: !!this.break,
      [`gap-${this.gap}`]: !!this.gap,
      'right-sidebar': !!(
        this.slotNotEmpty('right-sidebar') && this.slotEmpty('left-sidebar')
      ),
      'left-sidebar': !!(
        this.slotNotEmpty('left-sidebar') && this.slotEmpty('right-sidebar')
      ),
      'leftandright-sidebars': !!(
        this.slotNotEmpty('left-sidebar') && this.slotNotEmpty('right-sidebar')
      ),
    };
  }

  /**
   * @internal
   */
  checkForMobileView() {
    if (
      ['xs', 'sm', 'md'].includes(this.responsiveViewportSize) &&
      this.break === 'default'
    ) {
      return true;
    }
    if (
      ['xs', 'sm'].includes(this.responsiveViewportSize) &&
      this.break === 'faster'
    ) {
      return true;
    }
    if (
      ['xs', 'sm', 'md', 'lg'].includes(this.responsiveViewportSize) &&
      this.break === 'slower'
    ) {
      return true;
    }

    return false;
  }

  /**
   * @internal
   */
  getSlots() {
    const leftSidebarSlot = this.slotNotEmpty('left-sidebar')
      ? html`<slot name="left-sidebar"></slot>`
      : nothing;
    const mainContentSlot = html`<slot></slot>`;

    const rightSidebarSlot = this.slotNotEmpty('right-sidebar')
      ? html`<slot name="right-sidebar"></slot>`
      : nothing;

    return {
      leftSidebar: leftSidebarSlot,
      mainContent: mainContentSlot,
      rightSidebar: rightSidebarSlot,
    };
  }

  @requiredSlot(['default'])
  render() {
    const slots = this.getSlots();
    return html`<div class=${this.getClass()}>
      ${this.checkForMobileView()
        ? html`
            ${slots.mainContent} ${slots.leftSidebar} ${slots.rightSidebar}
          `
        : html`
            ${slots.leftSidebar} ${slots.mainContent} ${slots.rightSidebar}
          `}
    </div>`;
  }
}
