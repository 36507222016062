import {
    PdsAlert,
    PdsCard,
    PdsAccordion,
    PdsHeading,
    PdsLayoutContainer,
    PdsLink,
    PdsSidebar,
    PdsStatusIndicator
} from "@principal/design-system-react";
import React from "react";
import "@pds-react/tab/dist/tab.min.css"
import { Relationship } from "../enums/Relationship";
import { Coverage } from "../enums/Coverage";
import { useCoverage } from "../hooks/useCoverage";
import { isSelfAccounting } from "../service/BenefitKeyService";
import { useProduct } from "../hooks/useProduct";
import { useBenefitDocument } from "../hooks/useBenefitDocument";
import HospitalIndemnityBenefits from "./benefits/HospitalIndemnityBenefits";
import AccidentBenefits from "./benefits/AccidentBenefits";
import CriticalIllnessBenefits from "./benefits/CriticalIllnessBenefits";
import DisabilityBenefits from "./benefits/DisabilityBenefits";
import { useMember } from "../hooks/useMember";
import { BenefitDocumentType } from "../enums/BenefitDocumentType";
import LifeBenefits from "./benefits/LifeBenefits";
import PaidLeaveBenefits from "./benefits/PaidLeaveBenefits";
import VirtualSupplyLink from "../atoms/VirtualSupplyLink";
import { AdminSourceSystem } from "../enums/AdminSourceSystem";
import LoadingSpinner from "../atoms/LoadingSpinner";
import DownloadableLink from "../atoms/DownloadableLink";
import { PdsIconArrowRight } from "@principal/design-system-icons-react";
import { EvidenceOfInsurabilityStatus } from "../enums/EvidenceOfInsurabilityStatus";
import { Benefit } from "../domain/Benefit";
import { Dependent } from "../domain/Dependent";
import hasWellness = Coverage.hasWellness;
import hasPreExistingLimitation = Coverage.hasPreExistingLimitation;
import hasMaternityLimitation = Coverage.hasMaternityLimitation;
import showBenefitsForSelfAccounting = Coverage.showBenefitsForSelfAccounting;
import findCoverageNameFor = Coverage.findCoverageNameFor;
import findOnlineClaimFormUrlFor = Coverage.findOnlineClaimFormUrlFor;
import findStatusIndicatorColorFor = EvidenceOfInsurabilityStatus.findStatusIndicatorColorFor;
import isPendingEmployee = EvidenceOfInsurabilityStatus.isPendingEmployee;
import findDefinitionFor = EvidenceOfInsurabilityStatus.findDefinitionFor;
import findStatusFor = EvidenceOfInsurabilityStatus.findStatusFor;
import hasPregnancyClaimForm = Coverage.hasPregnancyClaimForm;
import hasOpenEnrollmentBenefitIncrease = Coverage.hasOpenEnrollmentBenefitIncrease;
import { State } from "../enums/State";

export interface BenefitsProps {
    relationship?: Relationship
}

const Benefits = (props: BenefitsProps) => {
    const coverage = useCoverage();

    const { loading: memberLoading, data: member, error: memberError } = useMember()
    const { loading: productLoading, data: product, error: productError } = useProduct()
    const { loading: bookletLoading, data: booklet, error: bookletError } = useBenefitDocument(BenefitDocumentType.BOOKLET)
    const { loading: scheduleOfBenefitsLoading, data: scheduleOfBenefits, error: scheduleOfBenefitsError } = useBenefitDocument(BenefitDocumentType.SCHEDULED_BENEFIT_SUMMARY)

    const buildBenefits = () => {
        return <div>
            <PdsSidebar>
                {buildBenefitTabs()}
                <div slot='right-sidebar'>
                    <div style={{ height: 'auto' }}>
                        <PdsCard>
                            <PdsHeading headingTag="h2" variant="title-sm" className="pds-u-padding-bottom-16">Resources</PdsHeading>
                            {buildBooklets()}
                            {buildLimitationsAndExclusions()}
                        </PdsCard>
                    </div>
                </div>
            </PdsSidebar>
        </div>
    }

    const buildBenefitsHeader = () => {
        if(memberLoading) {
            return <LoadingSpinner/>
        } else if(memberError || !member) {
            console.log('member error', memberError)
            return <p>Could not load member information, please try again later.</p>;
        } else if (member.dependents?.length) {
            return <div>Coverage may vary by individual. To view details, select the individual you would like to review.</div>;
        }
        return null;
    }

    return (
        <div id="benefits" className="pds-u-background-brand-gradient-subtle">
            <PdsLayoutContainer>
                <div className="flex-gap">
                    <PdsHeading headingTag="h2" variant="title-sm">{findCoverageNameFor(coverage, member)} summary</PdsHeading>
                    {buildBenefitsContainer()}
                </div>
            </PdsLayoutContainer>
        </div>
)

function buildBenefitsContainer() {
        if(memberLoading) {
            return <LoadingSpinner/>
        } else if (AdminSourceSystem.CGS === member?.sourceSystem) {
            return <PdsAlert variant='error' hideDismissButton={true}>This coverage has been terminated. You will have access to your claim
                activity for up to 24 months after your last claim payment.</PdsAlert>
        } else if (isSelfAccounting(coverage) && !showBenefitsForSelfAccounting(coverage)) {
            return <PdsAlert variant='information' hideDismissButton={true}>Your employer manages your benefits. Please contact them for an
                up-to-date version.</PdsAlert>
        }
        return <div className="flex-gap">
            {buildBenefitsHeader()}
            {buildBenefits()}
        </div>
    }

    function buildBenefitTabs() {
        if(![Coverage.ACCIDENT, Coverage.CRITICAL_ILLNESS, Coverage.HOSPITAL_INDEMNITY, Coverage.LIFE,
                Coverage.LONG_TERM_DISABILITY, Coverage.PAID_FAMILY_LEAVE, Coverage.PAID_MEDICAL_LEAVE,
                Coverage.SHORT_TERM_DISABILITY, Coverage.VOLUNTARY_TERM_LIFE].includes(coverage)) {
            return <p>Viewing benefits for this coverage is not supported yet. Please check back later.</p>;
        }
        return <>
            <AccidentBenefits {...props}/>
            <CriticalIllnessBenefits {...props}/>
            <DisabilityBenefits {...props}/>
            <HospitalIndemnityBenefits {...props}/>
            <LifeBenefits {...props}/>
            <PaidLeaveBenefits {...props}/>
        </>
    }

    function buildBooklets() {
        const bookletLink = buildBookletLink();
        const scheduleOfBenefitsLink = buildScheduleOfBenefitsLink();
        const flyerLink = buildFlyerLink();
        return <PdsAccordion>
                    <span slot="summary-title">Benefit booklet</span>
                    <div slot="accordion-content" className="flex-gap">
                        <div>Find information regarding your policy.</div>
                        <div>
                            {bookletLink}
                            {scheduleOfBenefitsLink}
                            {flyerLink}
                        </div>
                    </div>
                </PdsAccordion>
    }

    function buildLimitationsAndExclusions() {
        if(productLoading) {
            return <LoadingSpinner/>
        } else if(productError || !product) {
            console.log('product error', productError)
            return <p>Could not load product information, please try again later.</p>;
        }
        return <>
            <PdsAccordion>
                <span slot="summary-title">Limitations and exclusions</span>
                <div slot="accordion-content">
                    <span>
                        These conditions may make your claim not payable.
                        Speak to your HR representative or review the limitations and exclusions section
                        of your benefit booklet for an inclusive list.
                    </span>
                    <ul>
                        {hasPreExistingLimitation(coverage, product) && <li>Pre-existing conditions</li>}
                        {hasMaternityLimitation(coverage, product) && <li>Maternity coverage</li>}
                    </ul>
                    {buildBookletLink()}
                </div>
            </PdsAccordion>
            <PdsAccordion>
                <span slot="summary-title">Policy rider(s)</span>
                <div slot="accordion-content">
                    <span>
                        Your policy riders include provisions that add benefits to or amend the terms of an insurance policy to provide additional coverage.
                    </span>
                    <ul>
                        {hasWellness(coverage, product) && <li>Wellness/health screening rider</li>}
                    </ul>
                </div>
            </PdsAccordion>
            {buildEvidenceOfInsurability()}
            {hasPregnancyClaimForm(coverage) && <PdsAccordion>
                <span slot="summary-title">Pregnancy leave</span>
                <div slot="accordion-content" className="flex-gap">
                    <span>Find frequently asked questions about pregnancy leave and a timeline example.</span>
                    <VirtualSupplyLink formNumber="GP61100">Understand your pregnancy leave</VirtualSupplyLink>
                </div>
            </PdsAccordion>}
            {hasOpenEnrollmentBenefitIncrease(coverage) && <PdsAccordion>
                <span slot="summary-title">Increase benefit annually</span>
                <div slot="accordion-content" className="flex-gap">
                    <span>It's easy to purchase or increase voluntary term life insurance for yourself, spouse and children.</span>
                    <VirtualSupplyLink formNumber="GP62501">Periodic benefit increase</VirtualSupplyLink>
                </div>
            </PdsAccordion>}
            {hasWellness(coverage, product) &&
                <PdsAccordion>
                    <span slot="summary-title">Wellness/health screening rider</span>
                    <div slot="accordion-content" className="flex-gap">
                        <div>
                            With this benefit, employees and covered family members have a financial incentive for completing a covered test
                            or procedure.
                        </div>
                        <PdsLink type='button' button='primary' href={findOnlineClaimFormUrlFor(Coverage.WELLNESS, member)}>
                            File a claim
                            <span slot="icon-right"><PdsIconArrowRight/></span>
                        </PdsLink>
                        {buildWellnessFlyer()}
                    </div>
                </PdsAccordion>}
        </>;
    }

    function buildBookletLink() {
        if (!Coverage.hasBooklet(coverage)) {
            return null;
        } else if (bookletLoading) {
            return <LoadingSpinner/>
        } else if (bookletError || !booklet?.content) {
            console.log('booklet error', bookletError)
            return "Could not load booklet, please try again later."
        }
        return <DownloadableLink fileName="Booklet.pdf" base64DownloadableContent={booklet.content}>Benefit booklet</DownloadableLink>
    }

    function buildScheduleOfBenefitsLink() {
        if (!Coverage.hasScheduleOfBenefits(coverage)) {
            return null;
        } else if (scheduleOfBenefitsLoading) {
            return <LoadingSpinner/>
        } else if(scheduleOfBenefitsError || !scheduleOfBenefits?.content) {
            console.log('schedule of benefits error', scheduleOfBenefitsError)
            return "Could not load schedule of benefits, please try again later."
        }
        return <DownloadableLink fileName="ScheduleOfBenefits.pdf" base64DownloadableContent={scheduleOfBenefits.content}>
            Schedule of benefits
        </DownloadableLink>
    }

    function buildFlyerLink() {
        if(!Coverage.hasBenefitsFlyer(coverage)) {
            return null;
        } else if(memberLoading){
            return <LoadingSpinner/>
        } else if(memberError || !member) {
            console.log('flyer error', memberError)
            return "Could not load benefits flyer, please try again later."
        }
        return <VirtualSupplyLink formNumber={Coverage.findBenefitsFlyerFormNumberFor(coverage, member.employment.workState) as string}>Flyer</VirtualSupplyLink>
    }

    function buildEvidenceOfInsurability() {
        if(!Coverage.hasUnderwriting(coverage)) {
            return null;
        } else if(memberLoading){
            return <LoadingSpinner/>
        } else if(memberError || !member) {
            console.log('evidence of insurability url error', memberError)
            return "Could not load evidence of insurability, please try again later."
        }
        const benefit = member.benefit;
        if(benefit.pendingAmount === 0 && member.dependents.every((dependent: Dependent) => dependent.benefit.pendingAmount === 0)) {
            return null;
        }
        const evidenceOfInsurabilityStatus = benefit.evidenceOfInsurabilityStatus;
        return <PdsAccordion>
            <span slot="summary-title">Evidence of insurability (proof of good health)</span>
            <div slot="accordion-content" className="flex-gap">
                <div>
                    Depending on the amount of coverage requested, you may be required to submit evidence of insurability.
                </div>
                {buildEvidenceOfInsurabilityStatusFor(evidenceOfInsurabilityStatus)}
                {buildEvidenceOfInsurabilityUrlFor(benefit)}
                <VirtualSupplyLink formNumber="GP60831">Why is EOI needed?</VirtualSupplyLink>
            </div>
        </PdsAccordion>
    }

    function buildEvidenceOfInsurabilityUrlFor(benefit: Benefit) {
        if (!benefit?.evidenceOfInsurabilityUrl || !isPendingEmployee(benefit.evidenceOfInsurabilityStatus as EvidenceOfInsurabilityStatus)) {
            return null;
        }
        return <div><PdsLink href={benefit.evidenceOfInsurabilityUrl}>Complete your EOI online</PdsLink></div>
    }

    function buildEvidenceOfInsurabilityStatusFor(evidenceOfInsurabilityStatus: EvidenceOfInsurabilityStatus) {
        return <div>
            <div><strong>EOI status:</strong></div>
            <PdsStatusIndicator variant={findStatusIndicatorColorFor(evidenceOfInsurabilityStatus)}>
                {findStatusFor(evidenceOfInsurabilityStatus)}
            </PdsStatusIndicator>
            <div>{findDefinitionFor(evidenceOfInsurabilityStatus)}</div>
        </div>
    }

    function buildWellnessFlyer() {
        if(memberLoading){
            return <LoadingSpinner/>
        } else if(memberError || !member) {
            console.log('wellness flyer error', memberError)
            return "Could not load wellness flyer, please try again later."
        }
        return <>
            {member.benefit?.contractState !== State.AZ
                && <div><VirtualSupplyLink formNumber="GP62824">Wellness/health screening benefit</VirtualSupplyLink></div>}
        </>;
    }
}
export default Benefits;
