import React from 'react';
import { Field, useField } from "formik";
import { FieldProps } from "formik/dist/Field";
import { PdsCheckbox } from "@principal/design-system-react";
import _ from "lodash";

export type CheckboxProps = {
    id: string,
    label: string,
    required?: boolean,
    changeCallback?: (target: EventTarget | null) => void
    children?: React.ReactNode,
}

const Checkbox = (props: CheckboxProps) => {
    const name = _.camelCase(props.id);
    const [, meta] = useField({
        name: name,
        validate: validateInput
    });

    return <Field component={Input} {...props} name={name} meta={meta}/>

    function validateInput(value: string) {
        if (!props.required && !value) {
            return '';
        } else if (props.required && !value) {
            return 'Please check this box.'
        }
    }
};

const Input = ({ field, form, meta, ...props }: FieldProps & CheckboxProps) => {
    return <PdsCheckbox {...field}
                        id={props.id}
                        fieldId={props.id}
                        label={props.label}
                        checked={field?.value}
                        errorMessage={meta.error}
                        onChange={(event) => {
                            field.onChange(event);
                            props.changeCallback?.(event.target);
                        }}/>
};

export default Checkbox;