import { html, PropertyValues } from 'lit';
import { property, query } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
// eslint-disable-next-line import/no-duplicates
import { PdsButton } from '../button/button';
import styles from './show-more.scss?inline';
// eslint-disable-next-line import/no-duplicates
import '../button/button';
import '@principal/design-system-icons-web/chevron-down';
import { requiredSlot } from '../../decorators/requiredSlot';

/**
 * @summary This component is show more that toggles content when it is clicked
 *
 * @slot default Required: Hidden/shown content
 *
 * @fires pds-show-more-open an event dispatched on click when show more is opened
 * @fires pds-show-more-close an event dispatched on click when show more is closed
 */
@customElement('pds-show-more', {
  category: 'component',
  type: 'component',
  state: 'stable',
  styles,
})
export class PdsShowMore extends PdsElement {
  /**
   * Style variant
   * - **default** renders the standard show-more color variant
   * - **inverted** renders the inverted show-more color variant
   */
  @property()
  variant: 'default' | 'inverted' = 'default';

  /**
   * Flag to control the expand/collapse behavior
   */
  @property({ type: Boolean, reflect: true })
  open: boolean = false;

  /**
   * Trigger button text for the collapsed state
   */
  @property({ type: String })
  showMoreText: string;

  /**
   * Trigger button text for the expanded state
   */
  @property({ type: String })
  showLessText: string;

  /**
   * The content element's wrapper, used to calculate height for expand/collapse animations
   * @internal
   */
  @query('.pds-c-show-more__content-wrapper')
  contentWrapper: HTMLElement;

  /**
   * The pds-button element containing the trigger button in its shadow DOM
   * @internal
   */
  @query('pds-button')
  showMoreButton: PdsButton;

  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
    this.addEventListener('keydown', this.handleKeyDown);
    this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
  }

  disconnectedCallback() {
    super.connectedCallback();
    this.removeEventListener('keydown', this.handleKeyDown);
    this.removeEventListener('transitionend', this.handleTransitionEnd);
  }

  handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      this.toggle();
    }
  }

  /**
   * Focus on the content area after the transition ends, with a delay to give time for the
   * screen reader to read out the state of the trigger button before moving focus
   */
  handleTransitionEnd() {
    if (this.open) {
      setTimeout(() => {
        this.contentWrapper.focus();
      }, 1500);
    }
  }

  /**
   * Toggle the show more component's state to either open or closed
   */
  toggle() {
    this.open = !this.open;
  }

  /**
   * Manually set the aria-expanded attribute on the Show More trigger's button element
   */
  async setAriaExpanded() {
    await this.updateComplete;

    if (this.showMoreButton.shadowRoot) {
      const buttonEl = this.showMoreButton.shadowRoot.querySelector('button');

      if (buttonEl) {
        buttonEl.setAttribute('aria-expanded', this.open.toString());
      }
    }
  }

  /**
   * Calls the content area expand animation, sets the aria-expanded attribute, and dispatches an event
   */
  expand() {
    this.contentWrapper.style.display = 'block';
    this.animateExpand();
    this.setAriaExpanded();

    const event = new Event('pds-show-more-open', {
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(event);
  }

  /**
   * Calls the content area collapse animation, sets the aria-expanded attribute, and dispatches an event
   */
  collapse() {
    this.animateCollapse();
    this.setAriaExpanded();

    const event = new Event('pds-show-more-close', {
      bubbles: true,
    });
    this.dispatchEvent(event);
  }

  /**
   * Sets the expand animation of the content area
   */
  animateExpand(): void {
    this.contentWrapper.style.height = '0px';
    this.contentWrapper.style.overflow = 'hidden';
    this.contentWrapper.style.transition = 'height 0.3s ease';
    window.requestAnimationFrame(() => {
      this.contentWrapper.style.height = `${this.contentWrapper.scrollHeight}px`;
    });
  }

  /**
   * Sets the collapse animation of the content area
   */
  animateCollapse(): void {
    const startHeight = `${this.contentWrapper.scrollHeight}px`;
    const endHeight = '0px';
    this.contentWrapper.style.overflow = 'hidden';
    this.contentWrapper.style.transition = 'height 0.3s ease';
    this.contentWrapper.style.height = startHeight;
    window.requestAnimationFrame(() => {
      this.contentWrapper.style.height = endHeight;
    });
    setTimeout(() => {
      this.contentWrapper.style.display = 'none';
    }, 300);
  }

  protected override async firstUpdated() {
    super.firstUpdated();
    await this.updateComplete;
    this.setAriaExpanded();

    this.contentWrapper.addEventListener(
      'transitionend',
      this.handleTransitionEnd,
    );
  }

  updated(
    changedProperties: PropertyValues<any> | Map<PropertyKey, unknown>,
  ): void {
    // Without the undefined check, the collapse function fires on page load unnecessarily
    if (
      changedProperties.has('open') &&
      changedProperties.get('open') !== undefined
    ) {
      if (this.open) {
        this.expand();
      } else {
        this.collapse();
      }
    }
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      'is-open': !!this.open,
      [this.variant]: !!this.variant,
    };
  }

  @requiredSlot(['default'])
  render() {
    return html`<div class=${this.getClass()}>
      <div
        class="${this.classEl('content-wrapper')}"
        aria-hidden="${!this.open}"
        tabindex="-1"
      >
        <div id="content" class="${this.classEl('content')}">
          <slot></slot>
        </div>
      </div>
      <pds-button
        link="icon-right"
        class="${this.classEl('button')}"
        variant="${this.variant}"
        ariaExpanded="${this.open}"
        ariaControls="content"
        @click="${this.toggle}"
      >
        <span>
          ${this.open
            ? this.showLessText || this.translateText('show-less')
            : this.showMoreText || this.translateText('show-more')}
        </span>
        <pds-icon-chevron-down
          class="${this.classEl('icon')}"
        ></pds-icon-chevron-down>
      </pds-button>
    </div>`;
  }
}
