// disable requiredSlot lint until requiredSlot error can be investigated for this component (causes table e2e storybook test to fail)
/* eslint-disable @nx/workspace-enforce-required-slot-decorator */

import { PropertyValues, html, isServer } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './table.scss?inline';
import shadowStyles from './table-shadow-styles.scss?inline';

/**
 * @summary A table wrapper that accepts table as its children
 *
 * @example
 * <pds-table>
 *  <table class="pds-c-table"> ... </table>
 * </pds-table>
 *
 * @slot default Required: Populate with the html table element
 *
 * @event pds-table-collapse-all Can be fired on the .pds-c-table__wrapper element if you need to manually collapse all rows
 * @event pds-table-expand-all Can be fired on the .pds-c-table__wrapper element if you need to manually expand all rows
 * @event change Can be fired on the .pds-c-table__wrapper element if you need to manually state that the HTML has changed
 * @event pds-table-changed Fired after the change event has been triggered
 *
 * @warn
 * pds-c-table css can affect other components
 */
@customElement('pds-table', {
  category: 'component',
  type: 'component',
  styles: shadowStyles,
})
export class PdsTable extends PdsElement {
  /**
   * Boolean to determine if the table should have "zebra" striping
   */
  @property()
  striped: 'odd' | 'even' | 'default' = 'default';

  /**
   * Boolean to expand all rows on a collapsible table on initial page load
   */
  @property({ type: Boolean })
  expandAllOnLoad: boolean = false;

  /**
   * Boolean to remove the borders and rounded corners of the table.  Default is false.
   */
  @property({ type: Boolean })
  removeBorder: boolean = false;

  /**
   * Boolean to add hoverable rows functionality to the table.  Default is false.
   */
  @property({ type: Boolean })
  hoverableRows: boolean = false;

  /**
   * Boolean to set the header row to sticky, default is false.
   *
   * Sticky row header will stick to the top of the page when scrolled away unless the table is fixed height, in which case it will stick to the top of the scrollable container.
   */
  @property({ type: Boolean })
  stickyHeader: boolean = false;

  /**
   * Boolean to set the first column to sticky, default is false.
   */
  @property({ type: Boolean })
  stickyColumn: boolean = false;

  /**
   * String to set a fixed height for the table. Example values: 300px, .25vh, 25%
   */
  @property()
  fixedHeight: string;

  /** @internal */
  get classNames() {
    return {
      'striped-even': this.striped === 'even',
      'striped-odd': this.striped === 'odd',
      'hoverable-rows': this.hoverableRows,
    };
  }

  /** @internal */
  @query('.pds-c-table__wrapper')
  wrapper: HTMLElement;

  table: HTMLTableElement;

  /** @internal */
  @state()
  ResizeObserver: any;

  /** @internal */
  @state()
  childNodeObserver: any;

  /** @internal */
  @state()
  resizeObserver: any;

  /** @internal */
  @state()
  // jest doesn't have an IntersectionObserver implementation, so including an any here
  intersectionObserver: IntersectionObserver | any;

  /**
   * @internal
   */
  disableTransition(elementArray: Array<HTMLElement>, transitionOff: boolean) {
    elementArray.forEach((element: HTMLElement) => {
      element.setAttribute('style', transitionOff ? 'transition: none' : '');
    });
  }

  /**
   * Initialize functions
   */
  constructor() {
    super();
    this.updateScroll = this.updateScroll.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
    if (!isServer && typeof window !== 'undefined') {
      window.addEventListener('scroll', this.updateScroll);
    }
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.childNodeObserver.disconnect();
    window.removeEventListener('scroll', this.updateScroll);
  }

  childNodeObserverCallback(currentTable: PdsElement): void {
    // When child nodes change, we need to reapply the classes for them to be styled appropriately
    this.applyScrollClasses();
    // @ts-expect-error prepareExpandableRows does exist, because we're calling it on this component
    currentTable.prepareExpandableRows({ initialLoad: false });
  }

  protected override async firstUpdated(): Promise<void> {
    super.firstUpdated();
    this.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
    this.resizeObserver = new this.ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        entries.forEach(() => this.resizedCallback());
      },
    );
    this.childNodeObserver = new MutationObserver(() =>
      this.childNodeObserverCallback(this),
    );

    this.table = this.querySelector('table')!;
    /**
     * Search for table and its element
     * and add the pds class to its respective element
     *
     * For Example
     * <tbody> => <tbody class='pds-c-table__body'>
     */
    this.applyScrollClasses();
    this.classList.add(this.classMod('can-be-scrolled-left'));

    const lightDomExists = document.head.querySelector('#pds-table-styles');
    if (!lightDomExists) {
      const lightDomStyle = document.createElement('style');

      lightDomStyle.id = 'pds-table-styles';
      lightDomStyle.innerHTML = styles.toString();
      document.head.appendChild(lightDomStyle);
    }
    // We need to wait for the update to complete to get accurate height measurements
    // on our expandable rows
    // See https://lit.dev/docs/components/lifecycle/#updatecomplete for more information
    // on why this is necessary
    await this.updateComplete;

    // Do not worry about hydration for Jest tests
    if (
      typeof window !== 'undefined' &&
      window.navigator &&
      window.navigator.userAgent &&
      window.navigator.userAgent.includes('jsdom')
    ) {
      this.prepareExpandableRows({ initialLoad: true });
    } else {
      // This fixes the hydration error with the table component
      // where these hooks are fired during hydration (though they should only occur after render)
      // this was the smallest value that consistently worked
      // TODOv4: we should re-write table so that the trigger button for expandable
      //       rows is an element (maybe even a PDS component) provided by the user
      //       so we don't do this janky, inject-the-td-into-the-dom nonsense
      /* istanbul ignore next */
      setTimeout(() => {
        this.prepareExpandableRows({ initialLoad: true });
      }, 750);
    }

    // Watch border-box for changes in our resize observer
    const observerOptions = {
      box: 'border-box',
    };

    if (this.table && this.resizeObserver) {
      this.resizeObserver.observe(this.table, observerOptions);
    }

    this.wrapper.addEventListener('scroll', () => {
      this.applyScrollClasses();
    });

    // Options for the observer (which mutations to observe)
    const config = { childList: true, subtree: true };

    // Start observing the target node for configured mutations
    if (this.table && this.childNodeObserver) {
      this.childNodeObserver.observe(this.table, config);
    }

    let options: IntersectionObserverInit = {
      rootMargin: '-150px',
      threshold: [1],
    };
    if (this.stickyHeader && this.fixedHeight) {
      options = { ...options, root: this.wrapper, rootMargin: '-110px' };
    }
    this.intersectionObserver = new IntersectionObserver(
      // Jest won't allow us to get into this function
      /* istanbul ignore next */
      ([e]) => {
        /* istanbul ignore next */
        e.target
          .closest(`.pds-c-table`)
          ?.querySelector('thead')
          ?.classList.toggle(
            `${this.classMod('is-pinned')}`,
            !e.isIntersecting,
          );
      },
      options,
    );
  }

  updated(changedProperties: PropertyValues<this>) {
    if (this.stickyColumn) {
      this.classList.add(this.classMod('sticky-column'));
    } else {
      this.classList.remove(this.classMod('sticky-column'));
    }

    if (!this.removeBorder) {
      this.classList.add(this.classMod('with-border'));
    } else {
      this.classList.remove(this.classMod('with-border'));
    }
    // Remove all striped classes and re-add the correct one
    // if striped has changed
    if (changedProperties.has('striped')) {
      this.classList.remove(this.classMod('striped-even'));
      this.classList.remove(this.classMod('striped-odd'));
      this.classList.remove(this.classMod('striped-default'));
      this.classList.add(this.classMod(`striped-${this.striped}`));
    }

    if (changedProperties.has('hoverableRows')) {
      if (this.hoverableRows) {
        this.classList.add(this.classMod('hoverable-rows'));
      } else {
        this.classList.remove(this.classMod('hoverable-rows'));
      }
    }

    // listen for sticky headers
    if (this.stickyHeader) {
      this.classList.add(`${this.classMod('sticky-header')}`);
      const firstTableRow = this.querySelector(`.pds-c-table > tbody > tr`);

      if (firstTableRow && this.intersectionObserver) {
        this.intersectionObserver.observe(firstTableRow);
      }
    } else if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
      this.classList.remove(`${this.classMod('sticky-header')}`);
      this.querySelectorAll(`.${this.classMod('is-pinned')}`).forEach((el) => {
        el.classList.remove(`${this.classMod('is-pinned')}`);
      });
    }
  }

  handleChange() {
    this.prepareExpandableRows({ initialLoad: false });
    const event = new Event('pds-table-changed', {
      bubbles: true,
      composed: true,
    });

    this.dispatchEvent(event);
  }

  handleCollapseAll(animate = true) {
    const expandableRegions = Array.from(
      this.querySelectorAll('.pds-c-table__expandable-row'),
    ) as HTMLElement[];
    const expandableRowWrappers = Array.from(
      this.querySelectorAll('.pds-c-table__expandable-row-wrapper'),
    ) as HTMLElement[];
    const expandableRowToggles = Array.from(
      this.querySelectorAll('.pds-c-table__toggle'),
    ) as HTMLElement[];

    // check if we need to disable transition
    if (!animate) {
      this.disableTransition(expandableRowWrappers, true);
      this.disableTransition(expandableRowToggles, true);
    }

    expandableRegions.forEach((region: HTMLElement) => {
      const trigger = region.querySelector(
        '.pds-c-table__toggle',
      ) as HTMLElement;
      const wrapper = region.querySelector(
        '.pds-c-table__expandable-row-wrapper',
      ) as HTMLElement;
      wrapper.classList.add('pds-c-table__expandable-row--is-collapsed');
      this.resetRegionHeight(region, trigger, wrapper);
      this.adjustKeyboardFocus(wrapper);
    });

    // reset transition
    if (!animate) {
      this.disableTransition(expandableRowWrappers, false);
      this.disableTransition(expandableRowToggles, false);
    }
  }

  handleExpandAll(animate = true) {
    const expandableRegions = Array.from(
      this.querySelectorAll('.pds-c-table__expandable-row'),
    ) as HTMLElement[];
    const expandableRowWrappers = Array.from(
      this.querySelectorAll('.pds-c-table__expandable-row-wrapper'),
    ) as HTMLElement[];
    const expandableRowToggles = Array.from(
      this.querySelectorAll('.pds-c-table__toggle'),
    ) as HTMLElement[];

    // check if we need to disable transition
    if (!animate) {
      this.disableTransition(expandableRowWrappers, true);
      this.disableTransition(expandableRowToggles, true);
    }

    expandableRegions.forEach((region: HTMLElement) => {
      const trigger = region.querySelector(
        '.pds-c-table__toggle',
      ) as HTMLElement;
      const wrapper = region.querySelector(
        '.pds-c-table__expandable-row-wrapper',
      ) as HTMLElement;
      wrapper.classList.remove('pds-c-table__expandable-row--is-collapsed');
      this.resetRegionHeight(region, trigger, wrapper);
      this.adjustKeyboardFocus(wrapper);
    });

    // reset transition
    if (!animate) {
      this.disableTransition(expandableRowWrappers, false);
      this.disableTransition(expandableRowToggles, false);
    }
  }

  /**
   * Set the child elements to be focusable or remove them from the tab order, based on if they're shown/hidden
   */
  adjustKeyboardFocus(wrapper: HTMLElement) {
    const tableBody = wrapper.querySelector('tbody');
    const focusableElements = [
      'a',
      'button',
      'input',
      'textarea',
      'select',
      'details',
    ];
    const isRowExpanded = !wrapper.classList.contains(
      'pds-c-table__expandable-row--is-collapsed',
    );

    if (tableBody) {
      if (isRowExpanded) {
        // If expanded, set aria-hidden on the tbody to true
        tableBody.setAttribute('aria-hidden', 'true');
      } else {
        // If collapsed, set aria-hidden on the tbody to false
        tableBody.setAttribute('aria-hidden', 'false');
      }
    }

    // Get all rows to loop through, but we'll skip the expandable ones because they're always visible
    const wrapperRows = Array.from(
      wrapper.querySelectorAll('tr'),
    ) as HTMLElement[];
    wrapperRows.forEach((wrapperRow: HTMLElement, index: number) => {
      if (index !== 0) {
        // Not on the first row, so now we can get all the child elements
        const allRowChildren = Array.from(
          wrapperRow.querySelectorAll('*'),
        ) as HTMLElement[];
        allRowChildren.forEach((rowChildElement: HTMLElement) => {
          // If the child is a keyboard focusable element, add or remove tabindex
          if (
            focusableElements.includes(rowChildElement.tagName.toLowerCase())
          ) {
            if (isRowExpanded) {
              // If the row is expanded, add the element back into the natural tab order
              rowChildElement.removeAttribute('tabindex');
            } else {
              // If the row is closed, remove this element from the tab order by setting tabindex = -1
              rowChildElement.setAttribute('tabindex', '-1');
            }
          }
          // The above will work for all non-web components, but for web components we need to check their shadow dom
          if (rowChildElement.shadowRoot) {
            const shadowFocusableElements = Array.from(
              rowChildElement.shadowRoot.querySelectorAll(
                'a[href], button, input, textarea, select, details',
              ),
            ) as HTMLElement[];

            // Loop through all the focuable elements in shadowRoot and add/remove tabindex
            shadowFocusableElements.forEach((element: HTMLElement) => {
              if (isRowExpanded) {
                // If the row is expanded, add the elements back into the natural tab order
                element.removeAttribute('tabindex');
              } else {
                // If the row is collapsed, remove these elements from the tab order by setting tabindex = -1
                element.setAttribute('tabindex', '-1');
              }
            });
          }
        });
      }
    });
  }

  setCssCustomProps() {
    // set css custom properties
    const verticalAdjust =
      this.wrapper.offsetHeight - this.wrapper.clientHeight - 1;
    const horizontalAdjust =
      this.wrapper.offsetWidth - this.wrapper.clientWidth - 1;
    const pinnedAdjust = this.querySelector('thead')?.offsetHeight;
    this.style.setProperty(
      '--pds-table-horizontal-scroller-offset',
      `${horizontalAdjust}px`,
    );
    this.style.setProperty(
      '--pds-table-vertical-scroller-offset',
      `${verticalAdjust}px`,
    );
    this.style.setProperty('--pds-table-pinned-offset', `${pinnedAdjust}px`);
    if (this.fixedHeight) {
      this.style.setProperty('--pds-table-fixed-height', `${this.fixedHeight}`);
    }
  }

  updateScroll(): void {
    if (this.stickyHeader && !this.fixedHeight) {
      const rect = this.table.getBoundingClientRect();
      const header = this.table.querySelector('thead') as HTMLElement;
      const scrollTop = window.scrollY;
      const distFromTop = header.offsetHeight || 0;
      const top = rect?.top;
      const tableOffSetTop = top + scrollTop;
      const tableOuterHeight = this.table.offsetHeight;
      if (
        scrollTop > tableOffSetTop &&
        scrollTop < tableOffSetTop + tableOuterHeight - distFromTop
      ) {
        header.style.setProperty('top', `${scrollTop - tableOffSetTop}px`);
      } else {
        header.style.removeProperty('top');
      }
    }
  }

  applyScrollClasses(): void {
    // if we can scroll any direction, we need a tabindex=0 on the wrapper for a11y
    if (
      this.wrapper.scrollLeft > 0 ||
      this.wrapper.scrollTop > 0 ||
      ((!this.stickyHeader || this.fixedHeight) &&
        this.wrapper.scrollLeft <
          this.wrapper.scrollWidth - this.wrapper.clientWidth) ||
      this.wrapper.scrollTop <
        this.wrapper.scrollHeight - this.wrapper.clientHeight
    ) {
      this.wrapper.setAttribute('tabindex', '0');
    } else {
      this.wrapper.removeAttribute('tabindex');
    }

    if (this.wrapper.scrollLeft > 0) {
      this.classList.add(this.classMod('can-be-scrolled-left'));
    } else {
      this.classList.remove(this.classMod('can-be-scrolled-left'));
    }

    if (this.wrapper.scrollTop > 0) {
      this.classList.add(this.classMod('can-be-scrolled-up'));
    } else {
      this.classList.remove(this.classMod('can-be-scrolled-up'));
    }

    if (
      (!this.stickyHeader || this.fixedHeight) &&
      Math.ceil(this.wrapper.scrollLeft) <
        this.wrapper.scrollWidth - this.wrapper.clientWidth
    ) {
      this.wrapper.classList.add(this.classMod('can-be-scrolled-right'));
    } else {
      this.wrapper.classList.remove(this.classMod('can-be-scrolled-right'));
    }

    if (
      this.wrapper.scrollTop <
      this.wrapper.scrollHeight - this.wrapper.clientHeight
    ) {
      this.wrapper.classList.add(this.classMod('can-be-scrolled-down'));
    } else {
      this.wrapper.classList.remove(this.classMod('can-be-scrolled-down'));
    }
  }

  resizedCallback() {
    // If the wrapper isn't as wide as the table itself, it must be scrollable
    if (
      ((this.wrapper && this.wrapper.clientWidth) || 0) < this.table.clientWidth
    ) {
      // So we'll add the scroll classes
      this.applyScrollClasses();
    } else {
      // Remove the scroll classes if it's not scrollable anymore
      this.classList.remove(this.classMod('can-be-scrolled-left'));
      this.wrapper.classList.remove(this.classMod('can-be-scrolled-left'));
      this.classList.remove(this.classMod('can-be-scrolled-right'));
      this.wrapper.classList.remove(this.classMod('can-be-scrolled-right'));
    }

    this.setCssCustomProps();
  }

  /**
   * Add classes, attributes and trigger buttons to the expandable rows
   */
  prepareExpandableRows(options: { initialLoad: boolean }): void {
    const expandableRegions = Array.from(
      this.querySelectorAll('.pds-c-table__expandable-row'),
    ) as HTMLElement[];

    if (expandableRegions.length > 0) {
      this.classList.add('pds-c-table__has-collapsible-rows');
    }
    const headers = this.querySelectorAll(
      '.pds-c-table > thead > tr > th',
    ).length;
    this.querySelectorAll('.pds-c-table__expandable-row > td').forEach((td) => {
      td.setAttribute('colspan', headers.toString());
    });
    this.style.setProperty(
      '--pds-table-column-percentage',
      `${100 / headers}%`,
    );

    expandableRegions.forEach((region: HTMLElement) => {
      const wrapper = region.querySelector(
        '.pds-c-table__expandable-row-wrapper',
      ) as HTMLElement;
      wrapper.classList.add('pds-c-table__expandable-row--is-expandable');
      wrapper.classList.add('pds-c-table--rendered');
      if (options.initialLoad) {
        // If first update and expandAllOnLoad is true, don't add the collapsed class
        // but if it's the first update and expandAllOnLoad is false, we want to add it in
        if (!this.expandAllOnLoad) {
          wrapper.classList.add('pds-c-table__expandable-row--is-collapsed');
        }
      }
      // If it's not first update, don't mess with the class because we're just repainting

      const id = `pds-table__expandable-row--row${this.getRandomId()}`;
      region.setAttribute('id', id);
      const triggerButton = this.createTriggerButton(region);
      // Target the first td and add the toggle button in
      const firstTd = region.querySelector(
        '.pds-c-table__expandable-row-wrapper td',
      );

      // If firstTd exists and has a child, and that child is our cell wrapper,
      // we've already done this and we don't need to run it again
      // If firstTd exists but doesn't have a child, or that child does not have our cell wrapper,
      // we need to add it
      if (
        firstTd &&
        ((firstTd.firstElementChild &&
          !firstTd.firstElementChild.classList.contains(
            'pds-c-table__expandable-row__cell-wrapper',
          )) ||
          !firstTd.firstElementChild)
      ) {
        // Create a wrapper div and move everything in the td inside it
        const wrapperDiv = document.createElement('div');
        wrapperDiv.classList.add('pds-c-table__expandable-row__cell-wrapper');
        while (firstTd.firstChild) {
          wrapperDiv.appendChild(firstTd.firstChild);
        }
        firstTd.appendChild(wrapperDiv);

        wrapperDiv.prepend(triggerButton);
      }

      if (
        wrapper.classList.contains('pds-c-table__expandable-row--is-collapsed')
      ) {
        // If collapsed, height is just the expandable TR height
        const regionsTR = region.querySelector('tr') as HTMLElement;
        const initialHeight = regionsTR.scrollHeight;
        wrapper.style.setProperty('height', `${initialHeight}px`);
      } else {
        // If open, height is the expandable TR height + TR height of every contained row
        const allRowsInExpandable = wrapper.querySelectorAll(
          '.pds-c-table__expandable-row-table > tbody > tr',
        );
        let totalHeight = 0;
        allRowsInExpandable.forEach((row) => {
          totalHeight += row.scrollHeight;
        });

        wrapper.style.setProperty('height', `${totalHeight}px`);
      }
      // Determine if elements should be within the natural tab flow or not, based on whether they are shown/hidden
      this.adjustKeyboardFocus(wrapper);
    });
  }

  createTriggerButton(region: HTMLElement): HTMLButtonElement {
    const triggerButton = document.createElement('button');
    triggerButton.setAttribute('type', 'button');
    triggerButton.setAttribute('aria-expanded', 'false');
    triggerButton.setAttribute('aria-controls', region.id);
    triggerButton.setAttribute(
      'aria-label',
      this.translateText('expand-collapse-row'),
    );
    triggerButton.classList.add('pds-c-table__toggle');
    triggerButton.innerHTML = this.getToggleChevron();
    triggerButton.addEventListener('click', () => {
      this.toggleRegionCollapse(region, triggerButton);
    });
    return triggerButton;
  }

  getToggleChevron(): string {
    return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 15L12 9L6 15" stroke="#0076CF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
  }

  toggleRegionCollapse(region: HTMLElement, triggerButton: HTMLElement) {
    const wrapper = region.querySelector(
      '.pds-c-table__expandable-row-wrapper',
    ) as HTMLElement;
    wrapper.classList.toggle('pds-c-table__expandable-row--is-collapsed');
    this.resetRegionHeight(region, triggerButton, wrapper);
    this.adjustKeyboardFocus(wrapper);
  }

  // Readjust the expandable region's height after an expand/collapse event
  // This forces the open/close animation to occur because we have a height transition animation in css
  resetRegionHeight(
    region: HTMLElement,
    triggerButton: HTMLElement,
    wrapper: HTMLElement,
  ) {
    if (
      wrapper.classList.contains('pds-c-table__expandable-row--is-collapsed') &&
      triggerButton
    ) {
      const regionTR = region.querySelector('tr') as HTMLElement;
      const initialHeight = regionTR.scrollHeight;
      wrapper.style.setProperty('height', `${initialHeight}px`);
      triggerButton.setAttribute('aria-expanded', 'false');
    } else if (triggerButton) {
      const initialHeight = wrapper.scrollHeight;
      wrapper.style.setProperty('height', `${initialHeight}px`);
      triggerButton.setAttribute('aria-expanded', 'true');
    }
  }

  render() {
    return html`<div
      class="${this.classEl('wrapper')} ${this.removeBorder
        ? ''
        : this.classMod('with-border')} ${this.stickyHeader
        ? this.classMod('sticky-header')
        : ''} ${this.fixedHeight ? this.classMod('fixed-height') : ''}"
      @change=${this.handleChange}
      part="wrapper"
      @pds-table-collapse-all=${this.handleCollapseAll}
      @pds-table-expand-all=${this.handleExpandAll}
    >
      <slot></slot>
    </div>`;
  }
}
