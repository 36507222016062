import { html } from 'lit';
import { pdsCustomIconElement as customElement } from '../../../decorators/pds-icon';
import { PdsCustomIcon } from '../PdsCustomIcon';

@customElement('pds-icon-star-full')
export class PdsIconStarFull extends PdsCustomIcon {
  render() {
    return html`<svg
      width=${this.getSize(this.size)}
      height=${this.getSize(this.size)}
      viewBox="0 0 40 40"
      fill="none"
      strokeWidth="2"
      aria-hidden="true"
      display="block"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6574 2.03014C22.0274 1.74014 22.6774 4.11014 23.5274 5.81014C24.3774 7.51014 25.2974 9.81014 26.2974 11.4601C26.6974 12.1301 27.0874 12.3701 27.8474 12.5201C30.9674 13.1401 34.2474 13.2201 37.3274 14.0201C38.7974 14.7301 39.2574 16.6501 38.2774 17.9601L31.5074 24.6201C31.2274 24.9201 31.1074 25.3001 31.0574 25.7001L32.6574 35.4301C32.5474 37.2601 30.8174 38.4501 29.0674 37.8201C26.3374 36.5701 23.7174 34.8301 20.9974 33.6001C20.3374 33.3001 19.8374 33.2301 19.1474 33.5301C16.8574 34.5501 14.4974 36.0501 12.2274 37.1901C11.3474 37.6301 10.5674 38.1501 9.52737 37.9501C8.26737 37.7001 7.39737 36.5901 7.38737 35.3101L8.98737 25.7001C8.94737 25.5301 8.93737 25.3501 8.87737 25.1801C8.67737 24.5901 7.04737 23.1201 6.51737 22.5901C5.06737 21.1501 3.36737 19.7301 1.99737 18.2401C0.337372 16.4401 1.45737 14.1401 3.73737 13.7301C6.49737 13.2301 9.45737 13.0601 12.1874 12.5101C12.9374 12.3601 13.3474 12.1101 13.7374 11.4501C15.1974 8.90014 16.2474 5.72014 17.7774 3.26014C18.1874 2.60014 18.8774 2.11014 19.6674 2.02014L19.6574 2.03014Z"
        fill="${this.color}"
      />
    </svg>`;
  }
}
