import { html, PropertyValues } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import '../button/button';
import '@principal/design-system-icons-web/x';
import styles from './global-header-mobile-tray.scss?inline';
import { requiredSlot } from '../../decorators/requiredSlot';

/**
 * @summary Global header mobile breakpoint navigation tray
 *
 * @slot main Required: Main tray content
 * @slot footer Optional: Footer for tray content
 *
 * @fires pds-global-header-mobile-tray-open Dispatched on mobile tray open
 * @fires pds-global-header-mobile-tray-close Dispatched on mobile tray close
 */
@customElement('pds-global-header-mobile-tray', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsGlobalHeaderMobileTray extends PdsElement {
  /**
   * Controls direction of tray slide-out animation
   */
  @property({ reflect: true })
  variant: 'left' | 'right' = 'left';

  /**
   * Controls tray display
   */
  @property({ type: Boolean })
  open: boolean = false;

  /**
   * Screen reader label for tray
   */
  @property()
  ariaLabel: string;

  /**
   * Content area of tray
   * @internal
   */
  @query('.pds-c-global-header-mobile-tray__inner')
  mobileTrayInner: HTMLElement;

  /**
   * The footer slot
   * @internal
   */
  @query('slot[name="footer"]')
  footerSlot: HTMLSlotElement;

  /**
   * Tracks the empty slow of footer
   * @internal
   */
  @state()
  footerIsEmpty: boolean = true;

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
      open: !!this.open,
    };
  }

  constructor() {
    super();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
    document.addEventListener('mouseup', this.handleClickOutside, false);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('mouseup', this.handleClickOutside, false);
  }

  firstUpdated() {
    super.firstUpdated();
    this.setWindowResizeHandler();
    this.addEventListener('keydown', this.handleKeydown);
    if (this.open) {
      this.setAttribute('open', '');
    }
  }

  updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);

    if (changedProperties.has('open')) {
      if (this.open) {
        this.dispatchEvent(
          new CustomEvent('pds-global-header-mobile-tray-open', {
            bubbles: true,
            composed: true,
          }),
        );
      } else {
        this.dispatchEvent(
          new CustomEvent('pds-global-header-mobile-tray-close', {
            bubbles: true,
            composed: true,
          }),
        );
      }
    }
  }

  /**
   * Checks if the footer slot is empty
   * @internal
   */
  isFooterEmpty() {
    if (this.footerSlot && this.footerSlot.assignedElements().length > 0) {
      const footer = this.footerSlot.assignedElements()[0] as HTMLSlotElement;
      if (footer.tagName === 'SLOT') {
        if (footer.assignedElements().length > 0) {
          this.footerIsEmpty = false;
        }
      } else {
        this.footerIsEmpty = false;
      }
    }
  }

  /**
   * Checks for small screen size
   * @internal
   */
  isMobile() {
    return (
      this.responsiveViewportSize === 'xs' ||
      this.responsiveViewportSize === 'sm'
    );
  }

  /**
   * Close menu on ESC keypress
   * @internal
   */
  handleKeydown(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      e.preventDefault();
      this.open = !this.open;
      this.removeAttribute('open');
    }
  }

  /**
   * Handle click outside the tray
   * @internal
   */
  handleClickOutside(e: MouseEvent) {
    if (!this.open) {
      return;
    }
    const didClickInside = e.composedPath().includes(this.mobileTrayInner);

    if (this.open && !didClickInside) {
      this.open = !this.open;
      this.removeAttribute('open');
    }
  }

  // TODO: We should keep tabs on when the Voiceover bug is resolved (https://bugs.webkit.org/show_bug.cgi?id=284804),
  // so we can remove the expanded part of the aria-label
  /**
   * If the user passes in a custom aria-label, that will be populated.
   * If not, the label will be automated and language localized.
   * @internal
   */
  getAriaLabel() {
    if (!this.ariaLabel) {
      const localizedAriaLabel = `${this.translateText('mobile-navigation-menu')} ${this.translateText('expanded')}`;
      return localizedAriaLabel;
    }
    return `${this.ariaLabel} ${this.translateText('expanded')}`;
  }

  /**
   * Handle click on close button
   * @internal
   */
  closeMobileTray() {
    this.removeAttribute('open');
  }

  @requiredSlot(['main'])
  render() {
    const footerClasses = {
      [`${this.classEl('footer')}`]: true,
      [`${this.classEl('footer-has-content')}`]: !this.footerIsEmpty,
    };

    return html`<div class=${this.getClass()}>
      <div aria-label=${this.getAriaLabel()} class="${this.classEl('inner')}">
        <div class="${this.classEl('close-header')}">
          <pds-button
            class="${this.classEl('close-button')}"
            part="close-button"
            variant="icon"
            type="button"
            name="close-menu"
            ariaLabel="${this.translateText('close')} ${this.translateText(
              'user-menu',
            )}"
            size="${this.isMobile() ? 'sm' : 'default'}"
            @click=${this.closeMobileTray}
          >
            <pds-icon-x></pds-icon-x>
          </pds-button>
        </div>
        <div class="${this.classEl('slots-wrapper')}">
          <slot name="main"></slot>
          <slot
            name="footer"
            class="${classMap(footerClasses)}"
            @slotchange=${this.isFooterEmpty}
          ></slot>
        </div>
      </div>
    </div>`;
  }
}
