import React from 'react';
import { FieldProps } from "formik/dist/Field";
import { Field, FieldMetaProps, useField } from "formik";
import { PdsSelect } from "@principal/design-system-react";
import _ from "lodash";

export type SelectDropdownProps = {
    id: string,
    label: string,
    required: boolean,
    changeCallback?: (target: EventTarget | null) => void
    children?: React.ReactNode,
}

const SelectDropdown = (props: SelectDropdownProps) => {
    const id = props.id;
    const name = _.camelCase(id);
    const [, meta] = useField({
        name,
        validate: validateInput
    });

    return <Field component={Select} {...(props)} name={name} meta={meta}/>

    function validateInput(value: string) {
        if (!props.required && !value) {
            return '';
        } else if (props.required && !value) {
            return 'Please fill out this field.'
        }
    }
};

const Select = ({ field, form, meta, ...props }: FieldProps & SelectDropdownProps & { meta: FieldMetaProps<any> }) => {
    const error = meta.error
    return (
        <PdsSelect {...field}
                   id={props.id}
                   fieldId={props.id}
                   label={props.label}
                   required={props.required}
                   style={{ width: '100%' }}
                   errorMessage={error}
                   hideBlankOption={true}
                   onChange={(event) => {
                       field.onChange(event);
                       props.changeCallback?.(event.target);
                   }}>
            {props.children}
        </PdsSelect>
    );
};

export default SelectDropdown;
