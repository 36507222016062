import {
    PdsActionMenu,
    PdsActionMenuItem,
    PdsButton,
    PdsGrid,
    PdsGridItem,
    PdsLayoutContainer,
    PdsLink
} from "@principal/design-system-react";
import React, { useEffect, useRef } from "react";
import { Coverage } from "../enums/Coverage";
import { useCoverage } from "../hooks/useCoverage";
import LoadingSpinner from "../atoms/LoadingSpinner";
import { useMember } from "../hooks/useMember";
import { useProduct } from "../hooks/useProduct";
import FileAClaimModal from "./FileAClaimModal";
import findOnlineClaimFormUrlFor = Coverage.findOnlineClaimFormUrlFor;

const Actions = () => {
    const coverage = useCoverage();
    const { loading: memberLoading, data: member, error: memberError } = useMember()
    const { loading: productLoading, data: product, error: productError } = useProduct();

    const fileAClaimModalRef = useRef(null) as React.RefObject<any>;
    const actionMenuRef = useRef(null) as React.RefObject<any>
    const fileAClaimLinkRef = useRef(null) as React.RefObject<any>

    useEffect(() => {
        const invisibleButtonInterval = setInterval(() => {
            const actionMenu = actionMenuRef.current;
            const pdsButton = actionMenu?.shadowRoot?.querySelector('pds-button');
            const button = pdsButton?.shadowRoot?.querySelector('button');
            if(button) {
                const fullWidth = 'width: 100%; justify-content: center';
                actionMenu.setAttribute('style', fullWidth)
                pdsButton.setAttribute('style', fullWidth)
                button.setAttribute('style', fullWidth)
                clearInterval(invisibleButtonInterval)
            }
        }, 10)
        return () => clearInterval(invisibleButtonInterval)
    }, [actionMenuRef])

    useEffect(() => {
        const fileAClaimInterval = setInterval(() => {
            const pdsLink = fileAClaimLinkRef.current;
            const anchor = pdsLink?.shadowRoot?.querySelector('a');
            if(anchor) {
                const fullWidth = 'width: 100%; justify-content: center';
                pdsLink.setAttribute('style', fullWidth)
                anchor.setAttribute('style', fullWidth)
                clearInterval(fileAClaimInterval)
            }
        }, 10)
        return () => clearInterval(fileAClaimInterval)
    }, [fileAClaimLinkRef])

    return (
        <div id="actions">
            <PdsLayoutContainer>
                {buildActions()}
            </PdsLayoutContainer>
        </div>
    );

    function buildActions() {
        if(memberLoading || productLoading) {
            return <LoadingSpinner/>
        } else if(memberError || !member || productError || !product) {
            console.log('member error', memberError)
            console.log('product error', productError)
            return <p>Could not load actions, please try again later.</p>;
        }
        const claimFormsUrl = findOnlineClaimFormUrlFor(coverage, member);
        const fileAClaimText = `File a ${Coverage.findCoverageNameFor(coverage, member).toLowerCase()} claim`
        const links = [{
            linkHref: claimFormsUrl, id: 'file-a-claim', ariaLabel: fileAClaimText, children: fileAClaimText
        }];
        const hasWellness = Coverage.hasWellness(coverage, product)
        if(hasWellness) {
            links.push({
                linkHref: findOnlineClaimFormUrlFor(Coverage.WELLNESS, member),
                id: 'file-a-wellness-claim',
                ariaLabel: 'File a wellness/health screening claim',
                children: 'File a wellness/health screening claim'
            })
        }
        links.push({
            linkHref: '#benefits',
            id: 'view-benefits',
            ariaLabel: 'View benefits and coverages',
            children: 'View benefits and coverages'
        });
        const fileAClaimButton = hasWellness
            ? <PdsButton onClick={() => fileAClaimModalRef.current.openModal()} variant="primary" type="button" fullWidth={true}>
                File a claim
            </PdsButton>
            : <PdsLink href={claimFormsUrl} button="primary" ref={fileAClaimLinkRef}>File a claim</PdsLink>;
        return <PdsGrid variant="2up">
            <PdsGridItem>
                <FileAClaimModal ref={fileAClaimModalRef}/>
                {fileAClaimButton}
            </PdsGridItem>
            <PdsGridItem>
                <PdsActionMenu label="" buttonLabel="I want to..." buttonVariant="default" hideSeparator={true} size="default" ref={actionMenuRef}>
                    {links.map(l => <PdsActionMenuItem {...l} />)}
                </PdsActionMenu>
            </PdsGridItem>
        </PdsGrid>;
    }
}

export default Actions;
