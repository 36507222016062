import { html } from 'lit';
import { Headset } from 'lucide-static';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { state } from 'lit/decorators.js';
import { pdsCustomIconElement as customElement } from '../../../decorators/pds-icon';
import { PdsIcon } from '../PdsIcon';

@customElement('pds-icon-headset')
export class PdsIconHeadset extends PdsIcon {
  @state()
  icon = Headset;

  render() {
    return html`${unsafeHTML(this.setIconAttributes())}`;
  }
}
