import { html } from 'lit';
import { property, queryAssignedElements } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './footer-nav.scss?inline';
import '../footer-nav-item/footer-nav-item';
import { requiredSlot } from '../../decorators/requiredSlot';

/**
 * @summary A component that renders a group of pds-footer-nav-item elements
 *
 * @slot default Required: One or more pds-footer-nav-item elements
 */
@customElement('pds-footer-nav', {
  category: 'component',
  type: 'component',
  state: 'stable',
  styles,
})
export class PdsFooterNav extends PdsElement {
  /**
   * - **3col** renders the footer-nav-items in a three-across grid at desktop
   * - **4col** renders the footer-nav-item in a four-across grid at desktop
   */
  @property()
  behavior: '3col' | '4col' = '3col';

  /**
   * This grabs the footerNavItems from the slot
   * @internal
   */
  @queryAssignedElements({ slot: undefined, selector: 'pds-footer-nav-item' })
  footerNavItems: HTMLElement[];

  protected override firstUpdated() {
    super.firstUpdated();
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.behavior]: !!this.behavior,
    };
  }

  @requiredSlot(['default'])
  render() {
    return html`<div class=${this.getClass()} part="footer-nav">
      <slot></slot>
    </div>`;
  }
}
