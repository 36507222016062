import { PropertyValueMap, html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsLink } from '../link/link';
import styles from './footnote-link.scss?inline';
import { required } from '../../decorators/required';
import { PdsFootnoteItem } from '../footnote-item/footnote-item';

/**
 * @summary This component is meant to handle the superscript links that are used as a tie to a footnote.
 * @slot default Optional: Inherited from PdsLink, not applicable
 * @slot icon-left Optional: Inherited from PdsLink, not applicable
 * @slot icon-right Optional: Inherited from PdsLink, not applicable
 */
@customElement('pds-footnote-link', {
  category: 'component',
  type: 'component',
  state: 'stable',
  styles,
})
export class PdsFootnoteLink extends PdsLink {
  /**

   * The ID used to link together the footnote-item and the footnote-link on the page. This has to match the ID of the footnote-item for it to work properly.
   * The text will be replaced with the index of the footnote. This is a **required** property.
   */
  @required
  @property({ type: String })
  footnoteId: string;

  /**
   * - id for footnote link
   */
  @property({ type: String, reflect: true })
  id: string;

  /**
   * - **default** renders link for basic action
   * - **inverted** used on a darker background
   */
  @property({ type: String })
  variant: 'default' | 'inverted' = 'default';

  /*
   * The href for the footnote link.
   */
  @property({ reflect: true })
  href: string;

  /*
   * The number of the footnote.
   *
   * @internal
   */
  @state()
  footnoteNumber: string = '1';

  protected firstUpdated(): void {
    super.firstUpdated();
  }

  async updated(
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>,
  ) {
    super.updated(changedProperties);
    this.handleFootnoteNumbering();
  }

  /**
   * Retrieve the footnote item that corresponds with the footnoteId
   *
   *  @internal
   */
  getMatchingFootnoteItem(): PdsFootnoteItem | null {
    let footnote: PdsFootnoteItem | null = null;

    const footnotes = document.querySelectorAll('pds-footnote-item');
    // Bail early if we have no footnote items
    if (footnotes.length === 0) {
      return null;
    }
    // Loop through each footnote item to see if there's one with a matching id
    footnotes.forEach((footnoteItem) => {
      const item = footnoteItem as PdsFootnoteItem;
      if (item.id === this.footnoteId) {
        footnote = item;
      }
    });
    return footnote;
  }

  /**
   * Make the corresponding footnote item focusable when the footnote link is clicked
   *
   *  @internal
   */
  handleClick(): void {
    const footnoteItem = this.getMatchingFootnoteItem();

    if (footnoteItem) {
      footnoteItem.tabIndex = 0;
    }
  }

  /**
   * Handle the footnote numbering by checking for a corresponding footnote-item
   * element and then assigning the index of the footnote-item to the link text
   *  @internal
   */
  handleFootnoteNumbering() {
    const footnote = this.getMatchingFootnoteItem();

    // Bail if we didn't find a matching footnote
    if (!footnote) {
      return;
    }
    // Get its parent to validate that it is a footnote element
    const parent = footnote.parentElement as HTMLElement;
    // TODOv4: remove the check for PDS-FOOTNOTE when that component is removed
    if (
      parent.tagName === 'PDS-FOOTNOTE' ||
      parent.tagName === 'PDS-FOOTNOTES'
    ) {
      // Get the index of the footnote
      const index = Array.prototype.indexOf.call(parent.children, footnote);
      // change the text to the index of the footnote
      this.footnoteNumber = (index + 1).toString();
    }
  }

  // Note - this render overrides the render provided by PdsLink
  render() {
    return html`<sup
      ><a
        class="${this.getClass()}"
        href=${ifDefined(this.href)}
        aria-label=${ifDefined(this.ariaLabel)}
        target="_self"
        @click=${this.handleClick}
        >${this.footnoteNumber}</a
      ></sup
    >`;
  }
}
