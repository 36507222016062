import React from 'react';
import { Field, FieldMetaProps, useField } from "formik";
import { FieldProps } from "formik/dist/Field";
import { PdsTextInput } from "@principal/design-system-react";
import _ from "lodash";

type TextInputProps = {
    id: string,
    label: string,
    required: boolean,
    maxLength?: number,
    changeCallback?: (target: EventTarget | null) => void,
    children?: React.ReactNode
}

const TextInput = (props: TextInputProps) => {
    const name = _.camelCase(props.id);
    const [, meta] = useField({
        name: name,
        validate: validateInput
    });

    return <Field component={Input} {...(props)} name={name} meta={meta}/>

    function validateInput(value: string) {
        if(!props.required && !value) {
            return '';
        } else if(props.required && !value) {
            return 'Please fill out this field.'
        }
    }
};

const Input = ({ field, form, meta, ...props }: FieldProps & TextInputProps & {meta: FieldMetaProps<any>}) => {
    return <PdsTextInput {...field}
                         type="text"
                         id={props.id}
                         fieldId={props.id}
                         label={props.label}
                         required={props.required}
                         maxLength={props.maxLength}
                         errorMessage={meta.error}
                         onChange={(event) => {
                             field.onChange(event);
                             props.changeCallback?.(event.target);
                         }}/>;
};

export default TextInput;