// disable requiredSlot lint until requiredSlot error can be investigated for this component (throws error even if it is populated, may be a lifecycle issue)
/* eslint-disable @nx/workspace-enforce-required-slot-decorator */

/* eslint-disable import/no-duplicates */
/* eslint-disable no-param-reassign */
import { html, nothing, PropertyValues } from 'lit';
import {
  property,
  query,
  queryAssignedElements,
  state,
} from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsFormFieldsetElement } from '../pds-form-fieldset-element/PdsFormFieldsetElement';
import { PdsRadio } from '../radio/radio';
import '../radio/radio';
import styles from './radio-group.scss?inline';

/**
 * @summary This component, used with <pds-radio>, allow users
 *   to select a single option from a list of mutually exclusive options.
 *
 * @slot default Required: Use this slot to pass the actual radio. They should be <pds-radio> elements.
 * @slot help-text Optional: Use this slot instead of the helpText property, if the help text requires additonal markup.
 *
 * @fires pds-radio-group-change A custom event dispatched when a new radio is selected
 * @fires pds-radio-group-blur A custom event dispatched when the radio group is blurred
 * @fires pds-radio-group-focus A custom event dispatched when a radio is focused
 */
@customElement('pds-radio-group', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsRadioGroup extends PdsFormFieldsetElement {
  field: HTMLElement;

  /**
   * @internal
   * Div around the radios to manage focus/tab behavior
   */
  @query('#radios')
  radioContainer!: HTMLDivElement;

  /** @internal */
  @query('fieldset')
  fieldset: HTMLFieldSetElement;

  /**
   * The amount of space between the radio buttons.
   *
   * - **default**
   * - **sm** condense the spacing between radios
   */
  @property()
  spacing: 'sm' | 'default' = 'default';

  /**
   * Style variant
   * - **default** renders the standard radio-group color variant
   * - **inverted** renders the inverted radio-group color variant
   */
  @property()
  variant: 'default' | 'inverted' = 'default';

  /**
   * Provides an opt-out for specific business scenarios where the control should load without a default value checked.
   *
   * NOTE: This is not a preferred approach and should be set to true only when a specific business scenario requires it.
   */
  @property({ type: Boolean })
  defaultValueOptOut: boolean = false;

  /** @internal */
  @queryAssignedElements({ selector: 'pds-radio' })
  radios!: Array<PdsRadio>;

  /**
   * @internal
   * State to determine the appropriate radio variants are used in conjunction with radio group
   */
  @state()
  isValidVariant = true;

  /**
   * @internal
   *
   * This variable will be used to detect if the user has specified a radio to be
   * checked by default and if not, we will check the first radio in the group unless defaultValueOptOut is true.
   */
  @state()
  defaultChecked: PdsRadio | undefined;

  /**
   * @internal
   *
   * Whether or not one of the radios inside of this component has focus.
   * This is used internally to manage focus and tab behavior.
   */
  @state()
  isFocused: boolean = false;

  /**
   * @internal
   *
   * This boolean is used to determine if the radio-group has a legend
   * It will be validated in the ValidationTimeout
   */
  @state()
  hasLegend: boolean = true;

  /**
   * @internal
   *
   * Handle radio change events and dispatch
   * pds-radio-group-change
   */
  radioChangeHandler(e: Event) {
    this.setCheckedValues(e.target as PdsRadio);
    // consumers should listen to 'pds-radio-group-change'
    // instead of the 'pds-radio-change' event
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent('pds-radio-group-change', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  /**
   * @internal
   *
   * Handle form reset - form fieldsets don't have reset internals,
   * so we need to do this ourselves
   */
  resetHandler() {
    if (this.defaultValueOptOut) {
      this.radios.forEach((radio) => {
        radio.checked = false;
        radio.internals.setFormValue(null);
      });
    } else {
      this.setCheckedValues(this.defaultChecked || this.radios[0]);
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
    this.radioChangeHandler = this.radioChangeHandler.bind(this);
    this.resetHandler = this.resetHandler.bind(this);
    this.addEventListener('pds-radio-change', this.radioChangeHandler);
    this.closest('form')?.addEventListener('reset', this.resetHandler);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    // Remove event listeners
    this.removeEventListener('pds-radio-change', this.radioChangeHandler);
    this.closest('form')?.removeEventListener('reset', this.resetHandler);
  }

  setCheckedValues(target: PdsRadio) {
    // get the value that you want to set the radio to
    const targetValue = target.value;

    // uncheck every radio and recheck only the new checked one passed from the change event
    this.radios.forEach((radio) => {
      radio.checked = false;
      radio.isProgrammaticChange = false;
      radio.internals.setFormValue(null);
    });
    // Check the new radio
    target.checked = true;
    target.internals.setFormValue(targetValue);
  }

  /**
   * @internal
   *
   * This function sets the disabled property and error state of the children radios, and sets
   * the first option to be checked by default if one is not already provided by the user
   */
  setRadios() {
    // have to re-get the radios array here for this to work with SSR - even though
    // hydration is complete, our other "this.radios" array returns undefined in this instance.
    const radiosArray = this.querySelectorAll(
      'pds-radio',
    ) as unknown as PdsRadio[];
    radiosArray.forEach((radio: PdsRadio) => {
      // If disabled attribute is set on radio-group, turn all radios to disabled
      if (this.disabled && !radio.disabled) {
        radio.setAttribute('disabled', 'true');
      }

      if (radio.checked) {
        this.defaultChecked = radio;
      }

      if (this.errorMessage) {
        radio.setAttribute('error', 'true');
      } else {
        radio.removeAttribute('error');
      }
    });

    // if there is no radio checked by default in the group and defaultValueOptOut is false, then set the first one to be checked
    if (!this.defaultChecked && !this.defaultValueOptOut) {
      this.setCheckedValues(radiosArray[0]);
    }
  }

  /** validate that the proper the variant property of the children radios is used */
  protected checkRadioVariant() {
    if (this.variant === 'inverted') {
      this.radios.forEach((radio) => {
        if (radio.variant !== 'inverted') {
          console.error(
            'Please ensure radios have the same variant property as radio group.',
          );
          this.isValidVariant = false;
        }
      });
    } else if (this.variant === 'default') {
      this.radios.forEach((radio) => {
        if (radio.variant !== 'default') {
          console.error(
            'Please ensure radios have the same variant property as radio group.',
          );
          this.isValidVariant = false;
        }
      });
    }
  }

  protected override async firstUpdated() {
    super.firstUpdated();
    await this.updateComplete;
    this.setRadios();

    // TODOv4: [ValidationTimeout] - Check to see if this setTimeout is still needed
    // This validation logic needs to happen late because it messes
    // with the render content and leads to hydration issues (and issues with child elements)
    // This may be something we can remove if we can find a better way to handle this
    // The validation is only for development - so end users should never see the render content change
    setTimeout(() => {
      if (!this.verifyLegend()) {
        this.hasLegend = false;
      }
      this.checkRadioVariant();
    }, 1000);

    // Radio buttons can be controlled via the arrow keys as soon as one has focus.
    // Right/down checks the next radio.
    // Left/up checks the previous radio.
    this.shadowRoot?.addEventListener('keydown', (e: Event) => {
      const { code } = e as KeyboardEvent;
      const enabledRadios = this.radios.filter((radio) => !radio.disabled);
      const currentRadioIndex = enabledRadios.findIndex(
        (radio) => radio.isFocused,
      );

      if (code === 'ArrowRight' || code === 'ArrowDown') {
        e.preventDefault();

        // next radio or loop back to the first radio
        const index =
          currentRadioIndex + 1 === enabledRadios.length
            ? 0
            : currentRadioIndex + 1;

        // simulate click
        enabledRadios[index].field.click();
        enabledRadios[index].field.focus();
      }

      if (code === 'ArrowLeft' || code === 'ArrowUp') {
        e.preventDefault();

        // previous radio or loop forward to the last radio
        const index =
          currentRadioIndex === 0
            ? enabledRadios.length - 1
            : currentRadioIndex - 1;

        // simulate click
        enabledRadios[index].field.click();
        enabledRadios[index].field.focus();
      }
    });
  }

  protected updated(changedProperties: PropertyValues<this>) {
    if (
      changedProperties.has('disabled') ||
      changedProperties.has('errorMessage')
    ) {
      this.radios.forEach((radio: PdsRadio) => {
        // If disabled attribute is set on radio-group, turn all radios to disabled
        if (this.disabled && !radio.disabled) {
          radio.setAttribute('disabled', 'true');
          // If disabled attribute is unset on radio-group, turn all radios to enabled
        } else if (!this.disabled && radio.disabled) {
          radio.removeAttribute('disabled');
        }

        if (this.errorMessage) {
          radio.setAttribute('error', 'true');
        } else {
          radio.removeAttribute('error');
        }
      });
    }
  }

  /**
   * Listening to focus on the group of radios. Once it gets focus,
   * pass the focus down to the appropriate radio.
   */
  private handleFocus() {
    // when the radio group takes focus, instead put in on the currently checked radio
    // or on the first radio (assuming is it not disabled)
    const enabledRadios = this.radios.filter((radio) => !radio.disabled);
    const currentRadioIndex = enabledRadios.findIndex((radio) => radio.checked);

    enabledRadios[
      currentRadioIndex === -1 ? 0 : currentRadioIndex
    ].field.focus();

    this.isFocused = true;

    // focus doesn't bubble
    this.dispatchEvent(
      new CustomEvent('pds-radio-group-focus', {
        bubbles: false,
        composed: true,
      }),
    );
  }

  private triggerBlur() {
    if (
      !this.radios.reduce(
        (isFocused, radio) => isFocused || radio.isFocused,
        false,
      )
    ) {
      this.isFocused = false;

      // blur doesn't bubble
      this.dispatchEvent(
        new CustomEvent('pds-radio-group-blur', {
          bubbles: false,
          composed: true,
        }),
      );
    }
  }

  /**
   * Listening to focusout since that event bubbles. We do end up
   * getting all of the focusout events from the children, so in
   * order to tell that this is a "blur" from the component, this
   * is looking at the inner radios to check if any other them still
   * have focus.
   */
  private handleFocusOut() {
    // If we have tabbed out of the radios, set isFocused to false.
    // We need to do an arbitrary `setTimeout` here because of the order
    // of focus events: blur, focusout, focus, focusin. When clicking from
    // one radio to another, the selected radio first blur (setting isFocused
    // to false),then the radio group focusOut is called (here), then the
    // new radio's focus is called (setting isFocused to true). As a result,
    // when this callback is initially called, all of the children radios have
    // isFocused as false. We need the setTimeout to wait for the new radio's
    // focus event handle to happen before we evaluate if the radio group
    // still has focus.
    setTimeout(() => {
      this.triggerBlur();
    }, 100 /* 100 seems about right in testing */);
  }

  /**
   * This function renders a div, that is a parent to the actual radios,
   * that helps manage the focus/tab behavior for the radios.
   */
  protected radioTemplate() {
    return html`<div
      id="radios"
      class="${this.classEl('radios')}"
      tabindex="${this.isFocused ? -1 : 0}"
      @focus=${this.handleFocus}
      @focusout=${this.handleFocusOut}
    >
      <slot></slot>
    </div>`;
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
      'spacing-sm': this.spacing === 'sm',
      'hidden-legend': this.hideLegend,
      'is-disabled': !!this.disabled,
      'is-required': !!this.required,
      'is-error': !!this.errorMessage,
    };
  }

  render() {
    if (!this.isValidVariant || !this.hasLegend) {
      return nothing;
    }

    return html`<fieldset
      class="${this.getClass()}"
      variant="${this.variant}"
      name="${this.name}"
      form="${this.form}"
      role="radiogroup"
      aria-required=${this.required ? 'true' : nothing}
      aria-invalid=${this.errorMessage ? 'true' : 'false'}
      aria-describedby=${this.getAriaDescribedBy() || nothing}
      ?disabled=${this.disabled}
    >
      <div class="${this.classEl('text-group')}">${this.legendTemplate()}</div>
      ${this.radioTemplate()}
    </fieldset>`;
  }
}
