import { v4 as uuidv4 } from 'uuid';

const getAriaDescribedbyElements = (element: any) => {
  const { ariaDescribedby, ariaDescribedbyIds } = element;
  let newAriaDescribedby = '';

  // TODOv4: Remove the logic for ariaDescribedby when the property is removed
  if (ariaDescribedby || ariaDescribedbyIds) {
    const ids = ariaDescribedby
      ? ariaDescribedby.split(/\s+/)
      : ariaDescribedbyIds.split(/\s+/);
    ids.forEach((id: string) => {
      const ariaDescribedbyElement = document.getElementById(id) as HTMLElement;
      if (ariaDescribedbyElement) {
        const uniqueId = `${id}-${uuidv4()}`;
        const elementText = ariaDescribedbyElement.textContent;

        const span = document.createElement('span');
        span.id = uniqueId;
        span.hidden = true;
        span.textContent = elementText;
        element.shadowRoot.appendChild(span);

        newAriaDescribedby += `${uniqueId} `;
      }
    });
  }
  return newAriaDescribedby.trim();
};

/*
 * This function is used to get the aria-describedby element ids for the element, and return them as a string. If necessary, it will also move the text content of elements being used by aria-describedby to a span element in the shadow DOM.
 */
export const getAriaDescribedByElementIds = (element: any) => {
  const ariaDescribedbyElementIds = getAriaDescribedbyElements(element);

  if (element.target === '_blank') {
    return ariaDescribedbyElementIds.concat(' hyperlink-sr-label');
  }

  // TODOv4: Remove the logic for ariaDescribedby when the property is removed
  if (
    element.disabledContext &&
    (element.ariaDescribedby || element.ariaDescribedbyIds)
  ) {
    return ariaDescribedbyElementIds.concat(' disabled-context');
  }

  if (element.disabledContext) {
    return 'disabled-context';
  }

  return ariaDescribedbyElementIds;
};
