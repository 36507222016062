import React, { forwardRef } from "react";
import { PdsHeading, PdsLink, PdsModal } from "@principal/design-system-react";
import { PdsGrid, PdsGridItem } from "@principal/design-system-react";
import { Coverage } from "../enums/Coverage";
import { useCoverage } from "../hooks/useCoverage";
import { useMember } from "../hooks/useMember";
import findOnlineClaimFormUrlFor = Coverage.findOnlineClaimFormUrlFor;
import LoadingSpinner from "../atoms/LoadingSpinner";
import { PdsCard } from "@principal/design-system-react";
import { PdsIconArrowRight } from "@principal/design-system-icons-react";
import _ from "lodash";

const FileAClaimModal = forwardRef((props: Partial<React.ComponentProps<typeof PdsModal>>, ref: React.Ref<any>) => {
    const coverage = useCoverage();
    const { loading: memberLoading, data: member, error: memberError } = useMember()

    if (memberLoading) {
        return <LoadingSpinner/>
    } else if(memberError || !member) {
        console.log('member error', memberError)
        return <p>Could not load find a claim page, please try again later.</p>
    }
    return <PdsModal {...props} ref={ref} size='lg' modalTitle="Which claim form do you need to file?">
        <div className="flex-gap">
            <PdsGrid variant="2up">
                <PdsGridItem>
                    <PdsCard style={{ height: '100%' }}>
                        <PdsHeading headingTag="h2" variant="title-sm">{_.capitalize(Coverage.findCoverageNameFor(coverage, member))} form</PdsHeading>
                        <p>Use this claim form to file the primary benefit for this coverage. If you are filing for you or your covered
                            dependent's annual wellness/health screening, use the wellness/health screening claim form instead.</p>
                        <PdsLink button="primary" href={findOnlineClaimFormUrlFor(coverage, member)}>
                            File this claim
                            <span slot="icon-right">
                              <PdsIconArrowRight/>
                            </span>
                        </PdsLink>
                    </PdsCard>
                </PdsGridItem>
                <PdsGridItem>
                    <PdsCard style={{ height: '100%' }}>
                        <PdsHeading headingTag="h2" variant="title-sm">Wellness/health screening form</PdsHeading>
                        <p>If you or a covered dependent have a covered test performed, you may be eligible for a wellness/health screening
                            benefit. This benefit is payable only once per calendar year per covered person.</p>
                        <PdsLink button="primary" href={findOnlineClaimFormUrlFor(Coverage.WELLNESS, member)}>
                            File this claim
                            <span slot="icon-right">
                              <PdsIconArrowRight/>
                            </span>
                        </PdsLink>
                    </PdsCard>
                </PdsGridItem>
            </PdsGrid>
            <div>Not sure which one to file? Call us at 800-245-1522 or e-mail SBDClaims@principal.com.</div>
        </div>
    </PdsModal>
})
export default FileAClaimModal;
