import '@principal/design-system-icons-web/check';
import { html, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsFormElement } from '../pds-form-element/PdsFormElement';
import styles from './radio.scss?inline';

/**
 * @summary A radio button
 *
 * @slot label Optional: Use this slot instead of the label property, if the label requires additonal markup
 */
@customElement('pds-radio', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsRadio extends PdsFormElement {
  /**
   * - **default** renders the standard radio color variant
   * - **inverted** renders the inverted radio color variant
   */
  @property()
  variant: 'default' | 'inverted' = 'default';

  /**
   * @internal
   *
   * Tells consuming applications that this component is type radio.
   */
  readonly type = 'radio';

  /**
   * @internal
   */
  defaultChecked: boolean;

  // need to override the `value` setter and getter
  // because radio works differently
  override set value(newValue) {
    this.internalValue = newValue;

    // set the value on the field
    if (this.field && this.field.value !== newValue) {
      this.field.value = newValue;
    }
  }

  /**
   * The value tied to the radio.
   */
  @property()
  override get value() {
    return this.internalValue;
  }

  set checked(newChecked: boolean) {
    // ariaChecked needs to be a string
    this.internals.ariaChecked = newChecked ? 'true' : 'false';

    if (newChecked) {
      // Standard HTML form behavior adds the radio name
      // with its accompanying value to form data if the radio is checked.
      // If the radio does not have a value property defined,
      // then it adds the radio name with the string 'on' in your form data.
      this.internals.setFormValue(this.value || 'on');
    }
  }

  /**
   * Indicates whether the radio is checked
   */
  @property({
    type: Boolean,
    reflect: true,
  })
  get checked(): boolean {
    return this.internals.ariaChecked === 'true';
  }

  /**
   * @internal
   * only the pds-radio-group should set this property
   */
  @property({
    type: Boolean,
  })
  error: boolean;

  /**
   * @internal
   * A flag to track programmatic changes to the radio.
   * It prevents the updated() lifecycle method from triggering unnecessary updates when the radio state changes programmatically.
   */
  @state()
  isProgrammaticChange: boolean;

  /**
   * @internal
   */
  @query('input')
  field: HTMLInputElement;

  override connectedCallback(): void {
    super.connectedCallback();
  }

  protected override firstUpdated() {
    super.firstUpdated();
    this.setErrorState();
    this.defaultChecked = this.checked || this.hasAttribute('checked');
    this.isProgrammaticChange = false;
  }

  updated() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.checked
      ? this.setAttribute('checked', '')
      : this.removeAttribute('checked');
    if (this.checked && !this.isProgrammaticChange) {
      this.dispatchEvent(
        new Event('pds-radio-change', { bubbles: true, composed: true }),
      );
      this.isProgrammaticChange = true;
    }
  }

  setErrorState() {
    if (this.errorMessage) {
      this.error = true;
    } else {
      this.error = false;
    }
  }

  /**
   * @internal
   * needed to rerender the component when the hidden radio input gets or loses focus
   * managing this via component state and the .pds-is-focused class is simpler
   * than managing this via complicated SCSS selectors
   */
  @state()
  isFocused: boolean = false;

  /**
   * Reset the input checked back it to its default checked attribute if it has one
   */
  formResetCallback() {
    if (this.defaultChecked) {
      this.checked = this.defaultChecked;
    }
  }

  private handleChange() {
    this.checked = this.field.checked;
    this.field.focus();
    this.dispatchEvent(
      new Event('pds-radio-change', { bubbles: true, composed: true }),
    );
  }

  private handleBlur() {
    this.isFocused = false;
    this.dispatchEvent(
      new Event('pds-radio-blur', { bubbles: false, composed: true }),
    );
  }

  private handleFocus() {
    this.isFocused = true;
    this.dispatchEvent(
      new Event('pds-radio-focus', { bubbles: false, composed: true }),
    );
  }

  private handleKeydown(e: KeyboardEvent) {
    // check the radio when the space button is pressed
    if (e.code === 'Space') {
      this.handleChange();
    }
  }

  protected labelTemplate() {
    return html` <label
      for="${this.fieldId || `${this.name}-${this.randomId}-field`}"
      class="${this.classEl('label')}"
    >
      <span class="${this.classEl('circle')}">
        <span class="${this.classEl('inner-circle')}"></span>
      </span>
      <span class="${this.classEl('label-text')}">
        <slot name="label">${this.label}</slot>
      </span>
    </label>`;
  }

  /** @internal */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
      'is-checked': this.checked,
      'is-disabled': this.disabled,
      'is-error': this.error,
      'is-focused': this.isFocused,
      'hidden-label': this.hideLabel,
    };
  }

  render() {
    if (!this.hasLabel) {
      return nothing;
    }

    // The tabindex is set to "-1" so that the input
    // can recieve focus programmatically, but will
    // not be tabbed to manually. The focus is managed
    // by the <pds-radio-group>.
    // We add a pds-u-sr-only class to hide the browser's default radio, but keep it accessible to screen readers
    return html`<div class="${this.getClass()}">
      <input
        class="${this.classEl('input')} pds-u-sr-only"
        type="radio"
        name="${this.name}"
        id="${this.fieldId || `${this.name}-${this.randomId}-field`}"
        ?disabled=${this.disabled}
        ?checked=${this.checked}
        ?required=${this.required}
        aria-describedby=${this.getAriaDescribedBy() || nothing}
        aria-invalid=${this.error}
        value="${this.value || nothing}"
        tabindex="-1"
        @click=${this.handleChange}
        @change=${this.handleChange}
        @blur=${this.handleBlur}
        @focus=${this.handleFocus}
        @keydown=${this.handleKeydown}
      />
      ${this.labelTemplate()}
    </div>`;
  }
}
