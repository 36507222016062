import { html } from 'lit';
import { Wallet } from 'lucide-static';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { state } from 'lit/decorators.js';
import { pdsCustomIconElement as customElement } from '../../../decorators/pds-icon';
import { PdsIcon } from '../PdsIcon';

@customElement('pds-icon-wallet')
export class PdsIconWallet extends PdsIcon {
  @state()
  icon = Wallet;

  render() {
    return html`${unsafeHTML(this.setIconAttributes())}`;
  }
}
