import { html } from 'lit';
import {
  property,
  query,
  queryAssignedElements,
  state,
} from 'lit/decorators.js';
import {
  computePosition,
  autoUpdate,
  shift,
  arrow,
  offset,
  size,
} from '@floating-ui/dom';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './tooltip.scss?inline';
import { requiredSlot } from '../../decorators/requiredSlot';

/**
 * @summary This component provides a tooltip when a user hovers or focuses on an icon
 *
 * @slot default Required: Provides the inner contents of the tooltip
 * @slot trigger Required: Element from which the tooltip is triggered
 *
 * @fires pds-tooltip-open An event dispatched when tooltip is opened
 * @fires pds-tooltip-close An event dispatched when tooltip is closed
 */
@customElement('pds-tooltip', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsTooltip extends PdsElement {
  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
  }

  /**
   * Style variant
   * - **dark** renders the standard variant, with dark-colored tooltips.
   * - **light** renders the light variant, with light-colored tooltips.
   */
  @property()
  variant: 'dark' | 'light' = 'dark';

  /**
   * Position for tooltip
   * - **default** renders the tooltip at top.
   * - **right** renders the tooltip at right.
   * - **bottom** renders the tooltip at bottom.
   * - **left** renders the tooltip at left.
   */
  @property()
  placement: 'default' | 'right' | 'bottom' | 'left' = 'default';

  /**
   * This grabs the div element of the tooltip container
   * @internal
   */
  @query('.pds-c-tooltip__tooltip')
  tooltip: HTMLDivElement;

  /**
   * This grabs the div element of the tooltip arrow
   * @internal
   */
  @query('.pds-c-tooltip__arrow')
  tooltipArrow: HTMLElement;

  /**
   * This grabs the tooltip trigger element
   * @internal
   */
  @queryAssignedElements({ slot: 'trigger' })
  trigger: HTMLElement[];

  /**
   * @internal
   */
  @state()
  contentHasFocus: boolean = false;

  createInstance() {
    const button = this.trigger[0];
    if (button) {
      const arrowLen = this.tooltipArrow.offsetWidth;
      const tooltipEl = this.tooltip;
      let maxWidthVal: number;
      let maxHeightVal: number;

      autoUpdate(button, this.tooltip, () => {
        // ComputePosition take two arguments first is reference element and second is floating element and returns a Promise that resolves with the coordinates that can be used to apply styles to the floating element
        // Middleware allows to customise behavior of positioning
        // Offset is used to adjust the distance between reference and floating element
        // Shift prevents floating element from overflowing alogn with it's axis
        // Placement is used to define direction of tooltip
        computePosition(button, this.tooltip, {
          placement: this.placement === 'default' ? 'top' : this.placement,
          middleware: [
            offset(8),
            shift(),
            arrow({ element: this.tooltipArrow }),
            size({
              apply({ availableWidth, availableHeight }) {
                // Tooltips should have a max-width of 320px and a max-height of 136px if the amount of avaliable width is larger than 320px.
                if (availableWidth > 320) {
                  maxWidthVal = 320;
                  maxHeightVal = 136;
                } else {
                  maxWidthVal = availableWidth;
                  maxHeightVal = availableHeight;
                }
                Object.assign(tooltipEl.style, {
                  maxWidth: `${maxWidthVal}px`,
                  maxHeight: `${maxHeightVal}px`,
                });
              },
            }),
          ],
        }).then(({ x, y, middlewareData }) => {
          Object.assign(this.tooltip.style, {
            // left and top ensures the position of tooltip
            left: `${x}px`,
            top: `${y}px`,
          });
          // to provide inline-styling to tooltip arrow we need to find the static side according to placement of tooltip
          const staticSide: any = {
            default: 'bottom',
            right: 'left',
            bottom: 'top',
            left: 'right',
          }[this.placement];

          const borderName: any = `border${
            staticSide.charAt(0).toUpperCase() + staticSide.slice(1)
          }Width`;

          let borderWidth: any = parseFloat(
            getComputedStyle(this.tooltipArrow)[borderName].slice(0, -2),
          );

          if (
            this.variant === 'light' &&
            (this.placement === 'bottom' || this.placement === 'left')
          ) {
            borderWidth -= 0.2;
          }

          if (middlewareData.arrow) {
            const { x: arrowX, y: arrowY } = middlewareData.arrow;
            Object.assign(this.tooltipArrow.style, {
              // left, top and static side would ensure the position of tooltip arrow
              left: arrowX != null ? `${arrowX}px` : '',
              top: arrowY != null ? `${arrowY}px` : '',
              right: '',
              bottom: '',
              [staticSide]:
                this.variant === 'light'
                  ? `${-arrowLen / 2 - borderWidth}px`
                  : `${-arrowLen / 2}px`,
            });
          }
        });
      });
    }
  }

  protected override firstUpdated() {
    super.firstUpdated();
    this.setEvents();

    if (this.slotNotEmpty('trigger')) {
      this.handleTriggerSlotChange();
    }
  }

  show() {
    // Make the tooltip visible
    this.tooltip.setAttribute('data-show', '');
    this.tooltipArrow.setAttribute('aria-expanded', 'true');
    this.createInstance();

    const openEvent = new Event('pds-tooltip-open', {
      bubbles: true,
      composed: true,
    });

    this.dispatchEvent(openEvent);
  }

  hide() {
    // Hide the tooltip
    this.tooltip.removeAttribute('data-show');
    this.tooltipArrow.setAttribute('aria-expanded', 'false');

    const closeEvent = new Event('pds-tooltip-close', {
      bubbles: true,
      composed: true,
    });

    this.dispatchEvent(closeEvent);
  }

  /**
   * @internal
   */
  setEvents() {
    const trigger = this.trigger[0];
    const showEvents = ['mouseenter', 'focus'];
    const toggleEvents = ['touchstart'];
    const leaveEvents = ['mouseleave', 'blur'];

    if (trigger) {
      if (
        !trigger.hasAttribute('aria-label') &&
        !trigger.hasAttribute('arialabel')
      ) {
        trigger.setAttribute(
          'aria-label',
          this.translateText('this-triggers-a-tooltip'),
        );
      }
      showEvents.forEach((event) => {
        trigger.addEventListener(event, () => {
          this.show();
        });
        this.tooltip.addEventListener(event, () => {
          this.contentHasFocus = true;
        });
      });

      leaveEvents.forEach((event) => {
        this.tooltip.addEventListener(event, () => {
          this.contentHasFocus = false;
          this.hide();
          trigger.blur();
        });
        trigger.addEventListener(event, () => {
          setTimeout(() => {
            if (!this.contentHasFocus) {
              this.hide();
              trigger.blur();
            }
          }, 200);
        });
      });

      toggleEvents.forEach((event) => {
        trigger.addEventListener(event, () => {
          this.handleToggle();
        });
      });

      // keybord nav, hide tooltip if esc is pressed
      trigger.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          this.hide();
          trigger.blur();
        }
      });
    }
  }

  /**
   * @internal
   */
  handleToggle() {
    if (this.tooltip.hasAttribute('data-show')) {
      this.hide();
    } else {
      this.show();
    }
  }

  /**
   * @internal
   */
  handleTriggerSlotChange() {
    if (this.trigger[0]) {
      this.trigger[0].setAttribute('ariaDescribedby', 'tooltip-content');
      this.trigger[0].setAttribute('tabindex', '0');
      this.setEvents();
      const shadowEl = this.trigger[0].shadowRoot;
      if (shadowEl && shadowEl.firstElementChild) {
        shadowEl.firstElementChild.setAttribute('tabindex', '-1');
      }
    }
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
    };
  }

  @requiredSlot(['default', 'trigger'])
  render() {
    return html`<div class=${this.getClass()} role="tooltip">
      <slot
        name="trigger"
        @slotchange=${this.handleTriggerSlotChange}
        class=${this.classEl('trigger')}
      ></slot>
      <div id="tooltip-content" class="${this.classEl('tooltip')}">
        <slot></slot>
        <div
          class="${this.classEl('arrow')} ${this.classEl('arrow')}--${this
            .placement}"
        ></div>
      </div>
    </div> `;
  }
}
