import { html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './support-heading.scss?inline';
import { required } from '../../decorators/required';
import '../heading/heading';
import { requiredSlot } from '../../decorators/requiredSlot';

@customElement('pds-support-heading', {
  category: 'component',
  type: 'component',
  state: 'stable',
  styles,
})
/**
 * @summary A support heading
 *
 * @slot default Required: The text of the heading
 */
export class PdsSupportHeading extends PdsElement {
  /**
   * Heading variant
   * - **meta-default** renders a <pds-heading> with meta text variant capitalized
   * - **meta-sm** renders a <pds-heading> with smaller meta text variant capitalized
   * - **headline-default** renders a <pds-heading> sentence case and bold with blue border and light blue offset background
   */
  @property()
  headingVariant: 'meta-default' | 'meta-sm' | 'headline-default' =
    'meta-default';

  @property({ type: Boolean })
  inverted: boolean = false;

  /**
   * This is a **required** property.
   */
  @required
  @property()
  headingTagName: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

  /**
   * Tracks the internal heading markup, which needs to be set after the first update (see note)
   * @internal
   */
  @state()
  headingElementMarkup = html``;

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.headingVariant]: !!this.headingVariant,
      inverted: this.inverted,
    };
  }

  headingLevel() {
    return this.headingTagName.replace('h', '');
  }

  protected firstUpdated(): void {
    super.firstUpdated();

    // We have to wait for the first update to set the heading element markup
    // For some reason, it doesn't know what properties are set (even if explicitly set to strings)
    // when rendering on the server, so then the required decorator doesn't work
    // At some point, we should dig into this more to see if we can allow nested
    // components that have required fields to render on the server
    this.headingElementMarkup = html`<pds-heading
      headingTag=${this.headingTagName}
      variant=${this.headingVariant}
      ><slot></slot
    ></pds-heading>`;
  }

  @requiredSlot(['default'])
  render() {
    return html`<div
      class=${this.getClass()}
      role="heading"
      aria-level="${this.headingLevel()}"
    >
      <div class=${this.classEl('body')}>${this.headingElementMarkup}</div>
    </div>`;
  }
}
