// disable requiredSlot lint until testing issue can be resolved
/* eslint-disable @nx/workspace-enforce-required-slot-decorator */

import { html, nothing } from 'lit';
import { property, queryAssignedElements, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './alert.scss?inline';
import '../button/button';
import '../layout-container/layout-container';
import '@principal/design-system-icons-web/check';
import '@principal/design-system-icons-web/alert-circle';
import '@principal/design-system-icons-web/alert-triangle';
import '@principal/design-system-icons-web/x';
import '@principal/design-system-icons-web/info';
import { PdsLink } from '../link/link';
import { getSlottedElementsScreenreaderText } from '../../../utils/get-slotted-elements-screenreader-text';

/**
 * @summary This component provides feedback to the user about the state of their interaction with in a page or application.
 *
 * @slot default Required: The contents of the alert
 *
 * @fires pds-button-click A custom event dispatched on a click on the alert dismiss button
 */
@customElement('pds-alert', {
  category: 'component',
  type: 'component',
  state: 'stable',
  styles,
})
export class PdsAlert extends PdsElement {
  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
  }

  /**
   * Style variant
   * - **information** default, renders the alert used for informational alerts
   * - **success** renders the alert used for success alerts
   * - **error** renders the alert used for error alerts, is not dismissible
   * - **warning** renders the alert used for warning alerts
   * - **banner** renders the alert used for banner alerts, by default includes a layout-container
   */
  @property()
  variant: 'information' | 'success' | 'error' | 'warning' | 'banner' =
    'information';

  /**
   * Determines if we should place the header in a layout container
   * If this property isn't passed, no layout container is used.
   */
  @property({ type: String })
  layoutContainerVariant?: 'default' | 'narrow';

  /**
   * Remove layout container padding (only to be used if layoutContainerVariant has a value)
   * - **md** removes padding from the layout container below md breakpoint
   * - **all** removes padding from the layout container at all screens (used for nested layout containers)
   */
  @property({ type: String })
  layoutContainerRemovePadding?: 'md' | 'all';

  /**
   * Hide dismiss button
   */
  @property({ type: Boolean })
  hideDismissButton: boolean = false;

  /**
   * Show or hide alert
   @internal
   */
  @state()
  hidden: boolean = false;

  /**
   * Show or hide alert on page load
   */
  @property()
  hiddenOnPageLoad: boolean = false;

  /**
   * Checks to see if the alert has valid markup
   * @internal
   */
  @state()
  isValidAlert: boolean = true;

  @queryAssignedElements({ slot: undefined })
  defaultSlotElements: HTMLElement[];

  /**
   * @internal
   *
   * Checks markup for errors and adds browser console errors for misconfigurations
   */
  async checkLinkVariants() {
    if (this.variant === 'error' && this.hideDismissButton) {
      console.warn(
        'Error alert cannot be dismissible and will always render without a close button. Please remove the hideDismissButton property to remove this warning.',
        this.outerHTML,
      );
    }

    const links = this.querySelectorAll('pds-link');
    links.forEach(async (link) => {
      const pdsLink = link as PdsLink;
      await pdsLink.updateComplete;

      const linkAttribute = pdsLink.variant
        ? pdsLink.variant
        : link.getAttribute('variant');

      if (this.variant === 'banner' && linkAttribute !== 'strong-inverted') {
        console.error(
          `Invalid link variant: '${linkAttribute}'.  Links in a banner alert must use the 'strong-inverted' variant.
 Please update this link with the correct variant to remove this warning.`,
          link,
        );
        this.isValidAlert = false;
      } else if (this.variant !== 'banner' && linkAttribute !== 'strong') {
        console.error(
          `Invalid link variant:  '${linkAttribute}'.  Links in a non-banner alert must use the 'strong' variant.
 Please update this link with the correct variant to remove this warning.`,
          link,
        );
        this.isValidAlert = false;
      }
    });
  }

  /**
   * @internal
   *
   * @returns icon for the alert
   */
  getIcon() {
    switch (this.variant) {
      case 'success':
        return html`<pds-icon-check size="lg"></pds-icon-check>`;
      case 'information':
        return html`<pds-icon-info size="lg"></pds-icon-info>`;
      case 'warning':
        return html`<pds-icon-alert-triangle
          size="lg"
        ></pds-icon-alert-triangle>`;
      default:
        return html`<pds-icon-alert-circle size="lg"></pds-icon-alert-circle>`;
    }
  }

  showAlert() {
    this.hidden = false;
    this.style.display = 'block';
  }

  hideAlert() {
    this.hidden = true;
    this.style.display = 'none';
  }

  /**
   * @internal
   *
   * @returns close button markup for dismissable alerts
   */
  getCloseButtonMarkup() {
    return html`
      <div class=${this.classEl('close-button-wrapper')}>
        <pds-button
          class=${this.classEl('close-button')}
          variant="${this.variant === 'banner' ? 'icon-inverted' : 'icon'}"
          size="sm"
          ariaLabel="${this.translateText('close')}"
          @click=${this.hideAlert}
          ><pds-icon-x class=${this.classEl('close-button-x-icon')}></pds-icon-x
        ></pds-button>
      </div>
    `;
  }

  override async firstUpdated() {
    super.firstUpdated();
    // TODOv4: [ValidationTimeout] - Check to see if this setTimeout is still needed
    // This validation logic needs to happen late because it messes
    // with the render content and leads to hydration issues (and issues with child elements)
    // This may be something we can remove if we can find a better way to handle this
    // The validation is only for development - so end users should never see the render content change
    setTimeout(async () => {
      await this.checkLinkVariants();
    }, 1000);

    if (this.hiddenOnPageLoad) {
      this.hideAlert();
    }

    this.defaultSlotElements.forEach((element) => {
      this.applyLineHeightToSuperscriptElements(element);
    });
  }

  /**
   * Recursive function to apply line heights to all nested superscript elements
   * @internal
   */
  applyLineHeightToSuperscriptElements(element: Node) {
    element.childNodes.forEach((child) => {
      if (child.nodeName === 'SUP') {
        const supEl = child as HTMLElement;
        supEl.style.lineHeight = '0';
      }
      if (child.hasChildNodes()) {
        this.applyLineHeightToSuperscriptElements(child);
      }
    });
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
      'is-hidden': this.hidden,
    };
  }

  /**
   * @internal
   */
  getScreenReaderText() {
    let srText = '';
    const srParentNodes = new Set<Node>();

    switch (this.variant) {
      case 'success':
        srText += this.translateText('success-alert');
        break;
      case 'warning':
        srText += this.translateText('warning-alert');
        break;
      case 'error':
        srText += this.translateText('error-alert');
        break;
      case 'banner':
        srText += this.translateText('banner-alert');
        break;
      case 'information':
      default:
        srText += this.translateText('information-alert');
        break;
    }

    if (this.hideDismissButton || this.variant === 'error') {
      srText += ` ${this.translateText('alert-not-dismissible')}`;
    }

    // This grabs the text content of the slotted elements and adds it to the screenreader text
    const slot = this.shadowRoot?.querySelector('slot');
    if (slot) {
      const slottedElements = slot.assignedNodes();
      slottedElements.forEach((node) => {
        srText = getSlottedElementsScreenreaderText(
          node,
          srText,
          srParentNodes,
        );
      });
    }
    return html`<span
      class="pds-u-sr-only"
      id="alert-sr-text"
      role="application"
      >${srText}</span
    >`;
  }

  render() {
    if (!this.isValidAlert) {
      return nothing;
    }

    if (this.layoutContainerVariant && this.variant !== 'banner') {
      return html`<pds-layout-container
        variant=${this.layoutContainerVariant}
        removePadding=${ifDefined(this.layoutContainerRemovePadding)}
        ><div
          class=${this.getClass()}
          role=${this.variant === 'error' ? 'alert' : 'status'}
          aria-live=${this.variant === 'error' ? 'assertive' : 'polite'}
          aria-labelledby="alert-sr-text"
        >
          <div class="${this.classEl('card')}">
            <div class="${this.classEl('alert-body')}">
              <div class="${this.classEl('icon')}">${this.getIcon()}</div>
              <div class="${this.classEl('alert-content')}">
                ${this.getScreenReaderText()}
                <slot></slot>
              </div>
              ${this.variant === 'error' || this.hideDismissButton
                ? nothing
                : this.getCloseButtonMarkup()}
            </div>
          </div>
        </div></pds-layout-container
      >`;
    }
    if (this.layoutContainerVariant && this.variant === 'banner') {
      return html`<div
        class=${this.getClass()}
        role="status"
        aria-live="polite"
      >
        <pds-layout-container
          variant=${this.layoutContainerVariant}
          removePadding=${ifDefined(this.layoutContainerRemovePadding)}
        >
          <div class="${this.classEl('card')}">
            <div class="${this.classEl('alert-body')}">
              <div class="${this.classEl('icon')}">${this.getIcon()}</div>
              <div class="${this.classEl('alert-content')}">
                ${this.getScreenReaderText()}
                <slot></slot>
              </div>
              ${this.hideDismissButton ? nothing : this.getCloseButtonMarkup()}
            </div>
          </div>
        </pds-layout-container>
      </div>`;
    }
    return html`<div
      class=${this.getClass()}
      role=${this.variant === 'error' ? 'alert' : 'status'}
      aria-live=${this.variant === 'error' ? 'assertive' : 'polite'}
      aria-labelledby="alert-sr-text"
    >
      <div class="${this.classEl('card')}">
        <div class="${this.classEl('alert-body')}">
          <div class="${this.classEl('icon')}">${this.getIcon()}</div>
          <div class="${this.classEl('alert-content')}">
            ${this.getScreenReaderText()}
            <slot></slot>
          </div>
          ${this.variant === 'error' || this.hideDismissButton
            ? nothing
            : this.getCloseButtonMarkup()}
        </div>
      </div>
    </div>`;
  }
}
