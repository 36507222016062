import { isServer } from 'lit';

/**
 * The `@requiredSlot()` decorator can be used to indicate that
 * a render has one or more required slots. When used, if a slot
 * identified in the requiredSlotsArr is not defined by the
 * application, then the component will not render (starting in
 * v4) and will produce an error message in the console.
 *
 * The `@requiredSlot()` decorator should be used in conjunction
 * with the `@render()` function.
 */
export function requiredSlot(requiredSlotsArr: string[]) {
  return function requiredSlotDecorator(
    target: any,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<any>,
  ) {
    const originalMethod = descriptor.value;

    // this is the error function that will report the required slots that are not populated, and in v4 will return an empty template to prevent render of the component
    const errorFunction = (
      slotsInError: any[],
      result: any,
      node: TypedPropertyDescriptor<any>,
    ) => {
      console.error(
        'A required slot has not been populated: ',
        slotsInError,
        node,
      );
      // TODOv4 - enable breaking change to return an empty template to prevent the component from rendering, and remove the return result line
      // return html``;
      return result;
    };
    if (!isServer) {
      // eslint-disable-next-line no-param-reassign, func-names
      descriptor.value = function (...args: any[]) {
        const result = originalMethod.apply(this, args);
        const slotsInError: any[] = [];

        // handle default slot entries
        requiredSlotsArr.forEach((key: any) => {
          if (key === 'default') {
            // @ts-expect-error
            const nodes = Array.from(this.childNodes);

            const found = nodes.some((node: any) => {
              if (
                node.nodeType === Node.TEXT_NODE &&
                node.textContent.replaceAll('/n', '').trim() !== ''
              ) {
                return true;
              }
              if (
                node &&
                node.textContent.replaceAll('/n', '').trim() !== '' &&
                node instanceof Element &&
                !node.hasAttribute('slot')
              ) {
                return true;
              }
              return false;
            });
            // @ts-expect-error
            this.requiredSlotElements[key] = found;
            // @ts-expect-error
          } else if (this.querySelector(`[slot="${key}"]`)) {
            // @ts-expect-error
            this.requiredSlotElements[key] = this.querySelector(
              `[slot="${key}"]`,
            );
          } else {
            // @ts-expect-error
            this.requiredSlotElements[key] = false;
          }
        });

        // @ts-expect-error
        Object.entries(this.requiredSlotElements).forEach(([key, value]) => {
          if (
            // @ts-expect-error
            !this.initialRenderForRequiredSlotValidation &&
            !value
          ) {
            slotsInError.push(key);
          }
        });

        if (slotsInError.length > 0) {
          return errorFunction(slotsInError, result, this);
        }
        // @ts-expect-error
        this.initialRenderForRequiredSlotValidation = false;
        return result;
      };
    }
    return descriptor;
  };
}
