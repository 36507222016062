import { PropertyValueMap, html, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import intlTelInput from 'intl-tel-input';
import { AsYouType, Metadata } from 'libphonenumber-js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsFormElement } from '../pds-form-element/PdsFormElement';
import styles from './phone-number-input.scss?inline';

const submittable = ['pds-button[type="submit"]:not([disabled])'];

const blocking = ['pds-phone-number-input'];

/**
 * @summary The Principal phone number input component
 *
 * @fires pds-phone-number-input-input an event dispatched on input
 * @fires pds-phone-number-input-change an event dispatched on change
 * @fires pds-phone-number-input-blur an event dispatched on blur
 * @fires pds-phone-number-input-focus an event dispatched on focus
 *
 * @slot label Optional: Use this slot instead of the label property if the label requires additional markup
 * @slot help-text Optional: Use this slot instead of the helpText property if the help text requires additional markup
 */
@customElement('pds-phone-number-input', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsPhoneNumberInput extends PdsFormElement {
  /**
   * Style variant
   * - **default** renders the standard phone-number-input color variant
   * - **inverted** renders the inverted phone-number-input color variant
   */
  @property()
  variant: 'default' | 'inverted' = 'default';

  set value(newValue) {
    const oldValue = this.value;
    // remove non-numeric characters from newValue except spaces and plus sign
    let valueToSetInForm = newValue.replace(/[^\d\s+]/g, '');
    // sets the current value of the control
    const dialCode = this.usOnly
      ? '1'
      : this.intlPhoneInputLibrary?.getSelectedCountryData().dialCode;
    if (this.omitDialCode && dialCode && valueToSetInForm) {
      valueToSetInForm = `${valueToSetInForm.replace(/[^0-9]/g, '')}`;
    } else if (dialCode && valueToSetInForm) {
      valueToSetInForm = `+${dialCode}${valueToSetInForm.replace(/[^0-9]/g, '')}`;
    }
    this.internals.setFormValue(valueToSetInForm);
    // store the value so it can be retrieved by the getter
    this.internalValue = valueToSetInForm;

    // set the value on the field
    if (this.field && this.field.value !== newValue) {
      let valueToSetOnField = newValue;
      if (valueToSetOnField.startsWith('+1')) {
        valueToSetOnField = valueToSetOnField.replace('+1', '');
      }
      this.field.value = valueToSetOnField;
    }

    // rerender the component
    this.requestUpdate('value', oldValue);
  }

  /**
   * The value of the form field
   */
  @property()
  get value() {
    return this.internalValue;
  }

  /**
   * Displays the phone number input for US phone numbers only, without the dropdown country code menu
   */
  @property({ type: Boolean })
  usOnly: boolean = false;

  /**
   * Removes the dial code from the value passed by the input to the form
   */
  @property({ type: Boolean })
  omitDialCode: boolean = false;

  /**
   * The size of the component
   *
   * - **default**
   * - **sm** renders a the small version of the input
   *
   * NOTE: This is NOT the HTML size attribute that controls the width of the input.
   */
  @property()
  size: 'sm' | 'default' = 'default';

  /**
   * Standard input minlength attribute
   * See [HTML attribute: minlength](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/minlength)
   */
  @property()
  minLength?: string | number;

  /**
   * Standard input maxlength attribute
   * See [HTML attribute: maxlength](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/maxlength)
   */
  @property()
  maxLength?: string | number;

  /**
   * Standard input pattern attribute
   * See [HTML attribute: pattern](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/pattern)
   */
  @property()
  pattern?: string;

  /**
   * Stores the instance of intlTelInput when initialised
   * @internal
   */
  @state()
  intlPhoneInputLibrary: any;

  @state()
  isValidPhoneNumberInput: boolean = true;

  /**
   * Stores the country code of the selected country
   * @internal
   */
  @state()
  dialCode: string;

  /**
   * Is this the first parse of the phone number
   * @internal
   */
  @state()
  firstParse: boolean = true;

  /**
   * Stores the user's cursor selection range in the input field
   * @internal
   */
  @state()
  userSelectionPosition: { start: number; end: number } = {
    start: 0,
    end: 0,
  };

  /**
   * Store timeout for debouncing the parsePhoneNumber function
   *
   * @internal
   */
  @state()
  parsePhoneNumberTimeout: any;

  /**
   * Standard input autocomplete attribute
   * See [HTML attribute: autocomplete](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete)
   *
   */
  @property()
  autocomplete: 'off' | 'tel';

  /** @internal */
  @query('.pds-c-phone-number-input__input')
  field: HTMLInputElement;

  /** @internal */
  @query('.pds-c-phone-number-input__input-wrapper')
  inputWrapper: HTMLInputElement;

  /**
   * Update the field value
   *
   * @internal
   */
  protected updateField() {
    // set the value on the field
    // when the field exists and there is an internalValue
    if (
      this.field &&
      typeof this.internalValue !== 'undefined' &&
      this.field.value !== this.internalValue
    ) {
      this.field.value = this.internalValue;
    }
  }

  /**
   * Handle change event on the phone number input
   *
   * @internal
   */
  private handleChange() {
    this.parsePhoneNumber();
    this.dispatchEvent(
      new Event('pds-phone-number-input-change', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  /**
   * Handle input event on the phone number input
   *
   * @internal
   */
  private handleInput() {
    this.parsePhoneNumber();
    this.dispatchEvent(
      new Event('pds-phone-number-input-input', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  /**
   * Handle blur event on the phone number input
   *
   * @internal
   */
  private handleBlur() {
    this.dispatchEvent(
      new Event('pds-phone-number-input-blur', {
        bubbles: false,
        composed: true,
      }),
    );
  }

  /**
   * Handle focus event on the phone number input
   *
   * @internal
   */
  private handleFocus() {
    this.dispatchEvent(
      new Event('pds-phone-number-input-focus', {
        bubbles: false,
        composed: true,
      }),
    );
  }

  /**
   * Handle keydown event on the phone number input
   *
   * @internal
   */
  private handleKeydown(e: KeyboardEvent): void {
    const metadata = new Metadata();
    const isNumeric = /^[0-9]$/.test(e.key);
    let iso2;
    let possibleLengthsArray: number[] = [];

    // we force iso2 when usOnly is true
    if (this.usOnly) {
      iso2 = 'us';
    } else {
      iso2 = this.intlPhoneInputLibrary.getSelectedCountryData().iso2;
    }

    // get the possible lengths for the selected country
    metadata.selectNumberingPlan(iso2.toUpperCase());
    if (metadata.numberingPlan) {
      possibleLengthsArray = metadata.numberingPlan.possibleLengths();
    }

    // get the number and transform it with no spaces
    const number = this.field.value;
    const numberNoSpaces = number.replace(/\s/g, '');

    // if this is a form submission, do that, then exit handler
    if (e.key === 'Enter') {
      const { form } = this.internals;
      if (form) {
        const submitElement: any = form.querySelector(submittable.join(','));

        if (form) {
          // not testable in Jest
          /* istanbul ignore next */
          if (submitElement) {
            const clickEvent = new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              composed: true,
            });
            submitElement.button.dispatchEvent(clickEvent);
          } else {
            const blockingElements = form?.querySelectorAll(blocking.join(','));
            if (blockingElements && blockingElements.length <= 1) {
              // Older versions of Safari don't support the requestSubmit method
              if (form.requestSubmit) {
                form.requestSubmit();
              } else {
                form.submit();
              }
            }
          }
        }
      }
      e.preventDefault();
      return;
    }

    // If we already have the limit of characters for the type of phone number, prevent the user from entering more unless they are deleting, tabbing, pressing an arrow key or there is a selection of text
    // Or, prevent input if the key is not a numeric character and the key is not a modifier key, arrow key, or delete/backspace key
    // Otherwise allow the default behavior
    if (
      numberNoSpaces.length >= Math.max(...possibleLengthsArray) &&
      !/^Backspace$|^Delete$|^Tab$|^Arrow(Up|Down|Left|Right)$/.test(e.key) &&
      !e.altKey &&
      !e.ctrlKey &&
      !e.metaKey &&
      this.field.selectionStart === this.field.selectionEnd
    ) {
      e.preventDefault();
    } else if (
      !(
        /^Backspace$|^Delete$|^Tab$|^Arrow(Up|Down|Left|Right)$/.test(e.key) ||
        e.metaKey ||
        e.ctrlKey ||
        e.altKey
      ) &&
      !isNumeric
    ) {
      e.preventDefault();
    }
  }

  /** @internal */
  get classNames() {
    return {
      sm: this.size === 'sm',
      'is-error': !!this.errorMessage,
      'is-disabled': this.disabled,
      'hidden-label': this.hideLabel,
      [this.variant]: !!this.variant,
    };
  }

  /**
   * Gets the label id for the phone number input
   * @internal
   */
  getLabelId() {
    const fieldId = this.getFieldId();
    return fieldId.replace('-field', '-label');
  }

  /**
   * Set the cursor position in the input field
   *
   * @internal
   */
  setCursorPosition() {
    if (this.userSelectionPosition) {
      this.field.setSelectionRange(
        this.userSelectionPosition.start,
        this.userSelectionPosition.start,
      );
    }
  }

  doParse(startCursorPosition: number, endCursorPosition: number) {
    const metadata = new Metadata();
    let iso2;
    let possibleLengthsArray: number[] = [];
    let number = this.field.value;
    // get the number and transform it with no spaces
    if (number.startsWith(`+${this.dialCode}`)) {
      number = number.replace(`+${this.dialCode}`, '');
    }

    const numberNoSpaces = number.replace(/\s/g, '');

    // we force iso2 when usOnly is true
    if (this.usOnly) {
      iso2 = 'us';
    } else {
      iso2 = this.intlPhoneInputLibrary.getSelectedCountryData().iso2;
    }

    // get the possible lengths for the selected country
    metadata.selectNumberingPlan(iso2.toUpperCase());
    if (metadata.numberingPlan) {
      possibleLengthsArray = metadata.numberingPlan.possibleLengths();
    }

    if (numberNoSpaces.length > Math.max(...possibleLengthsArray)) {
      number = number.substring(0, Math.max(...possibleLengthsArray));
    }

    this.userSelectionPosition = {
      start: startCursorPosition,
      end: endCursorPosition,
    };
    const initialLength = number.length;

    this.dialCode = this.usOnly
      ? 1
      : this.intlPhoneInputLibrary.getSelectedCountryData().dialCode;
    if (this.field) {
      const scrubbedNumber = number.replace(`+${this.dialCode}`, '');
      const maskedPhoneNumber = new AsYouType().input(
        `+${this.dialCode}${scrubbedNumber}`,
      );
      const afterDialCode =
        maskedPhoneNumber.split(`+${this.dialCode}`)[1] || '';

      // if the first number entered for a US phone number is the same as the US dial code, remove it from the value
      // this handles a bug where the dial code can be repeated for US phone numbers in the field
      if (
        this.dialCode === '1' &&
        afterDialCode.trim().substring(0, 1) === this.dialCode
      ) {
        this.value = afterDialCode.trim().substring(1);
      } else {
        this.value = afterDialCode.trim();
      }

      const lengthAdjustment = this.field.value.length - initialLength;

      if (lengthAdjustment !== 0) {
        this.userSelectionPosition = {
          start:
            this.userSelectionPosition.start + lengthAdjustment >= 0
              ? this.userSelectionPosition.start + lengthAdjustment
              : 0,
          end:
            this.userSelectionPosition.end + lengthAdjustment >= 0
              ? this.userSelectionPosition.end + lengthAdjustment
              : 0,
        };
      }
      this.setCursorPosition();
    }
  }

  /**
   * Parse the phone number input and format it based on country code
   *
   * @internal
   */
  parsePhoneNumber() {
    const startCursorPosition = this.field.selectionStart || 0;
    const endCursorPosition = this.field.selectionEnd || 0;
    if (this.firstParse) {
      this.doParse(startCursorPosition, endCursorPosition);
      this.firstParse = false;
    } else {
      // debounce calls to this function
      clearTimeout(this.parsePhoneNumberTimeout);
      this.parsePhoneNumberTimeout = setTimeout(() => {
        this.doParse(startCursorPosition, endCursorPosition);
      }, 50);
    }
  }

  /**
   * Initialize the library for handling the phone number dial code drop down
   *
   * @internal
   */
  initializeIntlPhoneInputLibrary() {
    this.intlPhoneInputLibrary = intlTelInput(this.field, {
      separateDialCode: true,
      initialCountry: 'US',
      autoPlaceholder: 'off',
      showFlags: false,
    });

    this.dialCode =
      this.intlPhoneInputLibrary.getSelectedCountryData().dialCode;

    let searchInput;
    if (this.shadowRoot) {
      searchInput = this.shadowRoot.querySelector('.iti__search-input');
    }

    if (searchInput) {
      searchInput.setAttribute('placeholder', '');
    }
  }

  /**
   * Override the labelTemple function from PdsFormElement
   *
   * @internal
   */
  override labelTemplate() {
    return html`<label
      for="${this.getFieldId()}"
      class="${this.classEl('label')}"
      id="${this.getLabelId()}"
    >
      <slot name="label">${this.label}</slot>
      <span class="pds-u-sr-only"
        >${this.translateText('numeric-characters-allowed')}</span
      >
      ${this.disabled
        ? html` <span class="${this.classEl('disabled-sr-text')} pds-u-sr-only"
            >, ${this.translateText('disabled')}</span
          >`
        : ''}
      ${this.required
        ? html`<span
            class="${this.classEl('required-indicator')}"
            aria-hidden="true"
          >
            *
          </span>`
        : nothing}
    </label>`;
  }

  protected override async firstUpdated() {
    super.firstUpdated();

    if (!this.usOnly) {
      this.initializeIntlPhoneInputLibrary();
    }

    if (this.value) {
      this.updateField();
    }

    // Need to set a timeout so the label can be properly populated
    setTimeout(async () => {
      this.isValidPhoneNumberInput = this.verifyLabel();
    }, 1000);

    this.field.addEventListener('countrychange', () => {
      this.handleChange();
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
  }

  protected async updated(
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>,
  ) {
    if (changedProperties.has('value')) {
      this.handleChange();
    }

    if (changedProperties.has('usOnly') && this.shadowRoot) {
      const countryContainer = this.shadowRoot.querySelector(
        '.iti__country-container',
      ) as HTMLElement;

      if (this.usOnly && this.intlPhoneInputLibrary) {
        if (countryContainer) {
          countryContainer.style.display = 'none';
        }
      } else if (!this.usOnly) {
        if (countryContainer) {
          countryContainer.style.display = '';
        }
      }
    }
    if (this.shadowRoot) {
      const countryDropdownButton = this.shadowRoot.querySelector(
        '.iti__selected-country',
      );
      if (countryDropdownButton) {
        countryDropdownButton.setAttribute(
          'aria-owns',
          'iti-0__country-listbox',
        );
      }
      const srLabel = document.createElement('span');
      srLabel.classList.add('search-sr-only', 'pds-u-sr-only');
      srLabel.textContent = `${this.translateText('search-combo-box')}`;
      srLabel.setAttribute('aria-live', 'polite');
      srLabel.setAttribute('id', 'sr-label');
      const countrySearch = this.shadowRoot.querySelector('.iti__search-input');
      const srElement = this.shadowRoot.querySelector('.search-sr-only');
      const countryContent = this.shadowRoot.querySelector(
        '.iti__dropdown-content',
      );
      if (countrySearch) {
        countrySearch.setAttribute('aria-describedby', 'sr-label');
      }
      if (countryContent) {
        if (!srElement) {
          countryContent.appendChild(srLabel);
        }
      }

      if (
        changedProperties.has('disabled') ||
        changedProperties.has('disabledContext')
      ) {
        await this.updateComplete;

        const ariaLabelledbyId = this.shadowRoot
          ?.querySelector('.pds-c-phone-number-input__label')
          ?.getAttribute('id');

        if (ariaLabelledbyId) {
          this.inputWrapper.setAttribute('role', 'group');
          const newAriaLabelledbyIds = this.disabledContext
            ? ariaLabelledbyId.concat(` ${this.name}-disabled-context`)
            : ariaLabelledbyId;
          this.inputWrapper.setAttribute(
            'aria-labelledby',
            newAriaLabelledbyIds,
          );
        }

        this.inputWrapper.setAttribute('aria-disabled', `${this.disabled}`);
        this.inputWrapper.setAttribute('tabindex', this.disabled ? '0' : '-1');

        if (countryDropdownButton) {
          if (this.disabled) {
            countryDropdownButton.setAttribute('disabled', 'true');
          }
          if (!this.disabled) {
            countryDropdownButton.removeAttribute('disabled');
          }
        }
      }
    }
  }

  render() {
    if (!this.isValidPhoneNumberInput) {
      return nothing;
    }

    return html`<div class="${this.getClass()}">
      ${this.labelTemplate()} ${this.helpTextTemplate()}
      <div
        class="${this.classEl('input-wrapper')} ${this.usOnly
          ? this.classEl('us-only')
          : ''}"
      >
        <input
          class="${this.classEl('input')}"
          type="tel"
          name="${this.name}"
          id="${this.fieldId || `${this.name}-${this.randomId}-field`}"
          ?required=${this.required}
          ?readonly=${this.readonly}
          ?disabled=${this.disabled}
          autocomplete="${ifDefined(this.autocomplete)}"
          inputmode="tel"
          minlength="${ifDefined(this.minLength)}"
          maxlength="${ifDefined(this.maxLength)}"
          pattern="${ifDefined(this.pattern)}"
          aria-invalid=${this.errorMessage ? 'true' : 'false'}
          aria-describedby=${!this.disabled
            ? this.getAriaDescribedBy()
            : nothing}
          value=${this.internalValue || this.value || nothing}
          @change=${this.handleChange}
          @input=${this.handleInput}
          @blur=${this.handleBlur}
          @focus=${this.handleFocus}
          @keydown=${this.handleKeydown}
        />
      </div>
      ${this.disabledContextTemplate()} ${this.errorMessageTemplate()}
    </div>`;
  }
}
