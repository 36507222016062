// disable requiredSlot lint until requiredSlot error can be investigated for this component (throws error even if it is populated, may be a lifecycle issue)
/* eslint-disable @nx/workspace-enforce-required-slot-decorator */

import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './primary-navigation-utility-menu.scss?inline';
import '../hr/hr';

/**
 * @summary This component is a navigational element (nav) that holds an Un unordered list element (ul)
 *
 * @slot default Required: Contains one or more menu items within the pds-primary-navigation-utility-menu, restricted to pds-primary-navigation-utility-menu-item elements
 */
@customElement('pds-primary-navigation-utility-menu', {
  category: 'component',
  type: 'component',
  state: 'stable',
  styles,
})
export class PdsPrimaryNavigationUtilityMenu extends PdsElement {
  /**
   * Style variant
   * - **default** renders the default primary-navigation-utility-menu
   * - **inverted** renders the inverted primary-navigation-utility-menu
   */
  @property()
  variant: 'default' | 'inverted' = 'default';

  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
    };
  }

  protected override firstUpdated() {
    super.firstUpdated();
    this.handleSlotValidation();
  }

  render() {
    return html`<pds-hr
        class="pds-c-primary-navigation-utility-menu__divider"
      ></pds-hr>
      <nav
        class=${this.getClass()}
        aria-label=${this.translateText('utility-menu')}
      >
        <ul class="pds-c-primary-navigation-utility-menu__list" role="menubar">
          <slot
            allowed-elements="pds-primary-navigation-utility-menu-item"
          ></slot>
        </ul>
      </nav>`;
  }
}
