// disable requiredSlot lint until requiredSlot error can be investigated for this component (causes global-header to error, I suspect that slots marked required here are not actually required?)
/* eslint-disable @nx/workspace-enforce-required-slot-decorator */

import { html, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import '../global-header-user-menu-dropdown-view/global-header-user-menu-dropdown-view';
import '../global-header-user-menu-label/global-header-user-menu-label';
import '../user-avatar/user-avatar';
import styles from './global-header-user-menu.scss?inline';

/**
 * @summary Principal's global header user menu. This component incorporates the pds-global-header-user-menu-dropdown-view for larger screens
 * and swaps to a mobile styling for smaller screens.
 *
 * @slot user-avatar Required: Replaces the default svg and initials with a user's image, restricted to img and svg
 * @slot default Required: The user menu's menu content area
 * @slot footer Optional: The user menu's footer content area
 */
@customElement('pds-global-header-user-menu', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsGlobalHeaderUserMenu extends PdsElement {
  /**
   * The full name to be placed in the user menu label
   */
  @property()
  fullName: string;

  /**
   * Indicates if the user has notifications
   */
  @property()
  notifications: boolean = false;

  /**
   * The intials to be displayed
   */
  @property()
  initials: string;

  /**
   * Tracks the open/closed state of the userm menu when it's in mobile view
   * @internal
   */
  @state()
  isUserMenuMobileActive: boolean = false;

  /**
   * @internal
   */
  @query('slot[name="user-avatar"]')
  userAvatarSlot: HTMLSlotElement;

  /**
   * Flag for mobile view
   * @internal
   */
  isMobile() {
    if (
      ((this.responsiveViewportSize === 'xs' ||
        this.responsiveViewportSize === 'sm' ||
        this.responsiveViewportSize === 'md') &&
        !this.classList.contains(this.classEl('break-faster'))) ||
      (this.responsiveViewportSize === 'xs' &&
        this.classList.contains(this.classEl('break-faster')))
    ) {
      return true;
    }
    return false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();

    // Since the close button for the user mobile menu is in a different component, we need to listen to its
    // custom event and change the state accordingly
    this.addEventListener('pds-global-header-mobile-tray-close', (e: Event) => {
      e.stopPropagation();
      const customEvent = e as CustomEvent;

      this.dispatchEvent(
        new CustomEvent('pds-global-header-user-menu-tray-close', {
          bubbles: true,
          composed: true,
          detail: customEvent.detail,
        }),
      );
      this.isUserMenuMobileActive = false;
    });
  }

  firstUpdated() {
    super.firstUpdated();
    this.setWindowResizeHandler();
    this.initLocalization();
  }

  async updated() {
    await this.updateComplete;
    // Adds title tip for user menu in case of a notification
    if (this.shadowRoot) {
      const dropdownViewElement = this.shadowRoot.querySelector(
        'pds-global-header-user-menu-dropdown-view',
      );
      if (dropdownViewElement) {
        if (dropdownViewElement.shadowRoot) {
          const dropdownButton = dropdownViewElement.shadowRoot.querySelector(
            '.pds-c-global-header-user-menu-dropdown-view__button',
          );
          const srElement = dropdownViewElement.shadowRoot.querySelector(
            '.pds-c-global-header-user-menu-dropdown-view__sr-only',
          );
          if (dropdownButton) {
            dropdownButton.setAttribute(
              'aria-describedby',
              'notification-sr-id',
            );
          }
          if (srElement) {
            if (this.notifications) {
              srElement.textContent = `${this.translateText('your-account-has-notifications')}`;
            } else {
              srElement.textContent = '';
            }
          }
          if (dropdownButton && srElement) {
            dropdownButton.addEventListener('focus', () => {
              if (this.notifications) {
                srElement.classList.remove('pds-u-sr-only');
              }
            });
            dropdownButton.addEventListener('mouseover', () => {
              if (this.notifications) {
                srElement.classList.remove('pds-u-sr-only');
              }
            });
            dropdownButton.addEventListener('focusout', () => {
              srElement.classList.add('pds-u-sr-only');
            });
            dropdownButton.addEventListener('mouseout', () => {
              srElement.classList.add('pds-u-sr-only');
            });
          }
        }
      }
      // Adds title tip on user menu in case of a notification in mobile view
      const mobileDropdownButton = this.shadowRoot.querySelector(
        '.pds-c-global-header-user-menu__mobile-button',
      );
      const mobileSrElement = this.shadowRoot.querySelector(
        '.pds-c-global-header-user-menu__sr-only',
      );
      if (mobileDropdownButton && mobileSrElement) {
        mobileDropdownButton.addEventListener('focus', () => {
          if (this.notifications) {
            mobileSrElement.classList.remove('pds-u-sr-only');
          }
        });
        mobileDropdownButton.addEventListener('mouseover', () => {
          if (this.notifications) {
            mobileSrElement.classList.remove('pds-u-sr-only');
          }
        });
        mobileDropdownButton.addEventListener('focusout', () => {
          mobileSrElement.classList.add('pds-u-sr-only');
        });
        mobileDropdownButton.addEventListener('mouseout', () => {
          mobileSrElement.classList.add('pds-u-sr-only');
        });
      }
    }
  }

  openUserMenuMobile() {
    this.isUserMenuMobileActive = true;

    setTimeout(() => {
      this.dispatchEvent(
        new CustomEvent('pds-global-header-user-menu-open', {
          bubbles: true,
          composed: true,
        }),
      );
    }, 250);
  }

  getAvatar() {
    const avatar = this.userAvatarSlot.assignedElements()[0];
    let clonedAvatar: HTMLElement | null = null;

    if (avatar && (avatar.tagName === 'IMG' || avatar.tagName === 'SVG')) {
      clonedAvatar = avatar.cloneNode(true) as HTMLElement;
      clonedAvatar.setAttribute('slot', 'user-image');
    }

    return clonedAvatar;
  }

  getMobileMarkup() {
    return html`
      <slot
        name="user-avatar"
        allowed-elements="img, svg"
        @slotchange=${this.handleSlotValidation}
        style="display: none"
      ></slot>
        <button
          class="${this.classEl('mobile-button')}"
          type="button"
          aria-expanded="${this.isUserMenuMobileActive}"
          @click=${this.openUserMenuMobile}
          aria-describedby="notification-sr-id"
        >
          <pds-global-header-user-menu-label
            slot="label"
            fullName=${this.fullName}
            initials=${this.initials ? this.initials : nothing}
            notifications=${this.notifications ? 'true' : nothing}
            hideName="true"
            >${this.getAvatar()}</pds-global-header-user-menu-label
          >
        </button>
        <pds-global-header-mobile-tray
          variant="right"
          open="${this.isUserMenuMobileActive ? true : nothing}"
          style="--pds-global-header-tray-padding: 16px"
          ><span slot="main"
            ><span class="${this.classEl('mobile-tray-label')}"
              ><pds-global-header-user-menu-label
                slot="label"
                fullName=${this.fullName}
                initials=${this.initials ? this.initials : nothing}
                >${this.getAvatar()}</pds-global-header-user-menu-label
              ></span
            ><span class="${this.classEl('mobile-main-content')}"
              ><slot></slot></span></span
          ><slot name="footer" slot="footer"></slot
        ></pds-global-header-mobile-tray>
      </slot>
      ${this.notifications ? html`<span class="${this.classEl('sr-only')} pds-u-sr-only" id="notification-sr-id">${this.translateText('your-account-has-notifications')}</span>` : nothing}
    `;
  }

  render() {
    return html`${this.isMobile()
      ? this.getMobileMarkup()
      : html`<pds-global-header-user-menu-dropdown-view class="${this.classList.contains(`${this.classEl('break-faster')}`) ? 'pds-c-global-header-user-menu-dropdown-view__break-faster' : ''}"
          ><pds-global-header-user-menu-label
            slot="label"
            fullName=${this.fullName}
            initials=${this.initials}
            notifications=${this.notifications ? 'true' : nothing}
            ><slot name="user-avatar" slot="user-image" allowed-elements="img, svg"
            @slotchange=${this.handleSlotValidation}></slot
          ></pds-global-header-user-menu-label>
          <slot></slot>
          <slot name="footer" slot="footer">
        </pds-global-header-user-menu-dropdown-view>`}`;
  }
}
