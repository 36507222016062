import { PdsHeading, PdsLayoutContainer, PdsLink } from "@principal/design-system-react";
import React, { ReactElement } from "react";
import { DateTime, Duration } from "luxon";
import { Claim } from "../domain/Claim";
import DataTable from "./DataTable";
import { Path } from "../enums/Path";
import ClaimStatusButton from "../atoms/ClaimStatusButton";
import { Coverage } from "../enums/Coverage";
import { useCoverage } from "../hooks/useCoverage";
import LoadingSpinner from "../atoms/LoadingSpinner";
import { useClaims } from "../hooks/useClaims";
import { ClaimStatus } from "../enums/ClaimStatus";
import findCoverageUrlFor = Coverage.findCoverageUrlFor;

interface ClaimsProps {
    onlyRecent?: boolean
}

const Claims = (props: ClaimsProps) => {
    const coverage = useCoverage();
    const { loading, data: claims, error } = useClaims();

    return (
        <div id="claims" className="pds-u-background-subtle">
            <PdsLayoutContainer>
                <div className="flex-gap">
                    <PdsHeading headingTag="h2" variant="title-sm">{props.onlyRecent ? 'Recent claims' : 'Claim history'}</PdsHeading>
                    <div>
                        {props.onlyRecent
                            ? <>Claims in progress or processed within the past 90 days will show here. For all other claims, view <PdsLink href={findCoverageUrlFor(coverage, Path.CLAIMS)}>Claim history</PdsLink>.</>
                            : 'View all of your claims. Click on a claim in the table below to view all of the claim details and documents or to update a claim.'}
                    </div>
                    {buildClaimsFor()}
                </div>
            </PdsLayoutContainer>
        </div>
    )

    function buildClaimsFor() {
        if(loading) {
            return <LoadingSpinner/>
        } else if(error) {
            console.log('error', error)
            return <p>Could not load claims information, please try again later.</p>;
        } else if(!claims?.length) {
            return <p>No claims found.</p>
        }
        const onlyRecent = props.onlyRecent;
        const filteredClaims = onlyRecent
            ? claims.filter((c: Claim) => {
                const mostRecentPaymentDate = c.payments
                    .flatMap(p => p.effectiveDate)
                    .sort((d1, d2) => d2.toMillis() - d1.toMillis())?.[0];
                const compareDate = mostRecentPaymentDate ?? c.statusDate;
                const decisionStatuses = [ClaimStatus.DENIED, ClaimStatus.APPROVED];
                return !decisionStatuses.includes(c.status) || withinNinetyDays(compareDate);
            })
            : claims;
        const sortedClaims = filteredClaims.sort((c1: Claim, c2: Claim) => c2.submitted.toMillis() - c1.submitted.toMillis())
        if(!sortedClaims.length) {
            return <p>No recent claims found.</p>
        }
        const headers = ['Claim number', 'Type', 'Person', 'Claim amount', 'Submitted', 'Status']
        const tableProps = {
            title: "Claims",
            headers,
            rows: sortedClaims.map((c: Claim) => buildClaimRow(c))
        }
        return <DataTable {...tableProps}/>
    }

    function buildClaimRow(claim: Claim): (string | ReactElement)[] {
        const claimUrl = `${findCoverageUrlFor(coverage, Path.CLAIMS)}/${claim.claimId}`;
        return [<PdsLink href={claimUrl}>{claim.claimId}</PdsLink>, claim.type, claim.person, claim.amount.format(),
            claim.submitted.toLocaleString(DateTime.DATE_SHORT), <ClaimStatusButton claim={claim}/>]
    }

    function withinNinetyDays(date: DateTime){
        return DateTime.now().startOf('day').diff(date) <= Duration.fromObject({ day: 90 });
    }
}
export default Claims;