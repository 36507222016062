import { html } from 'lit';
import { themedefault } from '@principal/design-system-tokens';
import { pdsCustomIconElement as customElement } from '../../../decorators/pds-icon';
import { PdsCustomIcon } from '../PdsCustomIcon';

@customElement('pds-icon-bell-notification')
export class PdsIconBellNotification extends PdsCustomIcon {
  render() {
    return html`<svg
      width=${this.getSize(this.size)}
      height=${this.getSize(this.size)}
      viewBox="0 0 40 40"
      fill="none"
      strokeWidth="2"
      aria-hidden="true"
      display="block"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.2477 26.6557H6.77438C7.02642 26.3545 7.28935 26.0609 7.55266 25.7669C8.09182 25.1649 8.63259 24.561 9.08492 23.8865C10.8155 21.3072 11.4848 18.2115 11.6608 15.1358C11.6831 14.7426 11.6878 14.3542 11.6925 13.9681C11.703 13.1088 11.7132 12.2607 11.9177 11.3948C12.9406 7.06246 17.2731 4.32102 21.6506 5.19171C21.8778 5.2369 22.0902 5.30106 22.2949 5.36287C22.9302 5.55472 23.4909 5.72401 24.1864 5.23391C25.196 4.52203 25.0506 2.9478 23.9617 2.37919C23.4227 2.09821 22.2838 1.87554 21.6666 1.78781C14.8704 0.822724 8.69419 5.89969 8.32787 12.7469C8.30582 13.1589 8.28785 13.5606 8.27028 13.9531C8.12117 17.2852 8.00116 19.9667 5.66156 22.8509C5.42974 23.1366 5.16892 23.4047 4.90817 23.6726C4.25127 24.3477 3.59485 25.0223 3.40375 25.9782C2.97971 28.1005 4.54764 29.9813 6.68724 30.0452C10.2003 30.0249 13.7156 30.0344 17.2309 30.0439C22.7802 30.0589 28.3294 30.0738 33.8698 29.9719C36.1726 29.5882 37.3803 27.035 36.212 24.9943C35.9917 24.6089 34.9433 23.369 34.5975 23.1113C33.3476 22.1807 31.7186 23.2163 31.9201 24.735C31.9864 25.2347 32.397 25.6842 32.7789 26.1022C32.9529 26.2926 33.1209 26.4765 33.2477 26.6557Z"
        fill="${this.color}"
      />
      <path
        d="M18.0236 33.5849C17.6967 33.3806 17.2427 33.2784 16.8614 33.3328C15.995 33.4567 15.3489 34.3068 15.4377 35.1725C15.5077 35.8527 16.2542 36.7445 16.7631 37.1782C19.0159 39.0961 22.6868 38.5875 24.2242 36.0348C25.3898 34.0997 23.0959 32.4605 21.7011 33.7882C21.6061 33.8787 21.5356 33.9982 21.4674 34.1137C21.4181 34.1973 21.3701 34.2788 21.3148 34.3457C20.812 34.9543 20.0111 35.1692 19.2785 34.8282C18.9369 34.669 18.7518 34.4123 18.5686 34.1581C18.4156 33.9459 18.2639 33.7355 18.0236 33.5849Z"
        fill="${this.color}"
      />
      <path
        d="M29.296 7.72476C33.573 7.31677 36.8221 11.2149 35.4279 15.2942C33.8283 19.9758 27.2169 20.4372 24.8941 16.0788C23.0426 12.6051 25.3237 8.10411 29.296 7.72476Z"
        fill="${themedefault.SemanticBorderError}"
      />
    </svg>`;
  }
}
