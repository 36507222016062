import { html, isServer, nothing } from 'lit';
import { property, queryAssignedElements, state } from 'lit/decorators.js';
import * as focusTrap from 'focus-trap';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './secondary-navigation.scss?inline';
import '../heading/heading';
import '../hr/hr';
import '../layout-container/layout-container';
// eslint-disable-next-line import/no-duplicates
import '../secondary-navigation-level-one/secondary-navigation-level-one';
import { required } from '../../decorators/required';
// eslint-disable-next-line import/no-duplicates
import { PdsSecondaryNavigationLevelOne } from '../secondary-navigation-level-one/secondary-navigation-level-one';
import { PdsSecondaryNavigationLevelTwo } from '../secondary-navigation-level-two/secondary-navigation-level-two';
import { requiredSlot } from '../../decorators/requiredSlot';

/**
 * @summary A component that renders a secondary navigation
 *
 * @slot content Optional: Open slot for additional content to be passed in
 * @slot default Required: Contains one or more secondary nav level one items, restricted to pds-secondary-navigation-level-one elements
 */
@customElement('pds-secondary-navigation', {
  category: 'component',
  type: 'component',
  state: 'stable',
  styles,
})
export class PdsSecondaryNavigation extends PdsElement {
  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
  }

  protected override firstUpdated() {
    super.firstUpdated();
    this.setWindowResizeHandler();
    this.handleSlotValidation();
    this.monitorTheDropdowns();
    this.checkBreakPropValue();
  }

  /**
   * @internal
   */
  @property()
  declare responsiveViewportSize: 'xs' | 'sm' | 'md' | 'lg' | 'xl';

  /**
   * Aria label to go around the navigation container
   */
  @property({ type: String })
  navAriaLabel: string;

  /**
   * Sets a heading for your secondary nav element. This is a **required** property.
   */
  @required
  @property({ type: String })
  heading: string;

  /**
   * Sets a description for your secondary nav element
   */
  @property()
  description: string;

  /**
   * Sets an description in a new line for your secondary nav element
   */
  @property()
  secondDescription?: string;

  /**
   * - **default** stacks to collapsed view at md viewport
   * - **faster** stacks to collapsed view one breakpoint smaller than default
   * - **slower** stacks to collapsed view one breakpoint larger than default
   */
  @property()
  break: 'faster' | 'slower' | 'default' = 'default';

  /**
   * Checks if it's a desktop view
   * @internal
   */
  @state()
  isDesktopView: boolean = true;

  /**
   * @internal
   * This grabs the logo from the content slot
   */
  @queryAssignedElements({ slot: 'content' })
  slottedContent: HTMLElement[];

  /**
   * @internal
   * This grabs the secondary-nav-level-one items
   */
  @queryAssignedElements({ slot: undefined })
  defaultSlotElements: HTMLElement[];

  /**
   * @internal
   */
  @state()
  trap: focusTrap.FocusTrap;

  /**
   * @internal
   */
  @state()
  openDropdown: any;

  /**
   * Checks to see if the secondary navigation has valid markup
   * @internal
   */
  @state()
  isValidSecondaryNav: boolean = true;

  handleSlotChange() {
    this.requestUpdate();
  }

  monitorTheDropdowns() {
    window.addEventListener(
      'pds-secondary-navigation-dropdown-open',
      (e: Event) => {
        this.openDropdown = e.target;
        this.initializeTrap();
        this.trap.activate();
      },
    );

    window.addEventListener('pds-secondary-navigation-dropdown-close', () => {
      this.trap.deactivate();
    });
  }

  /**
   * @internal
   * Initialize the focus trap
   */
  initializeTrap() {
    this.trap = focusTrap.createFocusTrap(this.openDropdown, {
      allowOutsideClick: true,
      // if there aren't any tabbable items within the dropdown, set the fallback focus on the dropdown to prevent errors from being thrown
      fallbackFocus: this.openDropdown,
      escapeDeactivates: true,
      returnFocusOnDeactivate: true,
      setReturnFocus: this.openDropdown,
      tabbableOptions: {
        getShadowRoot: true,
      },
    });
  }

  checkForMobileView() {
    this.isDesktopView = false;
    if (
      ['xs', 'sm', 'md'].includes(this.responsiveViewportSize) &&
      this.break === 'default'
    ) {
      return true;
    }
    if (
      ['xs', 'sm'].includes(this.responsiveViewportSize) &&
      this.break === 'faster'
    ) {
      return true;
    }
    if (
      ['xs', 'sm', 'md', 'lg'].includes(this.responsiveViewportSize) &&
      this.break === 'slower'
    ) {
      return true;
    }

    this.isDesktopView = true;
    return false;
  }

  /**
   * @internal
   *
   * Checks markup for errors and adds browser console errors for misconfigurations
   */
  checkBreakPropValue() {
    const secondaryNavLevelOnes = this.querySelectorAll(
      'pds-secondary-navigation-level-one',
    );
    const secondaryNavLevelTwos = this.querySelectorAll(
      'pds-secondary-navigation-level-two',
    );

    secondaryNavLevelOnes.forEach((secondaryNavLevelOne) => {
      const pdsSecondaryNavLevelOne =
        secondaryNavLevelOne as PdsSecondaryNavigationLevelOne;
      const breakAttribute = pdsSecondaryNavLevelOne.break
        ? pdsSecondaryNavLevelOne.break
        : pdsSecondaryNavLevelOne.getAttribute('break');

      const errorStart = `Invalid break prop value: '${breakAttribute}'. `;
      const errorEnd = `Please update the break prop value with the correct value to remove this error.`;

      if (this.break !== breakAttribute) {
        console.error(
          `${errorStart}Secondary navigation level one should use the same value for break prop as used in secondary navigation. ${errorEnd}`,
          pdsSecondaryNavLevelOne,
        );
        this.isValidSecondaryNav = false;
      }
    });
    secondaryNavLevelTwos.forEach((secondaryNavLevelTwo) => {
      const pdsSecondaryNavLevelTwo =
        secondaryNavLevelTwo as PdsSecondaryNavigationLevelTwo;
      const breakAttribute = pdsSecondaryNavLevelTwo.break
        ? pdsSecondaryNavLevelTwo.break
        : pdsSecondaryNavLevelTwo.getAttribute('break');

      const errorStart = `Invalid break prop value: '${breakAttribute}'. `;
      const errorEnd = `Please update the break prop value with the correct value to remove this error.`;

      if (this.break !== breakAttribute) {
        console.error(
          `${errorStart}Secondary navigation level two should use the same value for break prop as used in secondary navigation. ${errorEnd}`,
          pdsSecondaryNavLevelTwo,
        );
        this.isValidSecondaryNav = false;
      }
    });
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      'desktop-view': this.isDesktopView,
    };
  }

  @requiredSlot(['default'])
  render() {
    if (!this.isValidSecondaryNav) {
      return nothing;
    }

    if (!isServer && this.checkForMobileView()) {
      return html`<nav
      aria-label="${this.translateText('secondary-navigation')}"
      class=${this.getClass()}
    ><div class="${this.classMod('mobile')}"><div class="${this.classEl(
      'accordion',
    )}">
      <pds-accordion
        variant="inverted"
        class="${this.classEl('title-accordion')}"
        ><span slot="summary-title">
          <span class="${this.classEl('category-title')}"
            >${this.heading}</span
          ></span>
          ${
            this.description
              ? html`<div
                  slot="summary-description"
                  class="${this.classEl('description')}"
                >
                  <p class="${this.classEl('description')}">
                    ${this.description}
                  </p>
                  ${this.secondDescription
                    ? html`<p class="${this.classEl('description')}">
                        ${this.secondDescription}
                      </p>`
                    : nothing}
                </div>`
              : nothing
          }
        </span>
        <span slot="accordion-content"><slot
        @slotchange=${this.handleSlotChange}
      ></slot></span>
      </pds-accordion>
    </div></div></nav>`;
    }
    return html`<nav
      aria-label="${this.translateText('secondary-navigation')}"
      class=${this.getClass()}
    >
      <div class="${this.classMod('desktop')}">
        <div class="${this.classEl('desktop-wrapper')}">
          <pds-layout-container>
            <span class="${this.classEl('container')}">
              <span class="${this.classEl('content')}"
                ><slot name="content"></slot
              ></span>
              <span class="${this.classEl('category-title')}"
                >${this.heading}</span
              >
              <div class="${this.classEl('description-container')}">
                ${this.description
                  ? html`<p class="${this.classEl('description')}">
                      ${this.description}
                    </p>`
                  : nothing}
                ${this.description && this.secondDescription
                  ? html`<p class="${this.classEl('description')}">
                      ${this.secondDescription}
                    </p>`
                  : nothing}
              </div>
              <div
                aria-label="${this.navAriaLabel}"
                class="${this.classEl('nav-container')}"
              >
                <ul class="${this.classEl('nav-container-list')}" role="list">
                  <slot
                    allowed-elements="pds-secondary-navigation-level-one, slot"
                    @slotchange=${this.handleSlotChange}
                  ></slot>
                </ul>
              </div>
              <pds-hr class="${this.classEl('hr')}"></pds-hr
            ></span>
          </pds-layout-container>
        </div>
      </div>
    </nav>`;
  }
}
