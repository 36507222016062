import React from "react";
import "@pds-react/tab/dist/tab.min.css"
import {Relationship} from "../../enums/Relationship";
import {Coverage} from "../../enums/Coverage";
import LoadingSpinner from "../../atoms/LoadingSpinner";
import {useProduct} from "../../hooks/useProduct";
import {useCoverage} from "../../hooks/useCoverage";
import {BenefitsProps} from '../Benefits';
import BenefitsTab from "./BenefitsTab";
import {PaidLeaveBenefitScheduleGroup} from "../../domain/benefits/PaidLeaveBenefitScheduleGroup";
import _ from "lodash";
import { PdsAlert, PdsHeading, PdsLink } from "@principal/design-system-react";
import Linkify from "linkify-react";
import { PdsIconExternalLink } from "@principal/design-system-icons-react";

const PaidLeaveBenefits = (props: BenefitsProps) => {
    const coverage = useCoverage();
    const { loading: productLoading, data: product, error: productError } = useProduct()

    if(![Coverage.PAID_FAMILY_LEAVE, Coverage.PAID_MEDICAL_LEAVE].includes(coverage)) {
        return null;
    }

    return (
        <>
            {buildBenefits()}
        </>
    )

    function buildBenefits() {
        if(productLoading) {
            return <LoadingSpinner/>
        } else if(productError || !product) {
            console.log('member group error', productError)
            return <p>Could not load member information, please try again later.</p>;
        }
        return <>
            {buildBenefitTabs()}
        </>
    }

    function buildBenefitTabs() {
        const benefitScheduleGroups = product.configuration.benefitSchedule.groups
        const headers = benefitScheduleGroups.map((g: PaidLeaveBenefitScheduleGroup) => findGroupNameFor(g))
        const sectionsByRelationship = new Map([
            [Relationship.YOURSELF, benefitScheduleGroups.map((g: PaidLeaveBenefitScheduleGroup) => buildBenefitScheduleGroup(g))]
        ]);
        return <div className="flex-gap">
            <PdsAlert variant='warning' hideDismissButton={true}>The following benefits are not guaranteed, and reflect the leaves that you have taken with an estimated amount of leave remaining, if you continue to remain eligible.</PdsAlert>
            <BenefitsTab {...props} headers={headers} sectionsByRelationship={sectionsByRelationship} />
        </div>
    }

    function buildBenefitScheduleGroup(benefitScheduleGroup: PaidLeaveBenefitScheduleGroup) {
        const groupName = findGroupNameFor(benefitScheduleGroup);
        return <>
            {buildBenefitsFor(groupName, benefitScheduleGroup.benefitsPayableDescription)}
        </>
    }

    function buildBenefitsFor(title: string, benefits: string) {
        return <div className="flex-gap">
            <PdsHeading headingTag="h3" variant="label-default">{title}</PdsHeading>
            <div style={{whiteSpace: "pre-line"}}>
                <Linkify options={{
                    rel: "noreferrer",
                    target: "_blank",
                    nl2br: true,
                    render: {
                        url: ({ attributes, content }) => {
                            return <PdsLink {...attributes}>
                                {content}
                                <span slot="icon-right">
                                    <PdsIconExternalLink size="default"/>
                                </span>
                            </PdsLink>
                        }
                    }
                }}>
                    {benefits.replace(/((\\r)?\\n)/gi, ' $1')}
                </Linkify>
            </div>
        </div>;
    }

    function findGroupNameFor(benefitScheduleGroup: PaidLeaveBenefitScheduleGroup) {
        const benefitScheduleGroupType = benefitScheduleGroup.type
        const hyphenIndex = benefitScheduleGroupType.indexOf('-');
        return `${_.capitalize(hyphenIndex < 0 ? benefitScheduleGroupType : benefitScheduleGroupType.substring(hyphenIndex + 1).trim())} benefits`;
    }
}

export default PaidLeaveBenefits;
