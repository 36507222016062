import {
    PdsAccordion,
    PdsLayoutContainer,
    PdsSecondaryNavigation,
    PdsSecondaryNavigationLevelOne,
} from "@principal/design-system-react";
import React, { useEffect, useRef } from "react";
import { Coverage } from "../enums/Coverage";
import { Path } from "../enums/Path";
import { useCoverage } from "../hooks/useCoverage";
import LoadingSpinner from "../atoms/LoadingSpinner";
import { useMember } from "../hooks/useMember";
import { useAnnouncements } from "../hooks/useAnnouncements";
import { Announcement } from "../domain/Announcement";
import { PdsIconAlertCircle } from "@principal/design-system-icons-react";
import findCoverageUrlFor = Coverage.findCoverageUrlFor;
import findCoverageNameFor = Coverage.findCoverageNameFor;

const Header = () => {
    const coverage = useCoverage();

    const { loading: memberLoading, data: member, error: memberError } = useMember();
    const { loading: announcementsLoading, data: announcementsData, error: announcementsError } = useAnnouncements();

    const secondaryNavigationRef = useRef(null) as React.RefObject<any>;
    const announcementsRef = useRef(null) as React.RefObject<any>;

    useEffect(() => {
        const secondaryNavigationInterval = setInterval(() => {
            const secondaryNavigationDesktop = secondaryNavigationRef.current?.shadowRoot?.querySelector('.pds-c-secondary-navigation__desktop-wrapper')
            if (secondaryNavigationDesktop) {
                secondaryNavigationDesktop.setAttribute('style', 'padding-top: 0; margin-bottom: 0');
            }
        }, 100)
        return () => clearInterval(secondaryNavigationInterval)
    }, [secondaryNavigationRef])

    useEffect(() => {
        const announcementsInterval = setInterval(() => {
            const announcementsDetail = announcementsRef.current?.shadowRoot?.querySelector('details')
            if (announcementsDetail) {
                announcementsDetail.setAttribute('style', 'border-top: 0; border-bottom: 0');
            }
        }, 100)
        return () => clearInterval(announcementsInterval)
    }, [announcementsRef])

    const buildMember = () => {
        if(memberLoading) {
            return {
                heading: 'Loading...'
            }
        } else if(memberError) {
            console.log(memberError)
            return {
                heading: 'Could not load member information, please try again later.'
            }
        }
        const policyNumber = member?.policyNumber;
        const privacyId = member?.privacyId;
        return {
            heading: findCoverageNameFor(coverage, member),
            description: `${member?.firstName} ${member?.lastName}`,
            secondDescription: `${policyNumber ? `Group number: ${policyNumber}` : ''}${privacyId ? `, ID number: ${privacyId}` : ''}`
        }
    }

    const buildAnnouncements = () => {
        if(announcementsLoading) {
            return <LoadingSpinner/>
        } else if(announcementsError) {
            console.log(announcementsError)
            return <p>Could not load announcements, please try again later.</p>;
        }
        const announcements = announcementsData?.announcements;
        return !!announcements?.length
            && <div id="announcement" className="pds-u-background-brand-gradient-xstrong">
                <PdsLayoutContainer className="pds-u-padding-0">
                    <PdsAccordion variant="inverted" ref={announcementsRef}>
                        <div slot="summary-title" className="pds-u-flex-align-start">
                            <div className="pds-u-margin-right-16">
                                <PdsIconAlertCircle size="lg"/>
                            </div>
                            <div>
                                {announcementsData?.title}
                            </div>
                        </div>
                        <div slot="accordion-content">
                            {announcements?.map((a: Announcement) => (
                                <div key={a.message}>
                                    <div>{a.message}</div>
                                    <br/>
                                </div>
                            ))}
                        </div>
                    </PdsAccordion>
                </PdsLayoutContainer>
            </div>;
    }

    const hasResources = Coverage.hasResources(coverage);

    return (
        <>
            <PdsSecondaryNavigation ref={secondaryNavigationRef} {...buildMember()}>
                <PdsSecondaryNavigationLevelOne text='Overview' href={findCoverageUrlFor(coverage, Path.OVERVIEW)}/>
                <PdsSecondaryNavigationLevelOne text="Claim history" href={findCoverageUrlFor(coverage, Path.CLAIMS)}/>
                {hasResources &&
                    <PdsSecondaryNavigationLevelOne text="Discounts and services" href={findCoverageUrlFor(coverage, Path.RESOURCES)}/>}
                <PdsSecondaryNavigationLevelOne text='Forms' href={findCoverageUrlFor(coverage, Path.FORMS)}/>
                <PdsSecondaryNavigationLevelOne text='Contact us' href={process.env.REACT_APP_CONTACT_US_URL}/>
            </PdsSecondaryNavigation>
            {buildAnnouncements()}
        </>
)}

export default Header;
