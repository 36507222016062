import { html, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import styles from './currency-input.scss?inline';
import { PdsFormElement } from '../pds-form-element/PdsFormElement';
import { PdsButton } from '../button/button';

const submittable = ['pds-button[type="submit"]:not([disabled])'];

const blocking = ['pds-currency-input'];

/**
 * @summary This component provides currency input masking
 *
 * @fires pds-currency-input-input an event dispatched on input
 * @fires pds-currency-input-change an event dispatched on change
 * @fires pds-currency-input-blur an event dispatched on blur
 * @fires pds-currency-input-focus an event dispatched on focus
 */
@customElement('pds-currency-input', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsCurrencyInput extends PdsFormElement {
  /** @internal */
  @query('input')
  field: HTMLInputElement;

  /**
   * Style variant
   * - **default** renders the standard currency-input color variant
   * - **inverted** renders the inverted currency-input color variant
   */
  @property()
  variant: 'default' | 'inverted' = 'default';

  /**
   * The size of the component.
   *
   * - **default**
   * - **sm** renders a the small version of the input
   *
   * NOTE: This is NOT the HTML size attribute that controls the width of the input.
   */
  @property()
  size: 'sm' | 'default' = 'default';

  /**
   * Removes decimal from input value
   */
  @property({ type: Boolean })
  withoutDecimal: boolean = false;

  /**
   * Removes '$' prefix
   */
  @property({ type: Boolean })
  removePrefix: boolean = false;

  /**
   * A unique identifier for the currency prefix.
   @internal
   */
  @state()
  currencyPrefixId: string = '';

  /**
   * This grabs the input element
   * @internal
   */
  @query('input')
  inputEl: HTMLInputElement;

  firstUpdated() {
    super.firstUpdated();
    this.currencyPrefixId = `${this.classEl('label')}-${this.randomId}`;
    if (this.value) {
      this.handleInput();
    }
  }

  private handleChange() {
    this.dispatchEvent(
      new Event('pds-currency-input-change', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  private handleInput() {
    // Current cursor position
    let cursorPosition = this.inputEl.selectionStart as number;

    // Initial length of input value
    const initialLength = this.inputEl.value.length;

    // Remove non-numeric characters from the input value
    let el = this.inputEl.value.replace(/[^\d.]/g, '');

    // Find the index of the decimal point
    const decimalIndex = el.indexOf('.');

    if (decimalIndex !== -1) {
      if (this.withoutDecimal) {
        // Removing any decimal
        el = el.substring(0, decimalIndex);
      } else if (el.substring(decimalIndex + 1).indexOf('.') !== -1) {
        // Allow only one decimal point, remove other decimal points
        el =
          el.substring(0, decimalIndex + 1) +
          el.substring(decimalIndex + 1).replace(/\./g, '');
      }
      // Allow only two digits after decimal point
      el = el.substring(0, decimalIndex + 3);
    }

    this.value = el;
    // Add commas for thousand seperator
    el = el.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Update the input value with the formatted input
    this.inputEl.value = el;

    // Put cursor back in the orignal position
    const updatedLength = this.inputEl.value.length;
    if (cursorPosition > 0) {
      cursorPosition = updatedLength - initialLength + cursorPosition;
    }
    this.inputEl.setSelectionRange(cursorPosition, cursorPosition);

    this.dispatchEvent(
      new Event('pds-currency-input-input', {
        bubbles: true,
        composed: true,
      }),
    );
  }

  private handleBlur() {
    this.dispatchEvent(
      new Event('pds-currency-input-blur', {
        bubbles: false,
        composed: true,
      }),
    );
  }

  private handleFocus() {
    this.dispatchEvent(
      new Event('pds-currency-input-focus', {
        bubbles: false,
        composed: true,
      }),
    );
  }

  private handleKeydown(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
      const { form } = this.internals;
      const submitElement: PdsButton | undefined | null = form?.querySelector(
        submittable.join(','),
      );

      if (form) {
        // not testable in Jest
        /* istanbul ignore next */
        if (submitElement) {
          const clickEvent = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            composed: true,
          });
          submitElement.button.dispatchEvent(clickEvent);
        } else {
          const blockingElements = form?.querySelectorAll(blocking.join(','));
          if (blockingElements && blockingElements.length <= 1) {
            // Older versions of Safari don't support the requestSubmit method
            if (form.requestSubmit) {
              form.requestSubmit();
            } else {
              form.submit();
            }
          }
        }
      }
    }
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      sm: this.size === 'sm',
      'is-error': !!this.errorMessage,
      'is-disabled': this.disabled,
      'hidden-label': this.hideLabel,
      'help-text': !!this.helpText,
      'remove-prefix': !this.removePrefix,
      [this.variant]: !!this.variant,
    };
  }

  render() {
    return html`<div class=${this.getClass()}>
      ${this.labelTemplate()} ${this.helpTextTemplate()}
      <div>
        ${this.removePrefix
          ? nothing
          : html`<span
              class="${this.classEl('symbol-prefix')}"
              aria-hidden="true"
              id="${this.currencyPrefixId}"
              >$</span
            >`}
        <input
          class="${this.classEl('input')}"
          type="text"
          inputmode="decimal"
          name="${this.name}"
          id="${this.fieldId || `${this.name}-${this.randomId}-field`}"
          required=${this.required || nothing}
          disabled=${this.disabled || nothing}
          hideLabel=${this.hideLabel}
          aria-invalid=${this.errorMessage ? 'true' : 'false'}
          aria-describedby=${`${this.currencyPrefixId} ${this.getAriaDescribedBy()}`}
          readonly=${this.readonly || nothing}
          value="${this.value || nothing}"
          @change=${this.handleChange}
          @input=${this.handleInput}
          @blur=${this.handleBlur}
          @focus=${this.handleFocus}
          @keydown=${this.handleKeydown}
        />${this.errorMessageTemplate()}
      </div>
    </div>`;
  }
}
