import { property, query } from 'lit/decorators.js';
import { PdsGlobalHeaderDropdownItem } from '../global-header-dropdown-item/global-header-dropdown-item';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import styles from './global-header-user-menu-dropdown-view.scss?inline';

/**
 * @summary This component is a dropdown item that is only meant to be utilized within the global header. It should have all the functionalities
 * of a PdsGlobalHeaderDropdownItem but is styled differently.
 *
 * @slot label Required: The label for the user menu, restricted to pds-global-header-user-menu-label
 */
@customElement('pds-global-header-user-menu-dropdown-view', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsGlobalHeaderUserMenuDropdownView extends PdsGlobalHeaderDropdownItem {
  @property({ type: Boolean })
  current: false;

  @query('.pds-c-global-header-user-menu-dropdown-view__menu')
  menu: HTMLElement;

  @query('.pds-c-global-header-user-menu-dropdown-view__button')
  button: HTMLElement;

  firstUpdated() {
    super.firstUpdated();
    if (this.shadowRoot) {
      const dropdownWrapper = this.shadowRoot.querySelector(
        '.pds-c-global-header-user-menu-dropdown-view',
      );
      const srLabel = document.createElement('span');
      srLabel.classList.add(`${this.classEl('sr-only')}`, 'pds-u-sr-only');
      srLabel.setAttribute('id', 'notification-sr-id');
      if (dropdownWrapper) {
        dropdownWrapper.appendChild(srLabel);
      }
    }
  }
}
