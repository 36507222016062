// disable requiredSlot lint until requiredSlot error can be investigated for this component (causes other components that use this as a base class to fail tests)
/* eslint-disable @nx/workspace-enforce-required-slot-decorator */

import { PropertyValueMap, html, nothing } from 'lit';
import {
  property,
  query,
  queryAssignedElements,
  queryAssignedNodes,
  state,
} from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import { PdsElement } from '../PdsElement';
import styles from './link.scss?inline';
import { handleExternalLink } from './link-utils';
import { getAriaDescribedByElementIds } from '../../../utils/get-aria-described-by-element-ids';

const size = ['default', 'sm', 'lg', 'xl'] as const;
export type LinkSize = (typeof size)[number];

/**
 * @summary This component renders a styled anchor tag
 *
 * @slot icon-left Optional: Holds an icon to the left of the link, restricted to pds-icon
 * @slot default Required: Holds the link contents
 * @slot icon-right Optional: Holds an icon to the right of the link, restricted to pds-icon
 *
 * @fires pds-link-click A custom event dispatched on click
 */
@customElement('pds-link', {
  category: 'component',
  type: 'component',
  state: 'stable',
  styles,
})
export class PdsLink extends PdsElement {
  // TODOv4: remove deprecated emphasis variants
  /**
   * - **default** renders link for basic action
   * - **DEPRECATED**
   *     - **emphasis** provide more affordance
   *     - **emphasis-inverted** provide more affordance on a darker background
   * - **inverted** used on a darker background
   * - **strong** provide more affordance
   * - **strong-inverted** provide more affordance on a darker background
   * - **subtle** used for less important actions
   * - **subtle-inverted** used for less important actions on a darker background
   *
   */
  @property()
  // TODOv4: remove deprecated emphasis variants
  variant:
    | 'default'
    | 'emphasis'
    | 'inverted'
    | 'emphasis-inverted'
    | 'subtle'
    | 'subtle-inverted'
    | 'strong'
    | 'strong-inverted' = 'default';

  /**
   * - **default** renders default size
   * - **sm** smaller size
   * - **lg** larger size (not valid for link button)
   * - **xl** extra large size (not valid for link button)
   */
  @property()
  size: LinkSize = 'default';

  /**
   * Redirect url
   */
  @property({ reflect: true })
  href: string;

  /**
   * Indicates that the link references a file to download, see https://www.w3schools.com/tags/att_a_download.asp
   */
  @property()
  download?: string | boolean;

  /**
   * Specifies information about a linked document
   * Automatically set to 'noopener noreferrer' when target is '_blank'
   */
  @property()
  rel?: string;

  /**
   * Specifies the two-character language code of the document in the link
   */
  @property()
  hreflang?: string;

  /**
   * Specifes target to open the linked document
   */
  @property({ reflect: true })
  target?: '_self' | '_blank' | '_parent' | '_top';

  /**
   * Specifies an aria-label for the link
   */
  @property()
  ariaLabel: string;

  /**
   * Specifies an aria-current for the link
   */
  @property()
  ariaCurrent: 'page' | 'step' | 'location' | 'date' | 'time' | 'true';

  // TODOv4: Remove this property in favor of ariaDescribedbyIds
  /**
   * A space-separated list of element IDs that provide additional information to the user about the link
   * There needs to be an element with the same ID as the value of this property in the light DOM or else the aria-describedby attribute will not be set.
   * **DEPRECATED**
   */
  @property()
  ariaDescribedby: string;

  /**
   *
   * A space-separated list of element IDs that provide additional information to the user about the link
   * There needs to be an associated element with the same ID in the light DOM or else the aria-describedby attribute will not be set.
   */
  @property()
  ariaDescribedbyIds: string;

  /**
   * Specifies a role for the link
   */
  @property()
  role: string;

  /**
   * Specifies the Internet media type (formerly known as MIME type)
   * of the linked document for links, or the type of button if button
   * property is true
   */
  @property()
  type?: string | 'button' | 'reset' | 'submit';

  /**
   * Render the link as a button variant
   *
   * - **default** renders the button used for the most common calls to action that don't require as much visual attention.
   * - **default-inverted** renders a default button for use on darker backgrounds.
   * - **primary** renders the button used for the most important calls to action.
   * - **primary-inverted** renders a primary button for use on darker backgrounds.
   * - **icon** renders the button used for icon.
   * - **icon-inverted** renders the button for icons used on darker backgrounds.
   */
  @property()
  button:
    | 'default'
    | 'default-inverted'
    | 'primary'
    | 'primary-inverted'
    | 'icon'
    | 'icon-inverted'
    | '' = '';

  /**
   * Programatically indicate a link should display as hover state
   */
  @property({ type: Boolean })
  hover: boolean = false;

  /**
   * Checks to see if the icon size is valid
   * @internal
   */
  @state()
  isIconSizeValid: boolean = true;

  /**
   * Checks to see if the content is passed to default slot
   * @internal
   */
  @state()
  isSlotValid: boolean = true;

  /**
   * The full text of the link
   * @internal
   */
  @state()
  linkText: string | undefined;

  /**
   * The last word of the link text - used when there is a right icon in the link to append it
   * to the last word to prevent orphan icons when wrapping
   * @internal
   */
  @state()
  lastWord: string | undefined;

  handleClick(e: MouseEvent) {
    const customEvent = new CustomEvent('pds-link-click', {
      bubbles: true,
      composed: true,
      cancelable: true,
      detail: {
        summary: this.textContent,
      },
    });

    this.dispatchEvent(customEvent);

    if (customEvent.defaultPrevented) {
      e.preventDefault();
    }
  }

  handleSlotChange(e: Event) {
    // We could re-render here to make sure slot changes from
    // icon-left to icon-right are handled on the fly, but that's
    // likely not going to be a need for users.  If it ever does
    // become an issue, then we can address it here by re-rendering
    // with something like requestUpdate().
    this.handleSlotValidation(e);
    this.addSizeToLinkIcon();
    if (this.slotNotEmpty('icon-right')) {
      this.handleRightIconSlotChange();
    }
  }

  /**
   * If the user passes an icon in the right slot, remove the last word from the slotted text (it will be added back
   * in the render function in a span with the icon)
   * @internal
   */
  handleRightIconSlotChange() {
    let restOfLinkText = '';

    if (this.linkText) {
      // split the text into an array
      const linkTextArray = this.linkText.trim().split(' ');
      // remove the last word from the array
      linkTextArray.pop();
      // join the remaining words back together
      restOfLinkText = linkTextArray.join(' ');

      // if the slotted node contains the original text, clear it out so that it doesn't display when the
      // slotted content is overwritten with restofLinkText
      this.defaultSlotElement.forEach((node: any) => {
        if (node.textContent.trim() === this.linkText) {
          // eslint-disable-next-line no-param-reassign
          node.textContent = '';
        }
      });
    }

    // replace the slotted text with the string that doesn't include the last word (will be added in render)
    if (this.defaultSlotElement.length > 0) {
      this.defaultSlotElement[0].textContent = `${restOfLinkText} `;
    }
  }

  /**
   * Set the linkText state to the original text passed into the default slot before any changes are made
   * @internal
   */
  setLinkText() {
    let linkText = '';

    this.defaultSlotElement.forEach((node: any) => {
      linkText += node.textContent;
    });

    this.linkText = linkText.trim();
  }

  /**
   * @returns the last word of the link text
   * @internal
   */
  getLinkTextLastWord() {
    let linkText = '';
    this.defaultSlotElement.forEach((node: any) => {
      linkText += node.textContent;
    });

    // turn the title into an array
    const linkTextArray = linkText.trim().split(' ');
    // get the last word for attaching with the icon
    const lastWord = linkTextArray.pop();

    return lastWord;
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.variant]: !!this.variant,
      'icon-left': !!this.slotNotEmpty('icon-left'),
      'icon-right': !!this.slotNotEmpty('icon-right'),
      [this.size]: !!this.size,
      [`button-${this.button}`]: !!this.button,
      button: !!this.button,
      hover: !!this.hover,
    };
  }

  /**
   * @internal
   */
  @query('a')
  anchor: HTMLAnchorElement;

  /**
   * This grabs the icon-left slot
   * @internal
   */
  @queryAssignedElements({ slot: 'icon-left' })
  iconLeftList: HTMLElement[];

  /**
   * This grabs the icon-right slot
   * @internal
   */
  @queryAssignedElements({ slot: 'icon-right' })
  iconRightList: HTMLElement[];

  /**
   * This grabs the content from the default slot
   * @internal
   */
  @queryAssignedNodes({ slot: undefined })
  defaultSlotElement: any;

  addSizeToLinkIcon() {
    const icons = [...this.iconLeftList, ...this.iconRightList];
    if (icons && icons.length && size.includes(this.size)) {
      icons.forEach((icon) => {
        if (this.size && this.size === 'xl') {
          this.isIconSizeValid = false;
        } else if (this.size) {
          icon.setAttribute('size', this.size);
        }
      });
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.initLocalization();
  }

  defaultSlotValidation() {
    if (this.defaultSlotElement.length === 0) {
      this.isSlotValid = false;
    }
  }

  protected override firstUpdated() {
    super.firstUpdated();
    this.handleSlotValidation('icon-left');
    this.handleSlotValidation('icon-right');
    this.defaultSlotValidation();
    this.setLinkText();
    this.lastWord = this.getLinkTextLastWord();
  }

  async updated(
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>,
  ) {
    await this.updateComplete;
    this.addSizeToLinkIcon();

    if (this.slotNotEmpty('icon-right')) {
      this.handleRightIconSlotChange();
    }

    // TODOv4: Remove logic for ariaDescribedby property once we remove the property
    if (
      (changedProperties.has('ariaDescribedbyIds') &&
        this.ariaDescribedbyIds) ||
      (changedProperties.has('ariaDescribedby') && this.ariaDescribedby)
    ) {
      const ariaDescribedby = getAriaDescribedByElementIds(this);
      this.anchor.setAttribute('aria-describedby', ariaDescribedby);
    } else if (
      !this.ariaDescribedby &&
      !this.ariaDescribedbyIds &&
      this.target === '_blank'
    ) {
      this.anchor.setAttribute('aria-describedby', 'hyperlink-sr-label');
    }
  }

  render() {
    // TODOv4: remove this deprecation warning
    if (this.variant === 'emphasis' || this.variant === 'emphasis-inverted') {
      const newVariant =
        this.variant === 'emphasis-inverted' ? 'strong-inverted' : 'strong';
      console.warn(
        `The ${this.variant} link variant is deprecated and will be removed in the next major version of PDS.  Please use the ${newVariant} variant instead.`,
      );
    }

    // TODOv4: remove this deprecation warning
    if (this.ariaDescribedby) {
      console.warn(
        `The ariaDescribedby property is deprecated and will be removed in the next major version of PDS.  Please use the ariaDescribedbyIds instead.`,
      );
    }

    // https://docs.principal.com/display/FEDX/Use+noopener+and+noreferrer+when+opening+a+new+tab+or+window
    if (this.target === '_blank') {
      this.rel = 'noopener noreferrer';
    }

    if (!this.isSlotValid) {
      console.error(
        'PdsLink requires text content in the default slot to render the component.',
      );
      return nothing;
    }

    if (!this.isIconSizeValid) {
      console.error(
        'Icon size "xl" is not valid for an icon link. Please use a smaller size, like "lg".',
      );
      return nothing;
    }

    return html`<a
        class="${this.getClass()}"
        href=${ifDefined(this.href)}
        rel=${ifDefined(this.rel)}
        target="${ifDefined(this.target)}"
        part="link"
        download=${ifDefined(this.download)}
        aria-label=${ifDefined(this.ariaLabel)}
        aria-current=${ifDefined(this.ariaCurrent)}
        role=${ifDefined(this.role)}
        type=${ifDefined(this.type)}
        hreflang=${ifDefined(this.hreflang)}
        @click=${this.handleClick}
        >${this.slotNotEmpty('icon-left')
          ? html`<span class="pds-c-link__icon-left-wrapper">
              <slot
                allowed-elements="pds-icon, span"
                name="icon-left"
                @slotchange=${this.handleSlotChange}
              ></slot>
            </span>`
          : ''}
        <span class="pds-c-link__text" part="link-text"
          ><slot></slot>${this.slotNotEmpty('icon-right')
            ? html`<span class="pds-c-link__icon-right-wrapper">
                ${this.lastWord}
                <slot
                  allowed-elements="pds-icon, span"
                  name="icon-right"
                  @slotchange=${this.handleSlotChange}
                ></slot>
              </span>`
            : ''}</span
        ></a
      >${this.target === '_blank' ? handleExternalLink() : nothing}`;
  }
}
