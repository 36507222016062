// disable requiredSlot lint until testing issue can be resolved
/* eslint-disable @nx/workspace-enforce-required-slot-decorator */

/* eslint-disable import/no-duplicates */
import { PropertyValueMap, html, nothing } from 'lit';
import {
  property,
  query,
  queryAssignedElements,
  state,
} from 'lit/decorators.js';
import {
  computePosition,
  autoUpdate,
  platform,
  shift,
  arrow,
  flip,
  offset,
} from '@floating-ui/dom';
import * as focusTrap from 'focus-trap';
import { offsetParent } from 'composed-offset-position';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import styles from './action-menu.scss?inline';
import '@principal/design-system-icons-web/more-horizontal';
import '@principal/design-system-icons-web/chevron-down';
import { PdsElement } from '../PdsElement';
import { PdsButton, ButtonVariant, ButtonSize } from '../button/button';
import '../button/button';
import '../action-menu-item/action-menu-item';
import { PdsActionMenuItem } from '../action-menu-item/action-menu-item';

/**
 * @summary This component provides a set of menu items to the user when the action-menu button is clicked
 *
 * @slot default Required: Accepts subcomponent menu-items to be displayed in the menulist, restricted to pds-action-menu-item
 * @slot footer Optional: Accepts subcomponent menu-items to be displayed in the footer of the menulist i.e. after the seperator, restricted to pds-action-menu-item
 * @slot icon Required: Accepts icons to be displayed in button, restricted to pds-icon
 *
 * @fires pds-button-click A custom event dispatched on triggerButton click
 * @fires pds-action-menu-item-click A custom event dispatched on menu item click
 */
@customElement('pds-action-menu', {
  category: 'component',
  type: 'component',
  state: 'stable',
  styles,
})
export class PdsActionMenu extends PdsElement {
  /**
   * Style buttonVariant
   * - **default** renders the button used for the most common calls to action that don't require as much visual attention.
   * - **default-inverted** renders a default button for use on darker backgrounds.
   * - **primary** renders the button used for the most important calls to action.
   * - **primary-inverted** renders a primary button for use on darker backgrounds.
   * - **icon** renders the button used for icon.
   * - **icon-inverted** renders the button for icons used on darker backgrounds.
   */
  @property()
  buttonVariant: ButtonVariant = 'icon';

  /**
   * Small button
   */
  @property()
  size: ButtonSize = 'sm';

  /**
   * Removes a separator in the action menu item list
   */
  @property({ type: Boolean })
  hideSeparator: boolean = false;

  /**
   * Adds an aria-label to the action menu button
   */
  @property()
  buttonAriaLabel: string;

  /**
   * Adds a label to action menu list
   */
  @property()
  label: string;

  /**
   * Adds a label to trigger button
   */
  @property()
  buttonLabel: string;

  /**
   * This is used to open and close the action menu list
   */
  @property({ type: Boolean, reflect: true })
  open: boolean = false;

  /**
   * Checks to see if the action-menu has valid markup
   * @internal
   */
  @state()
  isValidActionMenu: boolean = true;

  /**
   * Checks if the action-menu filp
   @internal
   */
  @state()
  flip: boolean = false;

  /**
   * This grabs the pds-button element
   * @internal
   */
  @query('.pds-c-action-menu__button')
  triggerButton: PdsButton;

  /**
   * This grabs the action menu
   * @internal
   */
  @query('.pds-c-action-menu')
  actionMenu: HTMLElement;

  /**
   * This grabs the div element of action menu list
   * @internal
   */
  @query('.pds-c-action-menu__list')
  menuList: HTMLElement;

  /**
   * Label for action menu accessibility
   */
  @property()
  ariaLabelledBy: string = 'label';

  /**
   * This grabs the div element of arrow
   * @internal
   */
  @query('.pds-c-action-menu__arrow')
  arrow: HTMLElement;

  /**
   * @internal
   */
  @queryAssignedElements({ slot: 'footer' })
  footerSlot: HTMLElement[];

  /**
   * @internal
   */
  @state()
  trap: any;

  constructor() {
    super();
    this.handleOnClickOutsideActionMenuList =
      this.handleOnClickOutsideActionMenuList.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener(
      'mouseup',
      this.handleOnClickOutsideActionMenuList,
      false,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener(
      'mouseup',
      this.handleOnClickOutsideActionMenuList,
      false,
    );
    this.deactivateTrap();
  }

  createInstance() {
    if (this.triggerButton) {
      const arrowLen = this.arrow.offsetWidth;
      const isSafari =
        /^((?!chrome|android|firefox|edge|opera).)*safari/i.test(
          navigator.userAgent,
        ) || navigator.vendor.indexOf('Apple') > -1;
      autoUpdate(this.triggerButton, this.menuList, () => {
        computePosition(this.triggerButton, this.menuList, {
          placement: 'bottom',
          platform: {
            ...platform,
            getOffsetParent: (element) => {
              if (isSafari) {
                return platform.getOffsetParent(element, offsetParent);
              }
              return platform.getOffsetParent(element);
            },
          },
          middleware: [
            // offset of 28px will set the distance between the arrow and the trigger element according to our design
            offset(28),
            shift(),
            flip({
              fallbackPlacements: ['bottom', 'top'],
            }),
            arrow({ element: this.arrow }),
          ],
        }).then(({ x, y, middlewareData, placement }) => {
          Object.assign(this.menuList.style, {
            left: `${x}px`,
            top: `${y}px`,
          });
          const side = placement.split('-')[0];
          const staticSide: any = {
            top: 'bottom',
            bottom: 'top',
          }[side];

          this.flip = side === 'top';

          if (middlewareData.arrow) {
            const { x: arrowX, y: arrowY } = middlewareData.arrow;
            Object.assign(this.arrow.style, {
              left: `${arrowX}px`,
              top: `${arrowY}px`,
              [staticSide]: `${-arrowLen / 2 - 1}px`,
            });
          }
        });
      });
    }
  }

  showMenu() {
    this.initializeTrap();
    this.trap.activate();
    this.triggerButton.setAttribute('isActive', 'true');
    this.triggerButton.setAttribute('aria-haspopup', 'true');
    this.triggerButton.setAttribute('aria-expanded', 'true');
    this.menuList.setAttribute('show-menu', '');
    this.createInstance();
    this.menuList.addEventListener(
      'pds-action-menu-item-click',
      () => {
        this.open = false;
      },
      false,
    );
  }

  hideMenu() {
    this.deactivateTrap();
    this.triggerButton.removeAttribute('isActive');
    this.triggerButton.removeAttribute('aria-haspopup');
    this.triggerButton.setAttribute('aria-expanded', 'false');
    this.menuList.removeAttribute('show-menu');
    this.menuList.removeEventListener(
      'pds-action-menu-item-click',
      () => {
        this.open = false;
      },
      false,
    );
  }

  deactivateTrap() {
    if (this.trap && this.trap.deactivate) {
      this.trap.deactivate();
    }
  }

  /**
   * @internal
   * If the user passes in a custom ariaLabel, that will be populated.
   * If not, the buttonLabel will be used.
   * If neither are passed, an error will be logged, and the component will not render.
   */
  getAriaLabel() {
    if (!this.buttonAriaLabel && this.buttonLabel) {
      return this.buttonLabel;
    }
    return this.buttonAriaLabel;
  }

  /**
   * @internal
   * Initialize the focus trap
   */
  initializeTrap() {
    this.trap = focusTrap.createFocusTrap(this.actionMenu, {
      initialFocus: this.menuList,
      fallbackFocus: this.triggerButton,
      allowOutsideClick: true,
      tabbableOptions: {
        getShadowRoot: true,
      },
    });
  }

  protected override async updated(
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>,
  ): Promise<void> {
    if (changedProperties.has('open')) {
      if (this.open) {
        this.showMenu();
      } else {
        this.hideMenu();
      }
    }
    if (changedProperties.has('hideSeparator')) {
      this.addSeparator();
    }

    if (
      changedProperties.has('buttonAriaLabel') ||
      changedProperties.has('buttonLabel')
    ) {
      if (!this.buttonAriaLabel && !this.buttonLabel) {
        this.isValidActionMenu = false;
        console.error('Please provide a buttonAriaLabel for the action menu');
      } else {
        this.isValidActionMenu = true;
        await this.updateComplete;
        this.triggerButton.setAttribute('ariaLabel', this.getAriaLabel());
      }
    }
  }

  handleClick() {
    if (this.menuList.hasAttribute('show-menu')) {
      this.open = false;
    } else {
      this.open = true;
    }
  }

  handleOnClickOutsideActionMenuList(e: MouseEvent) {
    // If the action menu list is already closed then we don't care about outside clicks and we
    // can bail early
    if (!this.menuList.hasAttribute('show-menu')) {
      return;
    }

    // If clicking the action menu button again, bail here and let the toggle function take over
    if (this.triggerButton && e.composedPath().includes(this.triggerButton)) {
      return;
    }

    let didClickInside = false;

    // Check to see if we clicked inside the active action menu item
    if (this.menuList) {
      didClickInside = e.composedPath().includes(this.menuList);
    }

    // If the action menu list is active and we've clicked outside of the list then it should be closed.
    if (this.menuList.hasAttribute('show-menu') && !didClickInside) {
      this.open = false;
    }
  }

  /**
   * closes the action-menu on escape
   * @internal
   */
  handleKeydown(e: KeyboardEvent) {
    if (e.key === 'Escape' || e.key === 'Esc') {
      this.open = false;
    }
  }

  /**
   * Adds the separator when hideSeparator is false
   * @internal
   */
  addSeparator() {
    const actionMenuItem = this.footerSlot[0];
    if (actionMenuItem) {
      if (!this.hideSeparator) {
        actionMenuItem.classList.add(
          (actionMenuItem as PdsActionMenuItem).classEl('separator-above'),
        );
      } else {
        actionMenuItem.classList.remove(
          (actionMenuItem as PdsActionMenuItem).classEl('separator-above'),
        );
      }
    }
  }

  /**
   * @internal
   *
   * Checks if the user has provided a buttonAriaLabel or a buttonLabel
   * If either are provided, it sets the ariaLabel on the triggerButton accordingly
   * If neither buttonAriaLabel nor buttonLabel is passed, an error will be logged, and the component will not render.
   */
  checkButtonAriaLabel() {
    if (!this.buttonAriaLabel && !this.buttonLabel) {
      this.isValidActionMenu = false;
      console.error('Please provide a buttonAriaLabel for the action menu');
    } else {
      this.triggerButton.setAttribute('ariaLabel', this.getAriaLabel());
    }
  }

  protected override firstUpdated() {
    super.firstUpdated();
    this.handleSlotValidation('icon');
    this.handleSlotValidation('footer');
    this.handleSlotValidation();
    // TODOv4: If we are removing NSKv1 support, move checkButtonAriaLabel out of the setTimeout and delete the setTimeout function.
    // This isn't needed once we drop NSK v1 support.  NSK v2 handles this properly.
    setTimeout(async () => {
      this.checkButtonAriaLabel();
    }, 1000);
  }

  /**
   * opens the action-menu on arrowDown, space and enter
   * @internal
   */
  async openMenuOnKeydown(e: KeyboardEvent) {
    if (e.code === 'Space' || e.key === 'Enter' || e.code === 'Enter') {
      e.preventDefault();
      if (this.triggerButton.hasAttribute('isActive')) {
        this.open = false;
        await this.updateComplete;
      } else {
        this.open = true;
        await this.updateComplete;
      }
    }
    if (e.key === 'ArrowDown' || e.code === 'ArrowDown') {
      e.preventDefault();
      this.open = true;
      await this.updateComplete;
    }
    this.handleKeydown(e);
  }

  handleSlotChange(e: Event) {
    this.handleSlotValidation(e);
    this.addSeparator();
  }

  renderButton() {
    const iconButton =
      this.buttonVariant === 'icon' || this.buttonVariant === 'icon-inverted';

    return html`<pds-button
      type="button"
      variant="${this.buttonVariant}"
      size="${this.size}"
      class="${this.classEl('button')}"
      ariaExpanded="${this.open}"
      ariaLabel=${this.getAriaLabel()}
      @keydown=${this.openMenuOnKeydown}
      isActive="${this.open ? 'true' : nothing}"
      @click=${this.handleClick}
      >${iconButton
        ? html`<slot
            name="icon"
            allowed-elements="pds-icon"
            @slotchange=${this.handleSlotValidation}
            ><pds-icon-more-horizontal></pds-icon-more-horizontal
          ></slot>`
        : html`<span>${this.buttonLabel}</span
            ><slot
              name="icon"
              allowed-elements="pds-icon"
              @slotchange=${this.handleSlotValidation}
              ><pds-icon-chevron-down size="sm" class="${this.classEl('icon')}">
              </pds-icon-chevron-down
            ></slot>`}</pds-button
    >`;
  }

  /**
   * @internal
   */
  get classNames() {
    return {
      [this.buttonVariant]: !!this.buttonVariant,
      flip: !!this.flip,
    };
  }

  render() {
    if (!this.isValidActionMenu) {
      return nothing;
    }

    return html`<div class="${this.getClass()}">
      <section class="${this.classEl('section')}">
        ${this.renderButton()}
      </section>
      <div class="${this.classEl('list')}">
        ${this.label
          ? html`<span
              class="${this.classEl('label')}"
              id=${this.ariaLabelledBy}
              >${this.label}</span
            >`
          : nothing}
        <ul aria-labelledby=${this.ariaLabelledBy} role="list">
          <slot
            @keydown=${this.handleKeydown}
            allowed-elements="pds-action-menu-item"
            @slotchange=${this.handleSlotValidation}
          ></slot>
          <slot
            name="footer"
            @keydown=${this.handleKeydown}
            allowed-elements="pds-action-menu-item"
            @slotchange=${this.handleSlotChange}
          ></slot>
        </ul>
        <div class="${this.classEl('arrow')}"></div>
      </div>
    </div>`;
  }
}
