import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { pdsCustomElement as customElement } from '../../decorators/pds-custom-element';
import styles from './footer-legal-link.scss?inline';
import { PdsLink } from '../link/link';
import { requiredSlot } from '../../decorators/requiredSlot';

/**
 * @summary This component contains default or customizable legal links to be used within the PdsFooter
 *
 * @slot default Required: Contains the display text of custom links
 * @slot icon-right Optional: Inherited from PdsLink, not applicable
 * @slot icon-left Optional: Inherited from PdsLink, not applicable
 */

@customElement('pds-footer-legal-link', {
  category: 'component',
  type: 'component',
  styles,
})
export class PdsFooterLegalLink extends PdsLink {
  @property()
  override variant:
    | 'subtle'
    | 'default'
    | 'emphasis'
    | 'inverted'
    | 'emphasis-inverted'
    | 'subtle-inverted'
    | 'strong'
    | 'strong-inverted' = 'subtle';

  @requiredSlot(['default'])
  override render() {
    return html` <li>${super.render()}</li> `;
  }
}
