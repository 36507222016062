import React, { forwardRef } from "react";
import {
    PdsAlert,
    PdsButton,
    PdsAccordion,
    PdsGrid,
    PdsGridItem,
    PdsModal,
} from "@principal/design-system-react";
import { PaymentPreferenceType } from "../enums/PaymentPreferenceType";
import { State } from "../enums/State";
import { useApolloClient, useMutation } from "@apollo/client";
import {
    CREATE_CHECK_PAYMENT_PREFERENCE_MUTATION,
    CREATE_EFT_PAYMENT_PREFERENCE_MUTATION, VALIDATE_ADDRESS_QUERY,
    VALIDATE_ROUTING_NUMBER_QUERY
} from "../atoms/Queries";
import { Form, Formik, FormikProps } from "formik";
import { BankAccountType, ValidatedAddress } from "../generated/graphql";
import { object, ref as yupRef, string } from "yup";
import CheckGraphic from "../images/check.jpg"
import SelectDropdown from "../atoms/formik/SelectDropdown";
import TextInput from "../atoms/formik/TextInput";
import Checkbox from "../atoms/formik/Checkbox";

interface PaymentPreferenceValues {
    paymentPreference: PaymentPreferenceType,
    streetAddress: string,
    city: string,
    state: string,
    zipCode: string,
    correctedAddress: ValidatedAddress,
    useEnteredAddress: boolean,
    accountNumber: string,
    accountNumber2: string,
    routingNumber: string,
    accountType: BankAccountType
    eftConsent: boolean
}

const PaymentPreferenceModal = forwardRef((props: Partial<React.ComponentProps<typeof PdsModal>>, ref: React.Ref<any>) => {
    const [createCheckPaymentPreference, {
        data: checkPaymentPreferenceData,
        loading: checkPaymentPreferenceLoading,
        error: checkPaymentPreferenceError,
        reset: checkPaymentPreferenceReset
    }] = useMutation(CREATE_CHECK_PAYMENT_PREFERENCE_MUTATION)
    const [createEftPaymentPreference, {
        data: eftPaymentPreferenceData,
        loading: eftPaymentPreferenceLoading,
        error: eftPaymentPreferenceError,
        reset: eftPaymentPreferenceReset
    }] = useMutation(CREATE_EFT_PAYMENT_PREFERENCE_MUTATION)
    const apolloClient = useApolloClient()

    const loading = checkPaymentPreferenceLoading || eftPaymentPreferenceLoading;

    const initialValues = {
        paymentPreference: PaymentPreferenceType.EFT,
        streetAddress: '',
        city: '',
        state: State.AK,
        zipCode: '',
        correctedAddress: {},
        useEnteredAddress: false,
        accountNumber: '',
        accountNumber2: '',
        routingNumber: '',
        accountType: BankAccountType.Checking,
        eftConsent: false
    } as PaymentPreferenceValues;

    return (<PdsModal {...props} ref={ref} size='lg' modalTitle="Update claims payment preference">
        <div className="flex-gap">
            <div>We can deposit funds directly in your bank account (faster) or send you a check.</div>
            <div>Note that changing your payment preference will direct all of your claim payments to that location, except in certain circumstances. Please call us at 800-245-1522 or e-mail SBDClaims@principal.com for assistance with directing your claim payments.</div>
            <Formik initialValues={initialValues}
                    validationSchema={object({
                        zipCode: string()
                            .matches(/^[0-9]+$/, "ZIP code must be only digits.")
                            .min(5, 'ZIP code must be 5 digits.')
                            .max(5, 'ZIP code must be 5 digits.'),
                        routingNumber: string()
                            .matches(/^[0-9]+$/, "Routing number must be only digits.")
                            .min(9, 'Routing number must be 9 digits.')
                            .max(9, 'Routing number must be 9 digits.'),
                        accountNumber2: string().oneOf([yupRef('accountNumber')], 'The account numbers must match.')
                    })}
                    onSubmit={async (values, { setFieldError }) => {
                        checkPaymentPreferenceReset()
                        eftPaymentPreferenceReset()
                        const correctedAddress = await findCorrectedAddressFor(values)
                        if(correctedAddress) {
                            setFieldError('correctedAddress', correctedAddress as any as string)
                            return;
                        }
                        const validRoutingNumber = await validateRoutingNumberFor(values);
                        if(!validRoutingNumber) {
                            setFieldError('routingNumber', 'Please enter a valid routing number.');
                            return;
                        }
                        if(PaymentPreferenceType.CHECK === values.paymentPreference) {
                            await createCheckPaymentPreference({
                                variables: {
                                    createCheckPaymentPreference: {
                                        addressLine1: values.streetAddress,
                                        city: values.city,
                                        state: values.state,
                                        postalCode: values.zipCode
                                    }
                                }
                            })
                        } else if(PaymentPreferenceType.EFT === values.paymentPreference) {
                            await createEftPaymentPreference({
                                variables: {
                                    createEftPaymentPreference: {
                                        bankAccountNumber: values.accountNumber,
                                        bankRoutingNumber: values.routingNumber,
                                        bankAccountType: values.accountType
                                    }
                                }
                            })
                        }
                    }}>
                {(formikProps) => {
                    const { values, setFieldValue } = formikProps;
                    return <Form className="flex-gap">
                        <SelectDropdown id="payment-preference" label="Payment preference" required={true} changeCallback={(target) => {
                            const value = (target as HTMLInputElement)?.value;
                            if(PaymentPreferenceType.CHECK === value) {
                                setFieldValue('accountNumber', '')
                                setFieldValue('accountNumber2', '')
                                setFieldValue('routingNumber', '')
                                setFieldValue('accountType', BankAccountType.Checking)
                                setFieldValue('eftConsent', false)
                            } else if(PaymentPreferenceType.EFT === value) {
                                setFieldValue('streetAddress', '')
                                setFieldValue('city', '')
                                setFieldValue('state', State.AK)
                                setFieldValue('zipCode', '')
                            }
                        }}>
                            {Object.keys(PaymentPreferenceType).map(paymentPreferenceType => {
                                const paymentPreference = (PaymentPreferenceType as any)[paymentPreferenceType];
                                return <option value={paymentPreference} key={paymentPreference}>{paymentPreference}</option>
                            })}
                        </SelectDropdown>
                        {PaymentPreferenceType.CHECK === values.paymentPreference && <>
                            <TextInput id="street-address" label="Street address" required={true}/>
                            <PdsGrid variant="2up">
                                <PdsGridItem>
                                    <TextInput id="city" label="City" required={true}/>
                                </PdsGridItem>
                                <PdsGridItem>
                                    <PdsGrid variant="2up">
                                        <PdsGridItem>
                                            <SelectDropdown id="state" label="State" required={true}>
                                                {Object.keys(State)
                                                    .filter(key => !["UNKNOWN", "NA"].includes(key))
                                                    .map(state => <option value={state} key={state}>{state}</option>)}
                                            </SelectDropdown>
                                        </PdsGridItem>
                                        <PdsGridItem>
                                            <TextInput id="zip-code" label="ZIP" required={true} maxLength={5}/>
                                        </PdsGridItem>
                                    </PdsGrid>
                                </PdsGridItem>
                            </PdsGrid>
                        </>}
                        {PaymentPreferenceType.EFT === values.paymentPreference && <>
                            <PdsAccordion>
                                <span slot="summary-title">How to find your routing number and account number</span>
                                <span slot="accordion-content" className="flex-gap">
                                        <div>You can find your routing number and account number at the bottom of your check, or from your bank's website.</div>
                                        <img src={CheckGraphic} alt="Check graphic" style={{ maxWidth: "100%", maxHeight: "100%" }}/>
                                    </span>
                            </PdsAccordion>
                            <PdsGrid variant="2up">
                                <PdsGridItem>
                                    <TextInput id="account-number" label="Account number" required={true} maxLength={17}/>
                                </PdsGridItem>
                                <PdsGridItem>
                                    <TextInput id="account-number-2" label="Re-type account number" required={true} maxLength={17}/>
                                </PdsGridItem>
                            </PdsGrid>
                            <TextInput id="routing-number" label="Routing number" required={true} maxLength={9}/>
                            <SelectDropdown id="account-type" label="Account type" required={true}>
                                {Object.entries(BankAccountType)
                                    .map(([key, value]) => <option value={value} key={key}>{key}</option>)}
                            </SelectDropdown>
                            <Checkbox id="eft-consent"
                                      label="I agree to the terms of the authorization agreement for electronic funds transfer"
                                      required={true}/>
                            {buildEftAuthorizationAgreement()}
                        </>}
                        {!!(checkPaymentPreferenceData?.createCheckPaymentPreference || eftPaymentPreferenceData?.createEftPaymentPreference)?.paymentPreferenceId
                            && <PdsAlert variant="success">Payment preference has been updated successfully.</PdsAlert>}
                        {(checkPaymentPreferenceError || eftPaymentPreferenceError)
                            && <PdsAlert variant="error">Updating payment preference failed. Please verify your information and try again.</PdsAlert>}
                        <PdsButton type="submit" disabled={loading}>
                            {loading ? 'Updating...' : 'Update'}
                        </PdsButton>
                        {buildAddressSuggestions(formikProps)}
                    </Form>;
                }}
            </Formik>
        </div>
    </PdsModal>);

    async function findCorrectedAddressFor(values: PaymentPreferenceValues) {
        const { paymentPreference, useEnteredAddress, streetAddress, city, state, zipCode } = values
        if (PaymentPreferenceType.CHECK !== paymentPreference || !streetAddress || !city || !state || 5 !== zipCode?.length || useEnteredAddress) {
            return;
        }
        try {
            const validatedAddress = await apolloClient.query({
                query: VALIDATE_ADDRESS_QUERY,
                variables: {
                    validateAddressInput: {
                        streetAddress,
                        city,
                        state,
                        zipCode
                    }
                }
            });
            const uspsAddress = validatedAddress.data?.validateAddress
            if (uspsAddress
                && (uspsAddress.streetAddress.toUpperCase() !== streetAddress.toUpperCase()
                    || uspsAddress.city.toUpperCase() !== city.toUpperCase()
                    || uspsAddress.state.toUpperCase() !== state.toUpperCase()
                    || uspsAddress.zipCode.toUpperCase() !== zipCode.toUpperCase())) {
                return uspsAddress;
            }
        } catch(e) {
            console.log(e)
            return null;
        }
    }

    async function validateRoutingNumberFor(values: PaymentPreferenceValues) {
        const { paymentPreference, routingNumber } = values
        if (PaymentPreferenceType.EFT !== paymentPreference || 9 !== routingNumber?.length) {
            return true;
        }
        try {
            const routingNumberResult = await apolloClient.query({
                query: VALIDATE_ROUTING_NUMBER_QUERY,
                variables: {
                    routingNumber
                }
            })
            return routingNumberResult.data.validateRoutingNumber
        } catch(e) {
            console.log(e)
            return false;
        }
    }

    function buildAddressSuggestions(formikProps: FormikProps<PaymentPreferenceValues>) {
        const { values, setFieldValue, errors } = formikProps;
        const correctedAddress = errors.correctedAddress;

        if(!correctedAddress || values.useEnteredAddress) {
            return null;
        }
        return <div className="flex-gap">
            <div>We are unable to verify your address as entered, but found a close match. Please confirm the correct address.</div>
            <PdsGrid variant="2up">
                <PdsGridItem>
                    <div className="flex-gap">
                        <strong>Entered Address:</strong>
                        <div>
                            <div>{values.streetAddress}</div>
                            <div>{values.city + ', ' + values.state + ' ' + values.zipCode}</div>
                        </div>
                        <PdsButton type="submit"
                                   onClick={async () => {
                                       setFieldValue('useEnteredAddress', true);
                                   }}>
                            Select Address
                        </PdsButton>
                    </div>
                </PdsGridItem>
                <PdsGridItem>
                    <div className="flex-gap">
                        <strong>Suggested Address:</strong>
                        <div>
                            <div>{correctedAddress?.streetAddress}</div>
                            <div>{correctedAddress?.city + ', ' + correctedAddress?.state + " " + correctedAddress?.zipCode}</div>
                        </div>
                        <PdsButton type="submit"
                                   onClick={async () => {
                                       setFieldValue('streetAddress', correctedAddress?.streetAddress);
                                       setFieldValue('city', correctedAddress?.city);
                                       setFieldValue('state', correctedAddress?.state);
                                       setFieldValue('zipCode', correctedAddress?.zipCode);
                                   }}>
                            Select Address
                        </PdsButton>
                    </div>
                </PdsGridItem>
            </PdsGrid>
        </div>;
    }

    function buildEftAuthorizationAgreement() {
        return <PdsAccordion>
            <span slot="summary-title">Authorization agreement for electronic funds transfer</span>
            <span slot="accordion-content">
            <div>I Hereby Authorize:</div>
            <ul>
                <li>
                    The Company to initiate credit entries to my account, at the financial institution named above
                    (herein called Bank).
                </li>
                <li>
                    The Company, if necessary, to initiate debit entries and adjustments to correct any credit entries
                    made in error.
                </li>
                <li>
                    The Bank to credit and/or debit entries to my account.
                </li>
            </ul>
            <div>This Authorization:</div>
            <ul>
                <li>
                    Applies to any payments that hereafter become due and payable to me under the provisions of the
                    contract(s) identified by the above Account Number.
                </li>
                <li>
                    This authorization is to remain in full force and effect until Principal Life Insurance Company has
                    written notice from me of its termination.
                </li>
                <li>
                    I understand and agree that any payment(s) made into an incorrect bank account pursuant to the
                    information reported on this form, will be forfeited by me and that Principal Life Insurance has no
                    obligation to retrieve those funds or make replacement payment(s) to me.
                </li>
            </ul>
        </span>
        </PdsAccordion>;
    }
})

export default PaymentPreferenceModal;
